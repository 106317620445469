import axios from 'axios';

const TIRE_REPLACEMENTS_PATH_ENDING = '/api/tire';

class TireService {
  async getTireReplacements(parameters) {
    const url = `${TIRE_REPLACEMENTS_PATH_ENDING}/${encodeURIComponent(parameters.serviceOrderId)}/${encodeURIComponent(
      parameters.unitId
    )}/`;
    const tireReplacements = await axios.get(url);
    return tireReplacements;
  }
  async saveUpdateTireReplacements(parameters) {
    const url = `${TIRE_REPLACEMENTS_PATH_ENDING}`;
    await axios.post(url, parameters);
  }
}

export default new TireService();
