import namespaceHelper from '@/shared/helpers/namespace-helper';
import UnitStatusHistoryService from '@/shared/services/UnitStatusHistoryService';
import { namespace, UnitStatusHistoryActions, UnitStatusHistoryMutations } from './types';

const _actions = namespaceHelper.removeNamespace(namespace, UnitStatusHistoryActions);
const _mutations = namespaceHelper.removeNamespace(namespace, UnitStatusHistoryMutations);

export default {
  async [_actions.FETCH_UNIT_STATUS_HISTORY]({ commit }, unitId) {
    const unitStatusHistory = await UnitStatusHistoryService.fetchUnitStatusHistory(unitId);
    commit(_mutations.SET_UNIT_STATUS_HISTORY, unitStatusHistory.data);
  }
};
