import { ASIDES } from '@/shared/components/floating-actions/FloatingActionsComponent';

export default {
  data: function () {
    return {
      activeAside: ASIDES.CLOSED,
      ASIDES: ASIDES
    };
  },
  computed: {
    showAside() {
      return this.activeAside !== ASIDES.CLOSED;
    }
  },
  methods: {
    openAside(asideNumber) {
      this.activeAside = asideNumber;
    },
    async changeAside(asideNumber, doChange = true) {
      if (doChange) {
        this.activeAside = asideNumber;
      }
    },
    closeAside() {
      this.activeAside = ASIDES.CLOSED;
    }
  }
};
