import namespaceHelper from '@/shared/helpers/namespace-helper';
import AttachmentService from '@/shared/services/AttachmentService';
import { namespace, AttachmentActions, AttachmentMutations } from './types';

const _actions = namespaceHelper.removeNamespace(namespace, AttachmentActions);
const _mutations = namespaceHelper.removeNamespace(namespace, AttachmentMutations);

export default {
  // Attachments
  async [_actions.FETCH_ATTACHMENTS]({ commit }, { key, route, isWarrantyAside }) {
    const results = await AttachmentService.getAttachments({ route });
    const attachments = results.data;
    if (isWarrantyAside) {
      commit(_mutations.SET_WARRANTY_ASIDE_ATTACHMENTS, { key, attachments });
    } else {
      commit(_mutations.SET_ATTACHMENTS, { key, attachments });
    }
  },
  async [_actions.UPLOAD_ATTACHMENTS]({ commit }, { key, route, files }) {
    const uploadResults = await AttachmentService.uploadAttachments({ route, files });
    const attachments = uploadResults.data;
    commit(_mutations.SET_ATTACHMENTS, { key, attachments });
    return attachments;
  },
  async [_actions.UPDATE_ATTACHMENTS]({ commit }, { key, route, attachments }) {
    const updateResults = await AttachmentService.updateAttachments({ route, attachments });
    const updatedAttachments = updateResults.data;
    commit(_mutations.SET_ATTACHMENTS, { key, attachments: updatedAttachments });
  },
  async [_actions.DOWNLOAD_ATTACHMENT](store, { route, attachment }) {
    const attachmentExportResult = await AttachmentService.downloadAttachment({ route, attachment });
    return attachmentExportResult.data;
  },
  async [_actions.REMOVE_ATTACHMENT]({ commit }, { key, route, attachment }) {
    const result = await AttachmentService.removeAttachment({ route, attachment });
    const updatedAttachments = result.data;
    commit(_mutations.SET_ATTACHMENTS, { key, attachments: updatedAttachments });
  }
};
