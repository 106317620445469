import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, NoteMutations } from './types';
import Cloner from '@/shared/helpers/cloner';
import { getDefaultState } from './state';

const _mutations = namespaceHelper.removeNamespace(namespace, NoteMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_NOTES](state, notes) {
    state.notes = Cloner.deepClone(notes);
  },
  [_mutations.SET_OLD_NOTE](state, note) {
    state.oldNote = Cloner.deepClone(note);
  },
  [_mutations.SET_NEW_NOTE](state, note) {
    state.newNote = Cloner.deepClone(note);
  }
};
