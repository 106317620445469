var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"py-2"},[_c('div',{staticClass:"section-title"},[_vm._v("Operations")]),(_vm.unitStandardOperations.length > 0)?_c('div',[_c('vams-collapse',{attrs:{"title":"Scheduled Operations","visible":_vm.visible}},[_c('b-table',{staticClass:"border",attrs:{"items":Object.values(_vm.$v.unitStandardOperations.$each.$iter),"fields":_vm.fields,"table-variant":"blue","thead-tr-class":"text-small","tbody-tr-class":"bg-half-alpha"},scopedSlots:_vm._u([{key:"head(selected)",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(Object.keys(_vm.assignedJobs).length == 0),expression:"Object.keys(assignedJobs).length == 0"}],staticClass:"text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectAll),expression:"selectAll"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.selectAll)?_vm._i(_vm.selectAll,null)>-1:(_vm.selectAll)},on:{"change":function($event){var $$a=_vm.selectAll,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectAll=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectAll=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectAll=$$c}}}})])]},proxy:true},{key:"cell(selected)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(!item.$model.isAssigned),expression:"!item.$model.isAssigned"},{name:"model",rawName:"v-model",value:(_vm.selectedStdOps),expression:"selectedStdOps"}],attrs:{"type":"checkbox"},domProps:{"value":item.$model,"checked":Array.isArray(_vm.selectedStdOps)?_vm._i(_vm.selectedStdOps,item.$model)>-1:(_vm.selectedStdOps)},on:{"change":function($event){var $$a=_vm.selectedStdOps,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item.$model,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedStdOps=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedStdOps=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedStdOps=$$c}}}}),_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(item.$model.isAssigned),expression:"item.$model.isAssigned"}],staticClass:"text-success",attrs:{"icon":"check"}})],1)]}},{key:"cell(excedeJobId)",fn:function(ref){
var item = ref.item;
return [(!item.$model.isAssigned)?_c('b-input-group',[_c('v-select',{class:item.excedeJob.$error ? 'is-invalid' : '',attrs:{"filter-by":_vm.excedeJobDropdownSearchFilter,"placeholder":"Select","options":item.$model.excedeJobOptions,"clearable":false,"label":"excedeJobId","select-on-tab":""},scopedSlots:_vm._u([{key:"option",fn:function(excedeJob){return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(excedeJob.excedeJobId)+" - "+_vm._s(excedeJob.description))])]}},{key:"selected-option",fn:function(selectedExcedeJob){return [_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(selectedExcedeJob.excedeJobId)+" - "+_vm._s(selectedExcedeJob.description)+" ")])]}}],null,true),model:{value:(item.$model.excedeJob),callback:function ($$v) {_vm.$set(item.$model, "excedeJob", $$v)},expression:"item.$model.excedeJob"}}),_c('span',{staticClass:"required-asterisk",style:({ visibility: item.$model.isSelected ? 'visible' : 'hidden' })},[_vm._v(" * ")])],1):_vm._e(),(item.$model.isAssigned)?_c('div',[_vm._v(" "+_vm._s(item.$model.excedeJob.excedeJobId)+" - "+_vm._s(item.$model.excedeJob.description)+" ")]):_vm._e()]}},{key:"cell(current)",fn:function(){return [_c('div',{staticClass:"text-left d-flex flex-column"},[_c('div',[_vm._v(_vm._s(_vm._f("number")(_vm.unit.mileage))+" Miles")]),_c('div',[_vm._v(_vm._s(_vm._f("number")(_vm.unit.engineHours))+" Hours")])])]},proxy:true},{key:"cell(lastCompleted)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left d-flex flex-column"},[(item.$model.lastCompletedDate)?_c('div',[_vm._v(_vm._s(_vm._f("date")(item.$model.lastCompletedDate)))]):_vm._e(),(item.$model.lastCompletedMileage)?_c('div',[_vm._v(_vm._s(_vm._f("number")(item.$model.lastCompletedMileage))+" Miles")]):_vm._e(),(item.$model.lastCompletedEngineHours)?_c('div',[_vm._v(" "+_vm._s(_vm._f("number")(item.$model.lastCompletedEngineHours))+" Hours ")]):_vm._e()])]}},{key:"cell(nextDue)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left d-flex flex-column"},[(item.$model.durationDue && item.$model.lastCompletedDate)?_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(_vm.getNextDueDate(item.$model)))+" ")]):_vm._e(),(item.$model.mileageDue && item.$model.lastCompletedMileage)?_c('div',[_vm._v(" "+_vm._s(_vm._f("number")(_vm.getNextDueMileage(item.$model)))+" Miles ")]):_vm._e(),(item.$model.engineHoursDue && item.$model.lastCompletedEngineHours)?_c('div',[_vm._v(" "+_vm._s(_vm._f("number")(_vm.getNextDueEngineHours(item.$model)))+" Hours ")]):_vm._e()])]}},{key:"cell($model.operationStatus)",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{directives:[{name:"show",rawName:"v-show",value:(value),expression:"value"}],staticClass:"mr-1",attrs:{"variant":_vm.CssHelper.getOperationStatusCssClass(value)}},[_vm._v(" "+_vm._s(value)+" ")])]}}],null,true)})],1)],1):_vm._e(),(_vm.formStage >= _vm.FORM_STAGES.EDIT_SO && _vm.hasDeferredJobs)?_c('div',{staticClass:"pb-3"},[_c('service-order-add-deferred-jobs',{attrs:{"visible":_vm.visible}})],1):_vm._e(),_c('b-row',{staticClass:"pt-3",attrs:{"no-gutters":"","cols-lg":"3"}},[_c('b-col',[_c('v-select',{attrs:{"label":"description","placeholder":"Select Operation","options":_vm.nonScheduledOperationsList,"clearable":true,"filterable":true,"filter-by":_vm.operationDropdownSearchFilter},scopedSlots:_vm._u([{key:"selected-option",fn:function(operation){return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(operation.id)+" - "+_vm._s(operation.description))])]}},{key:"option",fn:function(operation){return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(operation.id)+" - "+_vm._s(operation.description))])]}}]),model:{value:(_vm.selectedNonScheduledOp),callback:function ($$v) {_vm.selectedNonScheduledOp=$$v},expression:"selectedNonScheduledOp"}})],1),_c('div',{staticClass:"ml-3"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.formStage !== _vm.FORM_STAGES.EDIT_SO},on:{"click":_vm.assignJobs}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.formStage !== _vm.FORM_STAGES.ADDING_JOBS),expression:"formStage !== FORM_STAGES.ADDING_JOBS"}]},[_vm._v("Add To "+_vm._s(_vm.estimorderDisplayText))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.formStage === _vm.FORM_STAGES.ADDING_JOBS),expression:"formStage === FORM_STAGES.ADDING_JOBS"}]},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Adding... ")],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }