import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, PartsRequestActions, PartsRequestMutations } from './types';
import PartsRequestService from '@/shared/services/PartsRequestService';

const _actions = namespaceHelper.removeNamespace(namespace, PartsRequestActions);
const _mutations = namespaceHelper.removeNamespace(namespace, PartsRequestMutations);

export default {
  async [_actions.SEND_PARTS_REQUEST](_, partsRequest) {
    const response = await PartsRequestService.sendPartsRequest(partsRequest);
    return response.data;
  },
  async [_actions.SAVE_PARTS_REQUEST](_, partsRequest) {
    await PartsRequestService.savePartsRequest(partsRequest);
  },
  async [_actions.FETCH_REQUESTED_PARTS]({ commit }, salesOrderId) {
    const response = await PartsRequestService.fetchRequestedParts(salesOrderId);
    commit(_mutations.SET_REQUESTED_PARTS, response.data);
  },
  async [_actions.FETCH_PARTS_REQUEST]({ commit }, partsRequestId) {
    const response = await PartsRequestService.fetchPartsRequest(partsRequestId);
    commit(_mutations.SET_PARTS_REQUEST, response.data);
  },
  async [_actions.RESET_PARTS_REQUEST_FORM]({ commit }) {
    commit(_mutations.SET_PARTS_REQUEST, {
      partsRequestTypeId: null,
      dateDeliveryBy: null,
      requestParts: [],
      unitId: null
    });
    commit(_mutations.ADD_REQUEST_PART);
    commit(_mutations.ADD_DB_REQUEST_PART);
  },
  async [_actions.FETCH_ALL_OPS_EMPLOYEES]({ commit }) {
    const response = await PartsRequestService.fetchAllOpsEmployees();
    commit(_mutations.SET_ALL_OPS_EMPLOYEES, response.data);
  },
  async [_actions.FETCH_MATERIALS_EMPLOYEES]({ commit }) {
    const response = await PartsRequestService.fetchMaterialsEmployees();
    commit(_mutations.SET_MATERIALS_EMPLOYEES, response.data);
  },
  async [_actions.SEARCH_PARTS_REQUESTS]({ commit }, parameters) {
    const response = await PartsRequestService.searchPartsRequests(parameters);
    commit(_mutations.SET_PARTS_REQUEST_SEARCH_RESULTS, response.data);
  }
};
