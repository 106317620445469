import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, DvirGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, DvirGetters);

export default {
  [_getters.GET_DVIRS_COUNT]: state => {
    return state.dvirsCount;
  },
  [_getters.HAS_DVIR_DEFECTS]: state => {
    return state.dvirsCount?.count > 0;
  }
};
