<template>
  <div>
    <div>
      <b-alert
        v-if="customerAlert.note && !dismissCustomerAlert"
        :show="showCustomerAlert"
        class="alert alert-danger"
        dismissible
        fade
        @dismiss="dismissCustomerAlert = true"
      >
        {{ customerAlertLabel }}{{ customerAlert.note }}
        <button type="button" class="close" aria-label="close-customer-alert" @click="dismissCustomerAlert = true">
          <span aria-hidden="true">&times;</span>
        </button>

        <b-button class="mr-0 ml-auto" variant="secondary" @click="showAlertsModal({ isCustomer: true })">
          View All
        </b-button>
      </b-alert>

      <b-alert
        v-if="billToCustomerAlert.note && !dismissBillToCustomerAlert"
        class="alert alert-danger"
        :show="showBillToCustomerAlert"
        dismissible
        fade
        @dismiss="dismissBillToCustomerAlert = true"
      >
        {{ billToCustomerAlertLabel }}{{ billToCustomerAlert.note }}
        <button
          type="button"
          class="close"
          aria-label="close-bill-to-customer-alert"
          @click="dismissBillToCustomerAlert = true"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <b-button class="mr-0 ml-auto" variant="secondary" @click="showAlertsModal({ isBillToCustomer: true })">
          View All
        </b-button>
      </b-alert>
      <div v-if="showCustomerAlertsLink || showBillToCustomerAlertsLink" class="alert">
        <div class="btn-alert-link">
          <b-button v-if="showCustomerAlertsLink" class="btn-alert-link" @click="showAlertsModal({ isCustomer: true })">
            View All Customer Alerts
          </b-button>
          <b-button
            v-if="showBillToCustomerAlertsLink"
            class="btn-alert-link"
            @click="showAlertsModal({ isBillToCustomer: true })"
          >
            View All Bill-To-Customer Alerts
          </b-button>
        </div>
      </div>
      <b-row v-if="!readonly" class="no-gutters text-right mb-1">
        <b-col
          sm="12"
          :lg="stage === FORM_STAGES.SELECT_BRANCH ? 6 : 12"
          :xl="stage === FORM_STAGES.SELECT_BRANCH ? 5 : 11"
        >
          <span class="required-legend">* Required</span>
        </b-col>
      </b-row>
    </div>

    <b-row no-gutters>
      <b-col sm="12" md="5">
        <!--diplay invoiced date for customers-->
        <b-form-group
          v-if="$isCustomer && serviceOrder.dateInvoice"
          v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
          label="Invoiced:"
          label-for="dateInvoice"
        >
          <div v-if="readonly" id="dateInvoice" class="col-form-label">
            {{ serviceOrder.dateInvoice || null | date }}
          </div>
        </b-form-group>

        <!-- Branch Selection -->
        <b-form-group
          v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
          :label="branchLabel"
          label-for="branch"
        >
          <div v-if="readonly" id="branch" class="col-form-label">
            {{ serviceOrder.branchId ? `${serviceOrder.branchId} - ${serviceOrder.branchName}` : NA }}
          </div>
          <div v-else class="d-flex">
            <b-form-select
              id="branch"
              v-model="$v.branchId.$model"
              :state="!$v.branchId.$error ? null : false"
              :disabled="($v.branchId.$model && stage > FORM_STAGES.EDIT_HEADER) || isCloning"
              value-field="branchId"
              text-field="branchName"
            >
              <b-form-select-option v-for="branch in myBranches" :key="branch.branchId" :value="branch.branchId">
                {{ branch.branchId }} - {{ branch.branchName }}
              </b-form-select-option>
            </b-form-select>
            <span class="required-asterisk">*</span>
          </div>
        </b-form-group>

        <div v-show="stage >= FORM_STAGES.EDIT_HEADER">
          <!-- sales type -->
          <b-form-group
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            label="Sales Type:"
            label-for="salesType"
          >
            <div v-if="readonly" id="salesType" class="col-form-label">
              {{ salesType ? `${salesType.salesTypeId} - ${salesType.description}` : NA }}
            </div>
            <b-input-group v-else>
              <div class="d-flex w-100">
                <v-select
                  id="salesType"
                  v-model="$v.salesTypeId.$model"
                  :state="!$v.salesTypeId.$error ? null : false"
                  :options="salesTypesList"
                  :filter-by="filtersalesTypesList"
                  :clearable="false"
                  :reduce="salesType => salesType.salesTypeId"
                  label="salesTypeId"
                  :class="`${$v.salesTypeId.$error && !$v.salesTypeId.required ? 'is-invalid' : ''}`"
                >
                  <template #selected-option="type">
                    <template v-if="type.salesTypeId">
                      <span class="text-uppercase">{{ type.salesTypeId }} - {{ type.description }}</span>
                    </template>
                  </template>
                  <template #option="type">
                    <span class="text-uppercase">{{ type.salesTypeId }} - {{ type.description }}</span>
                  </template>
                </v-select>
                <span class="required-asterisk">*</span>
              </div>
            </b-input-group>
          </b-form-group>

          <!-- Repair Priority -->
          <b-form-group
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            label="Repair Priority:"
            label-for="repair-priority"
          >
            <div v-if="readonly" id="repair-priority" class="col-form-label">
              {{ repairPriority ? repairPriority.repairPriority : NA }}
            </div>
            <div v-else class="d-flex">
              <b-form-select
                id="repair-priority"
                v-model="$v.repairPriorityId.$model"
                :state="!$v.repairPriorityId.$error ? null : false"
                :options="activeRepairPriorities"
                value-field="id"
                text-field="repairPriority"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Repair Priority</b-form-select-option>
                </template>
              </b-form-select>
              <span class="required-asterisk">*</span>
            </div>
            <div v-if="!$isCustomer && !readonly">
              <span class="reference-guide-link">
                <b-link id="referenceGuidePriority" @click="navigateToGuide(repairPriorityLink)">
                  Reference Guide
                  <font-awesome-icon icon="external-link-alt" fixed-width />
                </b-link>
              </span>
            </div>
          </b-form-group>

          <!-- Repair Site -->
          <b-form-group
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            label="Repair Site:"
            label-for="repair-site"
          >
            <div v-if="readonly" id="repair-site" class="col-form-label">
              {{ repairSite ? repairSite.repairSite : NA }}
            </div>
            <div v-else class="d-flex">
              <b-form-select
                id="repair-site"
                v-model="$v.repairSiteId.$model"
                :state="!$v.repairSiteId.$error ? null : false"
                :options="activeRepairSites"
                value-field="id"
                text-field="repairSite"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Repair Site</b-form-select-option>
                </template>
              </b-form-select>
              <span class="required-asterisk">*</span>
            </div>
            <div v-if="!$isCustomer && !readonly">
              <span class="reference-guide-link">
                <b-link id="referenceGuideSite" @click="navigateToGuide(repairSiteLink)">
                  Reference Guide
                  <font-awesome-icon icon="external-link-alt" fixed-width />
                </b-link>
              </span>
            </div>
          </b-form-group>

          <!-- Repair Reason -->
          <b-form-group
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            label="Reason for Repair:"
            label-for="repair-reason"
          >
            <div v-if="readonly" id="repair-reason" class="col-form-label">
              {{ repairReason ? repairReason.repairReason : NA }}
            </div>
            <div v-else class="d-flex">
              <b-form-select
                id="repair-reason"
                v-model="$v.repairReasonId.$model"
                :state="!$v.repairReasonId.$error ? null : false"
                :options="selectableRepairReasons"
                value-field="id"
                text-field="repairReason"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>Select Reason for Repair</b-form-select-option>
                </template>
              </b-form-select>
              <span class="required-asterisk">*</span>
            </div>
            <div v-if="!$isCustomer && !readonly">
              <span class="reference-guide-link">
                <b-link id="referenceGuideReason" @click="navigateToGuide(reasonForRepairLink)">
                  Reference Guide
                  <font-awesome-icon icon="external-link-alt" fixed-width />
                </b-link>
              </span>
            </div>
          </b-form-group>

          <!-- Repair Status -->
          <b-form-group
            v-if="showRepairStatus"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            label="Repair Status:"
            label-for="repair-status"
          >
            <div v-if="readonly" id="repair-status" class="col-form-label">
              {{ repairStatus ? repairStatus.repairStatus : NA }}
            </div>
            <div v-else class="d-flex">
              <b-form-select
                id="repair-status"
                v-model="repairStatusId"
                :options="selectableRepairStatuses"
                value-field="id"
                text-field="repairStatus"
              ></b-form-select>
              <span class="required-placeholder" />
            </div>
          </b-form-group>

          <!-- License Plate -->
          <b-form-group
            v-if="showLicensePlate"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            label="License Plate:"
            label-for="license-plate"
          >
            <div v-if="readonly" id="license-plate" class="col-form-label">{{ unit.licensePlate || NA }}</div>
            <div v-else class="d-flex">
              <b-form-input id="license-plate" v-model="licensePlate"></b-form-input>
              <span class="required-placeholder" />
            </div>
          </b-form-group>

          <!-- Mileage -->
          <b-form-group
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            label="Mileage:"
            label-for="mileage"
          >
            <template #label>
              <div>
                Mileage:
                <sup>
                  <font-awesome-icon
                    id="mileage_tooltip"
                    icon="info-circle"
                    size="lg"
                    class="mx-1 text-primary align-self-center"
                    display="inline-block"
                  />
                  <b-tooltip target="mileage_tooltip" triggers="hover">
                    Last Updated {{ unit.dateMileageLastUpdated | date }}
                  </b-tooltip>
                </sup>
                <span v-if="!readonly" class="text-nowrap">(Current {{ unit.mileage | number }})</span>
              </div>
            </template>
            <div v-if="readonly" id="mileage" class="col-form-label">{{ serviceOrder.mileage || NA }}</div>
            <div v-else class="d-flex">
              <b-form-input
                id="mileage"
                v-model.number="$v.mileage.$model"
                type="number"
                :class="{ warning: $v.mileage.$model && lowerMileage }"
                :state="$v.mileage.$error || $v.engineHours.$error ? false : null"
                @keypress="NumberFieldHelper.isNumber"
              ></b-form-input>
              <span class="required-asterisk">*</span>
            </div>
            <warning-alert v-show="!readonly && $v.mileage.$model && lowerMileage">
              Note: Mileage entered is less than current. ({{ unit.mileage | number }})
            </warning-alert>
          </b-form-group>

          <!-- Engine Hours -->
          <b-form-group
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            label="Engine Hours:"
            label-for="engine-hours"
          >
            <template #label>
              <div>
                Engine Hours:
                <sup>
                  <font-awesome-icon
                    id="engine_hours_tooltip"
                    icon="info-circle"
                    size="lg"
                    class="mx-1 text-primary align-self-center"
                    display="inline-block"
                  />
                  <b-tooltip target="engine_hours_tooltip" triggers="hover">
                    Last Updated {{ unit.dateEngineHoursLastUpdated | date }}
                  </b-tooltip>
                </sup>
                <span v-if="!readonly" class="text-nowrap">(Current {{ unit.engineHours | number }})</span>
              </div>
            </template>
            <div v-if="readonly" id="engine-hours" class="col-form-label">{{ serviceOrder.engineHours || NA }}</div>
            <div v-else>
              <div class="d-flex">
                <b-form-input
                  id="engine-hours"
                  v-model.number="$v.engineHours.$model"
                  type="number"
                  :class="{ warning: $v.engineHours.$model && lowerEngineHours }"
                  :state="$v.mileage.$error || $v.engineHours.$error ? false : null"
                  @keypress="NumberFieldHelper.isNumber"
                ></b-form-input>
                <span class="required-asterisk">*</span>
              </div>
              <div class="note">Note: Mileage and/or Engine Hours is required.</div>
              <div v-show="$v.mileage.$error || $v.engineHours.$error" class="error">
                Update mileage and/or engine hours.
              </div>
              <warning-alert v-show="!readonly && $v.engineHours.$model && lowerEngineHours">
                Note: Engine Hours entered is less than current. ({{ unit.engineHours | number }})
              </warning-alert>
            </div>
          </b-form-group>
        </div>
      </b-col>
      <b-col offset-md="1" sm="12" md="6" xl="5">
        <div v-if="stage >= FORM_STAGES.EDIT_HEADER">
          <!-- Out of Service -->
          <b-form-group v-if="isOutOfService && !serviceOrder.invoiced">
            <div id="outOfService" class="unit-out-of-service">UNIT OUT OF SERVICE</div>
          </b-form-group>
          <!-- create-date -->
          <date-time-input
            v-if="!$isCustomer"
            id="dateCreate"
            :value="dateCreate"
            label="Created Date:"
            :readonly="readonly"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            hide-time
            :required="false"
            :show-today-btn="false"
            disabled
          />
          <!-- open-date -->
          <date-time-input
            v-if="!$isCustomer"
            id="dateOpen"
            v-model="$v.dateOpen.$model"
            label="Open Date:"
            :validator="$v.dateOpen"
            :readonly="readonly"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            required
            show-today-btn
            :disabled="!isCsrOrCsm"
          ></date-time-input>
          <!-- schedule-date -->
          <date-time-input
            v-if="!$isCustomer"
            id="dateSchedule"
            v-model="$v.dateSchedule.$model"
            label="Scheduled Date:"
            :validator="$v.dateSchedule"
            :disabled-dates="disabledDates"
            :readonly="readonly"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            required
            show-today-btn
          >
            <template #default>
              <div v-show="!$v.dateSchedule.minValue" class="error">
                Scheduled date must be 30 days prior to the create date or later. Enter time.
              </div>
              <div v-if="!readonly && showAppointmentLink">
                <span class="appointment-link">
                  <b-link id="navigateToAppointment" @click="navigateToAppointment()">
                    View Appointment
                    <font-awesome-icon icon="external-link-alt" fixed-width />
                  </b-link>
                </span>
              </div>
            </template>
          </date-time-input>
          <!-- unit-arrived -->
          <date-time-input
            v-if="!$isCustomer"
            id="dateUnitArrive"
            v-model="$v.dateUnitArrive.$model"
            label="Unit Arrived:"
            :validator="$v.dateUnitArrive"
            :disabled-dates="disabledDates"
            :readonly="readonly"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            required
            show-today-btn
          >
            <template #default>
              <div v-show="!$v.dateUnitArrive.minValue" class="error">
                Unit Arrived date must be 30 days prior to the create date or later. Enter time.
              </div>
            </template>
          </date-time-input>
          <!-- notified of assessment -->
          <date-time-input
            id="dateNotifyDiag"
            v-model="$v.dateNotifyDiag.$model"
            label="Notified of Assessment:"
            :validator="$v.dateNotifyDiag"
            :disabled-dates="disabledDates"
            :readonly="readonly"
            :required="false"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            show-today-btn
          >
            <template #default>
              <div v-show="!$v.dateNotifyDiag.minValue" class="error">
                Notified of Assessment date must be 30 days prior to the create date or later. Enter time.
              </div>
            </template>
          </date-time-input>
          <!-- pricing valid until / Expires -->
          <date-time-input
            v-if="isEstimate"
            id="dateExpire"
            v-model="$v.dateExpire.$model"
            label="Pricing Valid Until:"
            :validator="$v.dateExpire"
            :disabled-dates="disabledDates"
            :readonly="readonly"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            required
            show-today-btn
            hide-time
          >
            <template #label>
              Pricing Valid Until:
              <sup>
                <font-awesome-icon
                  id="expires_tooltip"
                  icon="question-circle"
                  size="lg"
                  class="mx-1 text-primary align-self-center"
                  display="inline-block"
                />
                <b-tooltip target="expires_tooltip" triggers="hover">Price subject to change after date.</b-tooltip>
              </sup>
            </template>
          </date-time-input>
          <!-- authorized-date -->
          <date-time-input
            v-if="isEstimate || !$isCustomer"
            id="dateAuthorize"
            v-model="$v.dateAuthorize.$model"
            label="Authorized:"
            :validator="$v.dateAuthorize"
            :disabled-dates="disabledDates"
            :readonly="readonly"
            :required="false"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            show-today-btn
          >
            <template #default>
              <div v-show="!$v.dateAuthorize.minValue" class="error">
                Authorized date must be 30 days prior to the create date or later. Enter time.
              </div>
            </template>
          </date-time-input>
          <!-- promised-date -->
          <date-time-input
            v-if="!$isCustomer || isEstimate"
            id="datePromise"
            v-model="$v.datePromise.$model"
            label="Promised (ETR):"
            :validator="$v.datePromise"
            :disabled-dates="disabledDates"
            :readonly="readonly"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            required
            show-today-btn
          >
            <template #label>
              Promised (ETR):
              <sup v-if="isEstimate">
                <font-awesome-icon
                  id="etr_tooltip"
                  icon="question-circle"
                  size="lg"
                  class="mx-1 text-primary align-self-center"
                  display="inline-block"
                />
                <b-tooltip target="etr_tooltip" triggers="hover">Subject to change upon approval.</b-tooltip>
              </sup>
            </template>
            <template #default>
              <div v-show="!$v.datePromise.minValue" class="error">
                Promised date must be 30 days prior to the create date or later. Enter time.
              </div>
            </template>
          </date-time-input>
          <!-- actual-time-of-repair -->
          <date-time-input
            v-if="!$isCustomer"
            id="dateActualRepair"
            v-model="$v.dateActualRepair.$model"
            label="Actual Time of Repair:"
            :validator="$v.dateActualRepair"
            :disabled-dates="disabledDates"
            :readonly="readonly"
            :required="false"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            show-today-btn
          >
            <template #default>
              <div v-show="!$v.dateActualRepair.minValue" class="error">
                Actual Time of Repair must be 30 days prior to the create date or later. Enter time.
              </div>
            </template>
          </date-time-input>
          <!-- notified of completion -->
          <date-time-input
            v-if="!$isCustomer || !isEstimate"
            id="dateNotify"
            v-model="$v.dateNotify.$model"
            label="Notified of Completion:"
            :validator="$v.dateNotify"
            :disabled-dates="disabledDates"
            :readonly="readonly"
            :required="false"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            show-today-btn
          >
            <template #default>
              <div v-show="!$v.dateNotify.minValue" class="error">
                Notified date must be 30 days prior to the create date or later. Enter time.
              </div>
            </template>
          </date-time-input>
          <!-- date-invoice -->
          <b-form-group
            v-if="!$isCustomer && serviceOrder.dateInvoice"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            label="Invoiced:"
            label-for="dateInvoice"
          >
            <div v-if="readonly" id="dateInvoice" class="col-form-label">
              {{ serviceOrder.dateInvoice || null | date }}
            </div>
          </b-form-group>

          <!-- Purchase Order -->
          <b-form-group
            v-if="$isCustomer"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            label="PO #:"
            label-for="purchaseOrder"
          >
            <div v-if="readonly" id="purchaseOrder" class="col-form-label">
              {{ serviceOrder.purchaseOrder || NA }}
            </div>
          </b-form-group>

          <!-- breakdown link -->
          <b-form-group
            v-if="stage >= FORM_STAGES.EDIT_SO && serviceOrder.breakdownId"
            v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
            label="Breakdown ID:"
            label-for="breakdownLink"
          >
            <div id="breakdownLink" class="pt-2">
              <router-link :to="'/units/' + serviceOrder.unitId + '/breakdowns/' + serviceOrder.breakdownId">
                {{ serviceOrder.breakdownId }}
              </router-link>
            </div>
          </b-form-group>

          <!-- customer attributes -->
          <div v-if="stage >= FORM_STAGES.EDIT_HEADER && attributes">
            <b-form-group
              v-for="attr in Object.values($v.attributes.$each.$iter)"
              :key="attr.attributeId"
              v-bind="{ labelColsSm, labelColsMd, labelColsLg, labelColsXl }"
              :label="`${attr.$model.label}:`"
              :label-for="`${attr.attributeId}`"
            >
              <div v-if="readonly" :id="`${attr.$model.attributeId}`" class="col-form-label">
                {{ attr.$model.value || NA }}
              </div>
              <b-input-group v-else>
                <b-input
                  v-if="!attr.$model.options || attr.$model.options.length == 0"
                  :id="cleanLabelForId(attr.$model.label)"
                  :value="attr.$model.value"
                  :state="attr.$error ? false : null"
                  @input="setAttribute(attr.$model.attributeId, $event)"
                />
                <v-select
                  v-else
                  :id="cleanLabelForId(attr.$model.label)"
                  :value="attr.$model.value"
                  :options="attr.$model.options"
                  :taggable="attr.$model.taggable"
                  :filterable="!attr.$model.taggable"
                  :class="`${attr.$error ? 'is-invalid' : ''}`"
                  select-on-tab
                  @input="setAttribute(attr.$model.attributeId, $event)"
                ></v-select>
                <span class="required-asterisk">*</span>
              </b-input-group>
            </b-form-group>
          </div>
          <!-- Warranty Submission -->
          <b-form-group v-if="stage >= FORM_STAGES.EDIT_SO && serviceOrder.warrantySubmissionId">
            <div v-if="!$isMobileApp && !$isCustomer" class="col-form-label">
              <safe-hyperlink id="warrantySubmission" :to="'/warranties/' + serviceOrder.warrantySubmissionId">
                View Warranty Submission
              </safe-hyperlink>
            </div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters class="mb-2">
      <b-col sm="12" lg="5">
        <!-- /Bill-To Customer Component -->
        <bill-to-customer v-if="stage >= FORM_STAGES.EDIT_HEADER" :readonly="readonly" visible />
      </b-col>

      <!-- Mounted Equipment -->
      <b-col offset-lg="1" sm="12" lg="5">
        <b-row>
          <b-alert
            v-if="
              mismatchedContractStatusAndMountedEquipment &&
              !dismissMountedEquipmentAlert &&
              stage >= FORM_STAGES.EDIT_HEADER
            "
            :show="mismatchedContractStatusAndMountedEquipment()"
            class="alert-warning d-inline-block w-100"
            dismissible
            fade
            @dismiss="dismissMountedEquipmentAlert = true"
          >
            <h4 class="alert-heading">
              <b>{{ mountedEquipmentWarningTitle }}</b>
            </h4>
            <div>
              {{ mountedEquipmentWarningMessage }}
            </div>
            <button
              type="button"
              class="close"
              aria-label="close-customer-alert"
              @click="dismissMountedEquipmentAlert = true"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </b-alert>
        </b-row>
        <mounted-equipment
          v-if="stage >= FORM_STAGES.EDIT_HEADER"
          :readonly="readonly"
          :serviceorder="equipmentShownInSO"
          visible
        />
      </b-col>
    </b-row>
    <warning-modal
      id="MountedEquipmentWarningModal"
      ref="MountedEquipmentWarningModal"
      :title="mountedEquipmentWarningTitle"
      :warning-text="mountedEquipmentWarningMessage"
      hide-continue-btn
    ></warning-modal>

    <customer-alerts-modal ref="CustomerAlertsModal"></customer-alerts-modal>
  </div>
</template>

<script>
// components
import BillToCustomerComponent from './ServiceOrderBillToCustomerComponent';
import CustomerAlertsModal from './CustomerAlertsModal';
import MountedEquipment from '@/shared/components/unit/MountedEquipmentsComponent';
import DateTimeInput from '@/shared/components/ui/DateTimeInput';
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
import WarningAlertComponent from '@/shared/components/WarningAlertComponent';
import WarningModal from '@/shared/components/WarningModal';
import vSelect from 'vue-select';

// vuex
import { ConfigGetters } from '@/shared/store/config/types';
import { UserGetters } from '@/shared/store/user/types';
import { UnitGetters } from '@/shared/store/unit/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { ServiceOrderActions, ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { MountedEquipmentGetters, MountedEquipmentActions } from '@/shared/store/unit/mounted-equipment/types';
import { OutOfServiceGetters, OutOfServiceActions } from '@/shared/store/unit/out-of-service/types';
import { FORM_STAGES } from '@/shared/store/service-order/state';
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';

import { required, requiredIf, minValue } from 'vuelidate/lib/validators';
import NumberFieldHelper from '@/shared/helpers/number-field-helper';
import moment from 'moment';

export default {
  name: 'ServiceOrderHeader',
  components: {
    'warning-alert': WarningAlertComponent,
    'date-time-input': DateTimeInput,
    'customer-alerts-modal': CustomerAlertsModal,
    'bill-to-customer': BillToCustomerComponent,
    'mounted-equipment': MountedEquipment,
    'warning-modal': WarningModal,
    'safe-hyperlink': SafeHyperlinkComponent,
    vSelect
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    created: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      NA: 'N/A',
      FORM_STAGES: FORM_STAGES,
      NumberFieldHelper: NumberFieldHelper,
      dismissCustomerAlert: false,
      dismissBillToCustomerAlert: false,
      dismissMountedEquipmentAlert: false,
      customerAlertLabel: 'CUSTOMER ALERT:  ',
      billToCustomerAlertLabel: 'BILL-TO-CUSTOMER ALERT:  ',
      equipmentShownInSO: true,
      mountedEquipmentWarningTitle: 'Unit has Non-Guaranteed Mounted Equipment',
      mountedEquipmentWarningMessage: 'Non-Guaranteed Mounted Equipment repairs should be Out-of-Scope.',
      repairReasonWarningMessage: null,
      repairReasonWarningTitle: 'Reason for Repair and Sales Type Mismatch',
      labelColsXl: '4',
      labelColsLg: '12',
      labelColsMd: '12',
      labelColsSm: '12'
    };
  },
  validations: {
    branchId: {
      required
    },
    salesTypeId: {
      required: requiredIf(function () {
        return this.stage >= FORM_STAGES.EDIT_HEADER;
      })
    },
    repairPriorityId: {
      required: requiredIf(function () {
        return this.stage >= FORM_STAGES.EDIT_HEADER;
      })
    },
    repairSiteId: {
      required: requiredIf(function () {
        return this.stage >= FORM_STAGES.EDIT_HEADER;
      })
    },
    repairReasonId: {
      required: requiredIf(function () {
        return this.stage >= FORM_STAGES.EDIT_HEADER;
      })
    },
    mileage: {
      required: requiredIf(function () {
        return this.stage >= FORM_STAGES.EDIT_HEADER && !this.engineHours;
      })
    },
    engineHours: {
      required: requiredIf(function () {
        return this.stage >= FORM_STAGES.EDIT_HEADER && !this.mileage;
      })
    },
    dateSchedule: {
      required: requiredIf(function () {
        return this.stage >= FORM_STAGES.EDIT_HEADER;
      }),
      minValue(value) {
        return minValue(this.minimumDate)(value);
      }
    },
    dateOpen: {
      required: requiredIf(function () {
        return this.stage >= FORM_STAGES.EDIT_HEADER;
      })
    },
    dateUnitArrive: {
      required: requiredIf(function () {
        return this.stage >= FORM_STAGES.EDIT_HEADER;
      }),
      minValue(value) {
        return minValue(this.minimumDate)(value);
      }
    },
    datePromise: {
      required: requiredIf(function () {
        return this.stage >= FORM_STAGES.EDIT_HEADER;
      }),
      minValue(value) {
        return minValue(this.minimumDate)(value);
      }
    },
    dateNotify: {
      minValue(value) {
        return minValue(this.minimumDate)(value);
      }
    },
    dateNotifyDiag: {
      minValue(value) {
        return minValue(this.minimumDate)(value);
      }
    },
    dateAuthorize: {
      minValue(value) {
        return minValue(this.minimumDate)(value);
      }
    },
    dateActualRepair: {
      minValue(value) {
        return minValue(this.minimumDate)(value);
      }
    },
    dateExpire: {
      required: requiredIf(function () {
        return this.isEstimate && this.stage >= FORM_STAGES.EDIT_HEADER;
      })
    },
    attributes: {
      $each: {
        value: {
          required: requiredIf(function () {
            return this.stage >= FORM_STAGES.EDIT_HEADER;
          })
        }
      }
    }
  },
  computed: {
    ...mapState('serviceOrder', ['estimorder']),
    ...mapGetters([UserGetters.AUTHORIZE_ROLE]),
    ...mapGetters({
      config: ConfigGetters.GET_CONFIG,
      stage: ServiceOrderGetters.GET_FORM_STAGE,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      isEstimate: ServiceOrderGetters.GET_IS_ESTIMATE,
      isOutOfService: OutOfServiceGetters.HAS_OPEN_UNIT_OUT_OF_SERVICE,
      customer: ServiceOrderGetters.GET_CUSTOMER,
      billToCustomer: ServiceOrderGetters.GET_BILL_TO_CUSTOMER,
      dateCreate: ServiceOrderGetters.GET_DATE_CREATE,
      customerAlert: ServiceOrderGetters.GET_CUSTOMER_ALERT,
      billToCustomerAlert: ServiceOrderGetters.GET_BILL_TO_CUSTOMER_ALERT,
      unit: UnitGetters.GET_UNIT,
      customerAlerts: ServiceOrderGetters.GET_CUSTOMER_ALERTS,
      billToCustomerAlerts: ServiceOrderGetters.GET_BILL_TO_CUSTOMER_ALERTS,
      salesTypesMap: LookupGetters.GET_SALES_TYPES_MAP,
      salesTypesList: LookupGetters.GET_SALES_TYPES_LIST,
      repairPrioritiesMap: LookupGetters.GET_REPAIR_PRIORITIES_MAP,
      repairPrioritiesList: LookupGetters.GET_REPAIR_PRIORITIES_LIST,
      repairSitesMap: LookupGetters.GET_REPAIR_SITES_MAP,
      repairSitesList: LookupGetters.GET_REPAIR_SITES_LIST,
      repairReasonsMap: LookupGetters.GET_REPAIR_REASONS_MAP,
      repairReasonsList: LookupGetters.GET_REPAIR_REASONS_LIST,
      customerSpecificRepairReasons: ServiceOrderGetters.GET_CUSTOMER_SPECIFIC_REPAIR_REASONS,
      repairStatusesMap: LookupGetters.GET_REPAIR_STATUSES_MAP,
      repairStatusesList: LookupGetters.GET_REPAIR_STATUSES_LIST,
      isFeatureFlagEnabled: LookupGetters.IS_FEATURE_FLAG_ENABLED,
      mountedEquipments: MountedEquipmentGetters.GET_MOUNTED_EQUIPMENTS,
      mountedEquipmentContractStatusesList: LookupGetters.GET_MOUNTED_EQUIPMENT_CONTRACT_STATUSES_LIST
    }),
    startDate() {
      return this.$route.query.startDate;
    },
    branchId: {
      get() {
        if (this.myBranches.length === 1) {
          return this.myBranches[0].branchId;
        } else {
          return this.$store.getters[ServiceOrderGetters.GET_BRANCH_ID];
        }
      },
      set(value) {
        this.$store.commit(ServiceOrderMutations.SET_BRANCH, value);
      }
    },
    salesTypeId: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_SALES_TYPE_ID];
      },
      set(value) {
        this.$store.commit(ServiceOrderMutations.SET_SALES_TYPE_ID, value);
      }
    },
    salesType() {
      return this.salesTypesMap[this.salesTypeId];
    },
    repairPriorityId: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_REPAIR_PRIORITY];
      },
      set(value) {
        this.$store.commit(ServiceOrderMutations.SET_REPAIR_PRIORITY, value);
      }
    },
    activeRepairPriorities() {
      return this.repairPrioritiesList.filter(x => !x.inactive);
    },
    repairPriority() {
      const repairPriority = this.repairPrioritiesMap[this.repairPriorityId];
      return repairPriority;
    },
    repairPriorityLink() {
      return 'https://clarkepsi.sharepoint.com/:b:/s/CPSI-VAMSDocs/ETEvdqTTAClDrakqZ_sEtdkB1EKx3wMMuvbHsoLjF9836w?e=43mNNq';
    },
    repairSiteId: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_REPAIR_SITE];
      },
      set(value) {
        this.$store.commit(ServiceOrderMutations.SET_REPAIR_SITE, value);
      }
    },
    activeRepairSites() {
      return this.repairSitesList.filter(x => !x.inactive);
    },
    repairSite() {
      const repairSite = this.repairSitesMap[this.repairSiteId];
      return repairSite;
    },
    repairSiteLink() {
      return 'https://clarkepsi.sharepoint.com/:b:/s/CPSI-VAMSDocs/EZfMMuIyUKRLhbT3dXuoEXUB-_aX8a1Ax5AJeKruYPa6rA?e=ZrHINS';
    },
    repairReasonId: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_REPAIR_REASON];
      },
      set(value) {
        this.$store.commit(ServiceOrderMutations.SET_REPAIR_REASON, value);
      }
    },
    repairStatusId: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_REPAIR_STATUS];
      },
      set(value) {
        this.$store.commit(ServiceOrderMutations.SET_REPAIR_STATUS, value);
      }
    },
    activeRepairReasons() {
      return this.repairReasonsList.filter(x => !x.inactive);
    },
    activeCustomerSpecificRepairReasons() {
      var activeReasons = this.customerSpecificRepairReasons.filter(x => !x.inactive);

      //If currently-selected 'repair reason' is not actually a selectable option, we still want to
      //display it - but not let the user select it again.
      if (
        activeReasons &&
        activeReasons.length > 0 &&
        this.repairReason &&
        !activeReasons.some(reason => reason.id === this.repairReason.id)
      ) {
        activeReasons.push(this.repairReason);
      }

      return activeReasons;
    },
    selectableRepairReasons() {
      if (this.activeCustomerSpecificRepairReasons && this.activeCustomerSpecificRepairReasons.length > 0) {
        return this.activeCustomerSpecificRepairReasons;
      } else {
        return this.activeRepairReasons;
      }
    },
    repairReason() {
      var repairReason = this.repairReasonsMap[this.repairReasonId];

      if (!repairReason) {
        repairReason = this.customerSpecificRepairReasons.find(repair => repair.id == this.repairReasonId);
      }

      return repairReason;
    },
    reasonForRepairLink() {
      return 'https://clarkepsi.sharepoint.com/:b:/s/CPSI-VAMSDocs/EetXXEp1ELtHli3Se8QGyVcBj2yEus59bze7dZkMXP9FlQ?e=1B4GgR';
    },
    repairStatus() {
      const repairStatus = this.repairStatusesMap[this.repairStatusId];
      return repairStatus;
    },
    selectableRepairStatuses() {
      return this.repairStatusesList.filter(status => status.selectable);
    },
    licensePlate: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_LICENSE_PLATE];
      },
      set(value) {
        this[ServiceOrderMutations.SET_PROP]({ key: 'licensePlate', value });
      }
    },
    mileage: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_MILEAGE];
      },
      set(value) {
        this[ServiceOrderMutations.SET_PROP]({ key: 'mileage', value });
      }
    },
    lowerMileage() {
      return this.stage > FORM_STAGES.EDIT_HEADER && this.mileage < this.unit.mileage;
    },
    engineHours: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_ENGINE_HOURS];
      },
      set(value) {
        this[ServiceOrderMutations.SET_PROP]({ key: 'engineHours', value });
      }
    },
    lowerEngineHours() {
      return this.stage > FORM_STAGES.EDIT_HEADER && this.engineHours < this.unit.engineHours;
    },
    attributes() {
      return this.serviceOrder.attributes;
    },

    // Dates
    dateOpen: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_DATE_OPEN];
      },
      set(value) {
        this[ServiceOrderMutations.SET_PROP]({ key: 'dateOpen', value });
      }
    },
    dateSchedule: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_DATE_SCHEDULE];
      },
      set(value) {
        this[ServiceOrderMutations.SET_PROP]({ key: 'dateSchedule', value });
      }
    },
    dateUnitArrive: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_DATE_UNIT_ARRIVE];
      },
      set(value) {
        this[ServiceOrderMutations.SET_PROP]({ key: 'dateUnitArrive', value });
      }
    },
    datePromise: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_DATE_PROMISE];
      },
      set(value) {
        this[ServiceOrderMutations.SET_PROP]({ key: 'datePromise', value });
      }
    },
    dateNotify: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_DATE_NOTIFY];
      },
      set(value) {
        this[ServiceOrderMutations.SET_PROP]({ key: 'dateNotify', value });
      }
    },
    dateNotifyDiag: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_DATE_NOTIFY_DIAG];
      },
      set(value) {
        this[ServiceOrderMutations.SET_PROP]({ key: 'dateNotifyDiag', value });
      }
    },
    dateAuthorize: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_DATE_AUTHORIZE];
      },
      set(value) {
        this[ServiceOrderMutations.SET_PROP]({ key: 'dateAuthorize', value });
      }
    },
    dateActualRepair: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_DATE_ACTUAL_REPAIR];
      },
      set(value) {
        this[ServiceOrderMutations.SET_PROP]({ key: 'dateActualRepair', value });
      }
    },
    dateExpire: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_DATE_EXPIRE];
      },
      set(value) {
        this[ServiceOrderMutations.SET_PROP]({ key: 'dateExpire', value });
      }
    },
    branchLabel() {
      let label = 'Branch:';

      // Inverted conditional from deteriming if the branch field would be disabled
      if ((!this.readonly && !this.branchId) || (this.stage <= this.FORM_STAGES.EDIT_HEADER && !this.isCloning)) {
        label = 'Select ' + label;
      }
      return label;
    },
    myBranches() {
      return this.$store.getters[UserGetters.GET_USER_PROFILE].employeeBranches;
    },
    isCloning() {
      return this.$route.query.clone != null;
    },
    showCustomerAlert() {
      return !this.$isCustomer && this.created;
    },
    showBillToCustomerAlert() {
      return !this.$isCustomer && this.created && this.customerAlert.customerId != this.customerAlert.billToCustomerId;
    },
    showCustomerAlertsLink() {
      return (
        !this.$isCustomer &&
        (this.dismissCustomerAlert || !this.created) &&
        !this.serviceOrder.invoiced &&
        this.customerAlerts.length > 0
      );
    },
    showBillToCustomerAlertsLink() {
      return (
        !this.$isCustomer &&
        this.customerAlert.customerId != this.customerAlert.billToCustomerId &&
        (this.dismissBillToCustomerAlert || !this.created) &&
        !this.serviceOrder.invoiced &&
        this.billToCustomerAlerts.length > 0
      );
    },
    hasInvoicedServiceOrder() {
      return this.$store.state.serviceOrder.serviceOrder.invoiced;
    },
    showLicensePlate() {
      return (this.isEstimate && !this.hasInvoicedServiceOrder) || (!this.isEstimate && !this.serviceOrder.invoiced);
    },
    showRepairStatus() {
      return !this.isEstimate && !this.$isCustomer && !this.readonly;
    },
    disabledDates() {
      return { to: this.minimumDate };
    },
    minimumDate() {
      return moment(this.dateCreate).startOf('day').subtract(30, 'days').toDate();
    },
    isCsrOrCsm() {
      return this[UserGetters.AUTHORIZE_ROLE](['CSR', 'CSM', 'RegionalCSM']);
    },
    showAppointmentLink() {
      return (
        !this.isEstimate &&
        this.serviceOrder.unitServiceOrderAppointmentId !== null &&
        this.isFeatureFlagEnabled('ServiceRequest')
      );
    }
  },
  watch: {
    branchId: {
      async handler() {
        if (this.branchId && this.stage === FORM_STAGES.SELECT_BRANCH) {
          // TODO does this fetch need to happen here? or should it happen on the bill-to-cus component?
          await this[ServiceOrderActions.FETCH_CUSTOMER_SPECIFIC_REPAIR_REASONS](this.billToCustomer.customerId);
          this.switchStage(FORM_STAGES.EDIT_HEADER);
        }
      },
      immediate: true
    },
    stage: {
      async handler() {
        if (this.stage === FORM_STAGES.EDIT_HEADER && this.mismatchedContractStatusAndMountedEquipment()) {
          this.$refs.MountedEquipmentWarningModal.show(this);
        }
      }
    },
    repairSite: {
      async handler() {
        if (!this.readonly && this.repairSite && this.repairSite.inactive) {
          this.repairSiteId = null;
          this.setOldProp('repairSiteId', this.repairSiteId);
        }
      },
      immediate: true
    },
    repairReason: {
      async handler() {
        if (!this.readonly && this.repairReason && this.repairReason.inactive) {
          this.repairReasonId = null;
          this.setOldProp('repairReasonId', this.repairReasonId);
        }
      },
      immediate: true
    },
    repairPriority: {
      async handler() {
        if (!this.readonly && this.repairPriority && this.repairPriority.inactive) {
          this.repairPriorityId = null;
          this.setOldProp('repairPriorityId', this.repairPriorityId);
        }
      },
      immediate: true
    },
    repairStatus: {
      async handler() {
        if (!this.readonly && !this.repairStatusId) {
          let workingStatusId = 12;
          this.repairStatusId = workingStatusId;
          this.setOldProp('repairStatusId', this.repairStatusId);
        }
      },
      immediate: true
    }
  },
  async created() {
    await this[OutOfServiceActions.FETCH_UNIT_OUT_OF_SERVICES](this.unit.unitId);
    if (this.$route.query['repairReason']) {
      this.repairReasonId = this.$route.query['repairReason'];
    }

    await this[MountedEquipmentActions.FETCH_MOUNTED_EQUIPMENTS](this.unit.unitId);

    if (this.$route.query.appointmentId && this.startDate) {
      this.dateSchedule = new Date(this.startDate);
    }

    if (this.isCsrOrCsm && this.customer?.customerId) {
      this[ServiceOrderActions.FETCH_CUSTOMER_ALERTS](this.customer.customerId);
    }
    if (
      this.isCsrOrCsm &&
      this.billToCustomer?.customerId &&
      this.billToCustomer?.customerId != this.customer?.customerId
    ) {
      this[ServiceOrderActions.FETCH_BILL_TO_CUSTOMER_ALERTS](this.billToCustomer.customerId);
    }
  },
  methods: {
    ...mapActions([
      ServiceOrderActions.FETCH_CUSTOMER_ALERTS,
      ServiceOrderActions.FETCH_BILL_TO_CUSTOMER_ALERTS,
      MountedEquipmentActions.FETCH_MOUNTED_EQUIPMENTS,
      ServiceOrderActions.FETCH_CUSTOMER_SPECIFIC_REPAIR_REASONS,
      OutOfServiceActions.FETCH_UNIT_OUT_OF_SERVICES
    ]),
    ...mapMutations([
      ServiceOrderMutations.SET_PROP,
      ServiceOrderMutations.SET_OLD_ESTIMORDER_PROP,
      ServiceOrderMutations.SET_ATTRIBUTE
    ]),
    isGuaranteedContractStatus: function () {
      const guaranteedString = 'Guaranteed';
      const UnitContracStatusAttributeLabel = 'Unit Contract Status';
      let guaranteedContractStatusAttribute = this.unit?.attributes?.find(
        attribute => attribute.label === UnitContracStatusAttributeLabel
      );
      if (guaranteedContractStatusAttribute) return guaranteedContractStatusAttribute.value === guaranteedString;
    },
    mismatchedContractStatusAndMountedEquipment: function () {
      const nonGuaranteedString = 'Non-Guaranteed';
      let mountedEquipmentNonGuaranteed = this.mountedEquipments.some(
        eq =>
          eq.mountedEquipmentContractStatusId === null ||
          eq.mountedEquipmentContractStatusId ===
            this.mountedEquipmentContractStatusesList.find(status => status.description === nonGuaranteedString).id
      );
      let guaranteedContractStatus = this.isGuaranteedContractStatus();
      return guaranteedContractStatus && mountedEquipmentNonGuaranteed;
    },
    async switchStage(newStage) {
      return await this.$store.dispatch(ServiceOrderActions.SWITCH_STAGE, { newStage, that: this });
    },
    filtersalesTypesList(option, label, search) {
      return (
        option.salesTypeId.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        option.description.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    },
    showAlertsModal(customerType) {
      this.$refs.CustomerAlertsModal.show(customerType);
    },
    setOldProp(key, value) {
      this[ServiceOrderMutations.SET_OLD_ESTIMORDER_PROP]({
        estimorder: this.estimorder,
        key,
        value
      });
    },
    setAttribute(attributeId, value) {
      this[ServiceOrderMutations.SET_ATTRIBUTE]({ attributeId, value });
    },
    cleanLabelForId(label) {
      return label.replace(/[\W]/g, '');
    },
    navigateToGuide: async function (link) {
      window.open(link, '_blank');
    },
    navigateToAppointment: async function () {
      const url = this.config.shopSchedulerUrl;
      const dateAppointment = moment(this.serviceOrder.dateAppointment).format('YYYY-MM-DD');
      window.open(
        `${url}/schedule/${this.branchId}?viewPreset=weekly&AppointmentId=${this.serviceOrder.unitServiceOrderAppointmentId}&date=${dateAppointment}`,
        'shopScheduler'
      );
    }
  }
};
</script>
