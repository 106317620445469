<template>
  <div>
    <div class="text-right mr-3"><span class="required-legend">* Required</span></div>
    <div class="d-flex flex-row flex-wrap align-content-start no-gutters position-relative">
      <div class="mr-4">
        <label class="d-block">Date of Last Service:</label>
        <date-picker
          v-model="lastCompletedDate.$model"
          v-bind="{ disabledDates }"
          class="d-inline-block"
          :input-class="`form-control ${lastCompletedDate.$error ? 'is-invalid' : ''}`"
          placeholder="MM/DD/YYYY"
          format="MM/dd/yyyy"
          typeable
        ></date-picker>
        <div class="required-asterisk date-required">*</div>
        <div v-if="lastCompletedDate.$dirty && !lastCompletedDate.required" class="error">
          Select the Date of Last Service.
        </div>
        <div v-if="!lastCompletedDate.maxValue" class="error">Enter a date equal to or preceding today.</div>
      </div>

      <div class="mr-3">
        <label>Mileage at Last Service:</label>
        <b-input
          v-model.number="mileage.$model"
          type="number"
          :state="mileage.$error ? false : null"
          @keypress="NumberFieldHelper.isNumber"
        ></b-input>
        <div v-if="!mileage.maxValue" class="error">Value must be less than 2 billion.</div>
        <div v-if="!mileage.minValue" class="error">Value must be greater than zero.</div>
        <div v-if="!mileage.integer" class="error">Value must be a number.</div>

        <label class="mt-2">Engine Hours at Last Service:</label>
        <b-input
          v-model.number="engineHours.$model"
          type="number"
          :state="engineHours.$error ? false : null"
          @keypress="NumberFieldHelper.isNumber"
        ></b-input>
        <div v-if="!engineHours.maxValue" class="error">Value must be less than 2 billion.</div>
        <div v-if="!engineHours.minValue" class="error">Value must be greater than zero.</div>
        <div v-if="!engineHours.integer" class="error">Value must be a number.</div>
      </div>
      <div class="mr-3 flex-grow-1">
        <label>Description:</label>
        <b-form-textarea
          id="textarea"
          v-model="description"
          placeholder="(250 character limit)"
          maxlength="250"
          rows="4"
        ></b-form-textarea>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';
import NumberFieldHelper from '@/shared/helpers/number-field-helper';
import { required, maxValue, minValue, integer } from 'vuelidate/lib/validators';

export default {
  name: 'UnitServiceOrderHistoryManual',
  components: {
    'date-picker': DatePicker
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      disabledDates: {
        from: new Date()
      }
    };
  },
  validations: {
    value: {
      lastCompletedDate: {
        required,
        maxValue: maxValue(new Date().setHours(23, 59, 59, 999))
      },
      engineHours: {
        integer,
        minValue: minValue(0),
        maxValue: maxValue(2000000000)
      },
      mileage: {
        integer,
        minValue: minValue(0),
        maxValue: maxValue(2000000000)
      }
    }
  },
  computed: {
    lastCompletedDate: {
      get() {
        return this.$v.value.lastCompletedDate;
      },
      set(value) {
        this.$emit('input', { ...this.value, lastCompletedDate: value });
      }
    },
    mileage: {
      get() {
        return this.$v.value.mileage;
      },
      set(value) {
        this.$emit('input', { ...this.value, mileage: value });
      }
    },
    engineHours: {
      get() {
        return this.$v.value.engineHours;
      },
      set(value) {
        this.$emit('input', { ...this.value, engineHours: value });
      }
    },
    description: {
      get() {
        return this.$v.value.description;
      },
      set(value) {
        this.$emit('input', { ...this.value, description: value });
      }
    }
  },
  created() {
    this.NumberFieldHelper = NumberFieldHelper;
  }
};
</script>
