import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, LookupActions, LookupMutations, LookupGetters } from './types';
import UnitService from '@/shared/services/UnitService';
import BranchService from '@/shared/services/BranchService';
import JobService from '@/shared/services/JobService';
import moment from 'moment';

const _actions = namespaceHelper.removeNamespace(namespace, LookupActions);
const _getters = namespaceHelper.removeNamespace(namespace, LookupGetters);
const _mutations = namespaceHelper.removeNamespace(namespace, LookupMutations);

export default {
  async [_actions.FETCH_LOOKUPS]({ commit, getters, state }) {
    if (state.loading || state.lookupFetchDate || moment().isBefore(moment(state.lookupFetchDate).add(10, 'minutes')))
      return;
    commit(_mutations.SET_LOADING, true);
    try {
      const lookupResults = await UnitService.getLookups(state.lookupReloadCache);
      const lookups = lookupResults.data;
      commit(_mutations.SET_LOOKUPS, lookups);
      const standardOperationsList = getters[_getters.GET_OPERATIONS_WITH_MATCHED_EXCEDE_JOBS](
        Object.values(lookups.standardOperationsMap)
      );
      commit(_mutations.SET_STANDARD_OPERATIONS, standardOperationsList);
    } catch (error) {
      throw Error(error);
    } finally {
      commit(_mutations.SET_LOADING, false);
    }
  },

  async [_actions.FETCH_EMPLOYEES_BY_BRANCH]({ commit }, branchId) {
    const response = await BranchService.getEmployees(branchId);
    commit(_mutations.SET_EMPLOYEE_LIST, response.data);
  },

  async [_actions.FETCH_VMRS_HIERARCHY]({ state, commit }) {
    if (state.vmrs.dateFetched || moment().isBefore(moment(state.vmrs.dateFetched).add(1, 'hour'))) return;
    const vmrsCodes = await JobService.getVMRSCodes();
    commit(_mutations.SET_VMRS_HIERARCHY, vmrsCodes.data);
  }
};
