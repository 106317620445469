import { mapGetters, mapMutations } from 'vuex';
import { OperationsForecastGetters, OperationsForecastMutations } from '@/shared/store/operations-forecast/types';
import Cloner from '@/shared/helpers/cloner';

export default {
  computed: {
    ...mapGetters({
      operationsForecast: Cloner.deepClone(OperationsForecastGetters.GET_OPERATIONS_FORECAST),
      filter: OperationsForecastGetters.GET_SEARCH_FILTER,
      sort: OperationsForecastGetters.GET_SORT_BY,
      currentFilteredItems: OperationsForecastGetters.GET_FILTERED_ITEMS,
      page: OperationsForecastGetters.GET_CURRENT_PAGE,
      unitCount: OperationsForecastGetters.GET_TOTAL_UNITS,
      rows: OperationsForecastGetters.GET_TOTAL_ROWS,
      asideBusyFlag: OperationsForecastGetters.GET_ASIDE_BUSY_FLAG,
      itemsPerPage: OperationsForecastGetters.GET_PAGE_SIZE,
      resultColumns: OperationsForecastGetters.GET_RESULT_TABLE_COLUMNS,
      bulkServiceRequestModalFlag: OperationsForecastGetters.GET_BULK_SERVICE_REQUEST_MODAL_FLAG,
      uniqueUnits: OperationsForecastGetters.GET_OPERATIONS_FORECAST_UNIQUE_UNITS
    }),
    searchFilter: {
      get() {
        return this.filter;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'searchFilter', value });
      }
    },
    sortBy: {
      get() {
        return this.sort;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'sortBy', value });
      }
    },
    filteredItems: {
      get() {
        return this.currentFilteredItems;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'filteredItems', value });
      }
    },
    totalUnits: {
      get() {
        return this.unitCount;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'totalUnits', value });
      }
    },
    totalRows: {
      get() {
        return this.rows;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'totalRows', value });
      }
    },
    currentPage: {
      get() {
        return this.page;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'currentPage', value });
      }
    },
    asideIsBusy: {
      get() {
        return this.asideBusyFlag;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'asideIsBusy', value });
      }
    },
    pageSize: {
      get() {
        return this.itemsPerPage;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'pageSize', value });
      }
    },
    resultTableColumns: {
      get() {
        return this.resultColumns;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'resultTableColumns', value });
      }
    },
    showBulkServiceRequestModal: {
      get() {
        return this.bulkServiceRequestModalFlag;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'showBulkServiceRequestModal', value });
      }
    },
    operationsForecastUniqueUnits: {
      get() {
        return this.uniqueUnits;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'operationsForecastUniqueUnits', value });
      }
    }
  },
  methods: {
    ...mapMutations([OperationsForecastMutations.SET_PROP])
  }
};
