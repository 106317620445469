<template>
  <div>
    <service-order-summary />
    <hr />

    <service-order-header readonly />

    <div class="pt-3" />

    <service-order-line-items
      readonly
      :has-posted-linked-service-order="hasPostedLinkedServiceOrder"
      @updateApprovalChangesFlag="updateApprovalChangesFlag"
    />

    <service-order-footer />

    <b-row class="my-3 pb-3">
      <b-button
        v-if="!hasPostedLinkedServiceOrder"
        :disabled="stage !== FORM_STAGES.EDIT_SO || !hasApprovalChangesFlag"
        variant="primary"
        @click="updateServiceOrder"
      >
        <div v-show="stage == FORM_STAGES.SAVING_SO">
          <b-spinner small></b-spinner>
          Saving...
        </div>
        <div v-show="stage != FORM_STAGES.SAVING_SO">Save Estimate</div>
      </b-button>
    </b-row>

    <unsaved-changes-modal
      ref="ApprovalRejectionWarningModal"
      :has-changes="hasApprovalChangesFunction"
      title="Save Changes"
      message="Approved/Rejected/Deferred statuses cannot be altered after saving.  Are you sure you want to Save?"
      continue-btn-text="Save changes"
    ></unsaved-changes-modal>
  </div>
</template>

<script>
// components
import ServiceOrderSummaryComponent from './ServiceOrderSummaryComponent';
import ServiceOrderHeaderComponent from './ServiceOrderHeaderComponent';
import ServiceOrderFooterComponent from './ServiceOrderFooterComponent';
import ServiceOrderLineItemsComponent from './line-items/ServiceOrderLineItemsComponent';
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';

import { UserGetters } from '@/shared/store/user/types';
import { ServiceOrderActions, ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { FORM_STAGES } from '@/shared/store/service-order/state';
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';

export default {
  name: 'ServiceOrderDetails',
  components: {
    'service-order-summary': ServiceOrderSummaryComponent,
    'service-order-header': ServiceOrderHeaderComponent,
    'service-order-line-items': ServiceOrderLineItemsComponent,
    'unsaved-changes-modal': UnsavedChangesModal,
    'service-order-footer': ServiceOrderFooterComponent
  },
  data: function () {
    return {
      FORM_STAGES: FORM_STAGES,
      hasApprovalChangesFlag: false,
      hasPostedLinkedServiceOrder: false
    };
  },
  computed: {
    ...mapGetters({
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      serviceOrderId: ServiceOrderGetters.GET_SERVICE_ORDER_ID,
      estimateId: ServiceOrderGetters.GET_ESTIMATE_ID,
      jobs: ServiceOrderGetters.GET_JOBS
    }),
    ...mapGetters([ServiceOrderGetters.GET_FORM_STAGE]),
    stage: {
      get() {
        return this[ServiceOrderGetters.GET_FORM_STAGE];
      },
      set(value) {
        this[ServiceOrderMutations.SET_FORM_STAGE](value);
      }
    },
    unitId() {
      return this.$router.currentRoute.params.unitId;
    }
  },
  created: function () {
    this.stage = FORM_STAGES.EDIT_SO;

    this.hasPostedLinkedServiceOrder = this.serviceOrder.readonly;
  },
  methods: {
    ...mapActions([ServiceOrderActions.UPDATE_SERVICE_ORDER]),
    ...mapMutations([ServiceOrderMutations.SET_FORM_STAGE]),
    hasApprovalChangesFunction() {
      return this.hasApprovalChangesFlag;
    },
    hasChanges() {
      return this.hasApprovalChangesFlag;
    },
    updateApprovalChangesFlag(flagValue) {
      this.hasApprovalChangesFlag = flagValue;
    },
    async updateServiceOrder() {
      var isVcpUser = this.$store.getters[UserGetters.AUTHORIZE_ROLE](['VcpUser']);
      var isVcpApprover = this.$store.getters[UserGetters.AUTHORIZE_ROLE](['VcpApprover']);

      if (isVcpUser && !isVcpApprover) return;

      if (this.stage !== FORM_STAGES.EDIT_SO) {
        return;
      }
      var vm = this;

      var handleUpdateServiceOrder = async () => {
        try {
          vm.stage = FORM_STAGES.SAVING_SO;

          const estimate = {
            estimateId: vm.estimateId,
            jobs: Object.values(vm.jobs)
          };

          const response = await vm[ServiceOrderActions.UPDATE_SERVICE_ORDER]({
            unitId: vm.unitId,
            serviceOrderId: vm.serviceOrderId,
            unitServiceOrder: estimate
          });
          SuccessService.createSuccessToast(vm.$root, `Estimate #${response.serviceOrderId} saved successfully.`);
        } catch (error) {
          ErrorService.createErrorToast(vm.$root, 'Error updating estimate.');
        } finally {
          vm.stage = FORM_STAGES.EDIT_SO;
          vm.updateApprovalChangesFlag(false);
        }
      };

      var handleNextAction = () => {
        vm.stage = FORM_STAGES.EDIT_SO;
      };

      this.$refs.ApprovalRejectionWarningModal.show(vm, handleNextAction, handleUpdateServiceOrder);
    }
  }
};
</script>
