export const namespace = 'unit/unitStatusHistory/';

export const UnitStatusHistoryGetters = {
  GET_UNIT_STATUS_HISTORY: namespace + 'getUnitStatusHistory'
};

export const UnitStatusHistoryActions = {
  FETCH_UNIT_STATUS_HISTORY: namespace + 'fetchUnitStatusHistory'
};

export const UnitStatusHistoryMutations = {
  SET_UNIT_STATUS_HISTORY: namespace + 'setUnitStatusHistory'
};
