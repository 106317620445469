import axios from 'axios';

const BRANCH_PATH = '/api/branch';

class BranchService {
  async getTechs(branchId) {
    const url = `${BRANCH_PATH}/${encodeURIComponent(branchId)}/techs`;
    const techs = await axios.get(url);
    return techs;
  }

  async getEmployees(branchId) {
    const url = `${BRANCH_PATH}/${encodeURIComponent(branchId)}/employees`;
    const techs = await axios.get(url);
    return techs;
  }

  async getWarrantyRecipients(branchId) {
    const url = `${BRANCH_PATH}/${encodeURIComponent(branchId)}/warranty-recipients`;
    const techs = await axios.get(url);
    return techs;
  }

  async getParts(branchId, search) {
    const url = `${BRANCH_PATH}/${encodeURIComponent(branchId)}/parts${
      search ? '?searchCriteria=' + encodeURIComponent(search) : ''
    }`;
    const parts = await axios.get(url);
    return parts;
  }

  async getPartQuote(branchId, customerId, unitId, parameters) {
    const url = `${BRANCH_PATH}/${encodeURIComponent(branchId)}/${encodeURIComponent(
      unitId
    )}/part-quote/${encodeURIComponent(customerId)}`;
    const partQuoteResponse = axios.post(url, parameters);
    return partQuoteResponse;
  }
}

export default new BranchService();
