import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import state from './state';

// Modules
import mountedEquipment from './mounted-equipment';
import outOfService from './out-of-service';
import unitStatusHistory from './unit-status-history';
import unitLocationHistory from './unit-location-history';

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    mountedEquipment,
    outOfService,
    unitStatusHistory,
    unitLocationHistory
  }
};
