import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, PartsRequestMutations } from './types';
import { getDefaultState } from './state';
import Cloner from '@/shared/helpers/cloner';
import Vue from 'vue';

const _mutations = namespaceHelper.removeNamespace(namespace, PartsRequestMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_PROP](state, { key, value }) {
    Vue.set(state.partsRequest, key, value);
  },
  [_mutations.SET_PART_PROP](state, { index, key, value }) {
    Vue.set(state.partsRequest.requestParts[index], key, value);
  },
  [_mutations.ADD_REQUEST_PART](state) {
    let part = { partNumber: null, partDescription: null, opsId: null, qty: null, isMounted: null };
    Vue.set(state.partsRequest.requestParts, state.partsRequest.requestParts.length, part);
  },
  [_mutations.ADD_DB_REQUEST_PART](state) {
    let part = { partNumber: null, partDescription: null, opsId: null, qty: null, isMounted: null };
    Vue.set(state.dbPartsRequest.requestParts, state.dbPartsRequest.requestParts.length, part);
  },
  [_mutations.REMOVE_REQUEST_PART](state, index) {
    Vue.delete(state.partsRequest.requestParts, index);
  },
  [_mutations.SET_REQUESTED_PARTS](state, requestedParts) {
    state.requestedParts = requestedParts;
  },
  [_mutations.SET_PARTS_REQUEST](state, partsRequest) {
    state.partsRequest = partsRequest;
    state.dbPartsRequest = Cloner.deepClone(partsRequest);
  },
  [_mutations.SET_PARTS_REQUEST_PROP](state, { key, value }) {
    Vue.set(state.partsRequest, key, value);
  },
  [_mutations.SET_ALL_OPS_EMPLOYEES](state, allOpsEmployees) {
    state.allOpsEmployees = allOpsEmployees;
  },
  [_mutations.SET_MATERIALS_EMPLOYEES](state, materialsEmployees) {
    state.materialsEmployees = materialsEmployees;
  },
  [_mutations.SET_PARTS_REQUEST_SEARCH_RESULTS](state, partsRequestSearchResults) {
    state.partsRequestSearchResults = partsRequestSearchResults;
  }
};
