export const namespace = 'inspection/';

export const InspectionGetters = {
  GET_INSPECTION: namespace + 'getInspection',
  GET_INSPECTION_ITEM: namespace + 'getInspectionItem',
  GET_AXLE_INSPECTIONS: namespace + 'getAxleInspections',
  GET_TIRE_INSPECTION_ITEM: namespace + 'getTireInspectionItem',
  GET_TIRE_CONFIGURATION_MAP: namespace + 'getTireConfigurationMap',
  GET_BRAKE_INSPECTION_ITEM: namespace + 'getBrakeInspectionItem',
  GET_BRAKE_CONFIGURATION_MAP: namespace + 'getBrakeConfigurationMap',
  GET_STAGE: namespace + 'getStage',
  HAS_CHANGES: namespace + 'hasChanges'
};

export const InspectionActions = {
  SWITCH_STAGE: namespace + 'switchStage',
  SEARCH_INSPECTIONS: namespace + 'searchInspections',
  CREATE_INSPECTION: namespace + 'createInspection',
  FETCH_INSPECTION: namespace + 'fetchInspection',
  FETCH_INSPECTIONS: namespace + 'fetchInspections',
  UPDATE_INSPECTION: namespace + 'updateInspection',
  SET_INSPECTION_AND_MAP: namespace + 'setInspectionAndMap',
  SET_AXLE_CONFIGURATION: namespace + 'setAxleConfiguration',
  PRINT_INSPECTION: namespace + 'printInspection',
  ASSIGN_SERVICE_ORDER: namespace + 'assignServiceOrder'
};

export const InspectionMutations = {
  RESET_STATE: namespace + 'resetState',
  SET_STAGE: namespace + 'setStage',
  SET_PROP: namespace + 'setProp',
  SET_ITEM_PROP: namespace + 'setItemProp',
  SET_TIRE_PROP: namespace + 'setTireProp',
  SET_BRAKE_PROP: namespace + 'setBrakeProp',
  SET_INSPECTION: namespace + 'setInspection',
  SET_DB_INSPECTION: namespace + 'setDbInspection',
  MAP_INSPECTION: namespace + 'mapInspection',
  SET_ITEM_MAP: namespace + 'setItemMap',
  SET_AXLE_INSPECTIONS: namespace + 'setAxleInspections',
  MAP_TIRE_CONFIGURATIONS: namespace + 'mapTireConfigurations',
  MAP_BRAKE_CONFIGURATIONS: namespace + 'mapBrakeConfigurations',
  SET_INSPECTION_SEARCH_RESULTS: namespace + 'setInspectionSearchResults',
  SET_ATTACHMENTS: namespace + 'setAttachments'
};
