import axios from 'axios';

const WARRANTY_PATH = '/api/warranties';
const WARRANTY_ADMIN_PATH = 'admins';
class WarrantyService {
  async getWarrantyAdmins() {
    const url = `${WARRANTY_PATH}/${WARRANTY_ADMIN_PATH}`;
    const adminList = await axios.get(url);
    return adminList;
  }
  async validateSalesOrderId(salesOrderId) {
    const url = `${WARRANTY_PATH}/salesOrders/${encodeURIComponent(salesOrderId)}`;
    const submission = await axios.get(url);
    return submission;
  }

  async searchWarranties(parameters) {
    const url = `${WARRANTY_PATH}`;
    const warranties = await axios.get(url, {
      params: parameters
    });
    return warranties;
  }

  async fetchSubmission(warrantySubmissionId) {
    const url = `${WARRANTY_PATH}/${encodeURIComponent(warrantySubmissionId)}`;
    const submission = await axios.get(url);
    return submission;
  }

  async createSubmission(parameters) {
    const url = `${WARRANTY_PATH}`;
    const submission = await axios.post(url, parameters);
    return submission;
  }

  async updateSubmission(parameters) {
    const url = `${WARRANTY_PATH}`;
    const submission = await axios.put(url, parameters);
    return submission;
  }
}

export default new WarrantyService(); // singleton object
