import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, OperationsForecastActions, OperationsForecastMutations } from './types';
import OperationsForecastService from '@/shared/services/OperationsForecastService';

const _mutations = namespaceHelper.removeNamespace(namespace, OperationsForecastMutations);
const _actions = namespaceHelper.removeNamespace(namespace, OperationsForecastActions);

export default {
  async [_actions.FETCH_OPERATIONS_FORECAST]({ commit }, parameters) {
    const operationsForecastResult = await OperationsForecastService.getOperationsForecast(parameters);
    commit(_mutations.SET_PROP, { key: 'operationsForecast', value: operationsForecastResult.data });
  }
};
