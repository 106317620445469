import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, CustomerMutations } from './types';
import { getDefaultState } from './state';
import Cloner from '@/shared/helpers/cloner';

const _mutations = namespaceHelper.removeNamespace(namespace, CustomerMutations);

export default {
  [_mutations.SET_CUSTOMER_SEARCH_RESULTS](state, payload) {
    state.customerSearchResults = payload;
  },
  [_mutations.SET_CUSTOMER](state, customer) {
    state.customer = customer;
    state.oldCustomer = Cloner.deepClone(customer);
  },
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_APPROVER_LIST](state, approverList) {
    state.approverList = approverList;
  },
  [_mutations.SET_VCP_USER_LIST](state, vcpUserList) {
    state.vcpUserList = vcpUserList;
  },
  [_mutations.SET_PROP](state, payload) {
    state.customer[payload.key] = payload.value;
  }
};
