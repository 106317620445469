<template>
  <div id="unit-edit-container" class="d-flex flex-column">
    <!-- fleetUnitId -->
    <b-form-group
      v-if="showField('fleetUnitId')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="Fleet Unit ID:"
      label-for="fleetUnitId"
    >
      <div v-if="readonly" id="fleetUnitId" class="col-form-label">
        {{ fleetUnitId || NA }}
      </div>
      <div v-else class="d-flex">
        <b-form-input
          id="fleetUnitId"
          v-model="$v.fleetUnitId.$model"
          maxlength="50"
          type="text"
          :state="$v.$dirty && hasError($v.fleetUnitId) ? false : null"
        />
        <span v-if="isRequired('fleetUnitId')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </div>
      <div v-show="$v.$dirty && hasError($v.fleetUnitId, 'required')" class="error">Enter Fleet Unit ID.</div>
    </b-form-group>

    <!-- vin -->
    <b-form-group
      v-if="showField('vin')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="VIN/Serial:"
      label-for="vin"
    >
      <div v-if="readonly" id="vin" class="col-form-label">
        {{ vin || NA }}
      </div>
      <div v-else class="d-flex">
        <b-form-input id="vin" type="text" :value="vin" readonly />
        <span v-if="isRequired('vin')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </div>
    </b-form-group>

    <!-- licensePlate -->
    <b-form-group
      v-if="showField('licensePlate')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="License Plate:"
      label-for="licensePlate"
    >
      <div v-if="readonly" id="licensePlate" class="col-form-label">
        {{ licensePlate || NA }}
      </div>
      <div v-else class="d-flex">
        <b-form-input
          id="licensePlate"
          v-model="$v.licensePlate.$model"
          :state="$v.$dirty && hasError($v.licensePlate) ? false : null"
          maxlength="15"
          type="text"
        />
        <span v-if="isRequired('licensePlate')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </div>
      <div v-show="$v.$dirty && hasError($v.licensePlate, 'required')" class="error">Enter License Plate.</div>
      <div v-show="$v.$dirty && hasError($v.licensePlate, 'alphaNumericSpecial')" class="error">
        {{ alphaNumericSpecialErrorMessage }}
      </div>
    </b-form-group>

    <!-- locationId -->
    <b-form-group
      v-if="showField('locationId')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="Location:"
      label-for="location"
    >
      <div v-if="readonly" id="locationId" class="col-form-label">
        {{ location ? location.description : 'NONE' }}
      </div>
      <div v-else-if="creating" class="d-flex">
        <v-select
          v-model="$v.locationId.$model"
          :class="`${hasError($v.locationId) ? 'is-invalid' : ''}`"
          label="description"
          :options="activeUnitLocations"
          :reduce="l => l.id"
          :clearable="false"
          placeholder="Select A Location"
          select-on-tab
        >
          <template #selected-option="l">
            <span class="text-uppercase">{{ l.description }}</span>
          </template>
        </v-select>
        <span v-if="isRequired('locationId')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </div>
      <div v-else class="d-flex">
        <b-form-input id="locationId" type="text" :value="location ? location.description : 'NONE'" readonly />
        <span class="required-placeholder" />
      </div>
      <div v-show="$v.$dirty && hasError($v.locationId, 'required')" class="error">Select a Location.</div>
    </b-form-group>

    <!-- customerName -->
    <b-form-group
      v-if="showField('customerName')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="Customer Name:"
      label-for="customerName"
    >
      <div v-if="readonly" id="customerName" class="col-form-label">
        {{ customerName || NA }}
      </div>
      <div v-else class="d-flex">
        <b-form-input id="customerName" type="text" :value="customerName" readonly />
        <span v-if="isRequired('customerName')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </div>
    </b-form-group>

    <!-- customerId -->
    <b-form-group
      v-if="showField('customerId')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="Customer ID:"
      label-for="customerId"
    >
      <div v-if="readonly" id="customerId" class="col-form-label">
        {{ customerId || NA }}
      </div>
      <router-link v-else class="d-flex" :to="'/customers/' + customerId">
        <b-form-input v-if="!readonly" id="customerId" type="text" :value="customerId" readonly />
        <span v-if="isRequired('customerId')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </router-link>
    </b-form-group>

    <!-- unitTypeId -->
    <b-form-group
      v-if="showField('unitTypeId')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="Unit Type:"
      label-for="unitType"
    >
      <div v-if="readonly" id="unitTypeId" class="col-form-label">
        {{ unitTypeDescription || NA }}
      </div>
      <div v-else class="d-flex">
        <v-select
          v-model="$v.unitTypeId.$model"
          placeholder="Select Unit Type"
          label="description"
          :filter-by="filterUnitTypes"
          :options="unitTypesList"
          select-on-tab
          :reduce="unitType => unitType.id"
          :clear-search-on-blur="() => true"
          :state="$v.$dirty && hasError($v.unitTypeId) ? false : null"
        >
          <template #no-option>No unit types available</template>
        </v-select>

        <span v-if="isRequired('unitTypeId')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </div>
      <div v-show="$v.$dirty && hasError($v.unitTypeId, 'required')" class="error">Select a Unit Type.</div>
    </b-form-group>

    <!-- unitStatusId -->
    <b-form-group
      v-if="showField('unitStatusId')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="Unit Status:"
      label-for="unitStatus"
    >
      <div v-if="readonly" id="unitStatusId" class="col-form-label">
        {{ unitStatusDescription || NA }}
      </div>
      <div v-else class="d-flex">
        <b-form-input id="unitStatusId" type="text" :value="unitStatusDescription" readonly />
        <span class="required-placeholder" />
      </div>
    </b-form-group>

    <!-- make -->
    <b-form-group
      v-if="showField('make')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="Make:"
      label-for="make"
    >
      <div v-if="readonly" id="make" class="col-form-label">
        {{ make || NA }}
      </div>
      <div v-else class="d-flex">
        <b-form-input
          id="make"
          v-model="$v.make.$model"
          maxlength="15"
          type="text"
          :state="$v.$dirty && hasError($v.make) ? false : null"
        />
        <span v-if="isRequired('make')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </div>
      <div v-show="$v.$dirty && hasError($v.make, 'required')" class="error">Enter Make.</div>
    </b-form-group>

    <!-- model -->
    <b-form-group
      v-if="showField('model')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="Model:"
      label-for="model"
    >
      <div v-if="readonly" id="model" class="col-form-label">
        {{ model || NA }}
      </div>
      <div v-else class="d-flex">
        <b-form-input
          id="model"
          v-model="$v.model.$model"
          maxlength="15"
          type="text"
          :state="$v.$dirty && hasError($v.model) ? false : null"
        />
        <span v-if="isRequired('model')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </div>
      <div v-show="$v.$dirty && hasError($v.model, 'required')" class="error">Enter Model.</div>
    </b-form-group>

    <!-- year -->
    <b-form-group
      v-if="showField('year')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="Model Year:"
      label-for="year"
    >
      <div v-if="readonly" id="year" class="col-form-label">
        {{ year || NA }}
      </div>
      <div v-else class="d-flex">
        <b-form-input
          id="year"
          v-model="$v.year.$model"
          :state="$v.$dirty && hasError($v.year) ? false : null"
          type="number"
          @keypress="NumberFieldHelper.isNumber($event)"
        />
        <span v-if="isRequired('year')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </div>
      <div v-show="$v.$dirty && hasError($v.year)" class="error">
        Enter a Model Year lower or equal to the current year plus 1.
      </div>
    </b-form-group>

    <!-- mileage -->
    <b-form-group
      v-if="showField('mileage')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="Mileage:"
      label-for="mileage"
    >
      <template #label>
        Mileage:
        <sup>
          <font-awesome-icon
            id="mileage_tooltip"
            icon="info-circle"
            size="lg"
            class="mx-1 text-primary align-self-center"
            display="inline-block"
          />
          <b-tooltip target="mileage_tooltip" triggers="hover">
            Last Updated {{ unit.dateMileageLastUpdated | date }}
          </b-tooltip>
        </sup>
      </template>
      <div v-if="readonly" id="mileage" class="col-form-label">
        {{ mileage || NA }}
      </div>
      <div v-else class="d-flex">
        <b-form-input
          id="mileage"
          v-model.number="$v.mileage.$model"
          :class="{ warning: historyWarnings.mileage }"
          :state="$v.$dirty && hasError($v.mileage) ? false : null"
          type="number"
          @keypress="NumberFieldHelper.isNumber($event)"
        />
        <span v-if="isRequired('mileage')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </div>
      <div v-show="$v.$dirty && hasError($v.mileage, 'required')" class="error">Enter Mileage.</div>
      <div v-show="$v.$dirty && hasError($v.mileage, 'between', 'numeric')" class="error">
        Enter a number between
        {{ $v.mileage.$params.between.min }} and {{ $v.mileage.$params.between.max }}.
      </div>
      <warning-alert v-show="historyWarnings.mileage">
        Mileage must be greater than previous input
        {{ mileageTracker.originalValue }}.
      </warning-alert>
    </b-form-group>

    <!-- averageWeeklyMileage -->
    <b-form-group
      v-if="showField('averageWeeklyMileage')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="Average Weekly Mileage:"
      label-for="averageWeeklyMileage"
    >
      <div v-if="readonly" id="averageWeeklyMileage" class="col-form-label">
        {{ averageWeeklyMileage || NA }}
      </div>
      <div v-else class="d-flex">
        <b-form-input
          id="averageWeeklyMileage"
          v-model.number="$v.averageWeeklyMileage.$model"
          :state="$v.$dirty && hasError($v.averageWeeklyMileage) ? false : null"
          type="text"
          @keypress="NumberFieldHelper.isNumber($event)"
        />
        <span v-if="isRequired('averageWeeklyMileage')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </div>
      <div v-show="$v.$dirty && hasError($v.averageWeeklyMileage, 'between', 'numeric', 'required')" class="error">
        Enter a number between
        {{ $v.averageWeeklyMileage.$params.between.min }} and {{ $v.averageWeeklyMileage.$params.between.max }}.
      </div>
    </b-form-group>

    <!-- engineHours -->
    <b-form-group
      v-if="showField('engineHours')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="Engine Hours:"
      label-for="engineHours"
    >
      <template #label>
        Engine Hours:
        <sup>
          <font-awesome-icon
            id="engine_hours_tooltip"
            icon="info-circle"
            size="lg"
            class="mx-1 text-primary align-self-center"
            display="inline-block"
          />
          <b-tooltip target="engine_hours_tooltip" triggers="hover">
            Last Updated {{ unit.dateEngineHoursLastUpdated | date }}
          </b-tooltip>
        </sup>
      </template>
      <div v-if="readonly" id="engineHours" class="col-form-label">
        {{ engineHours || NA }}
      </div>
      <div v-else class="d-flex">
        <b-form-input
          id="engineHours"
          v-model.number="$v.engineHours.$model"
          :class="{ warning: historyWarnings.engineHours }"
          :state="$v.$dirty && hasError($v.engineHours) ? false : null"
          type="number"
          @keypress="NumberFieldHelper.isNumber($event)"
        />
        <span v-if="isRequired('engineHours')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </div>
      <div v-show="$v.$dirty && hasError($v.engineHours, 'required')" class="error">Enter Engine Hours.</div>
      <div v-show="$v.$dirty && hasError($v.engineHours, 'between', 'numeric')" class="error">
        Enter a number between
        {{ $v.engineHours.$params.between.min }} and {{ $v.engineHours.$params.between.max }}.
      </div>
      <warning-alert v-show="historyWarnings.engineHours">
        Engine Hours must be greater than previous input
        {{ engineHoursTracker.originalValue }}
      </warning-alert>
    </b-form-group>

    <!-- averageWeeklyEngineHours -->
    <b-form-group
      v-if="showField('averageWeeklyEngineHours')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
      label="Average Weekly Engine Hours:"
      label-for="averageWeeklyEngineHours"
    >
      <div v-if="readonly" id="averageWeeklyEngineHours" class="col-form-label">
        {{ averageWeeklyEngineHours || NA }}
      </div>
      <div v-else class="d-flex">
        <b-form-input
          id="averageWeeklyEngineHours"
          v-model.number="$v.averageWeeklyEngineHours.$model"
          :state="$v.$dirty && hasError($v.averageWeeklyEngineHours) ? false : null"
          type="text"
          @keypress="NumberFieldHelper.isNumber($event)"
        />
        <span v-if="isRequired('averageWeeklyEngineHours')" class="required-asterisk">*</span>
        <span v-else class="required-placeholder" />
      </div>
      <div v-show="$v.$dirty && hasError($v.averageWeeklyEngineHours, 'between', 'numeric', 'required')" class="error">
        Enter a number between
        {{ $v.averageWeeklyEngineHours.$params.between.min }} and {{ $v.averageWeeklyEngineHours.$params.between.max }}.
      </div>
    </b-form-group>

    <!-- dateInService -->
    <date-time-input
      v-if="showField('dateInService')"
      id="dateInService"
      v-model="$v.dateInService.$model"
      :validator="$v.dateInService"
      label="Date In Service:"
      :required="isRequired('dateInService')"
      v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols, readonly }"
      hide-time
    >
      <template #default>
        <div v-show="$v.$dirty && hasError($v.dateInService, 'required')" class="error">Enter Date In Service.</div>
        <div v-show="$v.$dirty && hasError($v.dateInService, 'minValue')" class="error">
          Date must be formatted as MM/DD/YYYY
        </div>
      </template>
    </date-time-input>
  </div>
</template>

<script>
// components
import WarningAlertComponent from '@/shared/components/WarningAlertComponent';
import DateTimeInput from '@/shared/components/ui/DateTimeInput';
import vSelect from 'vue-select';
// vuex
import { mapGetters } from 'vuex';
import { LookupGetters } from '@/shared/store/lookup/types';
import { UnitGetters } from '@/shared/store/unit/types';
// helpers
import { required, numeric, between, minValue } from 'vuelidate/lib/validators';
import { hasError, alphaNumericSpecial, alphaNumericSpecialErrorMessage } from '@/shared/helpers/validator-helper.js';
import ErrorService from '@/shared/services/ErrorService';
import NumberFieldHelper from '@/shared/helpers/number-field-helper';
import FormGroupPropsMixin from '@/shared/mixins/FormGroupPropsMixin';
import { mapComputedGetSets } from '@/shared/helpers/form-generation';

const maxNumber = 200000000;

export default {
  name: 'UnitEdit',
  components: {
    'warning-alert': WarningAlertComponent,
    'date-time-input': DateTimeInput,
    'v-select': vSelect
  },
  mixins: [FormGroupPropsMixin],
  props: {
    readonly: Boolean(),
    hideFields: {
      type: Array,
      default: () => null
    },
    additionalRequiredFields: {
      type: Array,
      default: () => []
    },
    defaultRequiredFields: {
      type: Array,
      default: () => ['mileage', 'engineHours']
    },
    creating: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      NA: 'N/A',
      NumberFieldHelper,
      hasError,
      alphaNumericSpecialErrorMessage,
      allowOverride: true,
      engineHoursTracker: {},
      mileageTracker: {},
      historyWarnings: {
        engineHours: false,
        mileage: false
      },
      requiredFields: []
    };
  },
  validations() {
    const validations = {
      fleetUnitId: {},
      customerName: {},
      customerId: {},
      vin: {},
      licensePlate: {
        alphaNumericSpecial
      },
      locationId: {},
      unitTypeId: {},
      make: {},
      model: {},
      year: {
        numeric,
        between: between(0, new Date().getFullYear() + 1)
      },
      mileage: {
        numeric,
        between: between(0, maxNumber)
      },
      averageWeeklyMileage: {
        numeric,
        between: between(0, maxNumber)
      },
      engineHours: {
        numeric,
        between: between(0, maxNumber)
      },
      averageWeeklyEngineHours: {
        numeric,
        between: between(0, maxNumber)
      },
      dateInService: {
        minValue: minValue(new Date().setFullYear(1753))
      }
    };
    for (const id of this.requiredFields) {
      validations[id] = { ...validations[id], required };
    }
    return validations;
  },
  computed: {
    ...mapGetters({
      unit: UnitGetters.GET_UNIT,
      unitLocationsMap: LookupGetters.GET_LOCATIONS_MAP,
      unitLocationsList: LookupGetters.GET_LOCATIONS_LIST,
      unitTypesMap: LookupGetters.GET_UNIT_TYPES_MAP,
      unitTypesList: LookupGetters.GET_UNIT_TYPES_LIST,
      unitStatusesMap: LookupGetters.GET_UNIT_STATUSES_MAP
    }),
    activeUnitLocations() {
      return this.unitLocationsList.filter(l => !l.inactive);
    },
    location() {
      const unitLocation = this.unitLocationsMap[this.unit.locationId];
      return unitLocation;
    },
    unitStatusDescription() {
      const unitStatus = this.unitStatusesMap[this.unit.unitStatusId];
      return unitStatus?.description;
    },
    unitTypeDescription() {
      const unitType = this.unitTypesMap[this.unit.unitTypeId];
      return unitType?.description;
    },
    formGroupIds() {
      return document
        .getElementById('unit-edit-container')
        .getElementsByClassName('form-row form-group')
        .map(ele => ele.id);
    },
    vin() {
      return this.unit.vin;
    },
    customerName() {
      return this.unit.customerName;
    },
    customerId() {
      return this.unit.customerId;
    },
    ...mapComputedGetSets(
      [
        'fleetUnitId',
        'licensePlate',
        'locationId',
        'unitTypeId',
        'make',
        'model',
        'year',
        'mileage',
        'averageWeeklyMileage',
        'engineHours',
        'averageWeeklyEngineHours',
        'dateInService'
      ],
      {
        store: 'unit',
        object: 'unit'
      }
    )
  },
  watch: {
    unit: {
      async handler() {
        this.mileageTracker.originalValue = this.unit.mileage;
        this.mileageTracker.previousValue = -1;

        this.engineHoursTracker.originalValue = this.unit.engineHours;
        this.engineHoursTracker.previousValue = -1;
      },
      immediate: true
    }
  },
  mounted() {
    this.requiredFields = this.defaultRequiredFields.concat(this.additionalRequiredFields);
  },
  methods: {
    filterUnitTypes(option, label, search) {
      return option.description && option.description.toLowerCase().indexOf(search.toLowerCase()) > -1;
    },
    checkWarnings() {
      this.validateMileageHistory();
      this.validateEngineHoursHistory();
      if (!this.allowOverride) {
        this.allowOverride = true;
        return false;
      }
      return true;
    },
    validateMileageHistory() {
      var errorMessage = isGreaterOrEqualNumber(this.mileageTracker, this.unit.mileage, 'Mileage');
      if (errorMessage != undefined && this.historyWarnings.mileage == false) {
        this.historyWarnings.mileage = true;
        this.allowOverride = false;
        ErrorService.createWarningToast(this, errorMessage);
      }
    },
    validateEngineHoursHistory() {
      var errorMessage = isGreaterOrEqualNumber(this.engineHoursTracker, this.unit.engineHours, 'Engine Hours');
      if (errorMessage != undefined && this.historyWarnings.engineHours == false) {
        this.historyWarnings.engineHours = true;
        this.allowOverride = false;
        ErrorService.createWarningToast(this, errorMessage);
      }
    },
    resetWarnings() {
      this.historyWarnings = {
        mileage: false,
        engineHours: false
      };
    },
    showField(id) {
      if (!this.hideFields || this.hideFields.length == 0) return true;
      return !this.hideFields.includes(id);
    },
    isRequired(id) {
      return this.requiredFields.includes(id);
    }
  }
};

function isGreaterOrEqualNumber(tracker, currentValue, parameterName) {
  if (tracker.previousValue == currentValue) {
    return;
  }

  if (tracker.originalValue > currentValue) {
    tracker.previousValue = currentValue;
    return `${parameterName} must be greater than previous input (${tracker.originalValue}); click save again to override.`;
  }
  return;
}
</script>
