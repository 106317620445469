<template>
  <div>
    <b-row>
      <b-col><div class="line-titles">MISC</div></b-col>
    </b-row>

    <div v-for="(misc, miscIndex) in miscLines" :key="miscIndex">
      <b-row v-if="!misc.toBeDeleted" :class="miscIndex < miscLines.length - 1 ? 'pb-1' : ''">
        <b-col :lg="misc._searchingMisc ? 6 : 2" :cols="misc._searchingMisc ? 12 : 4">
          <b-form-group
            label="CODE:"
            :label-class="miscIndex > 0 ? 'col-head line-item' : 'col-head line-item first-row'"
            :label-for="`op-${opId}-misc-${miscIndex}-code`"
          >
            <div v-if="readonly" :id="`op-${opId}-misc-${miscIndex}-code`" class="pl-0">
              {{ misc.miscCode || NA }}
            </div>
            <div v-else>
              <b-input-group>
                <v-select
                  v-if="misc._local"
                  :id="`op-${opId}-misc-${miscIndex}-code`"
                  :value="misc"
                  :options="miscCodesList"
                  :filter-by="filterMiscCodes"
                  :clearable="false"
                  label="miscCode"
                  :class="hasError(vMisc(miscIndex).miscCode, 'required') ? 'is-invalid' : ''"
                  @input="updateMiscCode(miscIndex, $event)"
                  @search:focus="toggleMiscSearch(miscIndex, true)"
                  @search:blur="toggleMiscSearch(miscIndex, false)"
                >
                  <template #selected-option="mis">
                    <template v-if="misc.miscCode">
                      <span class="text-uppercase">{{ mis.miscCode }}</span>
                    </template>
                  </template>
                  <template #option="mis">
                    <span class="text-uppercase">{{ mis.miscCode }} - {{ mis.description }}</span>
                  </template>
                </v-select>
                <b-form-input v-else :id="`op-${opId}-misc-${miscIndex}-code`" :value="misc.miscCode" disabled />
              </b-input-group>
              <div v-if="hasError(vMisc(miscIndex).miscCode, 'required')" class="error">
                Enter a valid Miscellaneous Code.
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col v-show="!misc._searchingMisc" lg="4" cols="8">
          <b-form-group
            label="DESCRIPTION:"
            :label-class="miscIndex > 0 ? 'col-head line-item' : 'col-head line-item first-row'"
            :label-for="`op-${opId}-misc-${miscIndex}-description`"
          >
            <div v-if="readonly" :id="`op-${opId}-misc-${miscIndex}-description`" class="pl-0">
              {{ misc.description || NA }}
            </div>
            <div v-else>
              <b-input-group>
                <b-form-input
                  :id="`op-${opId}-misc-${miscIndex}-description`"
                  :value="misc.description"
                  :disabled="!misc._local && !allowEdit"
                  :state="hasError(vMisc(miscIndex).description, 'required') ? false : null"
                  @input="updateDescription(miscIndex, $event)"
                />
              </b-input-group>
              <div v-if="hasError(vMisc(miscIndex).description, 'required')" class="error">Enter a description.</div>
            </div>
          </b-form-group>
        </b-col>

        <b-col v-if="!isPackagedPricing || !$isCustomer" lg="2" cols="4">
          <b-form-group
            label="PRICE:"
            :label-class="miscIndex > 0 ? 'col-head line-item' : 'col-head line-item first-row'"
            :label-for="`op-${opId}-misc-${miscIndex}-price`"
          >
            <div v-if="readonly" :id="`op-${opId}-misc-${miscIndex}-price`" class="pl-0">
              {{ misc.price | currency }}
            </div>
            <b-input-group v-else prepend="$">
              <b-form-input
                :id="`op-${opId}-misc-${miscIndex}-price`"
                v-currency="currencyOptions"
                :value="misc.price"
                :disabled="!misc._local && !allowEdit"
                class="rounded-right"
                @input="updateMiscPrice(miscIndex, $event)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="2" cols="4">
          <b-form-group
            label="QTY:"
            :label-class="miscIndex > 0 ? 'col-head line-item' : 'col-head line-item first-row'"
            :label-for="`op-${opId}-misc-${miscIndex}-quantity`"
          >
            <div v-if="readonly" :id="`op-${opId}-misc-${miscIndex}-quantity`" class="pl-0">
              {{ misc.quantity }}
            </div>
            <div v-else>
              <b-input-group>
                <b-form-input
                  :id="`op-${opId}-misc-${miscIndex}-quantity`"
                  :value="misc.quantity"
                  :state="hasError(vMisc(miscIndex).quantity, 'required') ? false : null"
                  :disabled="!misc._local && !allowEdit"
                  class="rounded-right"
                  @keypress="NumberFieldHelper.isDecimal($event, true)"
                  @blur="updateQuantity(miscIndex, $event.target.value)"
                />
              </b-input-group>
              <div v-if="hasError(vMisc(miscIndex).quantity, 'required')" class="error">Enter a quantity.</div>
            </div>
          </b-form-group>
        </b-col>
        <b-col v-if="!readonly" xl="1" md="2" cols="4">
          <b-form-group
            label="ACTIONS:"
            :label-class="`col-head line-item invisible ${miscIndex > 0 ? '' : 'first-row'}`"
            :label-for="`op-${opId}-misc-${miscIndex}-actions`"
          >
            <div class="d-block">
              <b-button v-b-tooltip size="xsm" class="m-1" title="Remove" @click="removeMiscLine(miscIndex)">
                <font-awesome-icon icon="trash-alt" />
              </b-button>
            </div>
          </b-form-group>
        </b-col>
        <div class="line-wrap-border" />
      </b-row>
    </div>
    <b-row v-if="!readonly">
      <b-col offset-lg="8" lg="2" offset="10" cols="2">
        <b-button class="p-0 float-right mt-neg4px" variant="link" size="xsm" @click="addMiscLine()">
          <font-awesome-icon icon="plus" />
          Add Row
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { parse } from 'vue-currency-input';
import ErrorService from '@/shared/services/ErrorService';
import { mapMutations, mapGetters } from 'vuex';
import { ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import NumberFieldHelper from '@/shared/helpers/number-field-helper';
import { hasError } from '@/shared/helpers/validator-helper.js';

export default {
  name: 'ServiceOrderMiscLineComponent',
  components: {
    vSelect
  },
  inject: {
    validator: {
      from: 'validator',
      default: () => null
    }
  },
  props: {
    jobKey: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isPackagedPricing: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      NumberFieldHelper: NumberFieldHelper,
      currencyOptions: {
        currency: null,
        locale: 'en-US',
        allowNegative: false
      }
    };
  },
  computed: {
    ...mapGetters([ServiceOrderGetters.GET_MISC_LINES]),
    ...mapGetters({
      jobs: ServiceOrderGetters.GET_JOBS,
      miscCodesList: LookupGetters.GET_MISC_CODES_LIST
    }),
    opId() {
      return this.jobs[this.jobKey].operationId;
    },
    miscLines() {
      return this[ServiceOrderGetters.GET_MISC_LINES](this.jobKey);
    },
    miscCodeValidation() {
      return this.validator.jobs.$each[this.jobKey].details.miscLines.$each.$iter;
    }
  },
  methods: {
    ...mapMutations([
      ServiceOrderMutations.ADD_MISC_LINE,
      ServiceOrderMutations.REMOVE_MISC_LINE,
      ServiceOrderMutations.SET_MISC_CODE,
      ServiceOrderMutations.SET_MISC_DESCRIPTION,
      ServiceOrderMutations.SET_MISC_PRICE,
      ServiceOrderMutations.SET_MISC_QTY,
      ServiceOrderMutations.SET_MISC_SEARCH
    ]),
    addMiscLine() {
      let missingCode = false;
      for (let index in this.miscCodeValidation) {
        const v = this.miscCodeValidation[index];
        v.miscCode.$touch();
        if (!v.miscCode.required) missingCode = true;
      }
      if (missingCode) {
        const errorMessage = `Complete current line before adding an additional Miscellaneous Code.`;
        ErrorService.createErrorToast(this, errorMessage);
      } else {
        this[ServiceOrderMutations.ADD_MISC_LINE](this.jobKey);
        this.vMisc(this.miscLines.length - 1).$reset();
      }
    },
    removeMiscLine(index) {
      this[ServiceOrderMutations.REMOVE_MISC_LINE]({ jobKey: this.jobKey, index: index });
    },
    updateMiscCode(index, value) {
      this[ServiceOrderMutations.SET_MISC_CODE]({
        jobKey: this.jobKey,
        index: index,
        miscCode: value.miscCode,
        description: value.description,
        price: value.price,
        quantity: value.quantity
      });
      this.vMisc(index).miscCode.$touch();
    },
    updateDescription(index, description) {
      this[ServiceOrderMutations.SET_MISC_DESCRIPTION]({ jobKey: this.jobKey, index, description });
    },
    updateMiscPrice(index, miscPrice) {
      let price = parse(miscPrice, this.currencyOptions);
      this[ServiceOrderMutations.SET_MISC_PRICE]({ jobKey: this.jobKey, index, price });
    },
    updateQuantity(index, qty) {
      const quantity = qty ? parseFloat(qty) : qty;
      this[ServiceOrderMutations.SET_MISC_QTY]({ jobKey: this.jobKey, index, quantity });
    },
    filterMiscCodes(option, label, search) {
      return (
        option.miscCode.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        option.description.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    },
    toggleMiscSearch(miscIndex, value) {
      this[ServiceOrderMutations.SET_MISC_SEARCH]({ jobKey: this.jobKey, miscIndex, value });
    },
    vMisc(miscIndex) {
      return this.miscCodeValidation[miscIndex];
    },
    hasError: hasError
  }
};
</script>
