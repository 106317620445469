/**
 * function to generate duplicate computed properties for editing forms
 *
 * @param {string[]} props
 * @param {Object} [options]
 * @param {string} [options.store]    - namespace used in store where mutation is defined
 * @param {string} [options.object]   - the name of the object the prop is under, if null prop is on root of this
 * @param {string} [options.prepend]  - string to prepend to the prop name
 * @param {string} [options.event]    - if present will emit and event by this name with payload: { prop, value }
 * @param {string} [options.mutation='setProp'] - string defined within store/types to commit the mutation
 * @returns computed prop object with a get and set
 */
export function mapComputedGetSets(props = [], { store, object, prepend, event, mutation } = {}) {
  mutation = mutation ?? store + '/setProp';
  return props.reduce((obj, prop) => {
    const propModel = prepend ? prepend + prop.charAt(0).toUpperCase() + prop.slice(1) : prop;
    const computedProp = {
      get() {
        return object ? this[object][prop] : this[prop];
      },
      set(value) {
        if (event) this.$emit(event, { prop, value });
        if (store) this.$store.commit(mutation, { key: prop, value });
        else {
          if (object) this[object][prop] = value;
          else this[prop] = value;
        }
      }
    };
    obj[propModel] = computedProp;
    return obj;
  }, {});
}
