import namespaceHelper from '@/shared/helpers/namespace-helper';

import { namespace, WarrantyGetters } from './types';
import { LookupGetters } from '@/shared/store/lookup/types';

const _getters = namespaceHelper.removeNamespace(namespace, WarrantyGetters);

export default {
  [_getters.GET_WARRANTY]: state => {
    return state.details;
  },
  [_getters.GET_WARRANTY_SEARCH_RESULTS]: state => {
    return state.warrantySerachResults;
  },
  [_getters.GET_WARRANTY_SALES_ORDER]: state => {
    return state.salesOrder;
  },
  [_getters.GET_SUBMISSION]: state => {
    return state.submission;
  },
  [_getters.GET_STATUS]: (state, getters, rootState, rootGetters) => {
    return rootGetters[LookupGetters.GET_WARRANTY_STATUSES_MAP][state.submission.warrantyStatusId];
  },
  [_getters.GET_OLD_STATUS]: (state, getters, rootState, rootGetters) => {
    return rootGetters[LookupGetters.GET_WARRANTY_STATUSES_MAP][state.dbSubmission.warrantyStatusId];
  },
  [_getters.CHANGED_STATUS]: state => {
    return state.submission.warrantyStatusId !== state.dbSubmission.warrantyStatusId;
  },
  [_getters.GET_RECIPIENT_LIST]: state => {
    return state.recipientList;
  },
  [_getters.GET_WARRANTY_ADMINS]: state => {
    return state.warrantyAdmins;
  },
  [_getters.HAS_CHANGES]: state => {
    const replacer = (_k, v) => (v === '' ? null : v);
    return JSON.stringify(state.submission, replacer) !== JSON.stringify(state.dbSubmission, replacer);
  }
};
