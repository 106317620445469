export const namespace = 'note/';

export const NoteGetters = {
  GET_NOTES: namespace + 'getNotes',
  GET_NEW_NOTE: namespace + 'getNewNote',
  GET_OLD_NOTE: namespace + 'getOldNote',
  HAS_CHANGES: namespace + 'hasChanges'
};

export const NoteActions = {
  FETCH_NOTES: namespace + 'fetchNotes',
  POST_NOTE: namespace + 'postNote'
};

export const NoteMutations = {
  RESET_STATE: namespace + 'resetState',
  SET_NOTES: namespace + 'setNotes',
  SET_OLD_NOTE: namespace + 'setOldNote',
  SET_NEW_NOTE: namespace + 'setNewNote'
};
