import namespaceHelper from '@/shared/helpers/namespace-helper';
import MountedEquipmentService from '@/shared/services/MountedEquipmentService';
import { namespace, MountedEquipmentActions, MountedEquipmentMutations } from './types';

const _actions = namespaceHelper.removeNamespace(namespace, MountedEquipmentActions);
const _mutations = namespaceHelper.removeNamespace(namespace, MountedEquipmentMutations);

export default {
  async [_actions.FETCH_MOUNTED_EQUIPMENTS]({ commit }, unitId) {
    const result = await MountedEquipmentService.getUnitMountedEquipments(unitId);
    const mountedEquipments = result.data;
    commit(_mutations.SET_MOUNTED_EQUIPMENTS, mountedEquipments);
  },
  async [_actions.CREATE_OR_UPDATE_MOUNTED_EQUIPMENT]({ commit }, params) {
    var result = await MountedEquipmentService.createOrUpdateMountedEquipment(params);
    commit(_mutations.SET_MOUNTED_EQUIPMENT, result);
  },
  async [_actions.DELETE_MOUNTED_EQUIPMENT](_, params) {
    await MountedEquipmentService.deleteMountedEquipment(params);
  }
};
