import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, MountedEquipmentMutations } from './types';
import Cloner from '@/shared/helpers/cloner';
import { getDefaultState } from './state';

const _mutations = namespaceHelper.removeNamespace(namespace, MountedEquipmentMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_MOUNTED_EQUIPMENTS](state, mountedEquipments) {
    state.mountedEquipments = mountedEquipments;
  },
  [_mutations.SET_MOUNTED_EQUIPMENT](state, mountedEquipment) {
    state.mountedEquipment = mountedEquipment;
    state.dbMountedEquipment = Cloner.deepClone(mountedEquipment);
  },
  [_mutations.SET_MOUNTED_EQUIPMENT_PROP](state, payload) {
    state.mountedEquipment[payload.key] = payload.value;
  }
};
