<template>
  <div>
    <b-row>
      <b-col><div class="line-titles">COMMENT</div></b-col>
    </b-row>

    <div v-for="(comment, commentIndex) in commentLines" :key="commentIndex">
      <b-row v-if="!comment.toBeDeleted" :class="commentIndex < commentLines.length - 1 ? 'pb-1' : ''">
        <b-col :lg="comment._searchingComments ? 8 : 2" :cols="comment._searchingComments ? 11 : 4">
          <b-form-group
            label="CODE:"
            :label-class="commentIndex > 0 ? 'col-head line-item' : 'col-head line-item first-row'"
            :label-for="`op-${opId}-comment-${commentIndex}-code`"
          >
            <div v-if="readonly" :id="`op-${opId}-comment-${commentIndex}-code`" class="col-form-label pl-0">
              {{ comment.commentCodeId || NA }}
            </div>
            <div v-else>
              <b-input-group>
                <v-select
                  v-if="comment._local"
                  :id="`op-${opId}-comment-${commentIndex}-code`"
                  :value="comment"
                  :options="commentCodesList"
                  :filter-by="filterCommentCodes"
                  :clearable="false"
                  label="commentCodeId"
                  :class="
                    vComment(commentIndex).$anyError && !vComment(commentIndex).commentCodeId.required
                      ? 'is-invalid'
                      : ''
                  "
                  @input="updateCommentCode(commentIndex, $event)"
                  @search:focus="toggleCommentSearch(commentIndex, true)"
                  @search:blur="toggleCommentSearch(commentIndex, false)"
                >
                  <template #selected-option="com">
                    <template v-if="com.commentCodeId">
                      <span class="text-uppercase">{{ com.commentCodeId }}</span>
                    </template>
                  </template>
                  <template #option="com">
                    <span class="text-uppercase">{{ com.commentCodeId }} - {{ com.description }}</span>
                  </template>
                </v-select>
                <b-form-input
                  v-else
                  :id="`op-${opId}-comment-${commentIndex}-code`"
                  :value="comment.commentCodeId"
                  disabled
                />
              </b-input-group>
              <div
                v-if="vComment(commentIndex).$anyError && !vComment(commentIndex).commentCodeId.required"
                class="error"
              >
                Enter a valid Comment Code.
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col v-show="!comment._searchingComments" lg="6" cols="7">
          <b-form-group
            label="DESCRIPTION:"
            :label-class="commentIndex > 0 ? 'col-head line-item' : 'col-head line-item first-row'"
            :label-for="`op-${opId}-comment-${commentIndex}-description`"
          >
            <div v-if="readonly" :id="`op-${opId}-comment-${commentIndex}-description`" class="col-form-label pl-0">
              {{ comment.description || NA }}
            </div>
            <b-input-group v-else>
              <b-form-input
                :id="`op-${opId}-comment-${commentIndex}-description`"
                :value="comment.description"
                :disabled="!comment._local && !allowEdit"
                class="rounded"
                @input="updateCommentText(commentIndex, $event)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col v-if="!readonly" xl="1" cols="1">
          <b-form-group
            label="ACTIONS:"
            :label-class="`col-head line-item invisible ${commentIndex > 0 ? '' : 'first-row'}`"
            :label-for="`op-${opId}-comment-${commentIndex}-actions`"
          >
            <div class="d-block">
              <b-button v-b-tooltip size="xsm" class="m-1" title="Remove" @click="removeCommentLine(commentIndex)">
                <font-awesome-icon icon="trash-alt" />
              </b-button>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-row v-if="!readonly">
      <b-col offset-lg="6" lg="2" offset="9" cols="2">
        <b-button class="p-0 float-right mt-neg4px" variant="link" size="xsm" @click="addCommentLine()">
          <font-awesome-icon icon="plus" />
          Add Row
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import ErrorService from '@/shared/services/ErrorService';
import { mapGetters, mapMutations } from 'vuex';
import { ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { LookupGetters } from '@/shared/store/lookup/types';

export default {
  name: 'ServiceOrderCommentComponent',
  components: {
    vSelect
  },
  inject: {
    validator: {
      from: 'validator',
      default: () => null
    }
  },
  props: {
    jobKey: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([ServiceOrderGetters.GET_COMMENT_LINES]),
    ...mapGetters({
      jobs: ServiceOrderGetters.GET_JOBS,
      commentCodesList: LookupGetters.GET_COMMENT_CODES_LIST
    }),
    opId() {
      return this.jobs[this.jobKey].operationId;
    },
    commentLines() {
      return this[ServiceOrderGetters.GET_COMMENT_LINES](this.jobKey);
    },
    commentCodeValidation() {
      return this.validator.jobs.$each[this.jobKey].details.commentLines.$each.$iter;
    }
  },
  methods: {
    ...mapMutations([
      ServiceOrderMutations.ADD_COMMENT_LINE,
      ServiceOrderMutations.REMOVE_COMMENT_LINE,
      ServiceOrderMutations.SET_COMMENT_CODE,
      ServiceOrderMutations.SET_COMMENT_SEARCH,
      ServiceOrderMutations.SET_COMMENT_TEXT
    ]),
    addCommentLine() {
      let missingCode = false;
      for (let index in this.commentCodeValidation) {
        const v = this.commentCodeValidation[index];
        v.commentCodeId.$touch();
        if (!v.commentCodeId.required) missingCode = true;
      }
      if (missingCode) {
        const errorMessage = `Complete current line before adding an additional Comment.`;
        ErrorService.createErrorToast(this, errorMessage);
      } else {
        this[ServiceOrderMutations.ADD_COMMENT_LINE](this.jobKey);
        this.vComment(this.commentLines.length - 1).$reset();
      }
    },
    removeCommentLine(index) {
      this[ServiceOrderMutations.REMOVE_COMMENT_LINE]({ jobKey: this.jobKey, index: index });
      return this.validator.jobs.$each[this.jobKey].details.commentLines.$touch();
    },
    updateCommentCode(index, value) {
      this[ServiceOrderMutations.SET_COMMENT_CODE]({
        jobKey: this.jobKey,
        index: index,
        commentCodeId: value.commentCodeId,
        description: value.description
      });
      this.vComment(index).$touch();
    },
    updateCommentText(index, value) {
      this[ServiceOrderMutations.SET_COMMENT_TEXT]({ jobKey: this.jobKey, index, value });
    },
    filterCommentCodes(option, label, search) {
      return (
        option.commentCodeId.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        option.description.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    },
    toggleCommentSearch(commentIndex, value) {
      this[ServiceOrderMutations.SET_COMMENT_SEARCH]({ jobKey: this.jobKey, commentIndex, value });
    },
    vComment(commentIndex) {
      return this.commentCodeValidation[commentIndex];
    }
  }
};
</script>
