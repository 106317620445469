import axios from 'axios';

const MOTORS_PATH = '/api/motors';
const TOKEN_PATH = MOTORS_PATH + '/token';

class MotorsService {
  async getToken() {
    const url = TOKEN_PATH;
    const token = await axios.get(url);
    return token;
  }
}

export default new MotorsService();
