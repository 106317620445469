import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, ConfigActions, ConfigMutations } from './types';

const _actions = namespaceHelper.removeNamespace(namespace, ConfigActions);
const _mutations = namespaceHelper.removeNamespace(namespace, ConfigMutations);

export default {
  // PLACEHOLDER is used here so that structure of file is in place for future use.
  // Replace this when adding first real action
  async [_actions.PLACEHOLDER]({ commit }) {
    commit(_mutations.PLACEHOLDER, null);
  }
};
