export const getDefaultState = () => {
  return {
    loading: false,
    lookupFetchDate: null,
    lookupReloadCache: false,
    myCustomerReloadCache: false,

    lookups: {
      featureFlags: {},
      appointmentStatusMap: {},
      axleConfigurationsList: [],
      brakeStrokesMap: {},
      breakdownReasonsMap: {},
      commentCodesList: [],
      customerIdNameList: [],
      customerBreakdownBranchesList: [],
      customerEstimateBranchesList: [],
      customerServiceOrderBranchesList: [],
      customerUnitLocationsList: [],
      dmvStatesMap: {},
      fuelTypesMap: {},
      inspectionResultTypesMap: {},
      inspectionsList: [],
      jobOperationStatusesMap: {},
      loadRangesMap: {},
      locationsMap: {},
      mileageRangesList: [],
      miscCodesList: [],
      mountedEquipmentContractStatusesMap: {},
      mountedEquipmentTypesMap: {},
      myAssociatedEmployeesMap: [],
      myBranchesMap: {},
      myLocationsList: [],
      nonScheduledOperationsList: [],
      operationStatusesList: [],
      ownershipTypesMap: {},
      padMaterialPercentsMap: {},
      partsRequestTypesList: [],
      printFormsList: [],
      repairPrioritiesMap: {},
      repairReasonsMap: {},
      repairSitesMap: {},
      repairStatusesMap: {},
      salesTypesMap: {},
      standardOperationExcedeJobsMap: {},
      standardOperationsMap: {},
      standardOperationsList: [],
      statesAndProvincesList: [],
      subletCodesList: [],
      supportedTypesList: [],
      tireSizesMap: {},
      treadDepthsMap: {},
      unitStatusDispositionsMap: {},
      unitStatusesMap: {},
      unitTypesMap: {},
      warrantyDocumentTypesMap: {},
      warrantyStatusesMap: {},
      warrantySubmissionTypesMap: {},
      warrantyTypesMap: {}
    },
    employeeList: [],
    vmrs: {
      dateFetched: null,
      hierarchy: []
    }
  };
};

export const JOB_OPERATION_STATUS_IDS = {
  ESTIMATE: 1,
  APPROVED: 2,
  REJECTED: 3,
  DEFERRED: 4,
  SERVICE_ORDER_APPROVED: 5,
  SERVICE_ORDER_SOLD: 6
};

export default {
  ...getDefaultState()
};
