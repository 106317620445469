<template>
  <vams-collapse :ref="`collapse-${jobKey}`" title="View VMRS Codes">
    <div class="d-flex flex-column">
      <b-form-group label="COMPONENT CODE" class="w120px" label-class="col-head" :label-for="`assembly_code_${_uid}`">
        <div v-if="readonly">{{ assemblyCodeDisplay }}</div>
        <b-form-input v-else :label-for="`assembly_code_${_uid}`" :value="assemblyCodeDisplay" disabled></b-form-input>
      </b-form-group>

      <b-form-group label="SYSTEM GROUP" label-class="col-head" :label-for="`system_group_${_uid}`">
        <div v-if="readonly">{{ systemGroupDisplay }}</div>
        <v-select
          v-else
          :id="`system_group_${_uid}`"
          v-model="systemGroup"
          :options="vmrsSystemGroups"
          :clearable="false"
          label="systemGroupDescription"
        ></v-select>
      </b-form-group>

      <b-form-group label="SYSTEM" label-class="col-head" :label-for="`system_${_uid}`">
        <div v-if="readonly">{{ systemDisplay }}</div>
        <v-select
          v-else
          :id="`system_${_uid}`"
          v-model="system"
          :options="vmrsSystemsBySystemGroup"
          :clearable="false"
          label="systemCode"
        >
          <template #selected-option>
            <span>{{ systemDisplay }}</span>
          </template>
          <template #option="opt">
            <span>{{ opt.systemCode }} - {{ opt.systemDescription }}</span>
          </template>
        </v-select>
      </b-form-group>

      <b-form-group label="ASSEMBLY" label-class="col-head" :label-for="`assembly_${_uid}`">
        <div v-if="readonly">{{ assemblyDisplay }}</div>
        <v-select
          v-else
          :id="`assembly_${_uid}`"
          v-model="assembly"
          :options="vmrsAssemblyCodesBySystem"
          :clearable="false"
          label="assemblyCode"
        >
          <template #selected-option>
            <span>{{ assemblyDisplay }}</span>
          </template>
          <template #option="opt">
            <span>{{ opt.assemblyCode }} - {{ opt.assemblyDescription }}</span>
          </template>
        </v-select>
      </b-form-group>

      <b-form-group label="COMPONENT" label-class="col-head" :label-for="`component_${_uid}`">
        <div v-if="readonly">{{ componentDisplay }}</div>
        <v-select
          v-else
          :id="`component_${_uid}`"
          v-model="component"
          :options="vrmsComponentCodesByAssemblyAndSystem"
          :clearable="false"
          label="componentCode"
        >
          <template #selected-option>
            <span>{{ componentDisplay }}</span>
          </template>
          <template #option="opt">
            <span>{{ opt.componentCode }} - {{ opt.componentDescription }}</span>
          </template>
        </v-select>
      </b-form-group>
    </div>
  </vams-collapse>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { LookupGetters } from '@/shared/store/lookup/types';
import { ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import CollapseComponent from '@/shared/components/ui/CollapseComponent';
import vSelect from 'vue-select';

export default {
  name: 'VMRSCodesComponent',
  components: {
    'vams-collapse': CollapseComponent,
    vSelect
  },
  inject: {
    validator: {
      from: 'validator',
      default: () => null
    }
  },
  props: {
    jobKey: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      NA: 'N/A'
    };
  },
  computed: {
    ...mapGetters([LookupGetters.GET_VMRS_HIERARCHY, ServiceOrderGetters.GET_VMRS_CODE_BY_JOB]),
    vmrsCodeByJob() {
      return this[ServiceOrderGetters.GET_VMRS_CODE_BY_JOB](this.jobKey);
    },
    vmrsSystemGroups() {
      const systemGroups = this[LookupGetters.GET_VMRS_HIERARCHY];
      if (!systemGroups) return [];

      return systemGroups;
    },
    vmrsSystemsBySystemGroup() {
      const systemGroups = this[LookupGetters.GET_VMRS_HIERARCHY];
      const systemGroup = systemGroups.find(x => x.systemGroupId === this.systemGroup.systemGroupId);
      if (!systemGroup) return [];

      return systemGroup.systems;
    },
    vmrsAssemblyCodesBySystem() {
      const systemGroups = this[LookupGetters.GET_VMRS_HIERARCHY];
      const systemGroup = systemGroups.find(x => x.systemGroupId === this.systemGroup.systemGroupId);
      if (!systemGroup) return [];

      const systems = systemGroup.systems;
      const system = systems.find(x => x.systemCode === this.system.systemCode);
      if (!system) return [];

      return system.assemblies;
    },
    vrmsComponentCodesByAssemblyAndSystem() {
      const systemGroups = this[LookupGetters.GET_VMRS_HIERARCHY];
      const systemGroup = systemGroups.find(x => x.systemGroupId === this.systemGroup.systemGroupId);
      if (!systemGroup) return [];

      const systems = systemGroup.systems;
      const system = systems.find(x => x.systemCode === this.system.systemCode);
      if (!system) return [];

      const assemblies = system.assemblies;
      const assembly = assemblies.find(x => x.assemblyCode === this.assembly.assemblyCode);
      return assembly.components;
    },
    systemGroup: {
      get() {
        return {
          systemGroupDescription: this.vmrsCodeByJob.systemGroupDescription,
          systemGroupId: this.vmrsCodeByJob.systemGroupId
        };
      },
      set(value) {
        this[ServiceOrderMutations.SET_VMRS_CODE]({
          jobKey: this.jobKey,
          vmrsCodeByJob: value
        });
      }
    },
    system: {
      get() {
        return {
          systemCode: this.vmrsCodeByJob.systemCode,
          systemDescription: this.vmrsCodeByJob.systemDescription
        };
      },
      set(value) {
        const defaultAssembly = value.assemblies.length > 0 ? value.assemblies[0] : null;
        const defaultComponent = defaultAssembly.components.length > 0 ? defaultAssembly.components[0] : null;
        this[ServiceOrderMutations.SET_VMRS_CODE]({
          jobKey: this.jobKey,
          vmrsCodeByJob: { ...this.systemGroup, ...value, ...defaultAssembly, ...defaultComponent }
        });
      }
    },
    assembly: {
      get() {
        return {
          assemblyCode: this.vmrsCodeByJob.assemblyCode,
          assemblyDescription: this.vmrsCodeByJob.assemblyDescription
        };
      },
      set(value) {
        const defaultComponent = value.components.length > 0 ? value.components[0] : null;
        this[ServiceOrderMutations.SET_VMRS_CODE]({
          jobKey: this.jobKey,
          vmrsCodeByJob: { ...this.systemGroup, ...this.system, ...value, ...defaultComponent }
        });
      }
    },
    component: {
      get() {
        return {
          componentCode: this.vmrsCodeByJob.componentCode,
          componentDescription: this.vmrsCodeByJob.componentDescription
        };
      },
      set(value) {
        this[ServiceOrderMutations.SET_VMRS_CODE]({
          jobKey: this.jobKey,
          vmrsCodeByJob: { ...this.systemGroup, ...this.system, ...this.assembly, ...value }
        });
      }
    },
    systemGroupDisplay() {
      if (this.vmrsCodeByJob && this.vmrsCodeByJob.systemGroupDescription) {
        return `${this.vmrsCodeByJob.systemGroupDescription}`;
      }
      return this.NA;
    },
    assemblyCodeDisplay() {
      if (this.vmrsCodeByJob && this.vmrsCodeByJob.systemCode && this.vmrsCodeByJob.assemblyCode) {
        return `${this.vmrsCodeByJob.systemCode} - ${this.vmrsCodeByJob.assemblyCode} - ${this.vmrsCodeByJob.componentCode}`;
      }
      return this.NA;
    },
    systemDisplay() {
      if (this.vmrsCodeByJob && this.vmrsCodeByJob.systemCode && this.vmrsCodeByJob.systemDescription) {
        return `${this.vmrsCodeByJob.systemCode} - ${this.vmrsCodeByJob.systemDescription}`;
      }
      return this.NA;
    },
    assemblyDisplay() {
      if (this.vmrsCodeByJob && this.vmrsCodeByJob.assemblyCode && this.vmrsCodeByJob.assemblyDescription) {
        return `${this.vmrsCodeByJob.assemblyCode} - ${this.vmrsCodeByJob.assemblyDescription}`;
      }
      return this.NA;
    },
    componentDisplay() {
      if (this.vmrsCodeByJob && this.vmrsCodeByJob.componentCode && this.vmrsCodeByJob.componentDescription) {
        return `${this.vmrsCodeByJob.componentCode} - ${this.vmrsCodeByJob.componentDescription}`;
      }
      return this.NA;
    }
  },
  methods: {
    ...mapMutations([ServiceOrderMutations.SET_VMRS_CODE])
  }
};
</script>
