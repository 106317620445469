<template>
  <div>
    <div class="row flex-wrap align-content-start align-items-stretch no-gutters">
      <div class="mr-4">
        <label class="col-head">Service Order Id:</label>
        <h6>
          <router-link
            v-if="!$isCustomer || serviceOrder.invoiced"
            :to="'/units/' + serviceOrder.unitId + '/service-orders/' + serviceOrder.serviceOrderId"
          >
            {{ serviceOrder.serviceOrderId }}
          </router-link>
          <div v-else>{{ serviceOrder.serviceOrderId }}</div>
        </h6>
      </div>
      <div class="mr-4">
        <label class="col-head">Created:</label>
        <h6>{{ serviceOrder.dateCreate | date }}</h6>
      </div>
      <div class="mr-4">
        <label class="col-head">Mileage:</label>
        <h6>{{ serviceOrder.mileage | number }}</h6>
      </div>
      <div class="mr-4">
        <label class="col-head">Hours:</label>
        <h6>{{ serviceOrder.engineHours | number }}</h6>
      </div>
      <div v-if="!selectable" class="mr-4">
        <label class="col-head">Branch:</label>
        <h6>{{ serviceOrder.branchId }} - {{ serviceOrder.branchName }}</h6>
      </div>
      <div v-if="!selectable" class="mr-4">
        <label class="col-head">Status:</label>
        <h6>{{ serviceOrder.invoiced ? 'INVOICED' : 'OPEN' }}</h6>
      </div>
      <div v-if="!selectable" class="mr-4">
        <label class="col-head">Total:</label>
        <h6>{{ serviceOrder.total | currency }}</h6>
      </div>
      <div v-if="!selectable" class="mr-4">
        <label class="col-head">Completed:</label>
        <h6>{{ serviceOrder.dateInvoice | date }}</h6>
      </div>
      <div v-if="serviceOrder.cloneable && !selectable" class="btn-clone">
        <b-button variant="secondary" @click="showCloneModalOrNavigateToDuplicateWarning">Clone</b-button>
      </div>
      <div v-if="serviceOrder.cloneable && !selectable" class="d-inline-block">
        <div>
          <b-badge :id="`tool_tip_cloneable_${_uid}`" variant="primary" class="w16px mr-1">?</b-badge>
          <b-tooltip :target="`tool_tip_cloneable_${_uid}`" triggers="hover">
            Available only on jobs mapped to Scheduled Operations in VAMS.
          </b-tooltip>
        </div>
      </div>
    </div>
    <div>
      <b-table
        striped
        :items="jobsToShow"
        :fields="jobOrderFields"
        class="nested-table border"
        :tbody-tr-class="$isCustomer ? '' : jobRowClass"
      >
        <template #cell(salesTypeId)>
          {{ serviceOrder.salesTypeId }}
        </template>
        <template #cell(complaint)="item">
          {{ item.value }}
        </template>

        <template #cell(description)="item">
          <div v-for="job in jobArray(item)" :key="job.description" style="line-height: 1.4rem">{{ job.des }}</div>
        </template>
        <template #cell(jobSubtotal)="{ item }">
          <div>{{ item.jobSubtotal | currency }}</div>
        </template>

        <template #cell(select)="row">
          <b-form-radio :value="{ serviceOrder: serviceOrder, selectedJob: row.item }" />
        </template>
      </b-table>
    </div>
    <service-order-clone-modal
      ref="ServiceOrderCloneModal"
      :branch="serviceOrder.branchId + ' - ' + serviceOrder.branchName"
      :service-order-id="serviceOrder.serviceOrderId"
    ></service-order-clone-modal>
  </div>
</template>

<script>
import ServiceOrderCloneModal from './ServiceOrderCloneModal';
export default {
  name: 'ServiceOrderHistoryItemComponent',
  components: {
    'service-order-clone-modal': ServiceOrderCloneModal
  },
  props: {
    serviceOrder: {
      type: Object,
      default: () => {}
    },
    jobsOnly: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    cloneable: {
      type: Boolean,
      default: false
    },
    unitHasOpenServiceOrders: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      counterData: 0
    };
  },
  computed: {
    jobOrderFields() {
      const fields = [
        { key: 'operationId', label: '#', thStyle: 'width: 40px' },
        { key: 'jobId', label: 'Job ID', thStyle: 'width: 100px' },
        {
          key: 'complaint',
          label: 'Job Name',
          formatter: this.formatCellItemForHistory
        }
      ];
      if (!this.jobsOnly) {
        fields.push(
          ...[
            { key: 'description', label: 'Service Items', formatter: this.formatCellItemForHistory },
            { key: 'salesTypeId', label: 'Sales Type', thStyle: 'width: 110px' },
            ...[{ key: 'jobSubtotal', label: 'Job Total', thStyle: 'width: 110px' }]
          ]
        );
      }
      if (this.selectable) {
        fields.push(
          ...[
            { key: 'complaint', label: 'Complaint' },
            { key: 'select', label: 'Select' }
          ]
        );
      }
      return fields;
    },
    mappedJobs() {
      return this.serviceOrder.jobs.map(i => {
        return { opId: i.operationId, des: i.description };
      });
    },
    jobsToShow() {
      return this.serviceOrder.jobs
        .filter(x => x.hideFromHistory != true)
        .sort((a, b) => (a.operationId > b.operationId ? 1 : -1));
    }
  },
  methods: {
    showCloneModalOrNavigateToDuplicateWarning() {
      if (this.unitHasOpenServiceOrders) {
        const query = `serviceOrderId=${this.serviceOrder.serviceOrderId}&branchId=${this.serviceOrder.branchId}&branchName=${this.serviceOrder.branchName}`;
        const path = `/units/${this.serviceOrder.unitId}/service-orders/warning?${query}`;
        this.$router.push(path);
      }
      this.$refs.ServiceOrderCloneModal.show();
    },
    jobRowClass(item) {
      return this.cloneable && !item.isStandardOperation ? 'unmapped-job-table-row' : '';
    },
    formatCellItemForHistory(item) {
      var length = 40;
      var clamp = clamp || '...';
      return item?.length > length ? item.slice(0, length) + clamp : item;
    },
    jobArray(item) {
      return this.mappedJobs.filter(x => x.opId == item.item.operationId);
    }
  }
};
</script>
