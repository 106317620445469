<template>
  <div>
    <b-row class="mt-3">
      <slot></slot>
      <div>
        <b-form-group>
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              variant="position-relative"
              type="search"
              placeholder="Type to Filter"
              :debounce="500"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="pageSize"
        aria-controls="my-table"
        align="left"
        class="ml-auto w-auto"
      ></b-pagination>
    </b-row>
    <div>
      <div class="clearfix"></div>
      <div class="aside-wrap">
        <b-form-radio-group v-model="serviceOrderWithJob">
          <b-table
            :items="unitServiceOrderHistory"
            :fields="fields"
            :per-page="pageSize"
            :current-page="currentPage"
            :filter="filter"
            show-empty
            :empty-text="status === 'Cloneable' ? 'No Cloneable Results.' : 'No results found.'"
            :empty-filtered-text="status === 'Cloneable' ? 'No Cloneable Results.' : 'No results found.'"
            class="simple-table"
          >
            <template #cell(serviceOrderId)="{ item }">
              <service-order-history-item-component
                :service-order="item"
                :unit-has-open-service-orders="hasOpenServiceOrders"
                v-bind="{ cloneable, selectable, jobsOnly }"
              />
            </template>
          </b-table>
        </b-form-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
// components
import ServiceOrderHistoryItemComponent from './ServiceOrderHistoryItemComponent';
// vuex
import { mapMutations, mapGetters } from 'vuex';
import { UnitGetters, UnitMutations } from '@/shared/store/unit/types';
import { UserGetters } from '@/shared/store/user/types';
import { LookupGetters } from '@/shared/store/lookup/types';
// helpers
import Cloner from '@/shared/helpers/cloner';
import moment from 'moment';

export default {
  name: 'UnitServiceOrderHistoryComponent',
  components: {
    'service-order-history-item-component': ServiceOrderHistoryItemComponent
  },
  props: {
    selectable: {
      type: Boolean,
      default: false
    },
    cloneable: {
      type: Boolean,
      default: false
    },
    jobsOnly: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: 'All'
    },
    value: Object()
  },
  data: function () {
    return {
      pageSize: 10,
      currentPage: 1,
      fields: [{ key: 'serviceOrderId', label: 'Service Orders', sortable: false }],
      isBusy: false,
      filter: null
    };
  },
  computed: {
    ...mapGetters([UserGetters.GET_USER_PROFILE]),
    ...mapGetters([UnitGetters.GET_UNIT_STANDARD_OPERATIONS]),
    ...mapGetters({ standardOperationExcedeJobMap: LookupGetters.GET_STANDARD_OPERATION_EXCEDE_JOBS_MAP }),
    serviceOrderWithJob: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    isVcpUser() {
      return this.$store.getters[UserGetters.AUTHORIZE_ROLE](['VcpUser']);
    },
    rows() {
      return this.unitServiceOrderHistory.length;
    },
    unitServiceOrderHistory() {
      this.flagCloneableServiceOrdersAndDuplicateOpsIds();
      const serviceOrderHistory = this.$store.getters[UnitGetters.GET_UNIT_SERVICE_ORDER_HISTORY];
      if (this.status === 'Invoiced' || this.isVcpUser) {
        return serviceOrderHistory.filter(so => so.invoiced);
      } else if (this.status === 'Open') {
        return serviceOrderHistory.filter(so => !so.invoiced);
      } else if (this.status === 'Cloneable') {
        return serviceOrderHistory.filter(so => so.cloneable);
      } else {
        return serviceOrderHistory;
      }
    },
    hasOpenServiceOrders() {
      const serviceOrderHistory = this.$store.getters[UnitGetters.GET_UNIT_SERVICE_ORDER_HISTORY];
      return serviceOrderHistory.filter(serviceOrder => !serviceOrder.invoiced).length > 0;
    }
  },
  created() {
    this.flagCloneableServiceOrdersAndDuplicateOpsIds();
  },
  methods: {
    ...mapMutations([
      UnitMutations.SET_UNIT_SERVICE_ORDER_HISTORY,
      UnitMutations.PUSH_LOADING,
      UnitMutations.POP_LOADING
    ]),
    jobIsMappedInVams(item, unitStandardOperations) {
      const mappedJob = this.standardOperationExcedeJobMap[item.jobId];
      if (!mappedJob) return;
      const jobIsMappedAndOnUnit = mappedJob.standardOperationIds.some(standardOperationId =>
        unitStandardOperations.some(unitStandardOperation => {
          return standardOperationId === unitStandardOperation.standardOperationId;
        })
      );

      return jobIsMappedAndOnUnit;
    },
    serviceOrderIsClonable(serviceOrder) {
      const vm = this;

      const accessibleBranches = this[UserGetters.GET_USER_PROFILE].employeeBranches;
      const branchIsAccessible = accessibleBranches.some(branch => branch.branchId == serviceOrder.branchId);

      const unitStandardOperations = this[UnitGetters.GET_UNIT_STANDARD_OPERATIONS];
      let cloneableJobExists = false;
      serviceOrder.jobs.forEach(function (job) {
        if (vm.jobIsMappedInVams(job, unitStandardOperations)) {
          cloneableJobExists = true;
          job.isStandardOperation = true;
        }
      });
      const createdWithin18Months = moment().diff(serviceOrder.dateCreate, 'months') <= 18;

      return cloneableJobExists && branchIsAccessible && serviceOrder.invoiced && createdWithin18Months;
    },
    flagCloneableServiceOrdersAndDuplicateOpsIds() {
      if (!this.$isCustomer) {
        try {
          this[UnitMutations.PUSH_LOADING]();
          let serviceOrderHistory = Cloner.deepClone(this.$store.getters[UnitGetters.GET_UNIT_SERVICE_ORDER_HISTORY]);
          const vm = this;

          serviceOrderHistory.forEach(function (serviceOrder) {
            // flag as cloneable
            if (vm.serviceOrderIsClonable(serviceOrder)) {
              serviceOrder.cloneable = true;
            } else {
              serviceOrder.cloneable = false;
            }
            // flag if opsId listed multiple times in So History
            let map = serviceOrder.jobs.map(x => x);
            let valuesSoFar = Object.create(null);
            for (var i = 0; i < map.length; ++i) {
              var value = map[i].operationId;
              if (value in valuesSoFar) {
                serviceOrder.jobs[i].hideFromHistory = true;
              }
              valuesSoFar[value] = true;
            }
          });
          this[UnitMutations.SET_UNIT_SERVICE_ORDER_HISTORY](serviceOrderHistory);
        } finally {
          this[UnitMutations.POP_LOADING]();
        }
      }
    }
  }
};
</script>
