export default {
  methods: {
    getNextDueDate(stdOp) {
      if (stdOp.lastCompletedDate) {
        const date = new Date(stdOp.lastCompletedDate);
        date.setDate(date.getDate() + stdOp.durationDue);
        return date;
      }
      return null;
    },
    getNextDueEngineHours(stdOp) {
      if (stdOp.lastCompletedEngineHours && stdOp.engineHoursDue) {
        return stdOp.lastCompletedEngineHours + stdOp.engineHoursDue;
      }
      return null;
    },
    getNextDueMileage(stdOp) {
      if (stdOp.lastCompletedMileage && stdOp.mileageDue) {
        return stdOp.lastCompletedMileage + stdOp.mileageDue;
      }
      return null;
    }
  }
};
