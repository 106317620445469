import { helpers } from 'vuelidate/lib/validators';

// forms a boolan expression ie. $error && (!required || !numeric || ...etc)
export function hasError(vProperty, ...validators) {
  var isInvalid = !validators.length > 0;
  for (const validator of validators) {
    if (vProperty[validator] == false) {
      isInvalid = true;
      continue;
    }
  }
  return vProperty.$error && isInvalid;
}

export function getStandardOperationErrorMessage(due, overdue) {
  if (!due.integer || !overdue.integer) {
    return 'Enter a whole number.';
  }

  if (!due.minValue || !overdue.minValue) {
    return 'Enter a positive number.';
  }

  if (!due.required || !overdue.required) {
    return 'Enter valid numbers for both fields.';
  }

  if (!due.lessThanOrEqual) {
    return 'Overdue value must be greater than or equal to Due.';
  }

  return null;
}

export const alphaNumericSpecial = helpers.regex('alphaNumericSpecial', /^[0-9a-zA-Z\-.()_/ ]+$/);
export const alphaNumericSpecialErrorMessage =
  'Enter only letters, numbers, spaces and special characters: / - . ( ) _';

export const dateFormat = value => {
  var jsonValue = JSON.stringify(value);
  return jsonValue.match(/(19[0-9][0-9]|20[0-2][0-9]|9999)-[0-9]{2}-[0-9]{2}\w/);
};

// Vuelidate validator value is less than other properity
export const lessThanOrEqual = prop =>
  helpers.withParams({ type: 'lessThanOrEqual', prop }, (value, parentVm) => {
    const propValue = helpers.ref(prop, this, parentVm);
    if (propValue) {
      return value <= propValue;
    } else {
      return true;
    }
  });
