class WorkboxDBService {
  async openDB() {
    return new Promise((resolve, reject) => {
      let request = window.indexedDB.open('workbox-background-sync', 3);

      request.onsuccess = e => {
        resolve(e.target.result);
      };

      request.onerror = e => {
        reject(e);
      };
    });
  }

  async getRequest(url) {
    const db = await this.openDB();
    let requests = await this.getAll(db);
    for (let request of requests.reverse()) {
      if (request.requestData.url.endsWith(url)) return request;
    }
    db.close();
  }

  getObjectStore(db, store_name, mode) {
    let tx = db.transaction(store_name, mode);
    return tx.objectStore(store_name);
  }

  getAll(db) {
    let store = this.getObjectStore(db, 'requests', 'readonly');
    return new Promise((resolve, reject) => {
      let req = store.index('queueName').getAll();
      req.onsuccess = function (evt) {
        let value = evt.target.result;
        if (value) resolve(value);
        else reject(evt);
      };
      req.onerror = function (evt) {
        reject(evt);
      };
    });
  }

  getCount() {
    return new Promise((resolve, reject) => {
      let store;
      try {
        store = this.getObjectStore('requests', 'readonly');
      } catch {
        resolve(0);
      }
      let req = store.count();
      req.onsuccess = function (evt) {
        resolve(evt.target.result);
      };
      req.onerror = function (evt) {
        reject(evt);
      };
    });
  }
}

export default new WorkboxDBService(); // singleton object
