import axios from 'axios';
import UploadService from '@/shared/services/UploadService';

const API = '/api';
const ATTACHMENTS_PATH_ENDING = 'attachments';

class AttachmentService {
  async getAttachments({ route }) {
    const url = `${API}${route}/${ATTACHMENTS_PATH_ENDING}`;
    const attachments = await axios.get(url);
    return attachments;
  }

  async uploadAttachments({ route, files }) {
    const url = `${API}${route}/${ATTACHMENTS_PATH_ENDING}`;
    return UploadService.upload(url, files);
  }

  async updateAttachments({ route, attachments }) {
    const url = `${API}${route}/${ATTACHMENTS_PATH_ENDING}`;
    const response = await axios.put(url, attachments);
    return response;
  }

  async downloadAttachment({ route, attachment }) {
    const url = `${API}${route}/${ATTACHMENTS_PATH_ENDING}/${encodeURIComponent(
      attachment.attachmentId
    )}/${encodeURIComponent(attachment.originalFilename)}`;

    const attachmentExportResponse = axios.post(
      url,
      { route, attachment },
      {
        responseType: 'blob'
      }
    );

    return attachmentExportResponse;
  }

  async removeAttachment({ route, attachment }) {
    const url = `${API}${route}/${ATTACHMENTS_PATH_ENDING}/${encodeURIComponent(attachment.attachmentId)}`;
    const attachments = await axios.delete(url);
    return attachments;
  }
}

export default new AttachmentService(); // singleton object
