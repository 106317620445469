import axios from 'axios';
export const UNIT_PATH = '/api/units';
export const UNIT_SERVICE_ORDERS_PATH_ENDING = 'service-orders';
const UNIT_DEFERRED_JOBS_PATH_ENDING = 'deferred-jobs';
const DEFERRED_JOB_PATH_ENDING = '/api/deferred-jobs';
const DEFERRED_JOBS_COUNT_PATH = '/api/deferred-jobs-count';

class DeferredJobService {
  async getDeferredJobs(unitId, params) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${UNIT_DEFERRED_JOBS_PATH_ENDING}`;
    const deferredJobsResponse = await axios.get(url, { params });

    return deferredJobsResponse;
  }
  async getDeferredJobsCount() {
    const url = `${DEFERRED_JOBS_COUNT_PATH}`;
    const deferredJobsCount = await axios.get(url);
    return deferredJobsCount;
  }
  // DELETE: api/units/{unitId}/service-orders/{serviceOrderId}/{operationId}/deferred-jobs}
  async deleteDeferredJob(params) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      params.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(params.item.jobSlsId)}/${encodeURIComponent(
      params.item.jobOpsId
    )}/${UNIT_DEFERRED_JOBS_PATH_ENDING}`;
    const result = await axios.delete(url);
    return result;
  }
  async searchDeferredJobs(parameters) {
    const url = `${DEFERRED_JOB_PATH_ENDING}`;
    const deferredJobs = await axios.get(url, {
      params: parameters
    });
    return deferredJobs;
  }
}

export default new DeferredJobService(); //singleton object
