<template>
  <b-form-input
    ref="input"
    :value="value"
    :formatter="formatter"
    :state="state"
    @input="updateValue"
    @focus="selectAll"
  />
</template>

<script>
import phoneValidator from '@/shared/helpers/phone-validator';

export default {
  name: 'VamsPhoneInput',
  props: {
    value: String(),
    state: Boolean()
  },
  data() {
    return {
      formattedValue: null,
      formatter: phoneValidator.format
    };
  },
  watch: {
    value(newValue, oldValue) {
      if (!oldValue && newValue) this.formatInput(newValue);
    }
  },
  mounted() {
    if (this.value) this.formatInput(this.value);
  },
  methods: {
    updateValue(value) {
      var result = phoneValidator.parse(value, this.value);
      this.$emit('input', result);
    },
    selectAll(event) {
      setTimeout(() => event.target.select(), 0);
    },
    formatInput(newValue) {
      this.$nextTick(function () {
        this.$refs['input'].localValue = phoneValidator.format(newValue);
      });
    }
  }
};
</script>
