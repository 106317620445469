import axios from 'axios';
export const UNIT_PATH = '/api/units';
const MOUNTED_EQUIPMENT_PATH_ENDING = 'mounted-equipment';

class MountedEquipmentService {
  async getUnitMountedEquipments(unitId) {
    const url = `${UNIT_PATH}/${MOUNTED_EQUIPMENT_PATH_ENDING}/${unitId}`;
    const equipments = await axios.get(url);
    return equipments;
  }

  async createOrUpdateMountedEquipment(params) {
    const url = `${UNIT_PATH}/${MOUNTED_EQUIPMENT_PATH_ENDING}`;
    const result = await axios.put(url, params.mountedEquipment);
    return result;
  }

  async deleteMountedEquipment(params) {
    const url = `${UNIT_PATH}/${MOUNTED_EQUIPMENT_PATH_ENDING}/${encodeURIComponent(params.mountedEquipmentId)}`;
    const result = await axios.delete(url);
    return result;
  }
}
export default new MountedEquipmentService(); // singleton object
