import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, InvoiceActions, InvoiceMutations } from './types';
import InvoiceService from '@/shared/services/InvoiceService';

const _actions = namespaceHelper.removeNamespace(namespace, InvoiceActions);
const _mutations = namespaceHelper.removeNamespace(namespace, InvoiceMutations);

export default {
  // Search
  async [_actions.SEARCH_INVOICES]({ commit }, parameters) {
    const response = await InvoiceService.searchInvoices(parameters);
    const searchResults = response.data;
    if (parameters.category == 'history') {
      await commit(_mutations.SET_HISTORY_INVOICES, response.data);
    } else if (parameters.category == 'review') {
      await commit(_mutations.SET_REVIEW_INVOICES, response.data);
    } else {
      await commit(_mutations.SET_INVOICES, { searchResults, parameters });
    }
    return response.data;
  },
  async [_actions.FETCH_INVOICE]({ commit }, parameters) {
    const response = await InvoiceService.fetchInvoice(parameters);
    await commit(_mutations.SET_INVOICE, response.data);
    await commit(_mutations.SET_INVOICE_OBJECTS, response.data[0]);
    return response.data[0];
  },
  async [_actions.FETCH_AP_PROCESSORS]({ commit }) {
    const response = await InvoiceService.fetchAPProcessors();
    await commit(_mutations.SET_AP_PROCESSORS, response.data);
  },
  async [_actions.FETCH_DIVISION_AP_PROCESSORS]({ commit }, params) {
    const response = await InvoiceService.fetchDivisionAPProcessors(params);
    await commit(_mutations.SET_DIVISION_AP_PROCESSORS, response.data);
  },
  async [_actions.FETCH_INVOICE_APPROVERS]({ commit }) {
    const response = await InvoiceService.fetchApprovers();
    await commit(_mutations.SET_INVOICE_APPROVERS, response.data);
  },
  async [_actions.FETCH_INVOICE_DIVISION_APPROVERS]({ commit }, params) {
    const response = await InvoiceService.fetchDivisionApprovers(params);
    await commit(_mutations.SET_INVOICE_DIVISION_APPROVERS, response.data);
  },
  async [_actions.SEARCH_VENDORS]({ commit }, params) {
    const vendorsList = await InvoiceService.searchVendors(params);
    commit(_mutations.SET_VENDORS_LIST, vendorsList.data);
  },
  async [_actions.FETCH_POS]({ commit }, params) {
    const vendorPOs = await InvoiceService.searchPOs(params);
    commit(_mutations.SET_POS, vendorPOs.data);
  },
  async [_actions.SAVE_INVOICE](_, invoice) {
    await InvoiceService.saveInvoice(invoice);
  },
  async [_actions.APPROVE_INVOICE](_, invoice) {
    await InvoiceService.approveInvoice(invoice);
  },
  async [_actions.REJECT_INVOICE](_, invoice) {
    await InvoiceService.rejectInvoice(invoice);
  },
  async [_actions.REQUEST_INVOICE_APPROVAL](_, invoice) {
    await InvoiceService.requestInvoiceApproval(invoice);
  },
  async [_actions.SEND_INVOICE](_, invoice) {
    await InvoiceService.sendInvoice(invoice);
  },
  async [_actions.ADD_NEW_LINE_ITEM]({ commit }, params) {
    let newItemInt = parseInt(params.previousItemNumber) + 10;
    params.newItemNumber = newItemInt.toString().padStart(6, '0');
    commit(_mutations.SET_NEW_LINE_ITEM, params);
  },
  async [_actions.REMOVE_LINE_ITEM]({ commit, state }, index) {
    commit(_mutations.REMOVE_LINE_ITEM, index);
    if (index < state.invoice.length) {
      commit(_mutations.UPDATE_LINE_ITEM_NUMBERS);
    }
  },
  async [_actions.REMOVE_INVOICE]({ commit }, index) {
    commit(_mutations.REMOVE_INVOICE, index);
  },
  async [_actions.VALIDATE_FULL_INVOICE](_, invoice) {
    await InvoiceService.validateFullInvoice(invoice);
  },
  async [_actions.VALIDATE_HEADER_INVOICE](_, invoice) {
    await InvoiceService.validateHeaderInvoice(invoice);
  },
  async [_actions.FETCH_APP_E_URL]({ commit }) {
    const response = await InvoiceService.fetchAppEUrl();
    commit(_mutations.SET_APP_E_URL, response.data);
  },
  async [_actions.DOWNLOAD_PDF](_, objectId) {
    let result = await InvoiceService.fetchDownload(objectId);
    return result.data;
  }
};
