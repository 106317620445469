import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, UserMutations } from './types';

const _mutations = namespaceHelper.removeNamespace(namespace, UserMutations);

export default {
  [_mutations.SET_AUTHENTICATION_REDIRECT](state, targetUrl) {
    state.navigation.targetUrl = targetUrl;
  },
  [_mutations.SET_LOGGED_IN](state, userProfile) {
    state.userProfile = userProfile;
  },
  [_mutations.SET_LOGGED_OUT](state) {
    state.userProfile = {};
  },
  [_mutations.SET_PROFILE](state, userProfile) {
    state.userProfile = userProfile;
  },
  [_mutations.SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [_mutations.SET_MSAL_ACCOUNT](state, msalAccount) {
    state.msalAccount = msalAccount;
  }
};
