import axios from 'axios';
export const UNIT_PATH = '/api/units';
const UNIT_STATUS_PATH = 'unitStatus';

class UnitStatusHistoryService {
  async fetchUnitStatusHistory(unitId) {
    const url = `${UNIT_PATH}/${UNIT_STATUS_PATH}/${encodeURIComponent(unitId)}`;
    const response = await axios.get(url);
    return response;
  }
}

export default new UnitStatusHistoryService(); // singleton object
