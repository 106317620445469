import axios from 'axios';
const TOKEN_PATH = '/api/powerbi/token';

class PowerBIService {
  async getToken() {
    let token = await axios.get(TOKEN_PATH);
    return token;
  }
}

export default new PowerBIService(); // singleton object
