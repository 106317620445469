export const getDefaultState = () => {
  return {
    deferredJobs: [],
    deferredJobsSearchResults: [],
    deferredJobsCount: null,
    unitDeferredJobs: [],
    selectedDeferredJobs: []
  };
};

export default {
  ...getDefaultState()
};
