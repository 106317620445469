<template>
  <div>
    <b-row>
      <b-col><div class="line-titles">INSPECTIONS</div></b-col>
    </b-row>

    <div v-for="(inspection, index) in inspections" :key="index">
      <b-row v-if="!inspection.toBeDeleted" :class="index < inspections.length - 1 ? 'pb-1' : ''">
        <b-col :lg="6" :cols="8">
          <b-form-group
            label="Checklist:"
            :label-class="`col-head line-item ${index > 0 ? '' : 'first-row'}`"
            :label-for="`op-${opId}-inspection-${index}-code`"
          >
            <div v-if="readonly" :id="`op-${opId}-inspection-${index}-code`" class="pl-0">
              {{ inspection.name || NA }}
            </div>
            <div v-else>
              <b-input-group>
                <v-select
                  v-if="inspection._local"
                  :id="`op-${opId}-inspection-${index}-code`"
                  :value="inspection"
                  :options="inspectionOptions"
                  :filter-by="filterInspections"
                  :clearable="false"
                  label="name"
                  :class="hasError(vInspect(index).inspectionId, 'required') ? 'is-invalid' : ''"
                  @input="updateInspection(index, $event)"
                />
                <b-form-input v-else :id="`op-${opId}-inspection-${index}-code`" :value="inspection.name" disabled />
              </b-input-group>
              <div v-if="hasError(vInspect(index).inspectionId, 'required')" class="error">
                Enter a valid Inspection.
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="auto">
          <b-form-group
            label="view"
            :label-class="`col-head line-item invisible ${index > 0 ? '' : ' first-row'}`"
            :label-for="`op-${opId}-inspection-${index}-checklist-btn`"
          >
            <div class="d-flex align-items-center">
              <span
                v-if="!$isCustomer && (inspection._local || !inspection.unitInspectionId)"
                :id="`op-${opId}-inspection-${index}-checklist-btn-disabled`"
                class="d-inline-block"
                tabindex="0"
              >
                <b-button disabled>View Checklist</b-button>
              </span>
              <b-button
                v-else-if="!$isCustomer || ($isCustomer && inspection.dateCompleted != null)"
                :id="`op-${opId}-inspection-${index}-checklist-btn`"
                variant="secondary"
                :to="'/inspections/' + inspection.unitInspectionId"
              >
                View Checklist
              </b-button>
              <b-tooltip
                v-if="!$isCustomer && (inspection._local || !inspection.unitInspectionId)"
                :target="`op-${opId}-inspection-${index}-checklist-btn-disabled`"
                triggers="hover"
              >
                {{ `Save ${estimorderDisplayText} to view checklist.` }}
              </b-tooltip>

              <div v-else-if="inspection.dateCompleted" class="d-flex">
                <font-awesome-icon icon="check" class="text-success mx-2" size="lg" />
                <div>
                  INSPECTION COMPLETE
                  <span v-if="!$isCustomer">({{ inspection.failedItems }} Failed)</span>
                </div>
                <font-awesome-icon
                  :id="`op-${opId}-inspection-${index}-help-icon`"
                  icon="info-circle"
                  size="lg"
                  class="mx-1 text-primary align-self-center"
                  display="inline-block"
                />
                <b-tooltip
                  :id="`op-${opId}-inspection-${index}-tooltip`"
                  :target="`op-${opId}-inspection-${index}-help-icon`"
                  triggers="hover"
                >
                  Inspection Completed
                  <span v-if="!$isCustomer">
                    by
                    <br />
                    <employee-display :employee="inspection.empCompleted" :show-email="false" />
                  </span>
                  {{ completedDateFormat(inspection.dateCompleted) }}
                  <br />
                </b-tooltip>
              </div>
              <div v-if="inspection.dateCompleted == null && !readonly" class="d-block ml-2">
                <b-button v-b-tooltip size="xsm" class="m-1" title="Remove" @click="removeInspection(index)">
                  <font-awesome-icon icon="trash-alt" />
                </b-button>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-row v-if="!readonly">
      <b-col offset-lg="6" lg="2" offset="8" cols="2">
        <b-button class="p-0 mt-neg4px" style="margin-left: 3.6rem" variant="link" size="xsm" @click="addInspection()">
          <font-awesome-icon icon="plus" />
          Add Row
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// components
import vSelect from 'vue-select';
import EmployeeDisplayComponent from '@/shared/components/ui/EmployeeDisplayComponent';
// vuex
import { mapMutations, mapGetters } from 'vuex';
import { ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { LookupGetters } from '@/shared/store/lookup/types';
// helpers
import ErrorService from '@/shared/services/ErrorService';
import { hasError } from '@/shared/helpers/validator-helper.js';
import moment from 'moment';

export default {
  name: 'ServiceOrderInspectionComponent',
  components: {
    vSelect,
    'employee-display': EmployeeDisplayComponent
  },
  inject: {
    validator: {
      from: 'validator',
      default: () => null
    }
  },
  props: {
    jobKey: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isPackagedPricing: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters([ServiceOrderGetters.GET_INSPECTIONS]),
    ...mapGetters({
      inspectionList: LookupGetters.GET_INSPECTION_LIST,
      jobs: ServiceOrderGetters.GET_JOBS,
      estimorderDisplayText: ServiceOrderGetters.GET_ESTIMORDER_DISPLAY_TEXT,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER
    }),
    unitId() {
      return this.$route.params.unitId;
    },
    opId() {
      return this.jobs[this.jobKey].operationId;
    },
    inspections() {
      return this[ServiceOrderGetters.GET_INSPECTIONS](this.jobKey);
    },
    inspectionOptions() {
      const inpsections = this.inspectionList.filter(
        i =>
          i.billToCustomerIds == null ||
          i.billToCustomerIds.length === 0 ||
          i.billToCustomerIds.includes(this.serviceOrder?.billToCustomer?.customerId)
      );
      return inpsections;
    },
    inspectionValidation() {
      return this.validator.jobs.$each[this.jobKey].details.inspections.$each.$iter;
    }
  },
  methods: {
    ...mapMutations([
      ServiceOrderMutations.ADD_INSPECTION,
      ServiceOrderMutations.REMOVE_INSPECTION,
      ServiceOrderMutations.SET_INSPECTION
    ]),
    addInspection() {
      let missingId = false;
      for (let index in this.inspectionValidation) {
        const v = this.inspectionValidation[index];
        v.inspectionId.$touch();
        if (!v.inspectionId.required) missingId = true;
      }
      if (missingId) {
        const errorMessage = `Complete current line before adding an additional Inspections.`;
        ErrorService.createErrorToast(this, errorMessage);
      } else {
        this[ServiceOrderMutations.ADD_INSPECTION](this.jobKey);
        this.vInspect(this.inspections.length - 1).$reset();
      }
    },
    removeInspection(index) {
      this[ServiceOrderMutations.REMOVE_INSPECTION]({ jobKey: this.jobKey, index: index });
    },
    updateInspection(index, value) {
      this[ServiceOrderMutations.SET_INSPECTION]({
        jobKey: this.jobKey,
        index: index,
        ...value
      });
      this.vInspect(index).inspectionId.$touch();
    },
    filterInspections(option, label, search) {
      return option.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
    },
    vInspect(index) {
      return this.inspectionValidation[index];
    },
    completedDateFormat(date) {
      return moment(date).format('l');
    },
    hasError: hasError
  }
};
</script>
