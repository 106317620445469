import axios from 'axios';
import WorkboxDBService from '@/shared/services/WorkboxDBService';

const INSPECTION_PATH_ENDING = '/api/inspections';
const ASSIGN = 'assign';

class InspectionService {
  async searchInspections(parameters) {
    const url = `${INSPECTION_PATH_ENDING}`;
    const inspections = await axios.get(url, {
      params: parameters
    });
    return inspections;
  }

  getInspection(unitInspectionId) {
    const url = `${INSPECTION_PATH_ENDING}/${encodeURIComponent(unitInspectionId)}`;
    return axios.get(url);
  }

  async createInspection(inspection) {
    const url = `${INSPECTION_PATH_ENDING}`;
    const response = await axios.post(url, inspection);
    return response;
  }

  async updateInspection(parameters) {
    const url = `${INSPECTION_PATH_ENDING}/${encodeURIComponent(parameters.unitInspectionId)}`;
    try {
      const response = await axios.put(url, parameters.inspection);
      return response;
    } catch (error) {
      if (!error.status) {
        const request = await WorkboxDBService.getRequest(url);
        if (!request) throw error;
        const cache = await caches.open('api-cache');
        if (cache) {
          const cachedGet = await caches.match('/mobile' + url, { cacheName: 'api-cache' });
          await cache.put('/mobile' + url, new Response(request.requestData.body, cachedGet));
        }
        return { data: parameters.inspection, status: 205, statusText: 'Request queued via background sync' };
      } else {
        throw error;
      }
    }
  }

  async assignServiceOrder(parameters) {
    const url = `${INSPECTION_PATH_ENDING}/${encodeURIComponent(parameters.unitInspectionId)}/${ASSIGN}`;
    const response = await axios.post(url, parameters.inspection);
    return response;
  }
}

export default new InspectionService(); // singleton object
