<template>
  <!-- <div v-if="localShowMore || !overflow">
    <div v-for="(item, index) in list" :key="index" class="pb-2">
      <slot :item="item" />
    </div>
  </div> -->
  <div>
    <div v-for="(item, index) in shortList" :key="index" class="pb-2">
      <slot :item="item" />
    </div>
    <b-collapse :id="`tcl-collapse-${rowIndex}`" v-model="showMore">
      <div v-for="(item, index) in moreList" :key="index" class="pb-2">
        <slot :item="item" />
      </div>
    </b-collapse>
    <transition name="fade">
      <b-button
        v-if="!showMore && overflow"
        v-b-toggle="`tcl-collapse-${rowIndex}`"
        class="p-0 text-capitalize"
        variant="link"
        size="xsm"
      >
        {{ amountHidden }} MORE
      </b-button>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'TableCellList',
  props: {
    list: {
      type: Array,
      required: true
    },
    rowIndex: {
      type: Number,
      required: true
    },
    hideAtLength: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      showMore: false
    };
  },
  computed: {
    amountHidden() {
      return this.list.length - this.hideAtLength;
    },
    overflow() {
      return this.list.length > this.hideAtLength;
    },
    shortList() {
      return this.list.slice(0, this.hideAtLength);
    },
    moreList() {
      return this.list.slice(this.hideAtLength);
    }
  },
  methods: {
    setShowMore(value) {
      this.showMore = value;
    }
  }
};
</script>
