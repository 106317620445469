export const namespace = 'operationsForecast/';

export const OperationsForecastGetters = {
  GET_OPERATIONS_FORECAST: namespace + 'getOperationsForecast',
  GET_SEARCH_FILTER: namespace + 'getSearchFilter',
  GET_FILTERED_ITEMS: namespace + 'getFilteredItems',
  GET_SORT_BY: namespace + 'getSortBy',
  GET_CURRENT_PAGE: namespace + 'getCurrentPage',
  GET_TOTAL_UNITS: namespace + 'getTotalUnits',
  GET_TOTAL_ROWS: namespace + 'getTotalRows',
  GET_ASIDE_BUSY_FLAG: namespace + 'getAsideBusyFlag',
  GET_RESULT_TABLE_COLUMNS: namespace + 'getResultTableColumns',
  GET_PAGE_SIZE: namespace + 'getPageSize',
  GET_OPERATIONS_FORECAST_UNIQUE_UNITS: namespace + 'getOperationsForecastUniqueUnits',
  GET_BULK_SERVICE_REQUEST_MODAL_FLAG: namespace + 'getBulkServiceRequestModalFlag'
};

export const OperationsForecastActions = {
  FETCH_OPERATIONS_FORECAST: namespace + 'fetchOperationsForecast'
};

export const OperationsForecastMutations = {
  SET_PROP: namespace + 'setProp'
};
