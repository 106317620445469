<template>
  <div class="collapse-group">
    <!-- Customer Specific Attributes -->
    <vams-collapse
      v-for="[ref, section] of Object.entries(attributeSections)"
      :key="ref"
      :ref="ref"
      :title="section.title"
    >
      <!-- Catagory Specific Attributes for ALL Units -->
      <!-- Sub Details -->
      <div v-if="ref == 'subDetails'">
        <b-form-group v-bind="{ labelColsLg, labelColsSm }" label="Paired Unit:" label-for="pairedUnitId">
          <div v-if="!allowCustomersAndTechsToEdit" id="pairedUnitId" class="col-form-label">
            {{ pairedUnitId || NA }}
          </div>
          <b-form-input
            v-else
            id="pairedUnitId"
            v-model="pairedUnitId"
            :state="$v.$dirty && hasError($v.pairedUnitId) ? false : null"
            maxlength="15"
            type="text"
          />
          <div v-show="$v.$dirty && hasError($v.pairedUnitId, 'alphaNumericSpecial')" class="error">
            {{ alphaNumericSpecialErrorMessage }}
          </div>
        </b-form-group>
        <b-form-group v-bind="{ labelColsLg, labelColsSm }" label="Unit Color:" label-for="cs-unit-color">
          <div v-if="!allowCustomersAndTechsToEdit" id="cs-unit-color" class="col-form-label">
            {{ unitColor || NA }}
          </div>
          <b-form-input
            v-else
            id="cs-unit-color"
            v-model="$v.unitColor.$model"
            :state="$v.$dirty && hasError($v.unitColor) ? false : null"
            maxlength="15"
            type="text"
          />
          <div v-show="$v.$dirty && hasError($v.unitColor, 'alphaNumericSpecial')" class="error">
            {{ alphaNumericSpecialErrorMessage }}
          </div>
        </b-form-group>
        <b-form-group v-bind="{ labelColsLg, labelColsSm }" label="Unit Length (ft):" label-for="unitLength">
          <div v-if="!allowCustomersAndTechsToEdit" id="unitLength" class="col-form-label">
            {{ unitLength || NA }}
          </div>
          <b-form-input
            v-else
            id="unitLength"
            v-model.number="$v.unitLength.$model"
            :state="$v.$dirty && $v.unitLength.$error ? false : null"
            :input-class="`form-control ${$v.$dirty && $v.unitLength.$error ? 'is-invalid' : null}`"
            maxlength="15"
            @keypress="NumberFieldHelper.isDecimal($event)"
            @blur="unitLength = unitLength === '' ? null : unitLength"
          />
        </b-form-group>
        <b-form-group
          v-bind="{ labelColsLg, labelColsSm }"
          label="Gross Vehicle Weight (GVW):"
          label-for="grossVehicleWeight"
        >
          <div v-if="!allowCustomersAndTechsToEdit" id="grossVehicleWeight" class="col-form-label">
            {{ grossVehicleWeight || NA }}
          </div>
          <b-form-input
            v-else
            id="grossVehicleWeight"
            v-model.number="$v.grossVehicleWeight.$model"
            :state="$v.$dirty && hasError($v.grossVehicleWeight) ? false : null"
            type="number"
            @keypress="NumberFieldHelper.isDecimal($event)"
            @blur="grossVehicleWeight = grossVehicleWeight === '' ? null : grossVehicleWeight"
          />
        </b-form-group>
      </div>

      <!-- Power Source -->
      <div v-if="ref == 'powerSource'">
        <b-form-group v-bind="{ labelColsLg, labelColsSm }" label="Manufacturer:" label-for="ps-manufacturer">
          <div v-if="!allowTechsToEdit" id="ps-manufacturer" class="col-form-label">
            {{ powerSource.manufacturer || NA }}
          </div>
          <b-form-input
            v-else
            id="ps-manufacturer"
            v-model="powerSourceManufacturer"
            :state="$v.$dirty && hasError($v.powerSource.manufacturer) ? false : null"
            maxlength="50"
            type="text"
          />
          <div v-show="$v.$dirty && hasError($v.powerSource.manufacturer, 'alphaNumericSpecial')" class="error">
            {{ alphaNumericSpecialErrorMessage }}
          </div>
        </b-form-group>
        <b-form-group v-bind="{ labelColsLg, labelColsSm }" label="Model:" label-for="ps-model">
          <div v-if="!allowTechsToEdit" id="ps-model" class="col-form-label">
            {{ powerSource.model || NA }}
          </div>
          <b-form-input
            v-else
            id="ps-model"
            v-model="powerSourceModel"
            :state="$v.$dirty && hasError($v.powerSource.model) ? false : null"
            maxlength="15"
            type="text"
          />
          <div v-show="$v.$dirty && hasError($v.powerSource.model, 'alphaNumericSpecial')" class="error">
            {{ alphaNumericSpecialErrorMessage }}
          </div>
        </b-form-group>
        <b-form-group v-bind="{ labelColsLg, labelColsSm }" label="Engine Serial #:" label-for="ps-enigineSerial">
          <div v-if="!allowTechsToEdit" id="ps-engineSerial" class="col-form-label">
            {{ powerSource.serialNumber || NA }}
          </div>
          <b-form-input
            v-else
            id="ps-engineSerial"
            v-model="powerSourceSerialNumber"
            :state="$v.$dirty && hasError($v.powerSource.serialNumber) ? false : null"
            maxlength="50"
            type="text"
          />
          <div v-show="$v.$dirty && hasError($v.powerSource.serialNumber, 'alphaNumericSpecial')" class="error">
            {{ alphaNumericSpecialErrorMessage }}
          </div>
        </b-form-group>
        <b-form-group v-bind="{ labelColsLg, labelColsSm }" label="Fuel Type:" label-for="fuelTypeId">
          <div v-if="!allowTechsToEdit" id="fuelTypeId" class="col-form-label">
            {{ fuelDescription || NA }}
          </div>
          <v-select
            v-else
            id="fuelTypeId"
            v-model="fuelTypeId"
            label="description"
            :options="fuelTypesList"
            :reduce="fuelType => fuelType.id"
            :clearable="false"
            select-on-tab
          >
            <template #selected-option="fuel">{{ fuel.description }}</template>
          </v-select>
        </b-form-group>
      </div>

      <!-- Transmission -->
      <div v-if="ref == 'transmission'">
        <b-form-group v-bind="{ labelColsLg, labelColsSm }" label="Manufacturer:" label-for="transmission-manufacturer">
          <div v-if="!allowTechsToEdit" id="transmission-manufacturer" class="col-form-label">
            {{ transmission.manufacturer || NA }}
          </div>
          <b-form-input
            v-else
            id="transmission-manufacturer"
            v-model="transmissionManufacturer"
            :state="$v.$dirty && hasError($v.transmission.manufacturer) ? false : null"
            maxlength="50"
            type="text"
          />
          <div v-show="$v.$dirty && hasError($v.transmission.manufacturer, 'alphaNumericSpecial')" class="error">
            {{ alphaNumericSpecialErrorMessage }}
          </div>
        </b-form-group>
        <b-form-group v-bind="{ labelColsLg, labelColsSm }" label="Model:" label-for="transmission-model">
          <div v-if="!allowTechsToEdit" id="transmission-model" class="col-form-label">
            {{ transmission.model || NA }}
          </div>
          <b-form-input
            v-else
            id="transmission-model"
            v-model="transmissionModel"
            :state="$v.$dirty && hasError($v.transmission.model) ? false : null"
            maxlength="50"
            type="text"
          />
          <div v-show="$v.$dirty && hasError($v.transmission.model, 'alphaNumericSpecial')" class="error">
            {{ alphaNumericSpecialErrorMessage }}
          </div>
        </b-form-group>
        <b-form-group
          v-bind="{ labelColsLg, labelColsSm }"
          label="Transmission Serial #:"
          label-for="transmission-enigineSerial"
        >
          <div v-if="!allowTechsToEdit" id="transmission-engineSerial" class="col-form-label">
            {{ transmission.serialNumber || NA }}
          </div>
          <b-form-input
            v-else
            id="transmission-engineSerial"
            v-model="transmissionSerialNumber"
            :state="$v.$dirty && hasError($v.transmission.serialNumber) ? false : null"
            maxlength="50"
            type="text"
          />
          <div v-show="$v.$dirty && hasError($v.transmission.serialNumber, 'alphaNumericSpecial')" class="error">
            {{ alphaNumericSpecialErrorMessage }}
          </div>
        </b-form-group>
      </div>

      <!-- Registration -->
      <div v-if="ref == 'registration'">
        <b-form-group label="Registration Cost:" v-bind="{ labelColsLg, labelColsSm }" label-for="registrationCost">
          <div v-if="!allowCustomerToEdit" id="registrationCost" class="col-form-label pl-0">
            {{ registrationCost | currency | nullValueToNA }}
          </div>
          <b-input-group v-else prepend="$">
            <b-form-input
              id="registrationCost"
              v-currency="currencyOptions"
              :value="registrationCost"
              class="rounded-right"
              :input-class="`form-control ${$v.$dirty && $v.registrationCost.$error ? 'is-invalid' : null}`"
              @input="updateRegistrationCost($event)"
            />
          </b-input-group>
        </b-form-group>

        <date-time-input
          id="registrationExpirationDate"
          v-model="registrationExpirationDate"
          :readonly="!allowCustomerToEdit"
          v-bind="{ labelColsLg, labelColsSm }"
          :validator="$v.registrationExpirationDate"
          label="Registration Expiration Date:"
          hide-time
        >
          <template #default>
            <div
              v-show="$v.$dirty && $v.registrationExpirationDate.$error && !$v.registrationExpirationDate.minValue"
              class="error"
            >
              Date must be formatted as MM/DD/YYYY
            </div>
          </template>
        </date-time-input>

        <date-time-input
          id="purchaseDate"
          v-model="purchaseDate"
          :readonly="!allowCustomerToEdit"
          v-bind="{ labelColsLg, labelColsSm }"
          :validator="$v.purchaseDate"
          label="Purchase Date:"
          hide-time
        >
          <template #default>
            <div v-show="$v.$dirty && $v.purchaseDate.$error && !$v.leaseExpirationDate.minValue" class="error">
              Date must be formatted as MM/DD/YYYY
            </div>
          </template>
        </date-time-input>

        <date-time-input
          id="leaseExpirationDate"
          v-model="leaseExpirationDate"
          :readonly="!allowCustomerToEdit"
          v-bind="{ labelColsLg, labelColsSm }"
          :validator="$v.leaseExpirationDate"
          label="Lease Expiration Date:"
          hide-time
        >
          <template #default>
            <div v-show="$v.$dirty && $v.leaseExpirationDate.$error && !$v.leaseExpirationDate.minValue" class="error">
              Date must be formatted as MM/DD/YYYY
            </div>
          </template>
        </date-time-input>

        <b-form-group label="Lease Amount:" label-for="leaseAmount" v-bind="{ labelColsLg, labelColsSm }">
          <div v-if="!allowCustomerToEdit" id="leaseAmount" class="col-form-label pl-0">
            {{ leaseAmount | currency | nullValueToNA }}
          </div>
          <b-input-group v-else prepend="$">
            <b-form-input
              id="leaseAmount"
              v-model="leaseAmount"
              v-currency="currencyOptions"
              class="rounded-right"
              :input-class="`form-control ${$v.$dirty && $v.leaseAmount.$error ? 'is-invalid' : null}`"
              @input="updateLeaseAmount($event)"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group v-bind="{ labelColsLg, labelColsSm }" label="Ownership:" label-for="ownership">
          <div v-if="!allowCustomerToEdit" id="ownership" class="col-form-label">
            {{ ownershipDescription || NA }}
          </div>
          <v-select
            v-else
            id="ownership"
            v-model="ownershipTypeId"
            label="description"
            :options="ownershipTypesList"
            :reduce="ownership => parseInt(ownership.id)"
            :clearable="false"
            select-on-tab
          >
            <template #selected-option="ownership">{{ ownership.description }}</template>
          </v-select>
        </b-form-group>

        <b-form-group v-bind="{ labelColsLg, labelColsSm }" label="DMV State:" label-for="DMV-state">
          <div v-if="!allowCustomerToEdit" id="dmvState" class="col-form-label">
            {{ dmvStateDescription || NA }}
          </div>
          <v-select
            v-else
            id="dmvState"
            v-model="dmvState"
            label="description"
            :options="dmvStatesList"
            :reduce="state => state.id"
            :clearable="false"
            select-on-tab
          >
            <template #selected-option="dmvState">{{ dmvState.description }}</template>
          </v-select>
        </b-form-group>
      </div>

      <div v-if="ref == 'telematics'">
        <b-table :items="unit.telematics" :fields="fields"></b-table>
      </div>

      <!-- Axle Configuration -->
      <div v-if="ref == 'axleConfiguration'">
        <b-form-group v-bind="{ labelColsLg, labelColsSm }" label="Axle Configuration:" label-for="axle">
          <div v-if="readonly" id="axle" class="col-form-label">
            {{ axleConfiguration ? axleConfiguration.description : NA || NA }}
          </div>
          <v-select
            v-else
            id="axle"
            v-model="axleConfiguration"
            label="description"
            :options="axleConfigurationsList"
            :clearable="true"
            select-on-tab
          ></v-select>
          <img
            v-if="axleConfiguration"
            class="pt-2"
            :src="`/images/axles/${axleConfiguration.imageName}`"
            width="150px"
            height="auto"
            style="min-height: 1px"
            :alt="axleConfiguration.description"
          />
        </b-form-group>
      </div>

      <!-- DB Configured Attributes -->
      <unit-attribute-form-input
        v-for="attr in section.attributes"
        :key="attr.attributeId"
        v-bind="{ ...attr, labelColsLg, labelColsSm }"
      />
    </vams-collapse>
  </div>
</template>

<script>
// components
import CollapseComponent from '@/shared/components/ui/CollapseComponent';
import DateTimeInput from '@/shared/components/ui/DateTimeInput';
import vSelect from 'vue-select';
// vuex
import { mapGetters } from 'vuex';
import { UnitGetters, UnitMutations } from '@/shared/store/unit/types';
import { LookupGetters } from '@/shared/store/lookup/types';
// helpers
import { parse } from 'vue-currency-input';
import NumberFieldHelper from '@/shared/helpers/number-field-helper';
import { between, minValue, decimal } from 'vuelidate/lib/validators';
import { hasError, alphaNumericSpecial, alphaNumericSpecialErrorMessage } from '@/shared/helpers/validator-helper.js';
import { mapComputedGetSets } from '@/shared/helpers/form-generation';
import UnitAttributeFormInputComponent from './UnitAttributeFormInputComponent';
import FormGroupPropsMixin from '@/shared/mixins/FormGroupPropsMixin';
import UnitMixin from '@/shared/mixins/UnitMixin';

const maxDecimal = 7.9228 * Math.pow(10, 28);

export default {
  name: 'UnitAttributes',
  components: {
    'vams-collapse': CollapseComponent,
    'date-time-input': DateTimeInput,
    'unit-attribute-form-input': UnitAttributeFormInputComponent,
    vSelect
  },
  mixins: [UnitMixin, FormGroupPropsMixin],
  props: {
    readonly: Boolean()
  },
  data() {
    return {
      NumberFieldHelper,
      NA: 'N/A',
      alphaNumericSpecialErrorMessage,
      isBusy: false,
      currencyOptions: { currency: null, allowNegative: true },
      fields: [
        { key: 'provider', label: 'Provider', tdClass: 'align-middle' },
        { key: 'deviceId', label: 'Device ID', tdClass: 'align-middle' }
      ]
    };
  },
  validations: {
    unitColor: {
      alphaNumericSpecial
    },
    pairedUnitId: {
      alphaNumericSpecial
    },
    powerSource: {
      manufacturer: {
        alphaNumericSpecial
      },
      model: {
        alphaNumericSpecial
      },
      serialNumber: {
        alphaNumericSpecial
      }
    },
    transmission: {
      manufacturer: {
        alphaNumericSpecial
      },
      model: {
        alphaNumericSpecial
      },
      serialNumber: {
        alphaNumericSpecial
      }
    },
    registrationCost: {
      decimal,
      between: between(-maxDecimal, maxDecimal)
    },
    registrationExpirationDate: {
      minValue: minValue(new Date().setFullYear(1753))
    },
    purchaseDate: {
      minValue: minValue(new Date().setFullYear(1753))
    },
    leaseExpirationDate: {
      minValue: minValue(new Date().setFullYear(1753))
    },
    leaseAmount: {
      decimal,
      between: between(-maxDecimal, maxDecimal)
    },
    unitLength: {
      decimal,
      between: between(0, maxDecimal)
    },
    grossVehicleWeight: {
      decimal,
      minValue: minValue(0)
    }
  },
  computed: {
    ...mapGetters({
      unit: UnitGetters.GET_UNIT,
      fuelTypesMap: LookupGetters.GET_FUEL_TYPES_MAP,
      fuelTypesList: LookupGetters.GET_FUEL_TYPES_LIST,
      ownershipTypesMap: LookupGetters.GET_OWNERSHIP_TYPES_MAP,
      ownershipTypesList: LookupGetters.GET_OWNERSHIP_TYPES_LIST,
      dmvStatesMap: LookupGetters.GET_DMV_STATES_MAP,
      dmvStatesList: LookupGetters.GET_DMV_STATES_LIST,
      axleConfigurationsList: LookupGetters.GET_AXLE_CONFIGURATIONS_LIST
    }),
    attributeSections() {
      let attributes = {
        subDetails: {
          title: 'Sub Details'
        },
        powerSource: {
          title: 'Power Source'
        },
        transmission: {
          title: 'Transmission'
        },
        registration: {
          title: 'Registration'
        },
        axleConfiguration: {
          title: 'Axle Configuration'
        }
      };
      if (this.unit?.telematics && this.unit.telematics.length > 0) {
        attributes.telematics = {
          title: 'Telematics (Integrated)'
        };
      }
      if (this.unit?.attributes) {
        attributes = this.unit.attributes.reduce(function (accumulator, attr) {
          let category = camelize(attr.category);
          if (!accumulator[category]) {
            accumulator[category] = { title: attr.category, attributes: [] };
          } else if (!accumulator[category].attributes) accumulator[category].attributes = [];
          accumulator[category].attributes.push(attr);
          return accumulator;
        }, attributes);
      }

      return attributes;
    },
    fuelDescription() {
      const fuelType = this.fuelTypesMap[this.unit.fuelTypeId];
      return fuelType?.description;
    },
    ownershipDescription() {
      const ownershipType = this.ownershipTypesMap[this.unit.ownershipTypeId];
      return ownershipType?.description;
    },
    dmvStateDescription() {
      const state = this.dmvStatesMap[this.unit.dmvState];
      return state?.description;
    },
    powerSource() {
      return this.unit.powerSource || {};
    },
    ...mapComputedGetSets(['manufacturer', 'model', 'serialNumber'], {
      store: 'unit',
      object: 'powerSource',
      prepend: 'powerSource',
      mutation: UnitMutations.SET_POWER_SOURCE_PROP
    }),
    transmission() {
      return this.unit.transmission || {};
    },
    ...mapComputedGetSets(['manufacturer', 'model', 'serialNumber'], {
      store: 'unit',
      object: 'transmission',
      prepend: 'transmission',
      mutation: UnitMutations.SET_TRANSMISSION_PROP
    }),
    ...mapComputedGetSets(
      [
        'pairedUnitId',
        'unitColor',
        'unitLength',
        'grossVehicleWeight',
        'fuelTypeId',
        'registrationCost',
        'registrationExpirationDate',
        'purchaseDate',
        'leaseExpirationDate',
        'leaseAmount',
        'ownershipTypeId',
        'dmvState',
        'axleConfiguration'
      ],
      {
        store: 'unit',
        object: 'unit'
      }
    )
  },
  methods: {
    updateLeaseAmount(evt) {
      this.$v.leaseAmount.$model = parse(evt, this.currencyOptions);
    },
    updateRegistrationCost(evt) {
      this.$v.registrationCost.$model = parse(evt, this.currencyOptions);
    },
    showErrorsInCollapsedSections() {
      if (this.$v.powerSource.$anyError) {
        this.openCollapse('powerSource');
      }

      if (this.$v.transmission.$anyError) {
        this.openCollapse('transmission');
      }

      if (this.$v.unitColor.$error || this.$v.pairedUnitId.$error) {
        this.openCollapse('subDetails');
      }
    },
    openCollapse(ref) {
      var collapsible = this.$refs[ref][0] ?? this.$refs[ref];
      collapsible.expand();
    },
    camelize,
    hasError
  }
};

function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (/\s+/.test(match)) return '';
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}
</script>
