<template>
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="width"
    :height="height"
    viewBox="0 0 938.823 938.822"
    style="enable-background: new 0 0 938.823 938.822"
    xml:space="preserve"
  >
    <g fill="currentColor">
      <path
        d="M817.535,424.246c5.358,5.359,14.048,5.359,19.407,0l27.306-27.306c5.359-5.358,5.359-14.048,0-19.407L561.29,74.574
  			c-5.359-5.359-14.048-5.359-19.407,0l-27.306,27.306c-5.359,5.359-5.359,14.048,0,19.407L817.535,424.246z"
      />
      <path
        d="M934.802,306.978L631.844,4.019c-5.358-5.359-14.048-5.359-19.406,0l-27.307,27.306c-5.358,5.359-5.358,14.048,0,19.407
  			l302.958,302.959c5.359,5.359,14.048,5.359,19.407,0l27.306-27.306C940.162,321.026,940.162,312.337,934.802,306.978z"
      />
      <path
        d="M122.46,931.232c10.118,10.119,26.523,10.119,36.642,0l114.872-114.871c10.118-10.117,10.118-26.523,0-36.641
  			l-21.792-21.793L480.201,529.91c-8.962-14.311-19.552-27.643-31.599-39.689c-12.125-12.125-25.387-22.695-39.655-31.632
  			L180.894,686.641l-21.792-21.793c-10.118-10.117-26.522-10.119-36.642,0L7.589,779.721c-10.119,10.117-10.118,26.523,0,36.641
  			L122.46,931.232z M93.663,750.922c26.022-26.023,68.215-26.023,94.237,0c26.023,26.021,26.023,68.215,0,94.236
  			c-26.022,26.023-68.215,26.023-94.237,0C67.639,819.137,67.639,776.945,93.663,750.922z"
      />
      <path
        d="M228.934,406.828c0.646,0,1.318-0.078,2.008-0.245c19.385-4.704,39.216-7.057,59.053-7.057
  			c47.796,0,95.594,13.661,137.034,40.98c13.876,9.147,27.04,19.826,39.251,32.037c12.212,12.213,22.886,25.379,32.034,39.254
  			c38.655,58.637,49.966,129.998,33.927,196.084c-1.358,5.596,3.131,9.939,7.902,9.939c1.951,0,3.951-0.727,5.616-2.393
  			l244.844-244.842c5.617-5.619,5.617-14.728,0-20.346L488.583,148.22c-2.809-2.809-6.491-4.214-10.172-4.214
  			c-3.682,0-7.364,1.405-10.173,4.214L223.394,393.063C218.16,398.298,222.198,406.828,228.934,406.828z M538.587,344.986
  			c14.729-14.729,38.61-14.729,53.339,0c14.729,14.729,14.729,38.61,0,53.339c-14.729,14.729-38.609,14.729-53.339,0
  			C523.859,383.596,523.859,359.715,538.587,344.986z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 14
    },
    height: {
      type: [Number, String],
      default: 14
    }
  }
};
</script>
