import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, DeferredJobGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, DeferredJobGetters);

export default {
  [_getters.GET_DEFERRED_JOBS]: state => {
    return state.deferredJobs;
  },
  [_getters.GET_DEFERRED_JOBS_COUNT]: state => {
    return state.deferredJobsCount;
  },
  [_getters.HAS_DEFERRED_JOBS]: state => {
    return state.deferredJobsCount?.count > 0;
  },
  [_getters.GET_DEFERRED_JOBS_SEARCH_RESULTS]: state => {
    return state.deferredJobsSearchResults;
  },
  [_getters.GET_UNIT_DEFERRED_JOBS]: state => {
    return state.unitDeferredJobs;
  },
  [_getters.GET_SELECTED_DEFERRED_JOBS]: state => {
    return state.selectedDeferredJobs;
  }
};
