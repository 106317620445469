<template>
  <b-table
    striped
    sticky-header="63vh"
    class="border"
    no-border-collapse
    :items="operationsForecast"
    :fields="resultTableColumns"
    :per-page="pageSize"
    :current-page="currentPage"
    :filter="searchFilter"
    :filter-included-fields="filterOn"
    :sort-by.sync="sortBy"
    primary-key="key"
    show-empty
    empty-text="No results found."
    empty-filtered-text="No results found."
    @filtered="onFiltered"
  >
    <template #cell(vin)="{ item }">
      <safe-hyperlink :to="'units/' + item.unitId">
        {{ item.vin }}
      </safe-hyperlink>
    </template>
    <template #cell(customerId)="{ item }">
      <safe-hyperlink :to="'/customers/' + item.customerId">
        {{ item.customerId }}
      </safe-hyperlink>
    </template>
    <template #cell(currentMileageAndEngineHours)="{ item }">
      {{ item.mileage | number }} Miles
      <br />
      {{ item.engineHours | number }} Hours
    </template>

    <template #cell(location)="{ item }">
      <div v-if="item.location">{{ item.location }}</div>
    </template>

    <template #cell(lastCompleted)="{ item }">
      <div v-if="item.lastCompletedDate">{{ item.lastCompletedDate | date }}</div>
      <div v-if="item.lastCompletedMileage">{{ item.lastCompletedMileage | number }} Miles</div>
      <div v-if="item.lastCompletedEngineHours">{{ item.lastCompletedEngineHours | number }} Hours</div>
    </template>

    <template #cell(nextDue)="{ item }">
      <div v-if="item.nextDueDate">{{ item.nextDueDate | date }}</div>
      <div v-if="item.nextDueMileage">{{ item.nextDueMileage | number }} Miles</div>
      <div v-if="item.nextDueEngineHours">{{ item.nextDueEngineHours | number }} Hours</div>
    </template>

    <template #cell(operationStatus)="{ item }">
      <div class="text-nowrap">
        <b-badge :variant="CssHelper.getOperationStatusCssClass(item.operationStatus)" class="mr-1">
          {{ item.operationStatus }}
        </b-badge>
        <sup v-if="item.operationStatus == 'Undetermined'">
          <font-awesome-icon
            :id="`undetermined_tooltip_${item.key}`"
            icon="question-circle"
            size="lg"
            class="text-primary align-self-center"
            display="inline-block"
          />
          <b-tooltip :target="`undetermined_tooltip_${item.key}`" triggers="hover">
            Unable to forecast due to missing data.
            {{ $isCustomer ? 'Contact Clarke/VehiCare representative to update.' : '' }}
          </b-tooltip>
        </sup>
      </div>
    </template>

    <template #cell(serviceStatus)="{ item, index }">
      <div v-if="item.serviceRequest && item.serviceRequest.appointment" :id="`startDate_${index}`">
        <b-badge
          v-if="$isCustomer"
          variant="light"
          :style="AppointmentCssHelper.badgeStyle(item.serviceRequest.appointment)"
        >
          {{ item.serviceRequest.appointment.appointmentStatus.name }}
        </b-badge>
        <b-badge
          v-else
          :href="`${shopSchedulerUrl}/schedule/${item.serviceRequest.appointment.branchId}`"
          variant="light"
          :style="AppointmentCssHelper.badgeStyle(item.serviceRequest.appointment)"
        >
          {{ item.serviceRequest.appointment.appointmentStatus.name }}
        </b-badge>
      </div>
      <!-- TODO make this a link to the VCP Customer Calendar -->
      <div v-else-if="item.serviceRequest" :id="`serviceDateRequested_${index}`">
        <b-badge variant="light" :style="AppointmentCssHelper.requestBadgeStyle()">Requested</b-badge>
      </div>
      <b-tooltip
        v-if="item.serviceRequest && item.serviceRequest.appointment"
        :target="`startDate_${index}`"
        boundary="window"
        triggers="hover"
      >
        {{ item.serviceRequest.appointment.startDate | dateTime }}
      </b-tooltip>
      <b-tooltip
        v-else-if="item.serviceRequest && item.serviceRequest.serviceDateRequested"
        :target="`serviceDateRequested_${index}`"
        boundary="window"
        triggers="hover"
      >
        {{ item.serviceRequest.serviceDateRequested | date }}
      </b-tooltip>
    </template>

    <template #cell(actions)="{ item }">
      <div class="text-center">
        <b-dropdown size="xsm" right variant="secondary" no-caret boundary="viewport">
          <template #button-content>
            <font-awesome-icon icon="ellipsis-h" />
          </template>
          <b-link :to="'/units/' + item.unitId + '/scheduled-operations'" class="dropdown-item">View Schedule</b-link>
          <b-dropdown-item v-if="!$isCustomer" @click="openServiceOrderAside(item.unitId, item.standardOperationId)">
            Mark Complete
          </b-dropdown-item>
          <b-link
            v-if="!$isCustomer"
            class="dropdown-item"
            @click="goToCreateRoute(item.unitId, item.standardOperationId)"
          >
            Create Service Order
          </b-link>
          <b-link
            v-if="!$isCustomer && item.isCloneable"
            :to="{
              name: 'UnitServiceOrders',
              params: { unitId: item.unitId },
              query: { filterBy: 'Cloneable' }
            }"
            class="dropdown-item"
          >
            Clone Service Order
          </b-link>
          <b-dropdown-item v-if="serviceRequestEnabled" @click="requestService(item.unitId)">
            {{ $isCustomer ? 'Service Request' : 'Schedule Service' }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </b-table>
</template>

<script>
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
import { mapGetters, mapActions } from 'vuex';
import { ConfigGetters } from '@/shared/store/config/types';
import { UnitGetters, UnitActions } from '@/shared/store/unit/types';
import CssHelper from '@/shared/helpers/operation-status-css-class-helper';
import AppointmentCssHelper from '@/shared/helpers/appointment-css-helper';
import OperationsForecastMixin from '@/shared/mixins/OperationsForecastMixin';

export default {
  name: 'OperationsForecastSearchResultsTable',
  components: {
    'safe-hyperlink': SafeHyperlinkComponent
  },
  mixins: [OperationsForecastMixin],
  props: {
    serviceRequestEnabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      filterOn: [
        'fleetUnitId',
        'vin',
        'unitType',
        'city',
        'state',
        'customerId',
        'customerName',
        'operation',
        'mileage',
        'engineHours',
        'location',
        'lastCompletedDate',
        'lastCompletedMileage',
        'lastCompletedEngineHours',
        'nextDueDate',
        'nextDueMileage',
        'nextDueEngineHours',
        'operationStatus'
      ],
      CssHelper: CssHelper,
      AppointmentCssHelper: AppointmentCssHelper
    };
  },
  computed: {
    ...mapGetters({
      config: ConfigGetters.GET_CONFIG,
      unitServiceOrderHistory: UnitGetters.GET_UNIT_SERVICE_ORDER_HISTORY
    }),
    shopSchedulerUrl() {
      if (this.$isCustomer) return null;
      return this.config.shopSchedulerUrl;
    }
  },
  mounted() {
    if (this.$isCustomer) {
      this.filterOn = this.filterOn.filter(fieldName => fieldName !== 'customerId');
    }
  },
  methods: {
    ...mapActions([
      UnitActions.SHOW_SERVICE_ORDER_HISTORY_ASIDE,
      UnitActions.FETCH_UNIT,
      UnitActions.FETCH_UNIT_STANDARD_OPERATION,
      UnitActions.FETCH_UNIT_STANDARD_OPERATIONS,
      UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY
    ]),
    onFiltered(filteredItems) {
      this.filteredItems = filteredItems;
      this.totalRows = filteredItems.length; // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
    },
    openServiceOrderAside: async function (unitId, standardOperationId) {
      this[UnitActions.SHOW_SERVICE_ORDER_HISTORY_ASIDE]();
      await this.loadServiceOrderData(unitId, standardOperationId);
    },
    async goToCreateRoute(unitId, standardOperationId) {
      await this[UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY]({
        unitId: unitId,
        params: { completedOnly: false }
      });

      var serviceOrderHistory = this.unitServiceOrderHistory;

      var pathForward =
        serviceOrderHistory.filter(serviceOrder => !serviceOrder.invoiced).length > 0
          ? `/units/${unitId}/service-orders/warning/standard-operation-id/${standardOperationId}`
          : `/units/${unitId}/service-orders/create?mode=serviceOrder&standardOperationId=${standardOperationId}`;

      this.$router.push(pathForward);
    },
    loadServiceOrderData: async function (unitId, standardOperationId) {
      this.asideIsBusy = true;
      await this[UnitActions.FETCH_UNIT](unitId);
      await this[UnitActions.FETCH_UNIT_STANDARD_OPERATION]({ unitId, standardOperationId });
      await this[UnitActions.FETCH_UNIT_STANDARD_OPERATIONS](unitId);
      await this[UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY]({
        unitId,
        params: { completedOnly: true }
      });
      this.asideIsBusy = false;
    },
    requestService(unitId) {
      this.$emit('requestService', unitId);
    }
  }
};
</script>
