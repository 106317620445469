<template>
  <b-row class="flex-wrap justify-content-between" no-gutters>
    <div>
      <b-form-group>
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="searchFilter"
            variant="position-relative"
            type="search"
            placeholder="Type to Filter"
            :debounce="500"
          ></b-form-input>
          <b-input-group-append>
            <div v-show="exportIsBusy">
              <b-button variant="primary" disabled class="ml-2">
                <b-spinner small></b-spinner>
                EXPORTING...
              </b-button>
            </div>
            <div class="ml-4 pr-2">
              <b-button v-show="!exportIsBusy" variant="secondary" @click="exportClick">Export</b-button>
            </div>
          </b-input-group-append>
          <b-button v-if="serviceRequestEnabled && $isCustomer" class="ml-2" @click="multiunitServiceClick">
            MULTI-UNIT SERVICE
          </b-button>
        </b-input-group>
      </b-form-group>
    </div>

    <div class="align-self-center flex-grow-1 text-right mr-4 mb-2">{{ totalUnits }} Units</div>

    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="pageSize"
      aria-controls="my-table"
    ></b-pagination>
  </b-row>
</template>

<script>
import { mapActions } from 'vuex';
import { UnitActions } from '@/shared/store/unit/types';
import ExportService from '@/shared/services/ExportService';
import ErrorService from '@/shared/services/ErrorService';
import OperationsForecastMixin from '@/shared/mixins/OperationsForecastMixin';

export default {
  name: 'OperationsForecastResultsTableHeader',
  mixins: [OperationsForecastMixin],
  props: {
    serviceRequestEnabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      exportIsBusy: false
    };
  },
  methods: {
    ...mapActions([UnitActions.EXPORT_OPERATIONS_FORECAST]),
    multiunitServiceClick: function () {
      this.operationsForecastUniqueUnits = this.getOperationsForecastUniqueUnits();
      this.showBulkServiceRequestModal = true;
    },
    exportClick: async function () {
      try {
        this.exportIsBusy = true;
        var byteArray = await this[UnitActions.EXPORT_OPERATIONS_FORECAST](this.filteredItems);
        ExportService.downloadByteArray(byteArray, 'Operation Forecast Export', true, '.xlsx');
      } catch (error) {
        ErrorService.unknownExportError(this);
      } finally {
        this.exportIsBusy = false;
      }
    },
    getOperationsForecastUniqueUnits() {
      var forecastUniqueUnits = [];

      this.filteredItems.forEach(function (forecastOperation) {
        if (
          !forecastUniqueUnits.some(
            forecastUniqueUnitsOperation =>
              forecastUniqueUnitsOperation.unitId && forecastUniqueUnitsOperation.unitId === forecastOperation.unitId
          )
        ) {
          forecastUniqueUnits.push({
            unitId: forecastOperation.unitId,
            customerId: forecastOperation.customerId,
            customerName: forecastOperation.customerName,
            customer: forecastOperation.customerId + ' - ' + forecastOperation.customerName,
            fleetUnitId: forecastOperation.fleetUnitId,
            vin: forecastOperation.vin,
            branchId: forecastOperation.branchId,
            location: forecastOperation.location
          });
        }
      });

      return forecastUniqueUnits;
    }
  }
};
</script>
