<template>
  <b-row class="justify-content-start">
    <b-col cols="12" class="brake-inspection-item-name">
      <hr />
      <b>{{ description }}</b>
    </b-col>

    <b-col cols="12" lg="2" xl="2">
      <b-form-group
        label="Brake Thickness (32nd):"
        label-class="brake-col-head"
        :label-for="`brake-tread-depth-${_uid}`"
      >
        <div v-if="readonly" :id="`brake-tread-depth-${_uid}`" class="pl-0">
          {{ treadDepth != null ? treadDepth.description : NA }}
        </div>
        <div v-else>
          <b-input-group>
            <v-select
              :id="`brake-tread-depth-${_uid}`"
              v-model="treadDepthId"
              :options="treadDepthsList"
              :reduce="x => x.treadDepthId"
              label="description"
              select-on-tab
            />
          </b-input-group>
        </div>
      </b-form-group>
    </b-col>
    <b-col cols="12" lg="2" xl="2">
      <b-form-group label="Brake Thickness (MM):" label-class="brake-col-head" :label-for="`brake-thickness-${_uid}`">
        <div v-if="readonly" :id="`brake-thickness-${_uid}`" class="pl-0">
          {{ thickness != null ? thickness : NA }}
        </div>
        <div v-else>
          <b-input-group>
            <b-form-input
              :id="`brake-thickness-${_uid}`"
              :value="thickness"
              @keypress="NumberFieldHelper.isDecimal($event, false)"
              @blur="updateDecimal('thickness', $event.target.value)"
            />
          </b-input-group>
        </div>
      </b-form-group>
    </b-col>
    <b-col cols="12" lg="2" xl="2">
      <b-form-group label=" Rotor Thickness(MM):" label-class="brake-col-head" :label-for="`rotor-thickness-${_uid}`">
        <div v-if="readonly" :id="`rotor-thickness-${_uid}`" class="pl-0">
          {{ rotorThickness != null ? rotorThickness : NA }}
        </div>
        <div v-else>
          <b-input-group>
            <b-form-input
              :id="`rotor-thickness-${_uid}`"
              :value="rotorThickness"
              @keypress="NumberFieldHelper.isDecimal($event, false)"
              @blur="updateDecimal('rotorThickness', $event.target.value)"
            />
          </b-input-group>
        </div>
      </b-form-group>
    </b-col>
    <b-col cols="12" lg="3" xl="3">
      <b-form-group label="Push Rod Stroke:" label-class="brake-col-head" :label-for="`brake-stroke-${_uid}`">
        <div v-if="readonly" :id="`brake-stroke-${_uid}`" class="pl-0">
          {{ brakeStroke != null ? brakeStroke.description : NA }}
        </div>
        <div v-else>
          <b-input-group>
            <v-select
              :id="`brake-stroke-${_uid}`"
              v-model="brakeStrokeId"
              :options="strokeOptionsList"
              :reduce="x => x.brakeStrokeId"
              label="description"
              select-on-tab
            />
          </b-input-group>
          <div class="note">If has automatic slack adjusters, 90 psi applied.</div>
        </div>
      </b-form-group>
    </b-col>
    <b-col cols="12" lg="2" xl="2">
      <b-form-group label="Pad Material (%):" label-class="brake-col-head" :label-for="`pad-material-${_uid}`">
        <div v-if="readonly" :id="`pad-material-${_uid}`" class="pl-0">
          {{ padMaterialPercent != null ? padMaterialPercent.description : NA }}
        </div>
        <div v-else>
          <b-input-group>
            <v-select
              :id="`brake-stroke-${_uid}`"
              v-model="padMaterialPercentId"
              :options="padMaterialPercentsList"
              :reduce="x => x.padMaterialPercentId"
              label="description"
              select-on-tab
            />
          </b-input-group>
        </div>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
// components
import vSelect from 'vue-select';
// vuex
import { mapGetters, mapMutations } from 'vuex';
import { LookupGetters } from '@/shared/store/lookup/types';
import { InspectionGetters, InspectionMutations } from '@/shared/store/inspection/types';
// helpers
import NumberFieldHelper from '@/shared/helpers/number-field-helper';

export default {
  name: 'BrakeInspection',
  components: {
    vSelect
  },
  props: {
    itemId: Number(),
    brakeId: Number(),
    readonly: Boolean
  },
  data() {
    return {
      NA: 'N/A',
      NumberFieldHelper: NumberFieldHelper,
      brake: {}
    };
  },
  computed: {
    ...mapGetters({
      getInspection: InspectionGetters.GET_INSPECTION,
      getBrakeInspectionItem: InspectionGetters.GET_BRAKE_INSPECTION_ITEM,
      treadDepthsMap: LookupGetters.GET_TREAD_DEPTHS_MAP,
      treadDepthsList: LookupGetters.GET_TREAD_DEPTHS_LIST,
      strokeOptionsMap: LookupGetters.GET_BRAKE_STROKE_OPTIONS_MAP,
      strokeOptionsList: LookupGetters.GET_BRAKE_STROKE_OPTIONS_LIST,
      padMaterialPercentsMap: LookupGetters.GET_PAD_MATERIAL_PERCENTS_MAP,
      padMaterialPercentsList: LookupGetters.GET_PAD_MATERIAL_PERCENTS_LIST
    }),
    description() {
      return this.brake.description;
    },
    treadDepth() {
      return this.treadDepthsMap[this.treadDepthId];
    },
    treadDepthId: {
      get() {
        return this.brake.result?.treadDepthId;
      },
      set(value) {
        this.setBrakeProp('treadDepthId', value);
      }
    },
    thickness() {
      return this.brake.result?.thickness;
    },
    rotorThickness() {
      return this.brake.result?.rotorThickness;
    },
    brakeStroke() {
      return this.strokeOptionsMap[this.brakeStrokeId];
    },
    brakeStrokeId: {
      get() {
        return this.brake.result?.brakeStrokeId;
      },
      set(value) {
        this.setBrakeProp('brakeStrokeId', value);
      }
    },
    padMaterialPercentId: {
      get() {
        return this.brake.result?.padMaterialPercentId;
      },
      set(value) {
        this.setBrakeProp('padMaterialPercentId', value);
      }
    },
    padMaterialPercent() {
      return this.padMaterialPercentsMap[this.padMaterialPercentId];
    }
  },
  watch: {
    getInspection: {
      handler() {
        this.brake = this.getBrakeInspectionItem(this.itemId, this.brakeId);
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations([InspectionMutations.SET_BRAKE_PROP]),
    setBrakeProp(key, value) {
      this[InspectionMutations.SET_BRAKE_PROP]({ itemId: this.itemId, brakeId: this.brakeId, key, value });
    },
    updateDecimal(key, value) {
      const floatValue = value ? parseFloat(value) : null;
      this.setBrakeProp(key, floatValue);
    }
  }
};
</script>
