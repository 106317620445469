import moment from 'moment-timezone';

function configureZoneAbbreviations() {
  var abbrs = {
    EST: 'Eastern Standard Time',
    EDT: 'Eastern Daylight Time',
    CST: 'Central Standard Time',
    CDT: 'Central Daylight Time',
    MST: 'Mountain Standard Time',
    MDT: 'Mountain Daylight Time',
    PST: 'Pacific Standard Time',
    PDT: 'Pacific Daylight Time'
  };
  moment.fn.zoneName = function () {
    var abbr = this.zoneAbbr();
    return abbrs[abbr] || abbr;
  };
}

function userTimeZone() {
  const tzName = moment.tz.guess();
  return moment.tz(tzName).format('zz (z)');
}

export default { configureZoneAbbreviations, userTimeZone };
