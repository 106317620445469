export const namespace = 'tire/';

export const TireGetters = {
  GET_TIRE_REPLACEMENTS: namespace + 'getTireReplacements',
  GET_OLD_TIRE: namespace + 'getOldTire',
  GET_DB_OLD_TIRE: namespace + 'getDBOldTire',
  GET_NEW_TIRE: namespace + 'getNewTire',
  GET_DB_NEW_TIRE: namespace + 'getDBNewTire',
  HAS_CHANGES: namespace + 'hasChanges'
};

export const TireActions = {
  FETCH_TIRE_REPLACEMENTS: namespace + 'fetchTireReplacements',
  SAVE_UPDATE_TIRE_REPLACEMENTS: namespace + 'saveUpdateTireReplacements'
};

export const TireMutations = {
  RESET_STATE: namespace + 'resetState',
  SET_TIRE_REPLACEMENTS: namespace + 'setTireReplacements',
  SET_OLD_TIRE_PROP: namespace + 'setOldTireProp',
  SET_OLD_TIRE: namespace + 'setOldTire',
  SET_NEW_TIRE_PROP: namespace + 'setNewTireProp',
  SET_NEW_TIRE: namespace + 'setNewTire',
  SET_OLD_TIRE_INSPECTION_PROP: namespace + 'setOldTireInspectionProp',
  SET_NEW_TIRE_INSPECTION_PROP: namespace + 'setNewTireInspectiomProp'
};
