<template>
  <div>
    <b-row>
      <b-col><div class="line-titles">SUBLET</div></b-col>
    </b-row>

    <div v-for="(sublet, subletIndex) in subletLines" :key="subletIndex">
      <b-row v-if="!sublet.toBeDeleted" :class="subletIndex < subletLines.length - 1 ? 'pb-1' : ''">
        <b-col :lg="sublet._searchingSublets ? 6 : 2" :cols="sublet._searchingSublets ? 12 : 4">
          <b-form-group
            label="CODE:"
            :label-class="subletIndex > 0 ? 'col-head line-item' : 'col-head line-item first-row'"
            :label-for="`subletCode_${subletIndex}`"
          >
            <div v-if="readonly" :id="`op-${opId}-sublet-${subletIndex}-code`" class="pl-0">
              {{ sublet.subletCodeId || NA }}
            </div>
            <div v-else>
              <b-input-group>
                <v-select
                  v-if="sublet._local"
                  :id="`op-${opId}-sublet-${subletIndex}-code`"
                  :value="sublet"
                  :options="subletCodesList"
                  :filter-by="filterSubletCodes"
                  :clearable="false"
                  label="subletCodeId"
                  :class="hasError(vSublet(subletIndex).subletCodeId, 'required') ? 'is-invalid' : ''"
                  @input="updateSubletCode(subletIndex, $event)"
                  @search:focus="toggleSubletSearch(subletIndex, true)"
                  @search:blur="toggleSubletSearch(subletIndex, false)"
                >
                  <template #selected-option="subl">
                    <template v-if="sublet.subletCodeId">
                      <span class="text-uppercase">{{ subl.subletCodeId }}</span>
                    </template>
                  </template>
                  <template #option="subl">
                    <span class="text-uppercase">{{ subl.subletCodeId }} - {{ subl.description }}</span>
                  </template>
                </v-select>
                <b-form-input
                  v-else
                  :id="`op-${opId}-sublet-${subletIndex}-code`"
                  :value="sublet.subletCodeId"
                  disabled
                />
              </b-input-group>
              <div v-if="hasError(vSublet(subletIndex).subletCodeId, 'required')" class="error">
                Enter a valid Sublet Code.
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col v-show="!sublet._searchingSublets" lg="4" cols="8">
          <b-form-group
            label="DESCRIPTION:"
            :label-class="subletIndex > 0 ? 'col-head line-item' : 'col-head line-item first-row'"
            :label-for="`op-${opId}-sublet-${subletIndex}-description`"
          >
            <div v-if="readonly" :id="`op-${opId}-sublet-${subletIndex}-description`" class="pl-0">
              {{ sublet.description || NA }}
            </div>
            <div v-else>
              <b-input-group>
                <b-form-input
                  :id="`op-${opId}-sublet-${subletIndex}-description`"
                  :value="sublet.description"
                  :state="hasError(vSublet(subletIndex).description, 'required') ? false : null"
                  :disabled="!sublet._local && (!allowEdit || sublet.complete)"
                  @input="updateDescription(subletIndex, $event)"
                />
              </b-input-group>
              <div v-if="hasError(vSublet(subletIndex).description, 'required')" class="error">
                Enter a description.
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col v-if="!isPackagedPricing || !$isCustomer" lg="2" cols="4">
          <b-form-group
            label="PRICE:"
            :label-class="subletIndex > 0 ? 'col-head line-item' : 'col-head line-item first-row'"
            :label-for="`op-${opId}-sublet-${subletIndex}-price`"
          >
            <div v-if="readonly" :id="`op-${opId}-sublet-${subletIndex}-price`" class="pl-0">
              {{ sublet.price | currency }}
            </div>
            <b-input-group v-else prepend="$">
              <b-form-input
                :id="`op-${opId}-sublet-${subletIndex}-price`"
                v-currency="currencyOptions"
                :value="sublet.price"
                class="rounded-right"
                :disabled="!sublet._local && (!allowEdit || sublet.complete)"
                @input="updateSubletPrice(subletIndex, $event)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col v-if="!$isCustomer" lg="2" cols="4">
          <b-form-group
            label="COST:"
            :label-class="subletIndex > 0 ? 'col-head line-item' : 'col-head line-item first-row'"
            :label-for="`op-${opId}-sublet-${subletIndex}-cost`"
          >
            <div v-if="readonly" :id="`op-${opId}-sublet-${subletIndex}-cost`" class="pl-0">
              {{ sublet.cost | currency }}
            </div>
            <b-input-group v-else prepend="$">
              <b-form-input
                :id="`op-${opId}-sublet-${subletIndex}-cost`"
                v-currency="currencyOptions"
                :value="sublet.cost"
                class="rounded-right"
                :disabled="!sublet._local && (!allowEdit || sublet.complete)"
                @input="updateSubletCost(subletIndex, $event)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="1" cols="2">
          <b-form-group
            label="QTY:"
            :label-class="subletIndex > 0 ? 'col-head line-item' : 'col-head line-item first-row'"
            :label-for="`op-${opId}-sublet-${subletIndex}-quantity`"
          >
            <div v-if="readonly" :id="`op-${opId}-sublet-${subletIndex}-quantity`" class="pl-0">
              {{ sublet.quantity }}
            </div>
            <div v-else>
              <b-input-group>
                <b-form-input
                  :id="`op-${opId}-sublet-${subletIndex}-quantity`"
                  :value="sublet.quantity"
                  :state="hasError(vSublet(subletIndex).quantity, 'required') ? false : null"
                  class="rounded-right"
                  :disabled="!sublet._local && (!allowEdit || sublet.complete)"
                  @keypress="NumberFieldHelper.isDecimal($event)"
                  @blur="updateQuantity(subletIndex, $event.target.value)"
                />
              </b-input-group>
              <div v-if="hasError(vSublet(subletIndex).quantity, 'required')" class="error">Enter a quantity.</div>
            </div>
          </b-form-group>
        </b-col>
        <b-col v-if="!readonly" lg="1" cols="2">
          <b-form-group
            label="ACTIONS:"
            :label-class="`col-head line-item invisible ${subletIndex > 0 ? '' : 'first-row'}`"
            :label-for="`op-${opId}-sublet-${subletIndex}-actions`"
          >
            <div class="d-block">
              <b-button
                v-if="allowRemove(sublet)"
                v-b-tooltip
                size="xsm"
                class="m-1"
                title="Remove"
                @click="removeSubletLine(subletIndex)"
              >
                <font-awesome-icon icon="trash-alt" />
              </b-button>
            </div>
          </b-form-group>
        </b-col>
        <div class="line-wrap-border" />
      </b-row>
    </div>
    <b-row v-if="!readonly">
      <b-col offset-lg="9" lg="2" offset="10" cols="2">
        <b-button class="p-0 float-right mt-neg4px" variant="link" size="xsm" @click="addSubletLine()">
          <font-awesome-icon icon="plus" />
          Add Row
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { parse } from 'vue-currency-input';
import ErrorService from '@/shared/services/ErrorService';
import { mapMutations, mapGetters } from 'vuex';
import { ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import NumberFieldHelper from '@/shared/helpers/number-field-helper';
import { hasError } from '@/shared/helpers/validator-helper.js';

export default {
  name: 'ServiceOrderSubletComponent',
  components: {
    vSelect
  },
  inject: {
    validator: {
      from: 'validator',
      default: () => null
    }
  },
  props: {
    jobKey: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isPackagedPricing: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      NumberFieldHelper: NumberFieldHelper,
      currencyOptions: {
        currency: null,
        allowNegative: false
      }
    };
  },
  computed: {
    ...mapGetters([ServiceOrderGetters.GET_SUBLET_LINES, ServiceOrderGetters.HAS_LABOR_OR_SUBLET]),
    ...mapGetters({
      jobs: ServiceOrderGetters.GET_JOBS,
      subletCodesList: LookupGetters.GET_SUBLET_CODES_LIST
    }),
    opId() {
      return this.jobs[this.jobKey].operationId;
    },
    subletLines() {
      return this[ServiceOrderGetters.GET_SUBLET_LINES](this.jobKey);
    },
    subletCodeValidation() {
      return this.validator.jobs.$each[this.jobKey].details.subletLines.$each.$iter;
    }
  },
  methods: {
    ...mapMutations([
      ServiceOrderMutations.ADD_SUBLET_LINE,
      ServiceOrderMutations.REMOVE_SUBLET_LINE,
      ServiceOrderMutations.SET_SUBLET_CODE,
      ServiceOrderMutations.SET_SUBLET_DESCRIPTION,
      ServiceOrderMutations.SET_SUBLET_PRICE,
      ServiceOrderMutations.SET_SUBLET_COST,
      ServiceOrderMutations.SET_SUBLET_QTY,
      ServiceOrderMutations.SET_SUBLET_SEARCH
    ]),
    addSubletLine() {
      let missingCode = false;
      for (let index in this.subletCodeValidation) {
        const v = this.subletCodeValidation[index];
        v.subletCodeId.$touch();
        if (!v.subletCodeId.required) missingCode = true;
      }
      if (missingCode) {
        const errorMessage = `Complete current line before adding an additional Sublet Code.`;
        ErrorService.createErrorToast(this, errorMessage);
      } else {
        this[ServiceOrderMutations.ADD_SUBLET_LINE](this.jobKey);
        this.vSublet(this.subletLines.length - 1).$reset();
      }
    },
    allowRemove(sublet) {
      return this[ServiceOrderGetters.HAS_LABOR_OR_SUBLET](this.jobKey) && !sublet.complete;
    },
    removeSubletLine(index) {
      this[ServiceOrderMutations.REMOVE_SUBLET_LINE]({ jobKey: this.jobKey, index: index });
    },
    updateSubletCode(index, value) {
      this[ServiceOrderMutations.SET_SUBLET_CODE]({
        jobKey: this.jobKey,
        index: index,
        subletCodeId: value.subletCodeId,
        description: value.description,
        price: value.price,
        cost: value.cost,
        quantity: value.quantity
      });
      this.vSublet(index).subletCodeId.$touch();
    },
    updateDescription(index, description) {
      this[ServiceOrderMutations.SET_SUBLET_DESCRIPTION]({ jobKey: this.jobKey, index, description });
    },
    updateSubletPrice(index, subletPrice) {
      let price = parse(subletPrice, this.currencyOptions);
      this[ServiceOrderMutations.SET_SUBLET_PRICE]({ jobKey: this.jobKey, index, price });
    },
    updateSubletCost(index, cost) {
      const subletCost = parse(cost, this.currencyOptions);
      this[ServiceOrderMutations.SET_SUBLET_COST]({ jobKey: this.jobKey, index, subletCost });
    },
    updateQuantity(index, qty) {
      const quantity = qty ? parseFloat(qty) : qty;
      this[ServiceOrderMutations.SET_SUBLET_QTY]({ jobKey: this.jobKey, index, quantity });
    },
    filterSubletCodes(option, label, search) {
      return (
        option.subletCodeId.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        option.description.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    },
    toggleSubletSearch(subletIndex, value) {
      this[ServiceOrderMutations.SET_SUBLET_SEARCH]({ jobKey: this.jobKey, subletIndex, value });
    },
    vSublet(subletIndex) {
      return this.subletCodeValidation[subletIndex];
    },
    hasError: hasError
  }
};
</script>
