import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faArrowCircleRight,
  faArrowCircleLeft,
  faArrowLeft,
  faBars,
  faChevronLeft,
  faChevronRight,
  faCamera,
  faChartBar,
  faCheck,
  faClone,
  faCircle,
  faEllipsisH,
  faExclamationCircle,
  faExternalLinkAlt,
  faInfoCircle,
  faSignOutAlt,
  faMinus,
  faMinusCircle,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faQuestionCircle,
  faRecycle,
  faSearch,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faWrench,
  faEdit,
  faArrowDown
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarPlus, faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
  faAngleLeft,
  faAngleDown,
  faAngleRight,
  faArrowCircleRight,
  faArrowCircleLeft,
  faArrowLeft,
  faBars,
  faChevronLeft,
  faChevronRight,
  faCalendar,
  faCamera,
  faCalendarPlus,
  faChartBar,
  faCheck,
  faClone,
  faCircle,
  faEllipsisH,
  faExclamationCircle,
  faExternalLinkAlt,
  faInfoCircle,
  faSignOutAlt,
  faMinus,
  faMinusCircle,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faProductHunt,
  faQuestionCircle,
  faRecycle,
  faSearch,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faWrench,
  faEdit,
  faArrowDown
);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('FontAwesomeLayers', FontAwesomeLayers);
