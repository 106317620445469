<template>
  <div>
    <b-card class="page-card">
      <div class="page-header">
        <h2>Estimates</h2>
      </div>
      <responsive-search
        ref="search"
        :fields="searchFields"
        :is-busy="searchIsBusy"
        :storage-key="storageKey"
        @searchButtonClick="searchButtonClick"
        @resetButtonClick="resetButtonClick"
      >
        <template #assignedToEmployeeIds="props">
          <div>
            <label class="col-head">Assigned To:</label>
            <b-input-group>
              <employee-select
                v-model="props.data.assignedToEmployeeIds"
                :reduce="e => e.employeeId"
                class="min-w195px search-field-select"
                multiple
                placeholder="Name or ID"
                :show-email="false"
                :options="myAssociatedEmployeesList"
              />
            </b-input-group>
          </div>
        </template>
      </responsive-search>

      <b-row v-if="!$smallScreen">
        <b-form-group>
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              variant="position-relative"
              type="search"
              placeholder="Type to Filter"
              :debounce="500"
            ></b-form-input>
            <b-input-group-append v-if="!$isCustomer && filteredItems && filteredItems.length > 0">
              <div v-show="exportIsBusy">
                <b-button variant="primary" disabled class="ml-2">
                  <b-spinner small></b-spinner>
                  EXPORTING...
                </b-button>
              </div>
              <div class="ml-4 pr-2">
                <b-button v-show="!exportIsBusy" :disabled="searchIsBusy" variant="secondary" @click="exportClick">
                  Export
                </b-button>
              </div>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="pageSize"
          aria-controls="my-table"
          align="right"
          class="ml-auto w-auto"
        ></b-pagination>
      </b-row>
      <mobile-table v-if="$smallScreen" :items="estimates" :fields="fields">
        <template #[`serviceOrderId`]="{ item }">
          <label :class="`col-head`">Estimate ID:</label>
          <safe-hyperlink :to="'/units/' + item.unitId + '/service-orders/' + item.serviceOrderId">
            {{ item.serviceOrderId }}
          </safe-hyperlink>
        </template>
        <template #[`vin`]="{ item }">
          <label :class="`col-head`">VIN/Serial:</label>
          <safe-hyperlink :to="'/units/' + item.unitId">
            {{ item.vin }}
          </safe-hyperlink>
        </template>
        <template #[`customerId`]="{ item }">
          <label :class="`col-head`">Customer:</label>
          <safe-hyperlink :to="'/customers/' + item.customerId">
            {{ item.customerId }} - {{ item.customerName }}
          </safe-hyperlink>
        </template>
        <template #[`branchId`]="{ item }">
          <label :class="`col-head`">Branch:</label>
          <div>{{ item.branchId }} - {{ item.branchName }}</div>
        </template>
        <template #[`createdDate`]="{ item }">
          <label :class="`col-head`">Created:</label>
          <div>{{ item.createdDate | date }}</div>
        </template>
        <template #[`assignedToEmployeeName`]="{ item }">
          <label :class="`col-head`">Assigned To:</label>
          <employee-display :employee="{ name: item.assignedToEmployeeName }" />
        </template>
        <template #[`reviewed`]="{ item }">
          <label :class="`col-head`">Reviewed:</label>
          <div>{{ item.reviewed ? 'Yes' : 'No' }}</div>
        </template>
        <template #[`repairReason`]=""><span /></template>
        <template #[`repairStatus`]=""><span /></template>
      </mobile-table>
      <b-table
        v-else
        class="border"
        no-border-collapse
        striped
        sticky-header="63vh"
        :items="estimates"
        :fields="fields"
        :per-page="pageSize"
        :current-page="currentPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No results found."
        empty-filtered-text="No results found."
        @filtered="onFiltered"
      >
        <template #cell(serviceOrderId)="{ item }">
          <safe-hyperlink :to="'/units/' + item.unitId + '/service-orders/' + item.serviceOrderId">
            {{ item.serviceOrderId }}
          </safe-hyperlink>
        </template>
        <template #cell(vin)="{ item }">
          <safe-hyperlink :to="'/units/' + item.unitId">
            {{ item.vin }}
          </safe-hyperlink>
        </template>
        <template #cell(customerId)="{ item }">
          <safe-hyperlink :to="'/customers/' + item.customerId">
            {{ item.customerId }}
          </safe-hyperlink>
        </template>
        <template #cell(branchId)="item">
          {{ formatBranch(item) }}
        </template>
        <template #cell(reviewed)="item">
          <div class="text-center"><font-awesome-icon v-show="item.value" icon="check" class="text-success" /></div>
        </template>
        <template #cell(createdDate)="item">
          <span v-if="item.value">{{ item.value | date }}</span>
        </template>
        <template #cell(assignedToEmployeeName)="item">
          <employee-display :employee="{ name: item.value }" />
        </template>
        <template #cell(total)="item">
          {{ item.value | currency }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
// components
import ResponsiveSearchComponent from '@/shared/components/ResponsiveSearchComponent';
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
import EmployeeDisplayComponent from '@/shared/components/ui/EmployeeDisplayComponent';
import EmployeeSelectComponent from '@/shared/components/ui/EmployeeSelectComponent';
// mixins
import SearchMixin from '@/shared/mixins/SearchMixin';
// vuex
import { mapActions, mapGetters } from 'vuex';
import { ServiceOrderActions, ServiceOrderGetters } from '@/shared/store/service-order/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { UserGetters } from '@/shared/store/user/types';
// helpers
import ErrorService from '@/shared/services/ErrorService';
import ExportService from '@/shared/services/ExportService';

export default {
  name: 'ServiceOrdersSearchPage',
  components: {
    'employee-display': EmployeeDisplayComponent,
    'employee-select': EmployeeSelectComponent,
    'responsive-search': ResponsiveSearchComponent,
    'safe-hyperlink': SafeHyperlinkComponent,
    'mobile-table': () => import('@/shared/components/MobileTableComponent')
  },
  mixins: [SearchMixin],
  beforeRouteUpdate(to, from, next) {
    next();
    this.$refs.search?.loadSearchParameters(to.query);
    this.runSearch();
  },
  data() {
    return {
      totalRows: 0,
      pageSize: 50,
      currentPage: 1,
      filteredItems: [],
      filter: null,
      sortBy: '',
      searchIsBusy: false,
      exportIsBusy: false,
      storageKey: 'estimateSearch'
    };
  },
  computed: {
    ...mapGetters({
      estimates: ServiceOrderGetters.GET_EST_SEARCH_RESULTS,
      user: UserGetters.GET_USER_PROFILE,
      myAssociatedEmployeesList: LookupGetters.GET_MY_ASSOCIATED_EMPLOYEES_LIST,
      estimateBranchesList: LookupGetters.GET_ESTIMATE_BRANCHES_LIST,
      repairReasonsList: LookupGetters.GET_REPAIR_REASONS_LIST
    }),
    activeRepairReasons() {
      return this.repairReasonsList.filter(r => !r.inactive);
    },
    searchFields() {
      let searchFields = [];

      if (!this.$isMobileApp) {
        searchFields.push({
          key: 'reviewed',
          label: 'Reviewed',
          radioOptions: [
            { text: 'All', value: null },
            { text: 'Reviewed', value: 'true' },
            { text: 'Needs Reviewed', value: 'false' }
          ]
        });
      }

      let branchList = {};
      if (!this.$isCustomer) {
        searchFields.push({ key: 'assignedToEmployeeIds', label: 'Assigned To' }); // displayed via slot
      } else {
        branchList = { options: this.estimateBranchesList };
      }
      const customerField = this.$isCustomer ? this.myCustomersField : this.customerField;
      searchFields.push(
        ...[
          { key: 'serviceOrderId', label: 'Estimate ID' },
          { ...this.fleetUnitIdField },
          { ...this.vinField, collapsible: true },
          { ...customerField, collapsible: true },
          { ...this.branchField, ...branchList, collapsible: true },
          { ...this.licensePlateField, collapsible: true, hidden: true },
          { ...this.unitTypesField, collapsible: true, hidden: true },
          { ...this.unitStatusesField, collapsible: true, hidden: true },
          {
            key: 'repairReasons',
            label: 'Reason For Repair',
            placeholder: 'Select Reasons For Repair',
            options: this.activeRepairReasons,
            reduce: x => x.repairReason,
            selectLabel: 'repairReason',
            collapsible: true,
            hidden: true
          }
        ]
      );
      return searchFields;
    },
    fields() {
      const fields = [];
      fields.push({ key: 'serviceOrderId', label: 'Estimate ID', sortable: true });
      fields.push({ key: 'fleetUnitId', label: 'Fleet Unit ID', sortable: true });
      fields.push({ key: 'vin', label: 'VIN/Serial', sortable: true });

      if (!this.$isCustomer) {
        fields.push({ key: 'customerId', label: 'Customer ID', sortable: true, collapsible: true });
      }
      if (!this.$smallScreen)
        fields.push({ key: 'customerName', label: 'Customer', sortable: true, collapsible: true });

      fields.push({ key: 'branchId', label: 'Branch', sortable: true, collapsible: true });
      fields.push({ key: 'reviewed', label: 'Reviewed', sortable: true, collapsible: true });
      fields.push({ key: 'createdDate', label: 'Created', sortable: true, collapsible: true });

      fields.push({ key: 'repairReason', label: 'Reason For Repair', sortable: true, collapsible: true });

      if (!this.$isCustomer) {
        fields.push({ key: 'assignedToEmployeeName', label: 'Assigned To', sortable: true, collapsible: true });
      }
      if (!this.$isMobileApp) {
        fields.push({ key: 'total', label: 'Total', sortable: true, collapsible: true });
      }

      return fields;
    }
  },
  mounted() {
    const query = this.$refs.search?.getQueryString();
    if (query != null || this.$isCustomer || !this.user.employeeId) {
      this.$router.replace({ query }).catch(() => {
        this.runSearch();
      });
      return;
    }
    this.defaultSearchParameters();
  },
  methods: {
    ...mapActions([ServiceOrderActions.SEARCH_ESTIMATES, ServiceOrderActions.EXPORT]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filteredItems = filteredItems;
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetButtonClick: async function () {
      this.filter = '';
      this.sortBy = '';
      await this.searchButtonClick(null);
    },
    searchButtonClick: async function (query) {
      await this.$router.push({ query }).catch(() => {
        this.runSearch();
      });
    },
    async runSearch() {
      try {
        this.searchIsBusy = true;
        await this[ServiceOrderActions.SEARCH_ESTIMATES](this.$route.query);
        this.filteredItems = this.estimates;
        this.totalRows = this.estimates.length;
      } catch (error) {
        ErrorService.unknownSearchError(this);
      } finally {
        this.searchIsBusy = false;
      }
    },
    formatBranch(row) {
      return `${row.item.branchId} - ${row.item.branchName}`;
    },
    defaultSearchParameters() {
      let searchData = { assignedToEmployeeIds: this.user.employeeId };

      this.$refs.search.data = searchData;
      this.$refs.search?.searchButtonClick();
    },
    exportClick: async function () {
      try {
        this.exportIsBusy = true;
        var byteArray = await this[ServiceOrderActions.EXPORT](this.filteredItems);
        ExportService.downloadByteArray(byteArray, 'Service Order Export', true, '.xlsx');
      } catch (error) {
        ErrorService.unknownExportError(this);
      } finally {
        this.exportIsBusy = false;
      }
    }
  }
};
</script>
