<template>
  <b-modal
    :id="`customerAlertsModal_${_uid}`"
    :ref="`customerAlertsModal_${_uid}`"
    :hide-footer="true"
    size="lg"
    keyboard
  >
    <div>{{ customerType }} {{ title }}</div>
    <b-table striped bordered :fields="fields" :items="displayAlerts" class="align-middle mt-3">
      <template #cell(employee)="{ item }">
        <div class="w120px">
          <employee-display :employee="{ name: item.updatedByEmployeeName }"></employee-display>
        </div>
      </template>

      <template #cell(updateDate)="{ item }">
        {{ item.dateUpdated | date }}
      </template>

      <template #cell(note)="{ item }">
        {{ item.note }}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import EmployeeDisplayComponent from '@/shared/components/ui/EmployeeDisplayComponent';
import { ServiceOrderGetters } from '@/shared/store/service-order/types';
import { mapGetters } from 'vuex';
export default {
  name: 'CustomerAlertsModal',
  components: {
    'employee-display': EmployeeDisplayComponent
  },
  props: {
    id: {
      type: String,
      default: 'CustomerAlertsModal'
    }
  },
  data() {
    return {
      displayAlerts: [],
      customerType: '',
      title: 'ALERTS',
      fields: [
        { key: 'employee', label: 'POSTED BY', tdClass: 'align-middle', thStyle: 'width: 20px' },
        { key: 'updateDate', label: 'DATE', tdClass: 'align-middle' },
        { key: 'note', label: 'MESSAGE', tdClass: 'align-middle' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      customerAlerts: ServiceOrderGetters.GET_CUSTOMER_ALERTS,
      billToCustomerAlerts: ServiceOrderGetters.GET_BILL_TO_CUSTOMER_ALERTS
    })
  },
  methods: {
    show(customerType) {
      if (customerType.isCustomer) {
        this.displayAlerts = this.customerAlerts;
      }
      if (customerType.isBillToCustomer) {
        this.displayAlerts = this.billToCustomerAlerts;
      }

      this.customerType = '';
      if (customerType.isCustomer) {
        this.customerType = this.customerType.concat('CUSTOMER');
      }
      if (customerType.isBillToCustomer) {
        this.customerType = this.customerType.concat('BILL-TO-CUSTOMER');
      }
      this.$bvModal.show(`customerAlertsModal_${this._uid}`);
    }
  }
};
</script>
