export const getDefaultState = () => {
  return {
    notes: [],
    newNote: {
      vcpRecipients: [],
      internalRecipients: [],
      externalRecipients: [],
      isInternal: true
    },
    oldNote: {
      vcpRecipients: [],
      internalRecipients: [],
      externalRecipients: [],
      isInternal: true
    }
  };
};

export default {
  ...getDefaultState()
};
