import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, ConfigGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, ConfigGetters);

export default {
  [_getters.GET_CONFIG]: state => {
    return state.config;
  }
};
