export const STATUS_NAMES = {
  CREATED: 'Created',
  OPEN: 'Open',
  FILED: 'Claim Filed',
  CLOSED: 'Closed'
};

export const getDefaultState = () => {
  const submissionPrototype = {
    warrantyTypeId: null,
    salesOrderId: null,
    customerId: null,
    customerName: null,
    branchId: null,
    warrantySubmissionId: null,
    warrantyStatusId: null,
    assignedToEmployee: {},
    nextWarrantyStatusId: null,
    warrantyDocuments: [],
    recipients: []
  };
  return {
    warrantySearchResults: [],
    recipientList: [],
    warrantyAdmins: [],
    submission: {
      ...submissionPrototype
    },
    dbSubmission: {
      ...submissionPrototype
    }
  };
};

export default {
  ...getDefaultState()
};
