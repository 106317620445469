import moment from 'moment';
import phoneValidator from '@/shared/helpers/phone-validator';

const VamsFilters = {
  install(Vue) {
    Vue.filter('date', function (value, format = null) {
      if (value) {
        format = format ?? 'MM/DD/YYYY';
        return moment(value).format(format);
      } else {
        return 'N/A';
      }
    });

    Vue.filter('dateTime', function (value) {
      if (value) {
        return moment(value).format('MM/DD/YYYY h:mm A');
      } else {
        return 'N/A';
      }
    });

    Vue.filter('hourWithMinutes', function (value) {
      if (value) {
        return moment(value).format('h:mm A');
      } else {
        return 'N/A';
      }
    });

    Vue.filter('dateOrEmptyString', function (value, format = null) {
      if (value) {
        format = format ?? 'MM/DD/YYYY';
        return moment(value).format(format);
      } else {
        return '';
      }
    });

    Vue.filter('number', function (value) {
      return Number(value).toLocaleString();
    });

    Vue.filter('nullValueToNA', function (value) {
      if (value) {
        return value;
      } else {
        return 'N/A';
      }
    });

    Vue.filter('phone', function (value) {
      return value ? phoneValidator.format(value) : null;
    });

    Vue.filter('YesNo', function (value) {
      if (value === true) {
        return 'Yes';
      } else if (value === false) {
        return 'No';
      } else {
        return 'N/A';
      }
    });

    Vue.filter('currencyUS', function (value) {
      if (typeof value !== 'number') {
        return value;
      }
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      return formatter.format(value);
    });
  }
};

export default VamsFilters;
