export const SIDEBAR = {
  EXPANDED: true,
  COLLAPSED: false
};

// these sizes match bootstrpe breakpoints
export const SIZES = {
  xxl: 1400,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576
};

export const getDefaultState = () => {
  return {
    sidebar: SIDEBAR.EXPANDED,
    online: true,
    screenWidth: null
  };
};

export default {
  ...getDefaultState()
};
