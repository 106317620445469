import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, TireGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, TireGetters);

export default {
  [_getters.GET_TIRE_REPLACEMENTS]: state => {
    return state.tireReplacements;
  },
  [_getters.GET_OLD_TIRE]: state => {
    return state.oldTireReplacement;
  },
  [_getters.GET_DB_OLD_TIRE]: state => {
    return state.dbOldTireReplacement;
  },
  [_getters.GET_NEW_TIRE]: state => {
    return state.newTireReplacement;
  },
  [_getters.GET_DB_NEW_TIRE]: state => {
    return state.dbNewTireReplacement;
  },
  [_getters.HAS_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    let newChanges =
      JSON.stringify(state.newTireReplacement, replacer) !== JSON.stringify(state.dbNewTireReplacement, replacer);
    let oldChanges =
      JSON.stringify(state.oldTireReplacement, replacer) !== JSON.stringify(state.dbOldTireReplacement, replacer);
    return newChanges || oldChanges;
  },
  [_getters.GET_OLD_TIRE_REPLACEMENT]: state => {
    return state.oldTireReplacement;
  },
  [_getters.GET_NEW_TIRE_REPLACEMENT]: state => {
    return state.newTireReplacement;
  }
};
