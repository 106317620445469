<template>
  <div v-if="!$isCustomer">
    <h5>{{ headerText }}</h5>
    <b-tabs v-model="activeTab" class="tabs" nav-class="text-uppercase">
      <b-tab :title="label" class="py-3">
        <attachments-component
          v-bind="{ branchId, title, label, readonly, path, parentRequestsUpdate, isWarrantySubmissionPage }"
          :hide-header-text="true"
        />
      </b-tab>
      <b-tab v-if="!hideCustomerTab" :title="customerLabel" class="py-3">
        <template #title>
          Customer
          <b-badge v-if="numOfCustomerAttachments > 0" class="" variant="info">
            {{ numOfCustomerAttachments }}
          </b-badge>
        </template>
        <attachments-component
          :branch-id="branchId"
          :label="customerLabel"
          :path="`/customers/${customerId}`"
          :store-key="customerAttachmentsStoreKey"
          :readonly="true"
          :hide-header-text="true"
          :title="`CUSTOMER ID: ${customerId}`"
        />
      </b-tab>
    </b-tabs>
  </div>
  <div v-else>
    <attachments-component
      v-bind="{ branchId, headerText, title, label, readonly, path, parentRequestsUpdate, isWarrantySubmissionPage }"
    />
  </div>
</template>
<script>
// components
import AttachmentsComponent from '@/shared/components/floating-actions/AttachmentsComponent';
// vuex
import { AttachmentGetters } from '@/shared/store/attachment/types';
import { mapGetters } from 'vuex';
// mixin
import UploadMixin from '@/shared/mixins/UploadMixin';

export default {
  name: 'Attachments',
  components: {
    'attachments-component': AttachmentsComponent
  },
  mixins: [UploadMixin],
  props: {
    hideCustomerTab: {
      type: Boolean(),
      default: false
    },
    customerId: {
      type: String(),
      default: null
    }
  },
  data() {
    return {
      activeTab: 0,
      customerLabel: 'Customer',
      customerAttachmentsStoreKey: 'customerAttachments'
    };
  },
  computed: {
    ...mapGetters([AttachmentGetters.GET_ATTACHMENTS_BY_KEY]),
    numOfCustomerAttachments() {
      return this[AttachmentGetters.GET_ATTACHMENTS_BY_KEY](this.customerAttachmentsStoreKey)?.filter(
        x => !x.dateRemoved
      ).length;
    }
  }
};
</script>
