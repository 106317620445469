import axios from 'axios';
import { UNIT_PATH } from '@/shared/services/UnitService';

const BREAKDOWN_PATH = '/api/breakdown';
const UNIT_BREAKDOWN_PATH_ENDING = 'breakdowns';
const BREAKDOWN_EXPORT_PATH_ENDING = 'export';

class BreakdownService {
  async searchBreakdowns(parameters) {
    const url = `${BREAKDOWN_PATH}`;
    const breakdowns = await axios.get(url, {
      params: parameters
    });
    return breakdowns;
  }

  async getBreakdown(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_BREAKDOWN_PATH_ENDING}/${encodeURIComponent(parameters.breakdownId)}`;
    const response = await axios.get(url);
    return response;
  }

  async createBreakdown(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(parameters.unitId)}/${UNIT_BREAKDOWN_PATH_ENDING}`;
    const response = axios.post(url, parameters.unitBreakdown);
    return response;
  }

  async updateBreakdown(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_BREAKDOWN_PATH_ENDING}/${encodeURIComponent(parameters.unitBreakdown.breakdownId)}`;
    const response = await axios.put(url, parameters.unitBreakdown);
    return response;
  }

  async exportBreakdowns(parameters) {
    const url = `${BREAKDOWN_PATH}/${BREAKDOWN_EXPORT_PATH_ENDING}`;
    const response = await axios.post(url, parameters, {
      responseType: 'blob'
    });
    return response;
  }
}

export default new BreakdownService(); // singleton object
