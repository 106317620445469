import namespaceHelper from '@/shared/helpers/namespace-helper';

import { namespace, InvoiceGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, InvoiceGetters);

export default {
  [_getters.GET_INVOICES]: state => invoiceType => {
    return state.invoices[invoiceType];
  },
  [_getters.GET_HISTORY_INVOICES]: state => {
    return state.historyInvoices ? state.historyInvoices : [];
  },
  [_getters.GET_REVIEW_INVOICES]: state => {
    return state.reviewInvoices ? state.reviewInvoices : [];
  },
  [_getters.GET_FILTERED_INVOICES]: state => {
    return state.filteredInvoices;
  },
  [_getters.GET_INVOICE]: state => {
    return state.invoice;
  },
  [_getters.GET_DB_INVOICE]: state => {
    return state.dbInvoice;
  },
  [_getters.GET_INVOICE_OBJECTS]: state => {
    return state.invoiceObjects;
  },
  [_getters.GET_AP_PROCESSORS]: state => {
    return state.apProcessors;
  },
  [_getters.GET_DIVISION_AP_PROCESSORS]: state => {
    return state.divisionAPProcessors;
  },
  [_getters.GET_POS]: state => {
    return state.pos;
  },
  [_getters.GET_INVOICE_APPROVERS]: state => {
    return state.approvers;
  },
  [_getters.GET_INVOICE_DIVISION_APPROVERS]: state => {
    return state.divisionApprovers;
  },
  [_getters.SET_VENDORS]: state => {
    return state.vendorsList;
  },
  [_getters.GET_IS_LOADING_MODAL]: state => {
    return state.loadingModal;
  },
  [_getters.GET_IS_FETCHING_POS]: state => {
    return state.fetchingPOs;
  },
  [_getters.GET_APP_E_URL]: state => {
    return state.appEUrl;
  },
  [_getters.HAS_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    return JSON.stringify(state.invoice, replacer) !== JSON.stringify(state.dbInvoice, replacer);
  }
};
