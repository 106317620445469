export const getDefaultState = () => {
  return {
    invoices: {},
    historyInvoices: [],
    reviewInvoices: [],
    invoice: [],
    dbInvoice: [],
    apProcessors: [],
    divisionAPProcessors: [],
    divisionApprovers: [],
    invoiceObjects: [],
    vendorsList: [],
    filteredInvoices: [],
    pos: [],
    loadingModal: null,
    fetchingPOs: null,
    appEUrl: null
  };
};
export const DEFAULT_ACCOUNT_NUMBER = '1009030003000';
export default {
  ...getDefaultState()
};
