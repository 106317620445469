<template>
  <b-row class="justify-content-left">
    <b-col cols="12" class="inspection-item-name">{{ description }}</b-col>
    <b-col cols="12" lg="4">
      <b-form-group label="Tire Size:" label-class="col-head" :label-for="`tire-size-${_uid}`">
        <div v-if="readonly" :id="`tire-size-${_uid}`" class="pl-0">
          {{ tireSize || NA }}
        </div>
        <div v-else>
          <div class="d-flex">
            <v-select
              :id="`tire-size-${_uid}`"
              v-model="tireSize"
              :options="tireSizesList"
              :reduce="x => x.description"
              label="description"
              select-on-tab
            />
          </div>
        </div>
      </b-form-group>
    </b-col>

    <b-col cols="12" lg="4">
      <b-form-group label="Tin - Tire Identification Number:" label-class="col-head" :label-for="`tire-tin-${_uid}`">
        <div v-if="readonly" :id="`tire-tin-${_uid}`" class="pl-0">
          {{ tireIdentificationNumber || NA }}
        </div>
        <div v-else>
          <b-input-group>
            <b-form-input :id="`tire-tin-${_uid}`" v-model="tireIdentificationNumber" class="rounded" />
          </b-input-group>
        </div>
      </b-form-group>
    </b-col>

    <b-col cols="12" lg="4">
      <b-form-group
        label="Load Range (Drop down 2-Ply, 4-Ply... 24-Ply):"
        label-class="col-head"
        :label-for="`tire-load-range-${_uid}`"
      >
        <div v-if="readonly" :id="`tire-load-range-${_uid}`" class="pl-0">
          {{ loadRange != null ? loadRange.description : NA }}
        </div>
        <div v-else>
          <b-input-group>
            <v-select
              :id="`tire-load-range-${_uid}`"
              v-model="loadRangeId"
              :options="loadRangesList"
              :reduce="x => x.loadRangeId"
              label="description"
              :clearable="true"
              select-on-tab
            />
          </b-input-group>
        </div>
      </b-form-group>
    </b-col>

    <b-col cols="12" lg="4">
      <b-form-group label="Tread Depth:" label-class="col-head" :label-for="`tire-tread-depth-${_uid}`">
        <div v-if="readonly" :id="`tire-tread-depth-${_uid}`" class="pl-0">
          {{ treadDepth != null ? treadDepth.description : NA }}
        </div>
        <div v-else>
          <b-input-group>
            <v-select
              :id="`tire-tread-depth-${_uid}`"
              v-model="treadDepthId"
              :options="treadDepthsList"
              :reduce="x => x.treadDepthId"
              label="description"
              select-on-tab
            />
          </b-input-group>
        </div>
      </b-form-group>
    </b-col>

    <b-col cols="12" lg="4">
      <b-form-group label="PSI:" label-class="col-head" :label-for="`tire-psi-${_uid}`">
        <div v-if="readonly" :id="`tire-psi-${_uid}`" class="pl-0">
          {{ poundsPerSquareInch || NA }}
        </div>
        <div v-else>
          <b-input-group>
            <b-form-input
              :id="`tire-psi-${_uid}`"
              :value="poundsPerSquareInch"
              class="rounded"
              @keypress="NumberFieldHelper.isDecimal($event, true)"
              @blur="updateDecimal('poundsPerSquareInch', $event.target.value)"
            />
          </b-input-group>
        </div>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
// components
import vSelect from 'vue-select';
// vuex
import { mapGetters, mapMutations } from 'vuex';
import { LookupGetters } from '@/shared/store/lookup/types';
import { InspectionGetters, InspectionMutations } from '@/shared/store/inspection/types';
// helpers
import NumberFieldHelper from '@/shared/helpers/number-field-helper';

export default {
  name: 'TireInspection',
  components: {
    vSelect
  },
  props: {
    itemId: Number(),
    tireId: Number(),
    readonly: Boolean
  },
  data() {
    return {
      NA: 'N/A',
      NumberFieldHelper: NumberFieldHelper,
      savedTireDataSetOrSkipped: false,
      tire: {}
    };
  },
  computed: {
    ...mapGetters({
      getInspection: InspectionGetters.GET_INSPECTION,
      getTireInspectionItem: InspectionGetters.GET_TIRE_INSPECTION_ITEM,
      resultTypesMap: LookupGetters.GET_INSPECTION_RESULT_TYPES_MAP,
      resultTypesList: LookupGetters.GET_INSPECTION_RESULT_TYPES_LIST,
      loadRangesMap: LookupGetters.GET_LOAD_RANGES_MAP,
      loadRangesList: LookupGetters.GET_LOAD_RANGES_LIST,
      treadDepthsMap: LookupGetters.GET_TREAD_DEPTHS_MAP,
      treadDepthsList: LookupGetters.GET_TREAD_DEPTHS_LIST,
      tireSizesMap: LookupGetters.GET_TIRE_SIZES_MAP,
      tireSizesList: LookupGetters.GET_TIRE_SIZES_LIST
    }),
    passFailResults() {
      return this.resultTypesList.filter(result => result.resultTypeGroup === 'passfail');
    },
    description() {
      return this.tire.description;
    },
    tireSize: {
      get() {
        return this.tire.result?.tireSize;
      },
      set(value) {
        this.setTireProp('tireSize', value);
      }
    },
    tireIdentificationNumber: {
      get() {
        return this.tire.result?.tireIdentificationNumber;
      },
      set(value) {
        this.setTireProp('tireIdentificationNumber', value);
      }
    },
    loadRange() {
      return this.loadRangesMap[this.loadRangeId];
    },
    loadRangeId: {
      get() {
        return this.tire.result?.loadRangeId;
      },
      set(value) {
        this.setTireProp('loadRangeId', value);
      }
    },
    treadDepth() {
      return this.treadDepthsMap[this.treadDepthId];
    },
    treadDepthId: {
      get() {
        return this.tire.result?.treadDepthId;
      },
      set(value) {
        this.setTireProp('treadDepthId', value);
      }
    },
    poundsPerSquareInch() {
      return this.tire.result?.poundsPerSquareInch;
    }
  },
  watch: {
    getInspection: {
      handler() {
        this.tire = this.getTireInspectionItem(this.itemId, this.tireId);
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations([InspectionMutations.SET_TIRE_PROP]),
    setTireProp(key, value) {
      this[InspectionMutations.SET_TIRE_PROP]({ itemId: this.itemId, tireId: this.tireId, key, value });
    },
    updateDecimal(key, value) {
      const floatValue = value ? parseFloat(value) : null;
      this.setTireProp(key, floatValue);
    },
    buttonVariant(result) {
      if (result == null || result.success == null) return 'outline-secondary';
      else if (result.success === true) return 'outline-success';
      else if (result.success === false) return 'outline-danger';
    }
  }
};
</script>
