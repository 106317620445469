<template>
  <div>
    <results-table-header v-if="!$smallScreen" v-bind="{ serviceRequestEnabled }" />

    <div class="clearfix"></div>

    <search-results-table-mobile v-if="$smallScreen" />

    <search-results-table v-else v-bind="{ serviceRequestEnabled }" @requestService="requestService" />
  </div>
</template>

<script>
import ResultsTableHeader from './OperationsForecastResultsTableHeader';
import SearchResultsTable from './OperationsForecastSearchResultsTable';
import SearchResultsTableMobile from './OperationsForecastSearchResultsTableMobile';
import OperationsForecastMixin from '@/shared/mixins/OperationsForecastMixin';

export default {
  name: 'OperationsForecastSearchResults',
  components: {
    'results-table-header': ResultsTableHeader,
    'search-results-table': SearchResultsTable,
    'search-results-table-mobile': SearchResultsTableMobile
  },
  mixins: [OperationsForecastMixin],
  props: {
    serviceRequestEnabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.resultTableColumns = [
      { key: 'fleetUnitId', label: 'Fleet Unit Id', sortable: true },
      { key: 'vin', label: 'VIN/Serial', sortable: true, collapsible: true },
      { key: 'unitType', label: 'Unit Type', sortable: true, collapsible: true },
      { key: 'location', label: 'Location', sortable: true },
      { key: 'customerId', label: 'Customer ID', sortable: true },
      { key: 'customerName', label: 'Customer', sortable: true },
      { key: 'operation', label: 'Operation', sortable: true },
      { key: 'currentMileageAndEngineHours', label: 'Current', sortable: false, collapsible: true },
      { key: 'lastCompleted', label: 'Last Completed', sortable: false, collapsible: true },
      { key: 'nextDue', label: 'Next Due', sortable: false, collapsible: true },
      { key: 'operationStatus', label: 'Operation Status', sortable: true },
      { key: 'serviceStatus', label: 'Service Status', sortable: false },
      { key: 'actions', label: 'Actions', sortable: false, collapsible: true }
    ];

    if (this.$isCustomer) {
      this.resultTableColumns = this.resultTableColumns.filter(field => field.key !== 'customerId');
    }

    if (!this.serviceRequestEnabled) {
      this.resultTableColumns = this.resultTableColumns.filter(field => field.key !== 'serviceStatus');
    }
  },
  methods: {
    requestService(unitId) {
      this.$emit('requestService', unitId);
    }
  }
};
</script>
