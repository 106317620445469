<template>
  <div class="footer d-flex">
    <div :class="`${$smallScreen ? '' : 'mr-auto ml190px'}`">
      © 2012-Present Clarke Power Services, Inc. dba Vehicare Licensing, LLC
    </div>
    <div>
      <a
        href="https://www.clarkeww.com/wp-content/uploads/2022/07/Data-Services-Terms-and-Conditions.pdf"
        class="mr-4 footer-link"
      >
        Terms of Service
      </a>
      <a href="https://www.clarkeww.com/privacy-policy/" class="footer-link">Privacy Policy</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter'
};
</script>
