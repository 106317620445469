import namespaceHelper from '@/shared/helpers/namespace-helper';
import NoteService from '@/shared/services/NoteService';
import { namespace, NoteActions, NoteMutations } from './types';

const _actions = namespaceHelper.removeNamespace(namespace, NoteActions);
const _mutations = namespaceHelper.removeNamespace(namespace, NoteMutations);

export default {
  async [_actions.FETCH_NOTES]({ commit }, params) {
    const notes = await NoteService.getNotes(params);
    commit(_mutations.SET_NOTES, notes.data);
  },
  async [_actions.POST_NOTE]({ commit }, params) {
    const notes = await NoteService.postServiceOrderNote(params);
    commit(_mutations.SET_NOTES, notes.data);

    const value = {
      vcpRecipients: [],
      internalRecipients: [],
      externalRecipients: [],
      isInternal: true
    };
    commit(_mutations.SET_OLD_NOTE, value);
    commit(_mutations.SET_NEW_NOTE, value);
  }
};
