var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mobile-table',{attrs:{"items":_vm.operationsForecast,"fields":_vm.resultTableColumns},scopedSlots:_vm._u([{key:"vin",fn:function(ref){
var item = ref.item;
return [_c('label',{class:"col-head"},[_vm._v("VIN/Serial:")]),_c('safe-hyperlink',{attrs:{"to":'units/' + item.unitId}},[_vm._v(" "+_vm._s(item.vin)+" ")])]}},{key:"customerId",fn:function(ref){
var item = ref.item;
return [_c('label',{class:"col-head"},[_vm._v("Customer:")]),_c('safe-hyperlink',{attrs:{"to":'/customers/' + item.customerId}},[_vm._v(" "+_vm._s(item.customerId)+" - "+_vm._s(item.customerName)+" ")])]}},{key:"operation",fn:function(ref){
var item = ref.item;
return [_c('label',{class:"col-head"},[_vm._v("Operation:")]),_c('div',[_vm._v(" "+_vm._s(item.operation)+" "),_c('b-badge',{staticClass:"mr-1",attrs:{"variant":_vm.CssHelper.getOperationStatusCssClass(item.operationStatus)}},[_vm._v(" "+_vm._s(item.operationStatus)+" ")])],1)]}},{key:"currentMileageAndEngineHours",fn:function(ref){
var item = ref.item;
return [_c('label',{class:"col-head"},[_vm._v("Current:")]),_c('div',{staticClass:"date-mileage-hours"},[_c('div',[_vm._v(_vm._s(_vm._f("number")(item.mileage))+" Miles")]),_c('div',[_vm._v(_vm._s(_vm._f("number")(item.engineHours))+" Hours")])])]}},{key:"lastCompleted",fn:function(ref){
var item = ref.item;
return [_c('label',{class:"col-head"},[_vm._v("Last Complete:")]),_c('div',{staticClass:"date-mileage-hours"},[(item.lastCompletedDate)?_c('div',[_vm._v(_vm._s(_vm._f("date")(item.lastCompletedDate)))]):_vm._e(),(item.lastCompletedMileage)?_c('div',[_vm._v(_vm._s(_vm._f("number")(item.lastCompletedMileage))+" Miles")]):_vm._e(),(item.lastCompletedEngineHours)?_c('div',[_vm._v(_vm._s(_vm._f("number")(item.lastCompletedEngineHours))+" Hours")]):_vm._e()])]}},{key:"nextDue",fn:function(ref){
var item = ref.item;
return [_c('label',{class:"col-head"},[_vm._v("Next Due:")]),_c('div',{staticClass:"date-mileage-hours"},[(item.nextDueDate)?_c('div',[_vm._v(_vm._s(_vm._f("date")(item.nextDueDate)))]):_vm._e(),(item.nextDueMileage)?_c('div',[_vm._v(_vm._s(_vm._f("number")(item.nextDueMileage))+" Miles")]):_vm._e(),(item.nextDueEngineHours)?_c('div',[_vm._v(_vm._s(_vm._f("number")(item.nextDueEngineHours))+" Hours")]):_vm._e()])]}},{key:"operationStatus",fn:function(){return [_c('span')]},proxy:true},{key:"customerName",fn:function(){return [_c('span')]},proxy:true},{key:"actions",fn:function(){return [_c('span')]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }