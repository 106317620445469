import getters from '@/shared/store/operations-forecast/getters';
import mutations from '@/shared/store/operations-forecast/mutations';
import actions from '@/shared/store/operations-forecast/actions';
import state from '@/shared/store/operations-forecast/state';

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
