import getters from '@/shared/store/service-order/getters';
import mutations from '@/shared/store/service-order/mutations';
import actions from '@/shared/store/service-order/actions';
import state from '@/shared/store/service-order/state';

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
