import moment from 'moment';

function setDate(value, date) {
  let newDate;
  if (value == null) {
    newDate = null;
  } else {
    const mDate = moment(value);
    newDate = date
      ? moment(date).set({ year: mDate.year(), month: mDate.month(), date: mDate.date() }).toDate()
      : mDate.toDate();
  }

  return newDate;
}

function setTime(value, date) {
  const time = value ? moment(value, 'LT') : moment('12:00 AM', 'LT');
  const newDate = date ? moment(date).set({ hour: time.hour(), minute: time.minute() }).toDate() : date;

  return newDate;
}

export default {
  setDate,
  setTime
};
