function transformEmptyToNull(field) {
  if (field === '') {
    return null;
  }

  return field;
}
function normalizeBoolean(value) {
  if (typeof value === 'boolean') {
    return value;
  }

  return value.toLowerCase() === 'true';
}

function dateReviver(key, value) {
  const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.?\d*(-|Z?)(\d{2}:\d{2})?/;

  if (typeof value === 'string' && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
}

export default {
  transformEmptyToNull,
  normalizeBoolean,
  dateReviver
};
