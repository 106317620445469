import moment from 'moment';

export default {
  inserted(el) {
    if (!(el instanceof HTMLInputElement)) {
      throw 'Cannot apply v-time-input directive to a non-input element!';
    }
    el.addEventListener('blur', () => {
      if (el.value) {
        el.value = myTimeBlur(el.value);
        el.dispatchEvent(new Event('input'));
      }
    });
  }
};
function myTimeBlur(time) {
  // What formats do we want
  // h:m a = 12h:mm AM/PM
  // H:m = 24h:mm
  // h a = 12h assuming 00 for minute AM/PM
  // H = 24h assuming 00 for minutes
  const someTime = moment(time, ['h:m a', 'h:ma', 'H:m', 'h a', 'ha', 'H'], true);
  if (someTime.isValid()) {
    return someTime.format('LT');
  } else {
    return '';
  }
}
