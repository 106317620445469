import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, PartsRequestGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, PartsRequestGetters);

export default {
  [_getters.GET_PARTS_REQUEST]: state => {
    return state.partsRequest;
  },
  [_getters.GET_DB_PARTS_REQUEST]: state => {
    return state.dbPartsRequest;
  },
  [_getters.GET_ALL_OPS_EMPLOYEES]: state => {
    return state.allOpsEmployees;
  },
  [_getters.GET_MATERIALS_EMPLOYEES]: state => {
    return state.materialsEmployees;
  },
  [_getters.GET_REQUEST_PARTS]: state => {
    return state.partsRequest?.requestParts;
  },
  [_getters.GET_REQUESTED_PARTS]: state => {
    return state.requestedParts;
  },
  [_getters.GET_PARTS_REQUEST_SEARCH_RESULTS]: state => {
    return state.partsRequestSearchResults;
  },
  [_getters.HAS_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    return (
      JSON.stringify(state.partsRequest, replacer) !== JSON.stringify(state.dbPartsRequest, replacer) ||
      JSON.stringify(state.partsRequest.parts, replacer) !== JSON.stringify(state.dbPartsRequest.parts, replacer)
    );
  }
};
