<template>
  <div v-if="readonly" class="form-control" disabled readonly tabindex="0">
    <employee-display :employee="value" :show-email="showEmail" />
  </div>
  <v-select
    v-else
    v-bind="{ placeholder, multiple, taggable, disabled, clearable, reduce, value, options, createOption }"
    label="emailAddress"
    :filter-by="filterEmployee"
    select-on-tab
    :clear-search-on-blur="() => true"
    style="width: 1%"
    @input="$emit('input', $event)"
  >
    <template #selected-option="employee">
      <employee-display :employee="employee" :show-email="showEmail" />
    </template>
    <template #option="employee">
      <employee-display :employee="employee" :show-email="showEmail" />
    </template>

    <template #no-options="{ searching }">
      {{ !searching && noOptionsText != null ? noOptionsText : 'No results found.' }}
    </template>

    <template v-if="taggable" #list-footer>
      <li class="text-center">Click enter to add.</li>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select';
import EmployeeDisplayComponent from '@/shared/components/ui/EmployeeDisplayComponent';

import { mapGetters } from 'vuex';
import { LookupGetters } from '@/shared/store/lookup/types';

export default {
  name: 'EmployeeSelect',
  components: {
    vSelect,
    'employee-display': EmployeeDisplayComponent
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Object, String, Number],
      default: () => {
        return null;
      }
    },
    options: {
      type: Array,
      default() {
        return this.employeeList;
      }
    },
    noOptionsText: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    showEmail: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    multiple: Boolean,
    taggable: Boolean,
    clearable: Boolean,
    reduce: {
      type: Function,
      default: option => option
    },
    createOption: {
      type: Function,
      default: email => ({ emailAddress: email })
    }
  },
  data() {
    return {
      loading: false,
      employees: []
    };
  },
  computed: {
    ...mapGetters({
      employeeList: LookupGetters.GET_EMPLOYEE_LIST
    })
  },
  methods: {
    filterEmployee(option, label, search) {
      return (
        (option.employeeId && option.employeeId.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
        (option.emailAddress && option.emailAddress.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
        (option.name && option.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
      );
    }
  }
};
</script>
