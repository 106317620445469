import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, UnitStatusHistoryGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, UnitStatusHistoryGetters);

export default {
  [_getters.GET_UNIT_STATUS_HISTORY]: state => {
    return state.statusHistory;
  }
};
