function requestBadgeStyle() {
  return `border: 2px solid #fa8b0d; color: #fa8b0d; font-weight: 500;`;
}

function badgeStyle(appointment) {
  let { badgeColor } = appointment?.appointmentStatus?.config ?? { badgeColor: null };
  badgeColor = badgeColor ?? '#6c757d';
  return `border: 2px solid ${badgeColor}; color: ${badgeColor}; font-weight: 500; text-transform: uppercase;`;
}

export default { requestBadgeStyle, badgeStyle };
