<template>
  <b-modal :id="`serviceOrderCloneModal_${_uid}`" ref="serviceOrderCloneModal" title="Service Order Clone">
    <p>This clone is for Branch {{ branch }}</p>
    <template #modal-footer="{}">
      <b-button size="sm" variant="primary" :to="pathForward">CONTINUE</b-button>
      <b-button size="sm" @click="hide">CANCEL</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ServiceOrderCloneModal',
  props: {
    branch: {
      type: String,
      required: true
    },
    serviceOrderId: {
      type: String,
      required: true
    }
  },
  computed: {
    pathForward() {
      return `/units/${this.$route.params.unitId}/service-orders/create?mode=serviceOrder&clone=${this.serviceOrderId}`;
    }
  },
  methods: {
    show() {
      this.$bvModal.show(`serviceOrderCloneModal_${this._uid}`);
    },
    hide() {
      this.$bvModal.hide(`serviceOrderCloneModal_${this._uid}`);
    }
  }
};
</script>
