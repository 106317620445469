<template>
  <div>
    <h5>Unit Ready</h5>
    <h6 class="text-uppercase">{{ estimorderDisplayText }} ID: {{ serviceOrderId }}</h6>
    <b-row no-gutters>
      <b-col>
        <span class="required-legend float-right p-0">* Required</span>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col>
        <b-form-group label="Send To:" label-class="col-head" label-for="send-to">
          <b-input-group>
            <employee-select
              id="send-to"
              v-model="recipients"
              :options="recipientsList"
              :class="$v.recipients.$error ? 'is-invalid' : ''"
              multiple
              taggable
            />
            <span class="required-asterisk">*</span>
          </b-input-group>
          <div v-if="$v.recipients.$error && !$v.recipients.email" class="error">Enter a valid Email.</div>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col>
        <b-form-group label="Comments:" label-class="col-head" label-for="comments" class="required-placeholder">
          <b-textarea id="comments" v-model="comments" class="overflow-auto" size="sm" rows="3" max-rows="4" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-button variant="primary" :disabled="sending" @click="send">
      <div v-show="sending">
        <b-spinner small></b-spinner>
        Sending...
      </div>
      <div v-show="!sending">Send</div>
    </b-button>
  </div>
</template>

<script>
// Components
import EmployeeSelectComponent from '@/shared/components/ui/EmployeeSelectComponent';
import timeInput from '@/shared/directives/TimeInput';
// Vuex
import { CustomerGetters } from '@/shared/store/customer/types';
import { ServiceOrderGetters, ServiceOrderActions, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
// Helpers
import { required, email } from 'vuelidate/lib/validators';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';

export default {
  name: 'ServiceOrderUnitReady',
  components: {
    'employee-select': EmployeeSelectComponent
  },
  directives: {
    timeInput
  },
  data() {
    return {
      sending: false
    };
  },
  validations: {
    recipients: {
      required,
      $each: {
        emailAddress: {
          required,
          email
        }
      }
    }
  },
  computed: {
    ...mapState('unit', ['unit']),
    ...mapGetters({
      estimorderDisplayText: ServiceOrderGetters.GET_ESTIMORDER_DISPLAY_TEXT,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      readonly: ServiceOrderGetters.GET_READONLY,
      unitReady: ServiceOrderGetters.GET_UNIT_READY,
      employeeList: LookupGetters.GET_EMPLOYEE_LIST,
      vcpUserList: CustomerGetters.GET_VCP_USER_LIST
    }),
    serviceOrderId() {
      return this.serviceOrder.serviceOrderId;
    },
    recipientsList() {
      return [...this.vcpUserList, ...this.employeeList];
    },
    recipients: {
      get() {
        return this.unitReady.recipients;
      },
      set(value) {
        this.setData('recipients', value);
      }
    },
    comments: {
      get() {
        return this.unitReady.comments;
      },
      set(value) {
        this.setData('comments', value);
      }
    }
  },
  methods: {
    ...mapActions([ServiceOrderActions.SEND_UNIT_READY]),
    ...mapMutations([ServiceOrderMutations.SET_PROP]),
    async send() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        ErrorService.createErrorToast(this, 'Error sending Unit Ready. See indicated fields below.');
        return;
      }
      try {
        this.sending = true;
        await this[ServiceOrderActions.SEND_UNIT_READY]({
          recipients: this.recipients,
          comments: this.comments
        });
        SuccessService.createSuccessToast(this.$root, `Unit Ready email successfully sent.`);

        this.$v.$reset();
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to send Unit Ready email.');
      } finally {
        this.sending = false;
      }
    },
    setData(key, newValue) {
      let value = { ...this.unitReady };
      value[key] = newValue;
      this[ServiceOrderMutations.SET_PROP]({ key: 'unitReady', value });
    }
  }
};
</script>
