// TODO: Utilize Axios and log front-end errors.
// TODO: Create error store.
function saveError(that, errorMessages) {
  createErrorToast(that, `Error encountered saving. ${errorMessages.join(' ')}. `);
}

function unknownSaveError(that) {
  createErrorToast(that, `Unknown error saving scheduled operations. `);
}

function searchError(that, errorMessages) {
  createErrorToast(that, `Error encountered during search. ${errorMessages.join(' ')}. `);
}

function unknownSearchError(that) {
  createErrorToast(that, `Unknown error encountered during search.`);
}

function unknownCustomerSearchError(that) {
  createErrorToast(that, `Unknown error encountered during customer search.`);
}

function unknownExportError(that) {
  createErrorToast(that, `Unknown error encountered during export.`);
}

function unknownImportError(that) {
  createErrorToast(that, `Unknown error encountered during import.`);
}

function unknownPrintError(that) {
  createErrorToast(that, `Unknown error encountered during print.`);
}

function createErrorToast(that, errorMessage) {
  that.$bvToast.toast(errorMessage, {
    title: 'Error',
    autoHideDelay: 5000,
    toaster: 'b-toaster-top-center',
    variant: 'danger',
    appendToast: true,
    solid: true
  });
}

function createWarningToast(that, errorMessage) {
  that.$bvToast.toast(errorMessage, {
    title: 'Warning',
    autoHideDelay: 5000,
    toaster: 'b-toaster-top-center',
    variant: 'warning',
    appendToast: true,
    solid: true
  });
}

export default {
  saveError,
  searchError,
  unknownSaveError,
  unknownSearchError,
  unknownCustomerSearchError,
  unknownExportError,
  unknownImportError,
  unknownPrintError,
  createErrorToast,
  createWarningToast
};
export class Warning extends Error {
  constructor(message) {
    super(message);
    this.message = message;
    this.name = 'Warning';
  }
}
