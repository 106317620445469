export const namespace = 'user/';

export const UserGetters = {
  GET_NAVIGATION: namespace + 'getNavigation',
  GET_USER_PROFILE: namespace + 'getUserProfile',
  AUTHORIZE_ROLE: namespace + 'authorizeRole',
  GET_LOADING: namespace + 'getLoading',
  IS_AUTHENTICATED: namespace + 'isAuthenticated'
};

export const UserActions = {
  CLEAR_NAVIGATION: namespace + 'clearNavigation',
  FETCH_USER_PROFILE: namespace + 'fetchUserProfile',
  AFTER_LOGIN: namespace + 'afteLogin',
  LOGIN: namespace + 'login',
  LOGOUT: namespace + 'logout',
  SET_AUTHENTICATION_REDIRECT: namespace + 'setAuthenticationRedirect'
};

export const UserMutations = {
  SET_AUTHENTICATION_REDIRECT: namespace + 'setAuthenticationRedirect',
  SET_LOGGED_IN: namespace + 'setLoggedIn',
  SET_LOGGED_OUT: namespace + 'setLoggedOut',
  SET_PROFILE: namespace + 'setProfile',
  SET_LOADING: namespace + 'setLoading',
  SET_MSAL_ACCOUNT: namespace + 'setMsalAccount'
};
