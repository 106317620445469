import axios from 'axios';

const SERVICE_ORDER_PATH = '/api/serviceOrder';
const CUSTOMER_SPECIFIC_REPAIR_REASONS_PATH = `${SERVICE_ORDER_PATH}/reasonsForRepair`;
const EXPORT = 'export';
const RELATED = 'related';
const APPOINTMENT = 'appointment';

class ServiceOrderService {
  async searchOrders(parameters) {
    const url = `${SERVICE_ORDER_PATH}`;
    const orders = await axios.get(url, {
      params: parameters
    });
    return orders;
  }

  async getCustomerSpecificRepairReasons(billToCustomerId) {
    const url = `${CUSTOMER_SPECIFIC_REPAIR_REASONS_PATH}/${billToCustomerId}`;
    const response = axios.get(url);
    return response;
  }

  async exportServiceOrders(parameters) {
    const url = `${SERVICE_ORDER_PATH}/${EXPORT}`;
    const response = axios.post(url, parameters, {
      responseType: 'blob'
    });
    return response;
  }

  async getRelatedServiceOrders(serviceOrderId) {
    const url = `${SERVICE_ORDER_PATH}/${RELATED}/${serviceOrderId}`;
    const response = await axios.get(url);
    return response.data;
  }

  async getAppointmentUnitServiceOrders(appointmentId) {
    const url = `${SERVICE_ORDER_PATH}/${APPOINTMENT}/${appointmentId}`;
    const response = await axios.get(url);
    return response;
  }
}

export default new ServiceOrderService();
