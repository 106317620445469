<template>
  <div class="mt-3">
    <b-tabs v-show="multipleTabs" v-model="activeTab">
      <b-tab title="VCP Fleet Report" class="">
        <div ref="defaultReportContainerTab1" style="height: calc(100vh - 63px); width: 100%"></div>
      </b-tab>
      <b-tab title="Customer Specific Report" class="">
        <div ref="ABTier2ReportContainerTab2" style="height: calc(100vh - 63px); width: 100%">Tab 2</div>
      </b-tab>
    </b-tabs>
    <div v-show="!multipleTabs" ref="defaultReportContainerNoTab" style="height: calc(100vh - 63px); width: 100%"></div>
  </div>
</template>

<script>
import * as PowerBIClient from 'powerbi-client';
import PowerBIService from '@/shared/services/PowerBIService';
import { mapGetters } from 'vuex';
import { UserGetters } from '@/shared/store/user/types';

export default {
  name: 'PowerBIReportsPage',
  data() {
    return {
      activeTab: 0,
      multipleTabs: false
    };
  },
  computed: {
    ...mapGetters({ isAuthenticated: UserGetters.IS_AUTHENTICATED })
  },
  mounted: async function () {
    if (!this.isAuthenticated) return;
    // TODO: Consider caching PowerBI token for reuse in Vuex store

    let tokenData = await PowerBIService.getToken();
    let token = tokenData.data[0];

    this.multipleTabs = tokenData.data.length > 1;

    var config = {
      type: 'report',
      id: token.embedReportId,
      embedUrl: token.embedUrl,
      accessToken: token.accessToken,
      permissions: PowerBIClient.models.All,
      tokenType: PowerBIClient.models.TokenType.Embed,
      viewMode: PowerBIClient.models.ViewMode.View,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: true
      }
    };

    var defaultReportContainerRef = this.multipleTabs ? 'defaultReportContainerTab1' : 'defaultReportContainerNoTab';
    var reportContainer = this.$refs[defaultReportContainerRef];
    window.powerbi.embed(reportContainer, config);

    if (this.multipleTabs) {
      let token2 = tokenData.data[1];

      var config2 = {
        type: 'report',
        id: token2.embedReportId,
        embedUrl: token2.embedUrl,
        accessToken: token2.accessToken,
        permissions: PowerBIClient.models.All,
        tokenType: PowerBIClient.models.TokenType.Embed,
        viewMode: PowerBIClient.models.ViewMode.View,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: true
        }
      };

      var reportContainer2 = this.$refs['ABTier2ReportContainerTab2'];
      window.powerbi.embed(reportContainer2, config2);
    }
  }
};
</script>
