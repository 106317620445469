var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"page-card"},[_c('div',{staticClass:"page-header"},[_c('h2',[_vm._v("Breakdowns")])]),_c('responsive-search',{ref:"search",attrs:{"fields":_vm.searchFields,"is-busy":_vm.searchIsBusy,"storage-key":"breakdownSearch"},on:{"searchButtonClick":_vm.searchButtonClick,"resetButtonClick":_vm.resetButtonClick}}),(!_vm.$smallScreen)?_c('b-row',[_c('b-form-group',[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"filterInput","variant":"position-relative","type":"search","placeholder":"Type to Filter","debounce":500},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.exportIsBusy),expression:"exportIsBusy"}]},[_c('b-button',{staticClass:"ml-2",attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" EXPORTING... ")],1)],1),_c('div',{staticClass:"ml-4 pr-2"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.exportIsBusy),expression:"!exportIsBusy"}],attrs:{"variant":"secondary"},on:{"click":_vm.exportClick}},[_vm._v("Export")])],1)])],1)],1),_c('b-pagination',{staticClass:"ml-auto w-auto",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.pageSize,"aria-controls":"my-table","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e(),(_vm.$smallScreen)?_c('mobile-table',{attrs:{"items":_vm.breakdowns,"fields":_vm.fields},scopedSlots:_vm._u([{key:"breakdownId",fn:function(ref){
var item = ref.item;
return [_c('label',{class:"col-head"},[_vm._v("Breakdown ID:")]),_c('safe-hyperlink',{attrs:{"to":'units/' + item.unitId + '/breakdowns/' + item.breakdownId}},[_vm._v(" "+_vm._s(item.breakdownId)+" ")])]}},{key:"branchId",fn:function(ref){
var item = ref.item;
return [_c('label',{class:"col-head"},[_vm._v("Branch:")]),_c('div',[_vm._v(_vm._s(item.branchId)+" - "+_vm._s(item.branchName))])]}},{key:"vin",fn:function(ref){
var item = ref.item;
return [_c('label',{class:"col-head"},[_vm._v("VIN/Serial:")]),_c('safe-hyperlink',{attrs:{"to":'/units/' + item.unitId}},[_vm._v(" "+_vm._s(item.vin)+" ")])]}},{key:"dateHelpRequested",fn:function(ref){
var item = ref.item;
return [_c('label',{class:"col-head"},[_vm._v("Help Requested:")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(item.dateHelpRequested))+" ")])]}},{key:"serviceOrderId",fn:function(ref){
var item = ref.item;
return [_c('label',{class:"col-head"},[_vm._v("Service Order ID:")]),(_vm.$isCustomer && !item.posted)?_c('div',[_vm._v(_vm._s(item.serviceOrderId))]):_c('safe-hyperlink',{attrs:{"to":'units/' + item.unitId + '/service-orders/' + item.serviceOrderId}},[_vm._v(" "+_vm._s(item.serviceOrderId)+" ")])]}}],null,true)}):_c('b-table',{staticClass:"border",attrs:{"no-border-collapse":"","striped":"","sticky-header":"63vh","items":_vm.breakdowns,"fields":_vm.fields,"per-page":_vm.pageSize,"current-page":_vm.currentPage,"filter":_vm.filter,"sort-by":_vm.sortBy,"show-empty":"","empty-text":"No results found.","empty-filtered-text":"No results found."},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(breakdownId)",fn:function(ref){
var item = ref.item;
return [_c('safe-hyperlink',{attrs:{"to":'units/' + item.unitId + '/breakdowns/' + item.breakdownId}},[_vm._v(" "+_vm._s(item.breakdownId)+" ")])]}},{key:"cell(vin)",fn:function(ref){
var item = ref.item;
return [_c('safe-hyperlink',{attrs:{"to":'units/' + item.unitId}},[_vm._v(" "+_vm._s(item.vin)+" ")])]}},{key:"cell(branchId)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatBranch(item))+" ")])]}},{key:"cell(dateHelpRequested)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(item.dateHelpRequested))+" ")])]}},{key:"cell(serviceOrderId)",fn:function(ref){
var item = ref.item;
return [(_vm.$isCustomer && !item.posted)?_c('div',[_vm._v(_vm._s(item.serviceOrderId))]):_c('safe-hyperlink',{attrs:{"to":'units/' + item.unitId + '/service-orders/' + item.serviceOrderId}},[_vm._v(" "+_vm._s(item.serviceOrderId)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }