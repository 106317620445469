import axios from 'axios';

const JOB_PATH = '/api/job';

class JobService {
  async getLaborCodes(jobIds) {
    const url = `${JOB_PATH}/labor-codes`;
    const laborCodes = await axios.get(url, {
      params: { jobIds }
    });
    return laborCodes;
  }

  async getVMRSCodeByJob(jobId) {
    const url = `${JOB_PATH}/${jobId}/vmrs-code`;
    const vmrsCodeByJob = await axios.get(url);
    return vmrsCodeByJob;
  }

  async getVMRSCodes() {
    const url = `${JOB_PATH}/vmrs-codes`;
    const vmrsCodes = await axios.get(url);
    return vmrsCodes;
  }

  async searchLaborCodes(search) {
    const url = `${JOB_PATH}/labor-codes-search${search ? '?searchCriteria=' + encodeURIComponent(search) : ''}`;
    const searchLaborCodes = await axios.get(url);
    return searchLaborCodes;
  }
}

export default new JobService();
