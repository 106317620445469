<template>
  <div>
    <date-time-input
      v-if="type == 'date' || type == 'datetime'"
      :readonly="!allowAttributeEdit"
      v-bind="{ id, value, labelColsLg, labelColsSm }"
      :label="label + ': '"
      :hide-time="type == 'date'"
      @input="update"
    />
    <b-form-group v-else v-bind="{ labelColsLg, labelColsSm }" :label="label + ': '" :label-for="id">
      <div v-if="allowAttributeEdit">
        <v-select
          v-if="options && options.length > 0"
          v-bind="{ id, value, options, taggable }"
          :filterable="!taggable"
          select-on-tab
          @input="update"
        />
        <b-input v-else v-bind="{ id, value }" @input="update" />
      </div>
      <div v-else :id="id" class="col-form-label">
        {{ value || NA }}
      </div>
    </b-form-group>
  </div>
</template>

<script>
// components
import DateTimeInput from '@/shared/components/ui/DateTimeInput';
import vSelect from 'vue-select';
// vuex
import { mapMutations, mapGetters } from 'vuex';
import { UnitMutations } from '@/shared/store/unit/types';
import { UserGetters } from '@/shared/store/user/types';
// helpers
import FormGroupPropsMixin from '@/shared/mixins/FormGroupPropsMixin';
import UnitMixin from '@/shared/mixins/UnitMixin';

export default {
  name: 'UnitAttributeFormInput',
  components: {
    'date-time-input': DateTimeInput,
    vSelect
  },
  mixins: [UnitMixin, FormGroupPropsMixin],
  props: {
    attributeId: {
      type: Number,
      required: true
    },
    value: {
      type: [Date, String, Array, Boolean],
      default: () => null
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => null
    },
    allowCustomerEdits: Boolean(),
    allowRolesToEdit: Boolean()
  },
  data() {
    return {
      NA: 'N/A'
    };
  },
  computed: {
    ...mapGetters([UserGetters.AUTHORIZE_ROLE]),
    id() {
      // removes whitespace to make an html id for each input
      return this.label.replace(/[\W]/g, '');
    },
    allowAttributeEdit() {
      if (!this.allow({ customerToEdit: this.allowCustomerEdits, techsToEdit: true })) return false;
      if (this.allowRolesToEdit == null || this.allowRolesToEdit.length == 0) return true;
      if (this.$isCustomer) return true;
      else return this.authorizeRole(this.allowRolesToEdit);
    },
    isRegionalCsm() {
      return this[UserGetters.AUTHORIZE_ROLE](['RegionalCSM']);
    },
    taggable() {
      return this.isRegionalCsm;
    }
  },
  methods: {
    ...mapMutations([UnitMutations.SET_ATTRIBUTE_VALUE]),
    update(value) {
      this[UnitMutations.SET_ATTRIBUTE_VALUE]({ key: this.attributeId, value });
    }
  }
};
</script>
