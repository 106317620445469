<template>
  <div>
    <label
      v-if="field.label && !field.radioOptions"
      :class="`${labelClass}`"
      :label-for="`search-field-value-${field.key}`"
    >
      {{ field.label }}:
    </label>

    <b-button-group v-if="field.radioOptions" class="so-status order-neg1 w-100">
      <b-form-radio-group
        :id="`search-field-value-${field.key}`"
        v-model="localValue"
        class="w-100"
        buttons
        button-variant="secondary"
        @change="changeQuickFilter()"
      >
        <b-form-radio
          v-for="radio in field.radioOptions"
          :id="`search-field-${field.key}-radio-${radio.value}`"
          :key="radio.value"
          v-b-tooltip.nofade.dh0
          :value="radio.value"
          :title="radio.tooltip"
        >
          {{ radio.text }}
        </b-form-radio>
      </b-form-radio-group>
    </b-button-group>
    <v-select
      v-else-if="field.options"
      :id="`search-field-value-${field.key}`"
      v-model.trim="localValue"
      class="min-w195px search-field-select"
      :placeholder="field.placeholder"
      :options="field.options"
      :reduce="reduce"
      :multiple="multiple"
      :label="selectLabel"
      :clearable="true"
      :close-on-select="false"
      select-on-tab
    />
    <b-form-input
      v-else
      :id="`search-field-value-${field.key}`"
      v-model.trim="localValue"
      :class="inputClass"
      :placeholder="field.placeholder"
      autocomplete="off"
    />
  </div>
</template>

<script>
// components
import vSelect from 'vue-select';

export default {
  name: 'SearchFieldComponent',
  components: {
    'v-select': vSelect
  },
  props: {
    value: {
      type: [String, Array, Object],
      default: () => null
    },
    field: {
      type: Object,
      required: true
    },
    labelClass: {
      type: String,
      default: 'col-head'
    },
    inputClass: {
      type: String,
      default: null
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    selectLabel() {
      return this.field.selectLabel ?? 'description';
    },
    reduce() {
      return this.field.reduce ?? (x => x.description);
    },
    multiple() {
      return this.field.multiple ?? true;
    }
  },
  methods: {
    changeQuickFilter() {
      this.$nextTick(function () {
        this.$emit('quick-search');
      });
    }
  }
};
</script>
