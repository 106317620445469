import { UnitGetters } from '@/shared/store/unit/types';
import { UserGetters } from '@/shared/store/user/types';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      unit: UnitGetters.GET_UNIT,
      authorizeRole: UserGetters.AUTHORIZE_ROLE
    }),
    isCsrOrCsm() {
      return this.authorizeRole(['CSR', 'CSM', 'RegionalCSM']);
    },
    allowCustomersAndTechsToEdit() {
      return this.allow({ techsToEdit: true, customerToEdit: true });
    },
    allowCustomerToEdit() {
      return this.allow({ techsToEdit: false, customerToEdit: true });
    },
    allowTechsToEdit() {
      return this.allow({ techsToEdit: true, customerToEdit: false });
    }
  },
  methods: {
    allow({ techsToEdit, customerToEdit }) {
      return this.isCsrOrCsm || (!this.$isCustomer && techsToEdit) || (this.$isCustomer && customerToEdit);
    }
  }
};
