<template>
  <div>
    <b-card class="page-card">
      <div class="page-header">
        <h2>Breakdown</h2>
      </div>

      <div v-if="notFound">
        <p>Breakdown not found.</p>
      </div>
      <div v-else-if="loading" class="text-center py-4">
        <b-spinner></b-spinner>
      </div>
      <div v-else class="pb-3">
        <unit-summary :vin-as-link="stage >= STAGES.EDIT">
          <template v-if="stage >= STAGES.EDIT" #start>
            <div class="mr-4">
              <label class="col-head">Breakdown ID:</label>
              <h3>{{ breakdownId }}</h3>
            </div>
          </template>
        </unit-summary>

        <b-row v-if="!readonly" class="no-gutters text-right mb-1">
          <b-col sm="12" :lg="stage === STAGES.SELECT_BRANCH ? 5 : 11">
            <span class="required-legend">* Required</span>
          </b-col>
        </b-row>

        <b-row class="no-gutters">
          <b-col sm="12" lg="5">
            <!-- Branch Selection -->
            <b-form-group label-cols-lg="5" label-cols-sm="12" :label="branchLabel" label-for="branch">
              <div v-if="readonly" id="branch-read-only" class="col-form-label">
                {{ branchDisplay | nullValueToNA }}
              </div>
              <div v-else-if="$isCustomer || ($v.branchId.$model && stage > STAGES.EDIT_HEADER)">
                <div class="d-flex">
                  <b-form-input id="branch" :value="branchDisplay" disabled />
                  <span class="required-asterisk">*</span>
                </div>
              </div>
              <div v-else>
                <div class="d-flex">
                  <b-form-select
                    id="branch"
                    v-model="$v.branchId.$model"
                    :state="!$v.branchId.$error ? null : false"
                    :options="myBranchesList"
                    value-field="id"
                    text-field="description"
                  ></b-form-select>
                  <span class="required-asterisk">*</span>
                </div>
              </div>
            </b-form-group>

            <div v-show="stage >= STAGES.EDIT_HEADER">
              <!-- Driver Name -->
              <b-form-group label-cols-lg="5" label-cols-sm="12" label="Driver Name:" label-for="drive-name">
                <div v-if="readonly" id="driver-name-read-only" class="col-form-label">
                  {{ driverName | nullValueToNA }}
                </div>
                <div v-else class="d-flex">
                  <b-form-input id="drive-name" v-model="driverName" />
                  <span class="required-placeholder" />
                </div>
              </b-form-group>

              <!-- Driver Phone -->
              <b-form-group label-cols-lg="5" label-cols-sm="12" label="Driver Phone:" label-for="driver-phone">
                <div v-if="readonly" id="driver-phone-read-only" class="col-form-label">
                  {{ driverPhoneNumber | nullValueToNA }}
                </div>
                <div v-else class="d-flex">
                  <phone-input
                    id="driver-phone"
                    v-model="driverPhoneNumber"
                    :state="!$v.driverPhoneNumber.$error ? null : false"
                  />
                  <span class="required-placeholder" />
                </div>
                <div
                  v-if="!readonly"
                  v-show="$v.driverPhoneNumber.$error && !$v.driverPhoneNumber.length"
                  class="error"
                >
                  Enter a valid Phone Number of 7 or 10 digits.
                </div>
              </b-form-group>

              <!-- Associate Initiating Call -->
              <b-form-group
                label-cols-lg="5"
                label-cols-sm="12"
                label="Associate Initiating Call:"
                label-for="initiated-by-name"
              >
                <div v-if="readonly" id="initiated-by-name-read-only" class="col-form-label">
                  {{ initiatedByName | nullValueToNA }}
                </div>
                <div v-else class="d-flex">
                  <b-form-input id="initiated-by-name" v-model="initiatedByName" :disabled="$isCustomer" />
                  <span class="required-placeholder" />
                </div>
              </b-form-group>

              <!-- Associate Email -->
              <b-form-group
                label-cols-lg="5"
                label-cols-sm="12"
                label="Associate Initiating - Email:"
                label-for="initiated-by-email"
              >
                <div v-if="readonly" id="initiated-by-read-only" class="col-form-label">
                  {{ initiatedByEmail | nullValueToNA }}
                </div>
                <div v-else class="d-flex">
                  <b-form-input id="initiated-by-email" v-model="initiatedByEmail" :disabled="$isCustomer" />
                  <span class="required-placeholder" />
                </div>
                <div v-if="!readonly" v-show="$v.initiatedByEmail.$error && !$v.initiatedByEmail.email" class="error">
                  Enter a valid Email Address.
                </div>
              </b-form-group>

              <!-- Breakdown Location -->
              <b-form-group label-cols-lg="5" label-cols-sm="12" label="Breakdown Location:" label-for="location">
                <div v-if="readonly" id="location-read-only" class="col-form-label">
                  {{ location | nullValueToNA }}
                </div>
                <div v-else class="d-flex">
                  <b-form-input id="location" v-model="location" />
                  <span class="required-placeholder" />
                </div>
              </b-form-group>

              <!-- Breakdown Reason -->
              <b-form-group label-cols-lg="5" label-cols-sm="12" label="Breakdown Reason:" label-for="breakdown-reason">
                <div v-if="readonly" id="breakdownReason" class="col-form-label">
                  {{ breakdownReasonName | nullValueToNA }}
                </div>
                <div v-else class="d-flex">
                  <v-select
                    id="breakdown-reason"
                    v-model="breakdownReasonId"
                    :state="!$v.breakdownReasonId.$error ? null : false"
                    :options="breakdownReasonsList"
                    :filter-by="filterReasons"
                    :clearable="false"
                    :reduce="reason => reason.breakdownReasonId"
                    label="name"
                    select-on-tab
                    :class="`${$v.breakdownReasonId.$error && !$v.breakdownReasonId.required ? 'is-invalid' : ''}`"
                  ></v-select>
                  <span class="required-asterisk">*</span>
                </div>
              </b-form-group>

              <!-- Notify -->
              <b-form-group label-cols-lg="5" label-cols-sm="12" label="Notify:" label-for="notify">
                <div v-if="readonly" id="notify-read-only" class="col-form-label">
                  <div v-for="recipient in recipientsList" :key="recipient.name">
                    {{ recipient.name }}
                  </div>
                </div>
                <b-input-group v-else>
                  <employee-select
                    id="notify"
                    v-model="recipients"
                    :options="recipientsList"
                    :class="$v.recipients.$error ? 'is-invalid' : ''"
                    multiple
                    taggable
                  />
                  <span v-if="$isCustomer" class="required-asterisk">*</span>
                  <span v-else class="required-placeholder" />
                </b-input-group>
                <div v-if="!readonly && $v.recipients.$each.$error && hasInvalidEmail" class="error">
                  Enter a valid Email.
                </div>
                <div v-if="!readonly && $v.recipients.$error && !$v.recipients.hasVAMSUser" class="error">
                  At least one Vehicare representative is required for Notification.
                </div>
              </b-form-group>

              <!-- Complaint -->
              <b-form-group label-cols-lg="5" label-cols-sm="12" label="Complaint:" label-for="complaint">
                <div v-if="readonly" id="complaint-read-only" class="col-form-label">
                  {{ complaint | nullValueToNA }}
                </div>
                <div v-else class="d-flex">
                  <b-textarea
                    id="complaint"
                    v-model="complaint"
                    :state="!$v.complaint.$error ? null : false"
                    class="overflow-auto"
                    size="sm"
                    rows="2"
                    max-rows="4"
                  />
                  <span class="required-asterisk">*</span>
                </div>
              </b-form-group>

              <div v-if="stage >= STAGES.EDIT">
                <!-- Unit Towed -->
                <b-form-group label-cols-lg="5" label-cols-sm="12" label="Unit Towed:" label-for="unit-towed">
                  <div v-if="readonly" id="unit-towed-read-only" class="col-form-label">
                    {{ unitTowed | YesNo }}
                  </div>
                  <div v-else class="d-flex col-lg-6 col-sm-12 p-0">
                    <v-select
                      id="unit-towed"
                      v-model="unitTowed"
                      :options="yesOrNo"
                      select-on-tab
                      :reduce="x => x.code"
                    />
                    <span class="required-placeholder" />
                  </div>
                </b-form-group>

                <!-- One Way Mileage to Repair -->
                <b-form-group
                  label-cols-lg="5"
                  label-cols-sm="12"
                  label="One Way Mileage to Repair:"
                  label-for="one-way-mileage"
                >
                  <div v-if="readonly" id="one-way-mileage-read-only" class="col-form-label">
                    {{ oneWayMileageName | nullValueToNA }}
                  </div>
                  <div v-else class="d-flex col-lg-6 col-sm-12 p-0">
                    <v-select
                      id="one-way-mileage"
                      v-model="oneWayMileageRangeId"
                      :options="mileageRanges"
                      :reduce="range => range.mileageRangeId"
                      label="mileageRangeName"
                      select-on-tab
                    ></v-select>
                    <span class="required-placeholder" />
                  </div>
                </b-form-group>

                <!-- Preventable Breakdown -->
                <b-form-group
                  label-cols-lg="5"
                  label-cols-sm="12"
                  label="Preventable Breakdown:"
                  label-for="preventable-breakdown"
                >
                  <div v-if="readonly" id="preventable-breakdown-read-only" class="col-form-label">
                    {{ preventableBreakdown | YesNo }}
                  </div>
                  <div v-else class="d-flex col-lg-6 col-sm-12 p-0">
                    <v-select
                      id="preventable-breakdown"
                      v-model="preventableBreakdown"
                      :options="yesOrNo"
                      select-on-tab
                      :reduce="x => x.code"
                    />
                    <span class="required-placeholder" />
                  </div>
                </b-form-group>

                <!-- Stops Missed on Route -->
                <b-form-group
                  label-cols-lg="5"
                  label-cols-sm="12"
                  label="Stops Missed on Route:"
                  label-for="stops-missed"
                >
                  <div v-if="readonly" id="stops-missed-read-only" class="col-form-label">
                    {{ stopsMissed }}
                  </div>
                  <div v-else class="d-flex col-lg-6 col-sm-12 p-0">
                    <v-select id="stops-missed" v-model="stopsMissed" :options="numberOfStops" select-on-tab></v-select>
                    <span class="required-placeholder" />
                  </div>
                </b-form-group>

                <!-- Continued on Route -->
                <b-form-group
                  label-cols-lg="5"
                  label-cols-sm="12"
                  label="Continued on Route:"
                  label-for="continue-route"
                >
                  <div v-if="readonly" id="continue-route-read-only" class="col-form-label">
                    {{ continuedOnRoute | YesNo }}
                  </div>
                  <div v-else class="d-flex col-lg-6 col-sm-12 p-0">
                    <v-select
                      id="continue-route"
                      v-model="continuedOnRoute"
                      :options="yesOrNo"
                      select-on-tab
                      :reduce="x => x.code"
                    />
                    <span class="required-placeholder" />
                  </div>
                </b-form-group>
              </div>
            </div>
          </b-col>

          <b-col v-if="stage >= STAGES.EDIT_HEADER" offset-lg="1" sm="12" lg="5">
            <!-- Created Date -->
            <date-time-input
              id="dateCreate"
              :value="dateCreate"
              label="Created Date:"
              :readonly="readonly"
              label-cols-lg="5"
              label-cols-sm="12"
              date-col-class="col-sm-6"
              disabled
              required
              :show-today-btn="false"
            />

            <!-- Help Requested -->
            <date-time-input
              id="dateHelpRequested"
              v-model="dateHelpRequested"
              label="Help Requested:"
              :readonly="readonly"
              label-cols-lg="5"
              label-cols-sm="12"
              date-col-class="col-sm-6"
              required
              :show-today-btn="false"
            />

            <div v-if="stage >= STAGES.EDIT">
              <!-- Dispatched -->
              <date-time-input
                id="dateDispatched"
                v-model="dateDispatched"
                label="Dispatched:"
                :readonly="readonly"
                label-cols-lg="5"
                label-cols-sm="12"
                date-col-class="col-sm-6"
                show-today-btn
                :required="false"
              />

              <!-- Repair Completed -->
              <b-form-group
                v-if="readonly"
                label-cols-lg="5"
                label-cols-sm="12"
                label="Repair Completed:"
                label-for="date-repair-completed"
              >
                <div id="dateRepairCompletedReadOnly" class="col-form-label">
                  {{ dateRepairCompleted | dateTime }}
                </div>
              </b-form-group>
              <date-time-input
                v-else
                id="dateRepairCompleted"
                v-model="dateRepairCompleted"
                label="Repair Completed:"
                label-cols-lg="5"
                label-cols-sm="12"
                date-col-class="col-sm-6"
                show-today-btn
                :required="false"
              />

              <!-- Unit Released -->
              <b-form-group
                v-if="readonly"
                label-cols-lg="5"
                label-cols-sm="12"
                label="Unit Released:"
                label-for="date-unit-released"
              >
                <div id="dateUnitReleasedReadOnly" class="col-form-label">
                  {{ dateUnitReleased | dateTime }}
                </div>
              </b-form-group>
              <date-time-input
                v-else
                id="dateUnitReleased"
                v-model="dateUnitReleased"
                label="Unit Released:"
                label-cols-lg="5"
                label-cols-sm="12"
                date-col-class="col-sm-6"
                show-today-btn
                :required="false"
              />
            </div>
          </b-col>
        </b-row>
        <b-row v-if="stage >= STAGES.EDIT_HEADER" no-gutters>
          <b-col sm="12" lg="5">
            <!-- Create and Cancel -->
            <div v-if="!readonly">
              <b-form-group v-if="stage <= STAGES.CREATING" label-cols-lg="5" label-cols-sm="12" class="pt-2">
                <b-button
                  class="mr-3"
                  variant="primary"
                  :disabled="stage >= STAGES.CREATING"
                  @click="createBreakdown()"
                >
                  <div v-show="stage == STAGES.CREATING">
                    <b-spinner small></b-spinner>
                    Creating...
                  </div>
                  <div v-show="stage != STAGES.CREATING">Create Breakdown</div>
                </b-button>
                <b-button variant="secondary" @click="cancel">Cancel</b-button>
              </b-form-group>

              <!-- Save -->
              <b-form-group
                v-if="stage >= STAGES.EDIT && stage <= STAGES.SAVING"
                label-cols-lg="5"
                label-cols-sm="12"
                class="pt-2"
              >
                <b-button v-show="stage != STAGES.SAVING" class="mr-3" variant="primary" @click="saveBreakdown(false)">
                  <div v-show="stage != STAGES.SAVING">Save</div>
                </b-button>

                <b-button
                  v-show="stage != STAGES.SAVING"
                  class="mr-3"
                  variant="primary"
                  :disabled="!recipients.length"
                  @click="confirmNotification()"
                >
                  <div>Save & Notify</div>
                </b-button>

                <b-button v-show="stage == STAGES.SAVING" variant="primary" disabled>
                  <div>
                    <b-spinner small></b-spinner>
                    Saving...
                  </div>
                </b-button>
              </b-form-group>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="stage >= STAGES.EDIT" no-gutters>
          <b-col sm="12" lg="5">
            <b-form-group label-cols-lg="5" label-cols-sm="12">
              <div v-if="linkedServiceOrderId">
                <router-link :to="'/units/' + unitId + '/service-orders/' + linkedServiceOrderId">
                  View Service Order ID: {{ linkedServiceOrderId }}
                </router-link>
              </div>
              <div v-else-if="!readonly && !$isCustomer">
                <b-button
                  class="mr-3"
                  variant="secondary"
                  :to="
                    '/units/' +
                    unitId +
                    '/service-orders/create?mode=serviceOrder&breakdownId=' +
                    breakdownId +
                    '&repairReason=01'
                  "
                >
                  Create Service Order
                </b-button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <unsaved-changes-modal
      ref="UnsavedChangesModal"
      :has-changes="hasChanges"
      stay-on-page
      continue-btn-text="Continue with Breakdown"
    ></unsaved-changes-modal>
    <warning-modal
      id="NotificationRecipientWarning"
      ref="NotificationRecipientWarning"
      title="Warning"
      :warning-text="notificationRecipientMessage"
      continue-btn-text="Yes"
      cancel-btn-text="Cancel"
    ></warning-modal>
  </div>
</template>

<script>
// components
import UnitSummaryComponent from '@/shared/components/unit/UnitSummaryComponent';
import EmployeeSelectComponent from '@/shared/components/ui/EmployeeSelectComponent';
import DateTimeInput from '@/shared/components/ui/DateTimeInput';
import PhoneInput from '@/shared/components/ui/PhoneInput';
import vSelect from 'vue-select';
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';
import WarningModal from '@/shared/components/WarningModal';
// vuex
import { CustomerGetters, CustomerActions } from '@/shared/store/customer/types';
import { UserGetters } from '@/shared/store/user/types';
import { LookupGetters, LookupActions } from '@/shared/store/lookup/types';
import { UnitActions, UnitGetters } from '@/shared/store/unit/types';
import { BreakdownActions, BreakdownGetters, BreakdownMutations } from '@/shared/store/breakdown/types';
import { STAGES } from '@/shared/store/breakdown/state';
// helpers
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';

export default {
  name: 'BreakdownPage',
  components: {
    'unit-summary': UnitSummaryComponent,
    'date-time-input': DateTimeInput,
    'phone-input': PhoneInput,
    vSelect,
    'unsaved-changes-modal': UnsavedChangesModal,
    'warning-modal': WarningModal,
    'employee-select': EmployeeSelectComponent
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.from = from;
      next();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasChanges()) {
      this.$refs.UnsavedChangesModal.show(this, next);
    } else {
      next();
    }
  },
  data() {
    return {
      NA: 'N/A',
      STAGES: STAGES,
      from: null,
      notFound: false,
      loading: false,
      yesOrNo: [
        { label: 'Yes', code: true },
        { label: 'No', code: false }
      ]
    };
  },
  validations: {
    branchId: {
      required
    },
    breakdownReasonId: {
      required: requiredIf(function () {
        return this.stage >= STAGES.EDIT_HEADER;
      })
    },
    complaint: {
      required: requiredIf(function () {
        return this.stage >= STAGES.EDIT_HEADER;
      })
    },
    driverPhoneNumber: {
      length: value => !value || value.length == 7 || value.length == 10
    },
    initiatedByEmail: {
      email
    },
    recipients: {
      required: requiredIf(function () {
        return this.$isCustomer;
      }),
      hasVAMSUser: (value, vm) =>
        !vm.$isCustomer ||
        !value ||
        value.some(
          x =>
            vm.employeeList.findIndex(
              employee => employee.emailAddress.toLowerCase() == x.emailAddress.toLowerCase()
            ) !== -1
        ),
      $each: {
        emailAddress: {
          required,
          email
        }
      }
    }
  },
  computed: {
    ...mapState('unit', ['unit']),
    ...mapGetters([BreakdownGetters.HAS_CHANGES]),
    ...mapGetters({
      unit: UnitGetters.GET_UNIT,
      user: UserGetters.GET_USER_PROFILE,
      breakdown: BreakdownGetters.GET_BREAKDOWN,
      breakdownReasonsMap: LookupGetters.GET_BREAKDOWN_REASONS_MAP,
      breakdownReasonsList: LookupGetters.GET_BREAKDOWN_REASONS_LIST,
      mileageRanges: LookupGetters.GET_MILEAGE_RANGES_LIST,
      stage: BreakdownGetters.GET_STAGE,
      employeeList: LookupGetters.GET_EMPLOYEE_LIST,
      vcpUserList: CustomerGetters.GET_VCP_USER_LIST,
      myBranchesMap: LookupGetters.GET_MY_BRANCHES_MAP,
      myBranchesList: LookupGetters.GET_MY_BRANCHES_LIST,
      locationsMap: LookupGetters.GET_LOCATIONS_MAP
    }),
    notificationRecipientMessage: function () {
      return `Are you sure you want to save and notify recipients of this breakdown?`;
    },
    numberOfStops: function () {
      return Array.from(Array(11), (_, i) => i.toString());
    },
    unitId() {
      return this.$route.params.unitId;
    },
    breakdownId() {
      return this.$route.params.breakdownId;
    },
    breakdownReasonName() {
      var breakdownReason = this.breakdownReasonsList.find(
        reason => reason.breakdownReasonId === this.breakdownReasonId
      );

      if (breakdownReason) {
        return breakdownReason.name;
      } else {
        return '';
      }
    },
    oneWayMileageName() {
      var mileageRange = this.mileageRanges.find(range => range.mileageRangeId === this.oneWayMileageRangeId);

      if (mileageRange) {
        return mileageRange.mileageRangeName;
      } else {
        return '';
      }
    },
    readonly() {
      return this.breakdown.readonly;
    },
    recipientsList() {
      let filter = 'G-';
      const filteredEmailList = this.employeeList.filter(x => x.employeeId.indexOf(filter) != 0);
      return [...filteredEmailList, ...this.vcpUserList];
    },
    linkedServiceOrderId() {
      if (!this.breakdown.serviceOrder) return null;
      if (this.$isCustomer && !this.breakdown.serviceOrder.posted) return null;
      return this.breakdown.serviceOrder.serviceOrderId;
    },
    hasInvalidEmail() {
      return Object.values(this.$v.recipients.$each.$iter).some(v => !v.emailAddress.email);
    },
    // Form fields
    branchId: {
      get() {
        return this.breakdown.branchId;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'branchId', value });
      }
    },
    branchDisplay() {
      let display = `${this.breakdown.branchId} - ${this.breakdown.branchName}`;
      if (this.breakdown.branchName == null) display = this.myBranchesMap[this.branchId].description;
      return display || this.NA;
    },
    branchLabel() {
      let label = 'Branch:';
      if (!this.readonly) {
        label = 'Select ' + label;
      }
      return label;
    },
    driverName: {
      get() {
        return this.breakdown.driverName;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'driverName', value });
      }
    },
    driverPhoneNumber: {
      get() {
        return this.breakdown.driverPhoneNumber;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'driverPhoneNumber', value });
      }
    },
    initiatedByName: {
      get() {
        return this.breakdown.initiatedByName;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'initiatedByName', value });
      }
    },
    initiatedByEmail: {
      get() {
        return this.breakdown.initiatedByEmail;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'initiatedByEmail', value });
      }
    },
    location: {
      get() {
        return this.breakdown.location;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'location', value });
      }
    },
    breakdownReasonId: {
      get() {
        return this.breakdown.breakdownReasonId;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'breakdownReasonId', value });
      }
    },
    reason() {
      return this.breakdownReasonsMap[this.breakdownReasonId];
    },
    complaint: {
      get() {
        return this.breakdown.complaint;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'complaint', value });
      }
    },
    recipients: {
      get() {
        return this.breakdown.recipients;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'recipients', value });
      }
    },
    unitTowed: {
      get() {
        return this.breakdown.unitTowed;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'unitTowed', value });
      }
    },
    oneWayMileageRangeId: {
      get() {
        return this.breakdown.oneWayMileageRangeId;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'oneWayMileageRangeId', value });
      }
    },
    preventableBreakdown: {
      get() {
        return this.breakdown.preventableBreakdown;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'preventableBreakdown', value });
      }
    },
    stopsMissed: {
      get() {
        return this.breakdown.stopsMissed?.toString();
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'stopsMissed', value });
      }
    },
    continuedOnRoute: {
      get() {
        return this.breakdown.continuedOnRoute;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'continuedOnRoute', value });
      }
    },
    // DateTimes
    dateCreate() {
      return this.breakdown.dateCreate;
    },
    dateHelpRequested: {
      get() {
        return this.breakdown.dateHelpRequested;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'dateHelpRequested', value });
      }
    },
    dateDispatched: {
      get() {
        return this.breakdown.dateDispatched;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'dateDispatched', value });
      }
    },
    dateRepairCompleted: {
      get() {
        return this.breakdown.dateRepairCompleted;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'dateRepairCompleted', value });
      }
    },
    dateUnitReleased: {
      get() {
        return this.breakdown.dateUnitReleased;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'dateUnitReleased', value });
      }
    },
    notificationRequested: {
      get() {
        return this.breakdown.notificationRequested;
      },
      set(value) {
        this[BreakdownMutations.SET_PROP]({ key: 'notificationRequested', value });
      }
    }
  },
  watch: {
    myBranchesList() {
      if (this.myBranchesList.length === 1) {
        this.branchId = this.myBranchesList[0].branchId;
      }
    },
    branchId() {
      if (this.stage === STAGES.SELECT_BRANCH) {
        this[BreakdownMutations.SET_STAGE](STAGES.EDIT_HEADER);
      }
      if (this.branchId) this.fetchRecipients();
    }
  },
  async created() {
    if (this.breakdownId || this.$isCustomer) this.loading = true;
    try {
      const promises = [];
      promises.push(this[UnitActions.FETCH_UNIT](this.unitId));
      promises.push(this.fetchBreakdown(this));
      await Promise.all(promises);
    } catch (error) {
      throw Error(error);
    } finally {
      if (this.$isCustomer && !this.breakdownId) {
        const name = `${this.user.firstName} ${this.user.lastName}`;
        this[BreakdownMutations.DEFAULT_PROP]({
          key: 'branchId',
          value: this.locationsMap[this.unit.locationId]?.branchId
        });
        this[BreakdownMutations.DEFAULT_PROP]({ key: 'initiatedByName', value: name });
        this[BreakdownMutations.DEFAULT_PROP]({ key: 'initiatedByEmail', value: this.user.email });
      }
      this.loading = false;
    }
  },
  beforeDestroy() {
    this[BreakdownMutations.RESET_STATE]();
  },
  methods: {
    ...mapMutations([BreakdownMutations.DEFAULT_PROP, BreakdownMutations.SET_STAGE]),
    ...mapActions([
      UnitActions.FETCH_UNIT,
      BreakdownActions.FETCH_BREAKDOWN,
      BreakdownActions.CREATE_BREAKDOWN,
      BreakdownActions.UPDATE_BREAKDOWN,
      CustomerActions.FETCH_USERS_FOR_CUSTOMER,
      LookupActions.FETCH_EMPLOYEES_BY_BRANCH
    ]),
    ...mapMutations([BreakdownMutations.RESET_STATE, BreakdownMutations.SET_PROP]),
    async switchStage(newStage) {
      return await this.$store.dispatch(BreakdownActions.SWITCH_STAGE, { newStage, that: this });
    },
    fetchRecipients() {
      this[CustomerActions.FETCH_USERS_FOR_CUSTOMER](this.unit.customerId);
      this[LookupActions.FETCH_EMPLOYEES_BY_BRANCH](this.breakdown.branchId);
    },
    // FETCH (unscoped)
    async fetchBreakdown(vm) {
      if (vm.breakdownId) {
        try {
          await vm[BreakdownActions.FETCH_BREAKDOWN]({ breakdownId: vm.breakdownId, unitId: vm.unitId });
          vm[BreakdownMutations.SET_STAGE](STAGES.EDIT);
        } catch (error) {
          if (error.response && error.response.status == 404) vm.notFound = true;
        }
      }
    },
    async createBreakdown() {
      if (this.stage >= STAGES.CREATING) return;
      const switched = await this.switchStage(STAGES.CREATING);
      if (!switched) return;

      try {
        const response = await this[BreakdownActions.CREATE_BREAKDOWN]({
          unitId: this.unitId
        });
        SuccessService.createSuccessToast(this.$root, `Breakdown ID ${response.breakdownId} created successfully.`);
        this.switchStage(STAGES.EDIT);

        this.$router.replace({
          path: `/units/${this.unitId}/breakdowns/${response.breakdownId}`
        });
      } catch (err) {
        const errorMessage = `Error creating Breakdown.`;
        ErrorService.createErrorToast(this, errorMessage);
        this.switchStage(STAGES.EDIT_HEADER);
        throw Error(errorMessage);
      }
    },
    confirmNotification: function () {
      this.$refs.NotificationRecipientWarning.show(this, this.saveBreakdown.bind(this, true));
    },
    async saveBreakdown(notificationRequested) {
      if (this.stage >= STAGES.SAVING) return;
      const switched = await this.switchStage(STAGES.SAVING);
      if (!switched) return;

      this.notificationRequested = notificationRequested;

      try {
        const response = await this[BreakdownActions.UPDATE_BREAKDOWN]({
          unitId: this.unitId,
          breakdownId: this.breakdownId
        });
        SuccessService.createSuccessToast(this.$root, `Breakdown ID ${response.breakdownId} saved successfully.`);
      } catch (err) {
        const errorMessage = `Error saving Breakdown.`;
        ErrorService.createErrorToast(this, errorMessage);
        throw Error(errorMessage);
      } finally {
        this.switchStage(STAGES.EDIT);
      }
    },
    filterReasons(option, label, search) {
      return (
        option.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        (option.vmrsSystemCodeKey && option.vmrsSystemCodeKey.toLowerCase().indexOf(search.toLowerCase()) > -1)
      );
    },
    cancel() {
      this.$router.push(this.from);
    },
    hasChanges() {
      return this[BreakdownGetters.HAS_CHANGES];
    }
  }
};
</script>
