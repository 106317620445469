export const namespace = 'dvir/';

export const DvirGetters = {
  GET_DVIRS_COUNT: namespace + 'getDvirsCount',
  HAS_DVIR_DEFECTS: namespace + 'hasDvirDefects'
};

export const DvirActions = {
  FETCH_DVIRS_COUNT: namespace + 'fetchDvirsCount',
  SEARCH_DVIRS: namespace + 'searchDvirs'
};

export const DvirMutations = {
  RESET_STATE: namespace + 'resetState',
  SET_DVIRS_COUNT: namespace + 'setDvirsCount',
  SET_DVIR_SEARCH_RESULTS: namespace + 'setDvirSearchResults'
};
