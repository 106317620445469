import axios from 'axios';

const INVOICE_PATH = '/api/invoice';
const PURCHASE_ORDER_PATH = '/pos';
const SEARCH_PATH = '/search';
const GET_PATH = '/get';
const SAVE_PATH = '/save';
const APPROVE_PATH = '/approve';
const REJECT_PATH = '/reject';
const AP_PROCCESORS_ENDING = '/processors';
const APPROVERS_ENDING = '/approvers';
const VENDORS_ENDING = '/vendors';
const REQUEST_PATH = '/request';
const SEND_PATH = '/send';
const VALIDATE_PATH = '/validate';
const APP_E_URL_PATH = '/appEUrl';
const DOWNLOAD_PATH = '/download';

class InvoiceService {
  async searchInvoices(parameters) {
    if (parameters.category === 'history' || parameters.category === 'review') {
      const url = `${INVOICE_PATH}${SEARCH_PATH}/${encodeURIComponent(parameters.category)}`;
      const invoices = await axios.get(url, {
        params: parameters
      });
      return invoices;
    } else {
      const url = `${INVOICE_PATH}${SEARCH_PATH}/${encodeURIComponent(parameters.category)}`;
      const invoices = await axios.get(url);
      return invoices;
    }
  }
  async fetchInvoice(parameters) {
    const url = `${INVOICE_PATH}/${encodeURIComponent(parameters.divisionId)}/${encodeURIComponent(
      parameters.category
    )}/${encodeURIComponent(parameters.docId)}`;
    const invoice = await axios.get(url);
    return invoice;
  }
  async fetchAPProcessors() {
    const url = `${INVOICE_PATH}${GET_PATH}${AP_PROCCESORS_ENDING}`;
    const processors = await axios.get(url);
    return processors;
  }
  async fetchDivisionAPProcessors(param) {
    const url = `${INVOICE_PATH}${GET_PATH}/${param}${AP_PROCCESORS_ENDING}`;
    const processors = await axios.get(url);
    return processors;
  }
  async fetchApprovers() {
    const url = `${INVOICE_PATH}${GET_PATH}${APPROVERS_ENDING}`;
    const processors = await axios.get(url);
    return processors;
  }
  async fetchDivisionApprovers(param) {
    const url = `${INVOICE_PATH}${GET_PATH}/${param}${APPROVERS_ENDING}`;
    const processors = await axios.get(url);
    return processors;
  }
  async searchVendors(params) {
    const url = `${INVOICE_PATH}${GET_PATH}${VENDORS_ENDING}`;
    const vendorsList = await axios.get(url, { params });
    return vendorsList;
  }
  async searchPOs(params) {
    const url = `${INVOICE_PATH}${GET_PATH}${PURCHASE_ORDER_PATH}/${encodeURIComponent(params.vendorId)}`;
    const POs = await axios.get(url);
    return POs;
  }
  async saveInvoice(parameters) {
    const url = `${INVOICE_PATH}${SAVE_PATH}`;
    await axios.put(url, parameters);
  }
  async approveInvoice(parameters) {
    const url = `${INVOICE_PATH}${APPROVE_PATH}`;
    await axios.put(url, parameters);
  }
  async rejectInvoice(parameters) {
    const url = `${INVOICE_PATH}${REJECT_PATH}`;
    await axios.put(url, parameters);
  }
  async requestInvoiceApproval(parameters) {
    const url = `${INVOICE_PATH}${REQUEST_PATH}`;
    await axios.put(url, parameters);
  }
  async sendInvoice(parameters) {
    const url = `${INVOICE_PATH}${SEND_PATH}`;
    await axios.put(url, parameters);
  }
  async validateFullInvoice(parameters) {
    const url = `${INVOICE_PATH}${VALIDATE_PATH}/full`;
    await axios.put(url, parameters);
  }
  async validateHeaderInvoice(parameters) {
    const url = `${INVOICE_PATH}${VALIDATE_PATH}/header`;
    await axios.put(url, parameters);
  }
  async fetchAppEUrl() {
    const url = `${INVOICE_PATH}${GET_PATH}${APP_E_URL_PATH}`;
    const POs = await axios.get(url);
    return POs;
  }
  async fetchDownload(parameters) {
    const url = `${INVOICE_PATH}${GET_PATH}${DOWNLOAD_PATH}/${encodeURIComponent(parameters.objectId)}`;
    return await axios.get(url, {
      responseType: 'blob'
    });
  }
}

export default new InvoiceService(); // singleton object
