<template>
  <div>
    <b-overlay :show="timeoutOverlay">
      <b-overlay :show="cloningSo || loading || saving">
        <template #overlay>
          <div class="text-center py-4">
            <h3 id="loading-header">
              <span v-if="cloningSo">Cloning</span>
              <span v-else-if="creating">Creating</span>
              <span v-else-if="saving">Saving</span>
              <span v-else>Loading</span>
              {{ estimorderDisplayText }}...
            </h3>
            <b-spinner></b-spinner>
          </div>
        </template>
        <!-- unit-summary  -->
        <unit-summary-component v-if="stage <= FORM_STAGES.CREATING_SO">
          <a
            v-show="hasOpenServiceOrders"
            id="open-service-orders-link"
            href="#"
            class="nav-link p-0 mt-21px"
            @click="openServiceOrderAside"
          >
            View Existing Open Service Order
          </a>
        </unit-summary-component>
        <service-order-summary v-else />

        <!-- appointment warning -->
        <b-alert v-if="hasExistingAppointmentInHistory" show variant="warning" dismissible>
          <span class="font-weight-bold mr-1">OPEN SERVICE ORDER:</span>
          An Appointment was created for this Service Order by {{ existingAppointmentSoCreator.name }} on
          {{ existingAppointmentCreateDate | date }}.
        </b-alert>
        <b-alert v-if="hasAppointmentUnitServiceOrders" show variant="warning" dismissible>
          <span class="font-weight-bold mr-1">OPEN SERVICE ORDER:</span>
          A Service Order was created for the Appointment by {{ existingAppointmentSoCreator.name }} on
          {{ existingAppointmentCreateDate | date }}.
        </b-alert>

        <!-- header -->
        <service-order-header ref="ServiceOrderHeader" :created="createdSo" />

        <b-row v-if="stage >= FORM_STAGES.EDIT_HEADER && stage <= FORM_STAGES.CREATING_SO" class="pb-3">
          <b-button
            class="mr-3"
            variant="primary"
            :disabled="stage >= FORM_STAGES.CREATING_SO"
            @click="createServiceOrder()"
          >
            <div v-show="stage == FORM_STAGES.CREATING_SO">
              <b-spinner small></b-spinner>
              Creating...
            </div>
            <div v-show="stage != FORM_STAGES.CREATING_SO">Create {{ estimorderDisplayText }}</div>
          </b-button>
          <b-button variant="secondary" @click="cancel">Cancel</b-button>
        </b-row>

        <!-- select-stand-operations -->
        <div v-if="stage >= FORM_STAGES.EDIT_SO" class="pb-3">
          <service-order-add-operations
            :preselected-standard-operation-id="preselectedStandardOperationId"
            :preselect-due-operations="createdSo"
            :visible="stage == (FORM_STAGES.EDIT_SO || FORM_STAGE.ADDING_JOBS) && Object.keys(jobs).length == 0"
          ></service-order-add-operations>
        </div>

        <!-- /select-stand-operations -->

        <b-form v-show="stage >= FORM_STAGES.EDIT_SO">
          <!-- line-items -->
          <div v-if="Object.keys(jobs).length > 0">
            <service-order-line-items
              ref="LineItems"
              @updateApprovalChangesFlag="updateApprovalChangesFlag"
              @addJobsFromEstimate="attemptAddApprovedJobs"
            />
          </div>
          <div v-else>
            <p>No operations added.</p>
          </div>
          <!-- /line-items -->

          <service-order-footer />

          <b-row class="my-3">
            <b-button
              variant="primary"
              :disabled="stage > FORM_STAGES.EDIT_SO"
              @click="checkLiftgateContractAgainstJobSalesType()"
            >
              <div v-show="stage == FORM_STAGES.SAVING_SO">
                <b-spinner small></b-spinner>
                Saving...
              </div>
              <div v-show="stage != FORM_STAGES.SAVING_SO">Save {{ estimorderDisplayText }}</div>
            </b-button>
          </b-row>
          <b-row class="pb-3">
            <b-button
              v-if="canCreateOtherEstimorder"
              variant="secondary"
              :disabled="stage > FORM_STAGES.EDIT_SO"
              class="mr-2"
              @click="createOtherEstimorder()"
            >
              <div v-show="stage == FORM_STAGES.CREATING_OTHER">
                <b-spinner small></b-spinner>
                Creating...
              </div>
              <div v-show="stage != FORM_STAGES.CREATING_OTHER">Create {{ otherEstimorderDisplayText }}</div>
            </b-button>
            <b-button
              v-if="canScheduleService"
              variant="secondary"
              :disabled="stage > FORM_STAGES.EDIT_SO"
              @click="scheduleService()"
            >
              <div v-show="stage == FORM_STAGES.SCHEDULING_SERVICE">
                <b-spinner small></b-spinner>
                Scheduling...
              </div>
              <div v-show="stage != FORM_STAGES.SCHEDULING_SERVICE">
                Schedule Service
                <font-awesome-icon icon="external-link-alt" fixed-width />
              </div>
            </b-button>
          </b-row>
        </b-form>
      </b-overlay>
      <template #overlay>
        <div class="idle-timeout">
          <div>{{ idleTimeoutMessage }}</div>
          <div>{{ idleTimeoutMessage }}</div>
          <div>{{ idleTimeoutMessage }}</div>
        </div>
      </template>
    </b-overlay>

    <transition name="slide">
      <unit-service-order-history-aside v-if="showServiceOrderAside" class="fixed-right fixed-top" />
    </transition>

    <unsaved-changes-modal
      ref="ApprovalRejectionWarningModal"
      :has-changes="hasApprovalChangesFunction"
      title="Save Changes"
      message="Approved/Rejected/Deferred statuses cannot be altered after saving.  Are you sure you want to Save?"
      continue-btn-text="Save changes"
    ></unsaved-changes-modal>

    <warning-modal
      id="WarningModal"
      ref="WarningModal"
      :warning-text="warningMessage"
      :continue-btn-text="`Save ` + estimorderDisplayText"
    ></warning-modal>
    <warning-modal
      id="MismatchWarningModal"
      ref="MismatchWarningModal"
      :title="mismatchTitle"
      :warning-text="mismatchMessage"
      :continue-btn-text="cancelButtonText"
      cancel-btn-text="Edit Sales Type"
    ></warning-modal>
  </div>
</template>

<script>
// components
import ServiceOrderHeaderComponent from './ServiceOrderHeaderComponent';
import ServiceOrderSummaryComponent from './ServiceOrderSummaryComponent';
import ServiceOrderFooterComponent from './ServiceOrderFooterComponent';
import ServiceOrderLineItemsComponent from './line-items/ServiceOrderLineItemsComponent';
import ServiceOrderAddOperationsComponent from './ServiceOrderAddOperationsComponent';
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';
import WarningModal from '@/shared/components/WarningModal';
import UnitServiceOrderHistoryAside from '@/shared/components/service-order/UnitServiceOrderHistoryAside';
import UnitSummaryComponent from '@/shared/components/unit/UnitSummaryComponent';
// vuex
import { ConfigGetters } from '@/shared/store/config/types';
import { ServiceOrderActions, ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { UnitActions, UnitGetters } from '@/shared/store/unit/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { MountedEquipmentGetters } from '@/shared/store/unit/mounted-equipment/types';
import { UserGetters } from '@/shared/store/user/types';
import { FORM_STAGES, LIFTGATE_JOB_ID } from '@/shared/store/service-order/state';
import { mapActions, mapGetters } from 'vuex';
import { SCOPES } from '@/shared/store/service-order/state';

// helpers
import NumberFieldHelper from '@/shared/helpers/number-field-helper';
import Cloner from '@/shared/helpers/cloner';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';
import { required } from 'vuelidate/lib/validators';
import MotorService from '@/shared/services/MotorsService';
import SchedulerService from '@/shared/services/SchedulerService';
import { JOB_OPERATION_STATUS_IDS } from '@/shared/store/lookup/state';

export default {
  name: 'ServiceOrderEdit',
  components: {
    'unit-service-order-history-aside': UnitServiceOrderHistoryAside,
    'service-order-add-operations': ServiceOrderAddOperationsComponent,
    'service-order-line-items': ServiceOrderLineItemsComponent,
    'unit-summary-component': UnitSummaryComponent,
    'service-order-summary': ServiceOrderSummaryComponent,
    'warning-modal': WarningModal,
    'unsaved-changes-modal': UnsavedChangesModal,
    'service-order-header': ServiceOrderHeaderComponent,
    'service-order-footer': ServiceOrderFooterComponent
  },
  provide() {
    return {
      validator: this.$v
    };
  },
  data: function () {
    return {
      FORM_STAGES: FORM_STAGES,
      LIFTGATE_JOB_ID: LIFTGATE_JOB_ID,
      hasApprovalChangesFlag: false,
      NumberFieldHelper: NumberFieldHelper,
      cloningSo: false,
      loading: false,
      warningMessage: '',
      mismatchMessage: '',
      mismatchTitle: '',
      cancelButtonText: '',
      createdSo: false,
      hasServiceRequest: false,
      calulatingTaxes: false,
      timeoutOverlay: false,
      idleTimeoutMessage: 'You have been idle for 30 minutes. Press F5 to refresh the page'
    };
  },
  validations: {
    jobs: {
      $each: {
        details: {
          laborLines: {
            $each: {
              laborCode: {
                required
              },
              description: {
                required
              }
            }
          },
          parts: {
            $each: {
              partNumber: {
                required
              },
              quantityRequested: {
                required
              }
            }
          },
          subletLines: {
            $each: {
              subletCodeId: {
                required
              },
              description: {
                required
              },
              quantity: {
                required
              }
            }
          },
          miscLines: {
            $each: {
              miscCode: {
                required
              },
              description: {
                required
              },
              quantity: {
                required
              }
            }
          },
          commentLines: {
            $each: {
              commentCodeId: {
                required
              }
            }
          },
          inspections: {
            $each: {
              inspectionId: {
                required
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      config: ConfigGetters.GET_CONFIG,
      stage: ServiceOrderGetters.GET_FORM_STAGE,
      branchId: ServiceOrderGetters.GET_BRANCH_ID,
      jobs: ServiceOrderGetters.GET_JOBS,
      serviceOrderId: ServiceOrderGetters.GET_SERVICE_ORDER_ID,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      isEstimate: ServiceOrderGetters.GET_IS_ESTIMATE,
      estimorderDisplayText: ServiceOrderGetters.GET_ESTIMORDER_DISPLAY_TEXT,
      estimateId: ServiceOrderGetters.GET_ESTIMATE_ID,
      hasOtherEstimorder: ServiceOrderGetters.HAS_OTHER_ESTIMORDER,
      hasEstimorderError: ServiceOrderGetters.HAS_ESTIMORDER_ERROR,
      hasChanges: ServiceOrderGetters.HAS_CHANGES,
      salesTypeId: ServiceOrderGetters.GET_SALES_TYPE_ID,
      unit: UnitGetters.GET_UNIT,
      showServiceOrderAside: UnitGetters.GET_UNIT_SERVICE_ORDERS_ASIDE_SHOW,
      user: UserGetters.GET_USER_PROFILE,
      isFeatureFlagEnabled: LookupGetters.IS_FEATURE_FLAG_ENABLED,
      unitServiceOrderHistory: UnitGetters.GET_UNIT_SERVICE_ORDER_HISTORY,
      myAssociatedEmployeesMap: LookupGetters.GET_MY_ASSOCIATED_EMPLOYEES_MAP,
      AppointmentUnitServiceOrders: ServiceOrderGetters.GET_APPOINTMENT_UNIT_SERVICE_ORDERS,
      hasNonGuaranteedLiftgate: MountedEquipmentGetters.HAS_NON_GUARANTEED_LIFTGATE,
      hasLiftgate: MountedEquipmentGetters.HAS_LIFTGATE,
      isNonGuaranteedUnit: UnitGetters.IS_UNIT_NON_GUARANTEED,
      isTemporarilyExemptUnit: UnitGetters.IS_UNIT_TEMPORARILY_EXEMPT,
      hasInScopeLiftgateJob: ServiceOrderGetters.HAS_IN_SCOPE_LIFTGATE_JOB,
      hasTemporarilyExemptLiftgate: MountedEquipmentGetters.HAS_TEMPORARILY_EXEMPT_LIFTGATE
    }),
    creating() {
      return this.stage == FORM_STAGES.CREATING_SO;
    },
    saving() {
      return (
        this.stage == FORM_STAGES.CREATING_SO ||
        this.stage == FORM_STAGES.SAVING_SO ||
        this.stage == FORM_STAGES.REMOVING_JOB ||
        this.stage == FORM_STAGES.ADDING_JOBS ||
        this.stage == FORM_STAGES.CREATING_OTHER ||
        this.stage == FORM_STAGES.ADDING_JOBS_OTHER
      );
    },
    unitId() {
      return this.$router.currentRoute.params.unitId;
    },
    preselectedStandardOperationId() {
      return parseInt(this.$router.currentRoute.query.standardOperationId);
    },
    assignedStandardOperations() {
      return Cloner.deepClone(this.$store.getters[UnitGetters.GET_UNIT_STANDARD_OPERATIONS]);
    },
    hasOpenServiceOrders() {
      return this.$store.getters[UnitGetters.GET_UNIT_SERVICE_ORDER_HISTORY].filter(so => !so.invoiced).length > 0;
    },
    otherEstimorderDisplayText() {
      if (this.isEstimate) return 'Service Order';
      return 'Estimate';
    },
    canCreateOtherEstimorder() {
      if (this.isEstimate && this.hasEstimorderError('serviceOrder')) return false;
      if (!this.isEstimate && this.hasEstimorderError('estimate')) return false;
      if (this.hasOtherEstimorder) return false;
      return true;
    },
    canScheduleService() {
      var hasJobs = Object.keys(this.jobs).length > 0;
      return (
        this.isFeatureFlagEnabled('ServiceRequest') &&
        !this.serviceOrder.isEstimate &&
        hasJobs &&
        !this.hasServiceRequest &&
        !this.serviceOrder.hasServiceRequest &&
        !this.serviceOrder.appointmentId
      );
    },
    hasExistingAppointmentInHistory() {
      return this.unitServiceOrderHistory.some(h => h.appointmentId === this.serviceOrder?.appointmentId);
    },
    hasAppointmentUnitServiceOrders() {
      var soIds = this.AppointmentUnitServiceOrders.map(x => x.serviceOrderId);
      return this.unitServiceOrderHistory.some(item => soIds.includes(item.serviceOrderId));
    },
    existingAppointment() {
      if (this.hasExistingAppointmentInHistory) {
        return this.unitServiceOrderHistory
          .slice()
          .sort((a, b) => (a.dateCreate > b.dateCreate ? 1 : -1))
          .find(h => h.appointmentId === this.serviceOrder.appointmentId);
      } else if (this.hasAppointmentUnitServiceOrders) {
        return this.unitServiceOrderHistory
          .slice()
          .sort((a, b) => (a.dateCreate > b.dateCreate ? 1 : -1))
          .find(h => h.serviceOrderId === this.serviceOrder.serviceOrderId);
      } else {
        return {};
      }
    },
    existingAppointmentSoCreator() {
      return this.myAssociatedEmployeesMap[this.existingAppointment?.empIdCreate]
        ? this.myAssociatedEmployeesMap[this.existingAppointment?.empIdCreate]
        : null;
    },
    existingAppointmentCreateDate() {
      return this.existingAppointment.dateCreate ? this.existingAppointment.dateCreate : null;
    },
    hasInScopeJob() {
      let jobsArray = typeof this.jobs === 'object' ? Object.values(this.jobs) : [];
      let array = [];
      for (let job of jobsArray) {
        if (
          job.jobId == LIFTGATE_JOB_ID &&
          (this.hasNonGuaranteedLiftgate || !this.hasLiftgate || this.hasTemporarilyExemptLiftgate)
        ) {
          array.push({ salesTypeId: job.salesTypeId });
        } else if (job.jobId != LIFTGATE_JOB_ID) {
          array.push({ salesTypeId: job.salesTypeId });
        }
      }
      return array.filter(job => job.salesTypeId === SCOPES.IN_SCOPE).length > 0;
    }
  },
  watch: {
    stage: {
      handler() {
        if (this.stage > FORM_STAGES.CREATING_SO) {
          this.resetTimeout();
          window.addEventListener('click', this.resetTimeout);
          window.addEventListener('keydown', this.resetTimeout);
        }
      },
      immediate: true
    }
  },
  created: async function () {
    this.defaultValuesFromQuery();
    if (this.$route.query.clone) {
      this.cloningSo = true;

      await this[ServiceOrderActions.CLONE_SERVICE_ORDER]({
        unitId: this.$route.params.unitId,
        serviceOrderId: this.$route.query.clone
      });
    }

    if (this.stage <= FORM_STAGES.CREATING_SO && !this.cloningSo) {
      this.createdSo = true;
    }
    this.cloningSo = false;
  },
  methods: {
    ...mapActions([
      UnitActions.SHOW_SERVICE_ORDER_HISTORY_ASIDE,
      ServiceOrderActions.CLONE_SERVICE_ORDER,
      ServiceOrderActions.CREATE_SERVICE_ORDER,
      ServiceOrderActions.CREATE_OTHER_ESTIMORDER,
      ServiceOrderActions.UPDATE_SERVICE_ORDER,
      ServiceOrderActions.UPDATE_QTY_ON_HAND,
      ServiceOrderActions.ADD_JOBS_TO_SERVICE_ORDER,
      ServiceOrderActions.SET_SERVICE_ORDER,
      ServiceOrderActions.RESET_STATE,
      ServiceOrderActions.FETCH_SERVICE_ORDER,
      ServiceOrderActions.FETCH_LINKED_SERVICE_ORDER,
      ServiceOrderActions.DEFAULT_PROP,
      ServiceOrderActions.CALCULATE_SO_TAXES
    ]),
    on() {
      this.timeoutOverlay = true;
    },
    resetTimeout() {
      clearTimeout(this.$timeout);
      this.$timeout = setTimeout(this.on, this.serviceOrder.screenTimeout);
    },
    async switchStage(newStage) {
      var switchStageSuccess = await this.$store.dispatch(ServiceOrderActions.SWITCH_STAGE, {
        newStage,
        that: this,
        otherVs: [this.$refs['ServiceOrderHeader'].$v]
      });

      this.scrollIfError();

      return switchStageSuccess;
    },
    defaultValuesFromQuery() {
      if (this.$route.query.breakdownId) {
        this[ServiceOrderActions.DEFAULT_PROP]({ key: 'breakdownId', value: parseInt(this.$route.query.breakdownId) });
      }
      if (this.$route.query.appointmentId) {
        this[ServiceOrderActions.DEFAULT_PROP]({
          key: 'appointmentId',
          value: parseInt(this.$route.query.appointmentId)
        });
      }
      if (this.$route.query.branchId) {
        this[ServiceOrderActions.DEFAULT_PROP]({ key: 'branchId', value: this.$route.query.branchId });
      }
    },
    scrollIfError() {
      const headerElement = this.$refs['ServiceOrderHeader'];
      const jobsElement = this.$refs['LineItems'];

      if (headerElement.$v.$error || headerElement.$v.$anyError) {
        this.$emit('scrollToError', headerElement.$el.offsetTop);
      } else {
        this.$v.$touch();
        if (this.$v.jobs.$error || this.$v.jobs.$anyError) {
          for (let jobV of Object.values(this.$v.jobs.$each.$iter)) {
            if (jobV.$anyError) {
              if (!jobV.$model._showDetails) {
                jobsElement.toggleDetails(jobV.$model);
              }
            }
          }
          this.$emit('scrollToError', jobsElement.$el.offsetTop);
          return;
        }
        this.$v.$reset();
      }
    },
    updateApprovalChangesFlag(flagValue) {
      this.hasApprovalChangesFlag = flagValue;
    },
    checkLiftgateContractAgainstJobSalesType: async function () {
      if (this.hasInScopeLiftgateJob && (this.hasNonGuaranteedLiftgate || this.hasTemporarilyExemptLiftgate)) {
        this.mismatchTitle = 'Mismatched Sales Type for Mounted Equipment Contract Status';
        this.mismatchMessage =
          'Unexpected Sales Type based on Mounted Equipment Contract Status.  Expected Sales Type is Out-of-Scope for Non-Guaranteed or Temporarily Exempt Mounted Equipment.  What would you like to do?';
        this.cancelButtonText = 'Save Service Order';
        this.$refs.MismatchWarningModal.show(this, this.checkContractStatusJobSalesTypeMismatch.bind(this));
      } else {
        this.checkContractStatusJobSalesTypeMismatch();
      }
    },
    checkContractStatusJobSalesTypeMismatch: async function () {
      if ((this.isNonGuaranteedUnit || this.isTemporarilyExemptUnit) && this.hasInScopeJob) {
        this.mismatchTitle = 'Mismatched Sales Type for Unit Contract Status';
        this.mismatchMessage =
          'Unexpected Sales Type based on Unit Contract Status.  Expected Sales Type is Out-of-Scope for Non-Guaranteed and Temporarily Exempt Units.  What would you like to do?';
        this.cancelButtonText = 'Save Service Order';
        this.$refs.MismatchWarningModal.show(this, this.updateServiceOrder.bind(this));
      } else {
        this.updateServiceOrder();
      }
    },

    // CREATE
    async createServiceOrder() {
      if (this.stage >= FORM_STAGES.CREATING_SO) return;
      const switched = await this.switchStage(FORM_STAGES.CREATING_SO);
      if (!switched) return;

      let params = { unitId: this.unitId };
      if (this.$route.query.clone) params.clone = true;

      try {
        //DTB-2469 removing set branch Id for single branch user caused branch Id to be null
        //The following fix is for DTB-2517, set branch Id for single branch user
        const branchId = this.$store.getters[ServiceOrderGetters.GET_BRANCH_ID];
        if (this.$store.getters[UserGetters.GET_USER_PROFILE].employeeBranches.length === 1 && branchId == null) {
          this.$store.commit(
            ServiceOrderMutations.SET_BRANCH,
            this.$store.getters[UserGetters.GET_USER_PROFILE].employeeBranches[0].branchId
          );
        }

        const response = await this[ServiceOrderActions.CREATE_SERVICE_ORDER](params);
        SuccessService.createSuccessToast(
          this.$root,
          `${this.estimorderDisplayText} #${response.serviceOrderId} created successfully.`
        );
        this.switchStage(FORM_STAGES.EDIT_SO);
        this.$router.replace({
          path: `/units/${this.unitId}/service-orders/${this.serviceOrderId}`
        });
      } catch (err) {
        const errorMessage = `Error creating ${this.estimorderDisplayText}.`;
        ErrorService.createErrorToast(this, errorMessage);
        this.switchStage(FORM_STAGES.EDIT_HEADER);
        throw Error(errorMessage);
      }
    },

    // UPDATE
    async updateServiceOrder() {
      if (this.stage !== FORM_STAGES.EDIT_SO) return;
      const switched = await this.switchStage(FORM_STAGES.SAVING_SO);
      if (!switched) return;

      if (this.hasApprovalChangesFunction()) {
        this.$refs.ApprovalRejectionWarningModal.show(this, this.handleCancel, this.handleUpdateServiceOrder);
      } else {
        await this.handleUpdateServiceOrder();
      }
    },
    async handleUpdateServiceOrder() {
      try {
        if (Object.keys(this.jobs).length > 0) {
          this.$refs['LineItems'].saveTireOnServiceOrderSave();
        }
        const response = await this[ServiceOrderActions.UPDATE_SERVICE_ORDER]({
          unitId: this.unitId,
          serviceOrderId: this.serviceOrderId
        });

        await this[ServiceOrderActions.CALCULATE_SO_TAXES]({
          unitId: this.unitId,
          serviceOrderId: this.serviceOrderId
        });

        SuccessService.createSuccessToast(
          this.$root,
          `${this.estimorderDisplayText} #${response.serviceOrderId} saved successfully.`
        );

        this.hasApprovalChangesFlag = false;
      } catch (err) {
        if (err.response && err.response.status === 409) {
          if (err.response.data.type === 'qty') {
            this.warningMessage = this.buildQtyWarningMessage(err.response.data.message);
            this[ServiceOrderActions.UPDATE_QTY_ON_HAND](err.response.data.message);
            this.$refs.WarningModal.show(this, this.updateServiceOrder);
          }
          if (err.response.data.type === 'ovr') {
            const errorMessage = err.response.data.message;
            ErrorService.createErrorToast(this, errorMessage);
          }
        } else {
          const errorMessage = `Error saving ${this.estimorderDisplayText}.`;
          ErrorService.createErrorToast(this, errorMessage);
          throw Error(errorMessage);
        }
      } finally {
        this.switchStage(FORM_STAGES.EDIT_SO);
        await this[ServiceOrderActions.FETCH_SERVICE_ORDER]({
          unitId: this.unitId,
          serviceOrderId: this.serviceOrderId
        });
      }
    },
    handleCancel() {
      this.switchStage(FORM_STAGES.EDIT_SO);
    },

    // CREATE OTHER
    async createOtherEstimorder() {
      if (this.stage !== FORM_STAGES.EDIT_SO) return;
      if (this.hasChanges()) {
        ErrorService.createErrorToast(
          this,
          `Please save your changes to your ${this.estimorderDisplayText} before creating a ${this.otherEstimorderDisplayText}`
        );
        return;
      }

      const switched = await this.switchStage(FORM_STAGES.CREATING_OTHER);
      if (!switched) return;

      try {
        const response = await this[ServiceOrderActions.CREATE_OTHER_ESTIMORDER]({
          unitId: this.unitId
        });

        SuccessService.createSuccessToast(
          this.$root,
          `${this.otherEstimorderDisplayText} #${response.serviceOrderId} created successfully.`
        );
      } catch (err) {
        const errorMessage = `Error creating ${this.otherEstimorderDisplayText}.`;
        ErrorService.createErrorToast(this, errorMessage);
        throw Error(errorMessage);
      } finally {
        this.switchStage(FORM_STAGES.EDIT_SO);
      }
    },
    async scheduleService() {
      if (this.stage !== FORM_STAGES.EDIT_SO) return;
      const switched = await this.switchStage(FORM_STAGES.SCHEDULING_SERVICE);
      if (!switched) return;

      const complaint = this.getComplaints();

      try {
        const request = {
          serviceOrderId: this.serviceOrderId,
          units: [{ unitId: this.unitId, standardOperationIds: null, serviceOrderId: this.serviceOrderId }],
          complaint: complaint,
          branchId: this.branchId,
          contactEmail: this.user.email,
          contactName: this.user.name,
          sourceId: SchedulerService.sources.vams
        };
        try {
          const response = await SchedulerService.requestService([request]);
          if (response.data === 'HasServiceRequest') {
            this.hasServiceRequest = true;
            ErrorService.createErrorToast(this, 'Service request already exists.');
          } else {
            const url = this.config.shopSchedulerUrl;
            window.open(`${url}/schedule/${this.branchId}?viewPreset=daily`, 'shopScheduler');
            SuccessService.createSuccessToast(this.$root, `Service request sent.`);
            this.hasServiceRequest = true;
          }
        } catch (error) {
          ErrorService.createErrorToast(this.$root, `Error sending service request.`);
        } finally {
          this.sending = false;
        }
      } finally {
        this.switchStage(FORM_STAGES.EDIT_SO);
      }
    },
    // Add Jobs From Estimate - Could move to Line Item Component?
    async attemptAddApprovedJobs(jobList) {
      if (!this.isEstimate) return;

      if (this.hasApprovalChangesFunction()) {
        this.$refs.ApprovalRejectionWarningModal.show(this, this.handleCancel, async () => {
          if (this.stage !== FORM_STAGES.EDIT_SO) return;
          const switched = await this.switchStage(FORM_STAGES.SAVING_SO);
          if (!switched) return;
          await this.handleUpdateServiceOrder();
          await this.addJobsFromEstimateToServiceOrder(jobList);
        });
      } else {
        await this.addJobsFromEstimateToServiceOrder(jobList);
      }
    },
    async addJobsFromEstimateToServiceOrder(jobList) {
      if (this.stage !== FORM_STAGES.EDIT_SO) return;
      const switched = await this.switchStage(FORM_STAGES.ADDING_JOBS_OTHER);
      if (!switched) return;

      if (!Array.isArray(jobList)) jobList = [jobList];
      const assocServiceOrder = this.$store.state.serviceOrder.serviceOrder;
      try {
        const jobsToAdd = Cloner.deepClone(jobList);
        jobsToAdd.forEach(job => {
          job.jobOperationStatusId = JOB_OPERATION_STATUS_IDS.SERVICE_ORDER_APPROVED;
        });

        await this[ServiceOrderActions.ADD_JOBS_TO_SERVICE_ORDER]({
          serviceOrderId: assocServiceOrder.serviceOrderId,
          isEstimate: assocServiceOrder.isEstimate,
          linkedEstimateId: this.serviceOrderId,
          jobs: jobsToAdd,
          branchId: this.branchId,
          unitId: this.unitId
        });
        let operationMessage = 'Operation';
        if (jobList.length > 1) operationMessage += 's ';
        operationMessage += ' ' + jobList.map(job => job.jobId).join(', ');
        SuccessService.createSuccessToast(
          this.$root,
          `Added ${operationMessage} to ${this.otherEstimorderDisplayText} successfully.`
        );
        await this[ServiceOrderActions.FETCH_SERVICE_ORDER]({
          unitId: this.unitId,
          serviceOrderId: this.serviceOrderId
        });
      } catch (err) {
        const errorMessage = `Error adding job to ${this.estimorderDisplayText}.`;
        ErrorService.createErrorToast(this, errorMessage);
        throw Error(errorMessage);
      } finally {
        this.switchStage(FORM_STAGES.EDIT_SO);
      }
    },
    getComplaints() {
      return Object.values(this.jobs)
        .map(x => x.complaint)
        .join(', ');
    },
    cancel() {
      this.$emit('cancel');
    },
    hasApprovalChangesFunction: function () {
      return this.hasApprovalChangesFlag;
    },
    buildQtyWarningMessage(qtyData) {
      var qtyWarningText = '';
      qtyData.forEach(part => {
        var qtyBackOrdered = part.qtyRequested - part.qtyOnHand;
        qtyWarningText += `Quantity on hand has changed for part number ${part.partNumber}.  ${qtyBackOrdered} of ${part.qtyRequested} will be backordered. \r\n`;
      });
      qtyWarningText += 'To proceed with backorder click Save Service Order.';
      return qtyWarningText;
    },
    async motorsClicked() {
      const { data: token } = await MotorService.getToken();
      const vinEncoded = encodeURIComponent(this.unit.vin);
      const tokenEncoded = encodeURIComponent(token);
      const url = `https://v4.fleetcross.net/account/login.aspx?vin=${vinEncoded}&authtoken=${tokenEncoded}`;
      window.open(url, '_blank');
    },
    openServiceOrderAside: function () {
      this[UnitActions.SHOW_SERVICE_ORDER_HISTORY_ASIDE]();
    },
    resetVuelidate() {
      this.$v.$reset();
      this.$refs['ServiceOrderHeader'].$v.$reset();
    }
  }
};
</script>
