import axios from 'axios';
export const UNIT_PATH = '/api/units';
const OOS_PATH_ENDING = 'out-of-services';
const BACK_IN_SERVICE_ENDING = 'back-in-service';

class OutOfServiceService {
  async postUnitOutOfService(params) {
    const url = `${UNIT_PATH}/${encodeURIComponent(params.unitId)}/${OOS_PATH_ENDING}`;
    const oOS = await axios.post(url, params.outOfService);
    return oOS;
  }

  async getUnitOutOfServices(unitId) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${OOS_PATH_ENDING}`;
    const outOfServices = await axios.get(url);
    return outOfServices;
  }

  async updateUnitOutOfService(unitId, outOfService, notify) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${OOS_PATH_ENDING}/${notify}`;
    const oOS = await axios.put(url, outOfService);
    return oOS;
  }

  async markUnitBackInService(unitId, backInService) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${OOS_PATH_ENDING}/${BACK_IN_SERVICE_ENDING}`;
    const oOS = await axios.put(url, backInService);
    return oOS;
  }
}
export default new OutOfServiceService(); // singleton object
