import namespaceHelper from '@/shared/helpers/namespace-helper';
import Cloner from '@/shared/helpers/cloner';
import { namespace, WarrantyMutations } from './types';
import { getDefaultState } from './state';

const _mutations = namespaceHelper.removeNamespace(namespace, WarrantyMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_WARRANTY_SEARCH_RESULTS](state, results) {
    state.warrantySearchResults = results;
  },
  [_mutations.SET_SUBMISSION](state, results) {
    state.submission = results;
    state.dbSubmission = Cloner.deepClone(results);
  },
  [_mutations.SET_SUBMISSION_PROP](state, payload) {
    state.submission[payload.key] = payload.value;
  },
  [_mutations.SET_RECIPIENT_LIST](state, recipientList) {
    if (recipientList.length !== 0) {
      state.recipientList = recipientList;
    }
  },
  [_mutations.SET_WARRANTY_ADMINS](state, warrantyAdmins) {
    state.warrantyAdmins = warrantyAdmins;
  }
};
