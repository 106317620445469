function getOperationStatusCssClass(operationStatus) {
  switch (operationStatus) {
    case 'Overdue':
      return 'danger';
    case 'Due':
      return 'warning';
    case 'Current':
      return 'success';
    case 'Deferred':
      return 'notification';
    default:
      return '';
  }
}

function getOperationCriterionStatusCssClass(operationStatus, operationStatusParameter) {
  if (operationStatus !== operationStatusParameter) {
    return '';
  }

  switch (operationStatus) {
    case 'Overdue':
      return 'overdue';
    case 'Due':
      return 'due';
    case 'Current':
    default:
      return '';
  }
}

export default {
  getOperationCriterionStatusCssClass,
  getOperationStatusCssClass
};
