export const namespace = 'invoice/';

export const InvoiceGetters = {
  GET_INVOICES: namespace + 'getInvoices',
  GET_HISTORY_INVOICES: namespace + 'getHistoryInvoices',
  GET_REVIEW_INVOICES: namespace + 'getReviewInvoices',
  GET_FILTERED_INVOICES: namespace + 'getFilteredInvoices',
  GET_INVOICE: namespace + 'getInvoice',
  GET_DB_INVOICE: namespace + 'getDBInvoice',
  GET_INVOICE_OBJECTS: namespace + 'getInvoiceObjects',
  GET_AP_PROCESSORS: namespace + 'getAPProcessors',
  GET_DIVISION_AP_PROCESSORS: namespace + 'getDivisionAPProcessors',
  GET_INVOICE_APPROVERS: namespace + 'getInvoiceApprovers',
  GET_INVOICE_DIVISION_APPROVERS: namespace + 'getInvoiceDivisionApprovers',
  GET_VENDORS: namespace + 'getVendors',
  GET_POS: namespace + 'getPOs',
  GET_IS_LOADING_MODAL: namespace + 'getLoadingModal',
  GET_IS_FETCHING_POS: namespace + 'getIsFetchingPOs',
  GET_APP_E_URL: namespace + 'getAppEUrl',
  HAS_CHANGES: namespace + 'hasChanges'
};

export const InvoiceActions = {
  SEARCH_INVOICES: namespace + 'searchInvoices',
  FETCH_INVOICE: namespace + 'searchInvoice',
  FETCH_AP_PROCESSORS: namespace + 'fetchAPProcessors',
  FETCH_DIVISION_AP_PROCESSORS: namespace + 'fetchDivisionAPProcessors',
  FETCH_INVOICE_APPROVERS: namespace + 'fetchInvoiceApprovers',
  FETCH_INVOICE_DIVISION_APPROVERS: namespace + 'fetchInvoiceDivisionApprovers',
  SEARCH_VENDORS: namespace + 'searchVendors',
  FETCH_POS: namespace + 'fetchPOs',
  SAVE_INVOICE: namespace + 'saveInvoice',
  APPROVE_INVOICE: namespace + 'approveInvoice',
  REJECT_INVOICE: namespace + 'rejectInvoice',
  REQUEST_INVOICE_APPROVAL: namespace + 'requestInvoiceApproval',
  SEND_INVOICE: namespace + 'sendInvoice',
  ADD_NEW_LINE_ITEM: namespace + 'addNewLineItem',
  REMOVE_LINE_ITEM: namespace + 'removeLineItem',
  REMOVE_INVOICE: namespace + 'removeInvoice',
  VALIDATE_FULL_INVOICE: namespace + 'validateFullInvoice',
  VALIDATE_HEADER_INVOICE: namespace + 'validateHeaderInvoice',
  FETCH_APP_E_URL: namespace + 'fetchAppEUrl',
  DOWNLOAD_PDF: namespace + 'downloadPDF'
};

export const InvoiceMutations = {
  RESET_STATE: namespace + 'resetState',
  SET_INVOICES: namespace + 'setInvoices',
  SET_HISTORY_INVOICES: namespace + 'setHistoryInvoices',
  SET_REVIEW_INVOICES: namespace + 'setReviewInvoices',
  SET_INVOICE: namespace + 'setInvoice',
  SET_DB_INVOICE: namespace + 'setDBInvoice',
  SET_FILTERED_INVOICES: namespace + 'setFilteredInvoices',
  SET_INVOICE_OBJECTS: namespace + 'setInvoiceObjects',
  CLEAR_INVOICE_OBJECTS: namespace + 'clearInvoiceObjects',
  SET_AP_PROCESSORS: namespace + 'setAPProcessors',
  SET_DIVISION_AP_PROCESSORS: namespace + 'setDivisionAPProcessors',
  SET_INVOICE_APPROVERS: namespace + 'setInvoiceApprovers',
  SET_INVOICE_DIVISION_APPROVERS: namespace + 'setInvoiceDivisionApprovers',
  SET_VENDORS_LIST: namespace + 'setVendorsList',
  SET_POS: namespace + 'setPOs',
  SET_COMMON_PROP: namespace + 'setCommonProp',
  SET_LINEITEM_PROP: namespace + 'setLineItemProp',
  ADD_ORIGINAL_VENDOR: namespace + 'addOriginalVendor',
  REMOVE_INVOICE: namespace + 'removeInvoice',
  IS_LOADING_MODAL: namespace + 'isLoadingModal',
  IS_FETCHING_POS: namespace + 'isFetchingPOs',
  SET_NEW_LINE_ITEM: namespace + 'setNewLineItem',
  REMOVE_LINE_ITEM: namespace + 'removeLineItem',
  UPDATE_LINE_ITEM_NUMBERS: namespace + 'updateLineItemNumbers',
  SET_APP_E_URL: namespace + 'setAppEUrl'
};
