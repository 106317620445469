import moment from 'moment';

export const STAGES = {
  SELECT_BRANCH: 0,
  EDIT_HEADER: 1,
  CREATING: 2,
  EDIT: 3,
  SAVING: 4
};

export const getDefaultState = () => {
  const nowDate = moment().toDate();

  const defaultDetails = {
    breakdownId: null,
    // Header
    branchId: null,
    branchName: null,
    driverName: null,
    driverPhoneNumber: null,
    initiatedByName: null,
    initiatedByEmail: null,
    location: null,
    complaint: null,
    recipients: [],
    breakdownReasonId: null,
    unitTowed: null,
    oneWayMileageRangeId: null,
    stopsMissed: null,
    continuedOnRoute: null,
    // Date + Times
    dateCreate: nowDate,
    dateHelpRequested: nowDate,
    dateDispatched: null,
    dateRepairCompleted: null,
    dateUnitReleased: null
  };

  return {
    stage: STAGES.SELECT_BRANCH,
    breakdownSearchResults: [],
    details: {
      ...defaultDetails
    },
    oldDetails: {
      ...defaultDetails
    }
  };
};

export default {
  ...getDefaultState()
};
