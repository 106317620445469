import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, BreakdownGetters } from './types';
import { STAGES } from '@/shared/store/breakdown/state';

const _getters = namespaceHelper.removeNamespace(namespace, BreakdownGetters);

export default {
  [_getters.GET_BREAKDOWN]: state => {
    return state.details;
  },
  [_getters.GET_STAGE]: state => {
    return state.stage;
  },
  [_getters.HAS_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    if (state.stage == STAGES.SELECT_BRANCH) return false;
    return JSON.stringify(state.details, replacer) !== JSON.stringify(state.oldDetails, replacer);
  }
};
