var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{staticClass:"border",attrs:{"striped":"","sticky-header":"63vh","no-border-collapse":"","items":_vm.operationsForecast,"fields":_vm.resultTableColumns,"per-page":_vm.pageSize,"current-page":_vm.currentPage,"filter":_vm.searchFilter,"filter-included-fields":_vm.filterOn,"sort-by":_vm.sortBy,"primary-key":"key","show-empty":"","empty-text":"No results found.","empty-filtered-text":"No results found."},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(vin)",fn:function(ref){
var item = ref.item;
return [_c('safe-hyperlink',{attrs:{"to":'units/' + item.unitId}},[_vm._v(" "+_vm._s(item.vin)+" ")])]}},{key:"cell(customerId)",fn:function(ref){
var item = ref.item;
return [_c('safe-hyperlink',{attrs:{"to":'/customers/' + item.customerId}},[_vm._v(" "+_vm._s(item.customerId)+" ")])]}},{key:"cell(currentMileageAndEngineHours)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.mileage))+" Miles "),_c('br'),_vm._v(" "+_vm._s(_vm._f("number")(item.engineHours))+" Hours ")]}},{key:"cell(location)",fn:function(ref){
var item = ref.item;
return [(item.location)?_c('div',[_vm._v(_vm._s(item.location))]):_vm._e()]}},{key:"cell(lastCompleted)",fn:function(ref){
var item = ref.item;
return [(item.lastCompletedDate)?_c('div',[_vm._v(_vm._s(_vm._f("date")(item.lastCompletedDate)))]):_vm._e(),(item.lastCompletedMileage)?_c('div',[_vm._v(_vm._s(_vm._f("number")(item.lastCompletedMileage))+" Miles")]):_vm._e(),(item.lastCompletedEngineHours)?_c('div',[_vm._v(_vm._s(_vm._f("number")(item.lastCompletedEngineHours))+" Hours")]):_vm._e()]}},{key:"cell(nextDue)",fn:function(ref){
var item = ref.item;
return [(item.nextDueDate)?_c('div',[_vm._v(_vm._s(_vm._f("date")(item.nextDueDate)))]):_vm._e(),(item.nextDueMileage)?_c('div',[_vm._v(_vm._s(_vm._f("number")(item.nextDueMileage))+" Miles")]):_vm._e(),(item.nextDueEngineHours)?_c('div',[_vm._v(_vm._s(_vm._f("number")(item.nextDueEngineHours))+" Hours")]):_vm._e()]}},{key:"cell(operationStatus)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_c('b-badge',{staticClass:"mr-1",attrs:{"variant":_vm.CssHelper.getOperationStatusCssClass(item.operationStatus)}},[_vm._v(" "+_vm._s(item.operationStatus)+" ")]),(item.operationStatus == 'Undetermined')?_c('sup',[_c('font-awesome-icon',{staticClass:"text-primary align-self-center",attrs:{"id":("undetermined_tooltip_" + (item.key)),"icon":"question-circle","size":"lg","display":"inline-block"}}),_c('b-tooltip',{attrs:{"target":("undetermined_tooltip_" + (item.key)),"triggers":"hover"}},[_vm._v(" Unable to forecast due to missing data. "+_vm._s(_vm.$isCustomer ? 'Contact Clarke/VehiCare representative to update.' : '')+" ")])],1):_vm._e()],1)]}},{key:"cell(serviceStatus)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.serviceRequest && item.serviceRequest.appointment)?_c('div',{attrs:{"id":("startDate_" + index)}},[(_vm.$isCustomer)?_c('b-badge',{style:(_vm.AppointmentCssHelper.badgeStyle(item.serviceRequest.appointment)),attrs:{"variant":"light"}},[_vm._v(" "+_vm._s(item.serviceRequest.appointment.appointmentStatus.name)+" ")]):_c('b-badge',{style:(_vm.AppointmentCssHelper.badgeStyle(item.serviceRequest.appointment)),attrs:{"href":(_vm.shopSchedulerUrl + "/schedule/" + (item.serviceRequest.appointment.branchId)),"variant":"light"}},[_vm._v(" "+_vm._s(item.serviceRequest.appointment.appointmentStatus.name)+" ")])],1):(item.serviceRequest)?_c('div',{attrs:{"id":("serviceDateRequested_" + index)}},[_c('b-badge',{style:(_vm.AppointmentCssHelper.requestBadgeStyle()),attrs:{"variant":"light"}},[_vm._v("Requested")])],1):_vm._e(),(item.serviceRequest && item.serviceRequest.appointment)?_c('b-tooltip',{attrs:{"target":("startDate_" + index),"boundary":"window","triggers":"hover"}},[_vm._v(" "+_vm._s(_vm._f("dateTime")(item.serviceRequest.appointment.startDate))+" ")]):(item.serviceRequest && item.serviceRequest.serviceDateRequested)?_c('b-tooltip',{attrs:{"target":("serviceDateRequested_" + index),"boundary":"window","triggers":"hover"}},[_vm._v(" "+_vm._s(_vm._f("date")(item.serviceRequest.serviceDateRequested))+" ")]):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('b-dropdown',{attrs:{"size":"xsm","right":"","variant":"secondary","no-caret":"","boundary":"viewport"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"ellipsis-h"}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"dropdown-item",attrs:{"to":'/units/' + item.unitId + '/scheduled-operations'}},[_vm._v("View Schedule")]),(!_vm.$isCustomer)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.openServiceOrderAside(item.unitId, item.standardOperationId)}}},[_vm._v(" Mark Complete ")]):_vm._e(),(!_vm.$isCustomer)?_c('b-link',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.goToCreateRoute(item.unitId, item.standardOperationId)}}},[_vm._v(" Create Service Order ")]):_vm._e(),(!_vm.$isCustomer && item.isCloneable)?_c('b-link',{staticClass:"dropdown-item",attrs:{"to":{
            name: 'UnitServiceOrders',
            params: { unitId: item.unitId },
            query: { filterBy: 'Cloneable' }
          }}},[_vm._v(" Clone Service Order ")]):_vm._e(),(_vm.serviceRequestEnabled)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.requestService(item.unitId)}}},[_vm._v(" "+_vm._s(_vm.$isCustomer ? 'Service Request' : 'Schedule Service')+" ")]):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }