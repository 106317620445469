import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, AttachmentGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, AttachmentGetters);

export default {
  [_getters.GET_ATTACHMENTS]: state => {
    return state.attachments;
  },
  [_getters.GET_WARRANTY_ASIDE_ATTACHMENTS]: state => {
    return state.warrantyAsideAttachments;
  },
  [_getters.GET_ATTACHMENTS_BY_KEY]: state => key => {
    return state[key];
  },
  [_getters.HAS_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    return JSON.stringify(state.attachments, replacer) !== JSON.stringify(state.dbAttachments, replacer);
  }
};
