export const namespace = 'attachment/';

export const AttachmentGetters = {
  GET_ATTACHMENTS: namespace + 'getAttachments',
  GET_WARRANTY_ASIDE_ATTACHMENTS: namespace + 'getWarrantyAsideAttachments',
  GET_ATTACHMENTS_BY_KEY: namespace + 'getAttachmentsByKey',
  HAS_CHANGES: namespace + 'hasChanges'
};

export const AttachmentActions = {
  FETCH_ATTACHMENTS: namespace + 'fetchAttachments',
  UPLOAD_ATTACHMENTS: namespace + 'uploadAttachments',
  UPDATE_ATTACHMENTS: namespace + 'updateAttachments',
  DOWNLOAD_ATTACHMENT: namespace + 'downloadAttachment',
  REMOVE_ATTACHMENT: namespace + 'removeAttachment'
};

export const AttachmentMutations = {
  RESET_STATE: namespace + 'resetState',
  SET_ATTACHMENTS: namespace + 'setAttachments',
  SET_WARRANTY_ASIDE_ATTACHMENTS: namespace + 'setWarrantyAsideAttachments',
  SET_ATTACHMENT_DESCRIPTION: namespace + 'setAttachmentDescription',
  SET_ATTACHMENT_INTERNAL_FLAG: namespace + 'setAttachmentInternalFlag'
};
