<template>
  <mobile-table :items="operationsForecast" :fields="resultTableColumns">
    <template #[`vin`]="{ item }">
      <label :class="`col-head`">VIN/Serial:</label>
      <safe-hyperlink :to="'units/' + item.unitId">
        {{ item.vin }}
      </safe-hyperlink>
    </template>
    <template #[`customerId`]="{ item }">
      <label :class="`col-head`">Customer:</label>
      <safe-hyperlink :to="'/customers/' + item.customerId">
        {{ item.customerId }} - {{ item.customerName }}
      </safe-hyperlink>
    </template>
    <template #[`operation`]="{ item }">
      <label :class="`col-head`">Operation:</label>
      <div>
        {{ item.operation }}
        <b-badge :variant="CssHelper.getOperationStatusCssClass(item.operationStatus)" class="mr-1">
          {{ item.operationStatus }}
        </b-badge>
      </div>
    </template>

    <template #[`currentMileageAndEngineHours`]="{ item }">
      <label :class="`col-head`">Current:</label>
      <div class="date-mileage-hours">
        <div>{{ item.mileage | number }} Miles</div>
        <div>{{ item.engineHours | number }} Hours</div>
      </div>
    </template>

    <template #[`lastCompleted`]="{ item }">
      <label :class="`col-head`">Last Complete:</label>
      <div class="date-mileage-hours">
        <div v-if="item.lastCompletedDate">{{ item.lastCompletedDate | date }}</div>
        <div v-if="item.lastCompletedMileage">{{ item.lastCompletedMileage | number }} Miles</div>
        <div v-if="item.lastCompletedEngineHours">{{ item.lastCompletedEngineHours | number }} Hours</div>
      </div>
    </template>

    <template #[`nextDue`]="{ item }">
      <label :class="`col-head`">Next Due:</label>
      <div class="date-mileage-hours">
        <div v-if="item.nextDueDate">{{ item.nextDueDate | date }}</div>
        <div v-if="item.nextDueMileage">{{ item.nextDueMileage | number }} Miles</div>
        <div v-if="item.nextDueEngineHours">{{ item.nextDueEngineHours | number }} Hours</div>
      </div>
    </template>
    <template #[`operationStatus`]=""><span /></template>
    <template #[`customerName`]=""><span /></template>
    <template #[`actions`]=""><span /></template>
  </mobile-table>
</template>

<script>
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
import MobileTable from '@/shared/components/MobileTableComponent';
import CssHelper from '@/shared/helpers/operation-status-css-class-helper';
import OperationsForecastMixin from '@/shared/mixins/OperationsForecastMixin';

export default {
  name: 'OperationsForecastSearchResultsTableMobile',
  components: {
    'mobile-table': MobileTable,
    'safe-hyperlink': SafeHyperlinkComponent
  },
  mixins: [OperationsForecastMixin],
  data: function () {
    return {
      CssHelper: CssHelper
    };
  }
};
</script>
