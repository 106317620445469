<template>
  <div>
    <h5>Change Unit Status</h5>
    <div v-if="isCsmOrRegionalCsm">
      <b-row no-gutters>
        <b-col>
          <span class="required-legend float-right p-0">* Required</span>
        </b-col>
      </b-row>
      <b-form-group>
        <div class="col-head">Current Status:</div>
        {{ currentStatus }}
      </b-form-group>
      <div>
        <b-form-group label-class="col-head" label="New Status:" label-for="unitStatus">
          <div class="d-flex">
            <b-form-select
              v-model="$v.newUnitStatus.statusId.$model"
              :state="$v.$dirty && hasError($v.newUnitStatus.statusId) ? false : null"
              :options="unitStatusesList"
              value-field="id"
              text-field="description"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Select New Status</b-form-select-option>
              </template>
            </b-form-select>
            <span class="required-asterisk">*</span>
          </div>
          <div v-show="$v.$dirty && hasError($v.newUnitStatus.statusId, 'required')" class="error">
            Select a Unit Status.
          </div>
        </b-form-group>
      </div>
      <div>
        <b-form-group label="Status Comments: " label-class="col-head" label-for="status-comments" class="pb-2">
          <div class="d-flex">
            <b-textarea
              id="status-comments"
              v-model="newUnitStatus.comments"
              class="overflow-auto rounded"
              size="sm"
              rows="1"
              max-rows="4"
            />
            <span class="required-placeholder"></span>
          </div>
        </b-form-group>
      </div>
      <div>
        <b-form-group label-class="col-head" label="Disposition:" label-for="disposition">
          <div class="d-flex">
            <b-form-select
              v-model="newUnitStatus.dispositionId"
              :options="dispositionsList"
              value-field="id"
              text-field="description"
            >
              <template #first>
                <b-form-select-option :value="null" disabled>Select Disposition</b-form-select-option>
              </template>
            </b-form-select>
            <span class="required-placeholder"></span>
          </div>
        </b-form-group>
      </div>
      <div v-if="showIRP" class="d-flex">
        <b-form-group>
          <div class="d-flex">
            <b-form-checkbox id="irpAttached" v-model="newUnitStatus.irp">IRP Documentation Attached</b-form-checkbox>
            <span class="required-placeholder"></span>
          </div>
        </b-form-group>
      </div>

      <b-button variant="primary" :disabled="saving" @click="confirmUpdate">
        <div v-show="saving">
          <b-spinner small></b-spinner>
          Saving...
        </div>
        <div v-show="!saving">save</div>
      </b-button>
      <div class="py-2" />
    </div>
    <div v-else>
      <b-form-group>
        <div class="col-head">Current Status:</div>
        {{ currentStatus }}
      </b-form-group>
    </div>
    <div>
      <h3>Status History</h3>
      <b-overlay :show="loading" :opacity="0.6">
        <b-table
          striped
          sticky-header="90vh"
          :items="statusHistory"
          :fields="fields"
          class="align-middle border"
          show-empty
          :empty-text="`There is no history for this unit.`"
        >
          <template #cell(newUnitStatusId)="{ item }">{{ getStatusDescription(item.newUnitStatusId) }}</template>
          <template #cell(ChangedBy)="{ item }">
            <div>
              {{ item.changedByName.toUpperCase() }}
              <br />
              {{ item.dateChanged | date }}
            </div>
          </template>
          <template #cell(dispositionId)="{ item }">
            {{ getDispositionDescription(item.dispositionId) | nullValueToNA }}
          </template>
          <template #cell(IRP)="{ item }">
            <div><img v-if="item.irp" src="/images/unit-status-IRP.svg" /></div>
          </template>
        </b-table>
      </b-overlay>
    </div>
    <unsaved-changes-modal
      id="ConfirmUpdateWarning"
      ref="UpdateWarning"
      title="Warning"
      message="Are you sure you want to change the status of this unit?"
      continue-btn-text="Yes, Change Status"
      cancel-btn-text="Cancel"
    ></unsaved-changes-modal>
  </div>
</template>

<script>
// Components
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';
// Vuex
import { UnitGetters, UnitActions } from '@/shared/store/unit/types';
import { UnitStatusHistoryGetters, UnitStatusHistoryActions } from '@/shared/store/unit/unit-status-history/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { UserGetters } from '@/shared/store/user/types';
import { mapActions, mapGetters } from 'vuex';

import { required } from 'vuelidate/lib/validators';
import { hasError } from '@/shared/helpers/validator-helper.js';
// helpers
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';
import Cloner from '@/shared/helpers/cloner';

export default {
  name: 'UnitStatus',
  components: {
    'unsaved-changes-modal': UnsavedChangesModal
  },
  props: {
    label: String(),
    title: String()
  },
  data() {
    return {
      loading: false,
      uploadingFiles: false,
      attachmentFiles: null,
      showRemoved: false,
      updatingAttachments: false,
      hasError: hasError,
      newUnitStatus: {},
      defaultUnitStatus: {
        statusId: null,
        comments: null,
        dispositionId: null,
        irp: false
      },
      saving: false,
      fields: [
        { key: 'newUnitStatusId', label: 'New Status' },
        { key: 'ChangedBy', label: 'Changed' },
        { key: 'dispositionId', label: 'Disposition' },
        { key: 'comments', label: 'Status Comments' },
        { key: 'IRP', label: 'IRP' }
      ]
    };
  },
  validations: {
    newUnitStatus: {
      statusId: {
        required
      }
    }
  },
  computed: {
    ...mapGetters([UserGetters.AUTHORIZE_ROLE]),
    ...mapGetters({
      unit: UnitGetters.GET_UNIT,
      statusHistory: UnitStatusHistoryGetters.GET_UNIT_STATUS_HISTORY,
      unitStatusesMap: LookupGetters.GET_UNIT_STATUSES_MAP,
      unitStatusesList: LookupGetters.GET_UNIT_STATUSES_LIST,
      dispositionsMap: LookupGetters.GET_UNIT_STATUS_DISPOSITIONS_MAP,
      dispositionsList: LookupGetters.GET_UNIT_STATUS_DISPOSITIONS_LIST
    }),
    currentStatus() {
      const unitStatus = this.unitStatusesMap[this.unit.unitStatusId];
      return unitStatus?.description;
    },
    isCsmOrRegionalCsm() {
      return this[UserGetters.AUTHORIZE_ROLE](['CSM', 'RegionalCSM']);
    },
    showIRP() {
      return this.newUnitStatus.statusId != null
        ? this.getStatusDescription(this.newUnitStatus.statusId) === 'Decommissioned' ||
            this.getStatusDescription(this.newUnitStatus.statusId) === 'Decommissioned - Pending Pickup'
        : false;
    }
  },
  async created() {
    this.newUnitStatus = Cloner.deepClone(this.defaultUnitStatus);
    this.loading = true;
    try {
      await this[UnitStatusHistoryActions.FETCH_UNIT_STATUS_HISTORY](this.unit.unitId);
    } catch {
      ErrorService.createErrorToast(this, 'Error loading status history.');
    } finally {
      this.loading = false;
    }
  },
  beforeDestroy() {},
  methods: {
    ...mapActions([UnitActions.UPDATE_UNIT_STATUS, UnitStatusHistoryActions.FETCH_UNIT_STATUS_HISTORY]),
    confirmUpdate() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        ErrorService.createErrorToast(this, 'Error updating Unit Status. See indicated fields below.');
        return;
      }
      this.$refs.UpdateWarning.show(this, null, this.saveNewUnitStatus);
    },
    async saveNewUnitStatus() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        ErrorService.createErrorToast(this, 'Error updating Unit Status. See indicated fields below.');
        return;
      }
      this.saving = true;
      try {
        await this[UnitActions.UPDATE_UNIT_STATUS](this.newUnitStatus);
        this.newUnitStatus = Cloner.deepClone(this.defaultUnitStatus);
        this.$nextTick(function () {
          this.$v.$reset();
        });
        SuccessService.createSuccessToast(this.$root, 'Unit Status saved successfully.');
      } catch (err) {
        ErrorService.createErrorToast(this.$root, 'Error updating unit status.');
      } finally {
        this.saving = false;
      }
    },
    getStatusDescription(unitStatusId) {
      const unitStatus = this.unitStatusesMap[unitStatusId];
      return unitStatus?.description;
    },
    getDispositionDescription(dispositionId) {
      if (dispositionId == null || dispositionId == 0) return null;
      const disposition = this.dispositionsMap[dispositionId];
      return disposition?.description;
    },
    hasChanges() {
      return JSON.stringify(this.defaultUnitStatus) !== JSON.stringify(this.newUnitStatus);
    }
  }
};
</script>
