function createSuccessToast(that, successMessage) {
  that.$bvToast.toast(successMessage, {
    title: 'Success',
    autoHideDelay: 5000,
    toaster: 'b-toaster-top-center',
    variant: 'success',
    appendToast: true,
    solid: true
  });
}

export default {
  createSuccessToast
};
