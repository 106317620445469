export const namespace = 'warranty/';

export const WarrantyGetters = {
  GET_WARRANTY_SEARCH_RESULTS: namespace + 'getWarrantySearchResults',
  GET_SALES_ORDER: namespace + 'getSalesOrder',
  GET_SUBMISSION: namespace + 'getSubmission',
  GET_STATUS: namespace + 'getStatus',
  GET_OLD_STATUS: namespace + 'getOldStatus',
  CHANGED_STATUS: namespace + 'changedStatus',
  GET_RECIPIENT_LIST: namespace + 'getRecipientList',
  GET_WARRANTY_ADMINS: namespace + 'getWarrantyAdmins',
  HAS_CHANGES: namespace + 'hasChanges'
};

export const WarrantyActions = {
  SEARCH_WARRANTIES: namespace + 'searchWarranties',
  FETCH_SUBMISSION: namespace + 'fetchSubmission',
  CREATE_SUBMISSION: namespace + 'createSubmission',
  UPDATE_SUBMISSION: namespace + 'updateSubmission',
  FETCH_WARRANTY_RECIPIENTS_BY_BRANCH: namespace + 'fetchWarrantyRecipientsByBranch',
  FETCH_WARRANTY_ADMINS: namespace + 'fetchWarrantyAdmins'
};

export const WarrantyMutations = {
  RESET_STATE: namespace + 'resetState',
  SET_WARRANTY_SEARCH_RESULTS: namespace + 'setWarrantySearchResults',
  SET_SUBMISSION_PROP: namespace + 'setSubmissionProp',
  SET_SUBMISSION: namespace + 'setSubmission',
  SET_RECIPIENT_LIST: namespace + 'setRecipientList',
  SET_WARRANTY_ADMINS: namespace + 'setWarrantyAdmins'
};
