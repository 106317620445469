import axios from 'axios';
const PRINT_PATH = '/api/print';

class PrintService {
  async printForm(parameters) {
    const inspectionId = encodeURIComponent(parameters.inspectionId);
    const serviceOrderId = encodeURIComponent(parameters.serviceOrderId);
    const printFormId = encodeURIComponent(parameters.printFormId);

    let url = `${PRINT_PATH}/${printFormId}/`;
    if (parameters.inspectionId) {
      url += `inspection/${inspectionId}`;
    }
    if (parameters.serviceOrderId) {
      url += `service-order/${serviceOrderId}`;
    }

    const printServiceOrderResponse = await axios.post(url, parameters, {
      responseType: 'blob'
    });
    return printServiceOrderResponse;
  }
}

export default new PrintService(); // singleton object
