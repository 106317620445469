<template>
  <div v-if="employee">
    <div v-if="isList">
      <span v-for="(emp, index) in employee" :key="emp.employeeId">
        <span class="text-capitalize">{{ employeeName(emp) }}</span>
        <span v-if="showEmail">{{ ' ' + employeeEmail(emp) }}</span>
        <span v-if="index != last">{{ ', ' }}</span>
      </span>
    </div>
    <div v-else>
      <span class="text-capitalize">{{ employeeName(employee) }}</span>
      <span v-if="showEmail">{{ ' ' + employeeEmail(employee) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmployeeDisplay',
  props: {
    employee: {
      type: [Object, Array],
      default: null
    },
    showEmail: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isList() {
      return Array.isArray(this.employee);
    },
    last() {
      return Object.keys(this.employee).length - 1;
    }
  },
  methods: {
    employeeName(emp) {
      return emp.name ? emp.name.toLowerCase() : '';
    },
    employeeEmail(emp) {
      if (!emp.emailAddress) return '';
      if (!emp.name) return emp.emailAddress;
      return `<${emp.emailAddress}>`;
    }
  }
};
</script>
