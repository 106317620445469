// vuex
import { LookupGetters } from '@/shared/store/lookup/types';

export default {
  computed: {
    branchField() {
      return {
        key: 'branches',
        label: 'Branch',
        placeholder: 'Select Branches',
        options: this.$store.getters[LookupGetters.GET_MY_BRANCHES_LIST]
      };
    },
    customerField() {
      return { key: 'customer', label: 'Customer', placeholder: 'Name or ID' };
    },
    myCustomersField() {
      return {
        key: 'customerIds',
        label: 'Customer',
        placeholder: 'Select Customers',
        reduce: x => x.id,
        options: this.$store.getters[LookupGetters.GET_CUSTOMER_ID_NAME_LIST].filter(l => !l.inactive)
      };
    },
    fleetUnitIdField() {
      return { key: 'fleetUnitId', label: 'Fleet Unit Id' };
    },
    licensePlateField() {
      return { key: 'licensePlate', label: 'License Plate' };
    },
    locationsField() {
      return {
        key: 'locations',
        label: 'Location',
        placeholder: 'Select Locations',
        options: this.$store.getters[LookupGetters.GET_LOCATIONS_LIST].filter(l => !l.inactive)
      };
    },
    myLocationsField() {
      return {
        key: 'locations',
        label: 'Location',
        placeholder: 'Select Locations',
        options: this.$store.getters[LookupGetters.GET_UNIT_LOCATIONS_LIST].filter(l => !l.inactive)
      };
    },
    serviceOrderIdField() {
      return { key: 'serviceOrderId', label: 'Service Order ID' };
    },
    vinField() {
      return { key: 'vin', label: 'VIN/Serial' };
    },
    unitTypesField() {
      return {
        key: 'unitTypes',
        label: 'Unit Type',
        placeholder: 'Select Unit Types',
        options: this.$store.getters[LookupGetters.GET_UNIT_TYPES_LIST]
      };
    },
    unitStatusesField() {
      return {
        key: 'unitStatuses',
        label: 'Unit Status',
        placeholder: 'Select Unit Statuses',
        options: this.$store.getters[LookupGetters.GET_UNIT_STATUSES_LIST]
      };
    },
    requestedByField() {
      return { key: 'requestedBy', label: 'Requested By' };
    }
  }
};
