import axios from 'axios';

const API = '/api';
const NOTES_PATH_ENDING = 'notes';

class NoteService {
  async getNotes(parameters) {
    const url = `${API}${parameters.route}/${NOTES_PATH_ENDING}`;
    const notes = await axios.get(url);
    return notes;
  }

  async postServiceOrderNote(parameters) {
    const url = `${API}${parameters.route}/${NOTES_PATH_ENDING}`;
    const notes = await axios.post(url, parameters.note);
    return notes;
  }
}

export default new NoteService();
