import DataHelper from '@/shared/helpers/data-helper';

function shallowClone(value) {
  return Object.assign({}, value);
}

function deepClone(value) {
  return JSON.parse(JSON.stringify(value), DataHelper.dateReviver);
}

export default {
  shallowClone,
  deepClone
};
