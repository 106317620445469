import * as msal from '@azure/msal-browser';

const $msal = {
  install(Vue, msalConfig) {
    const b2cPolicies = msalConfig.b2cPolicies;
    msalConfig.auth.authority = b2cPolicies.authority + b2cPolicies.signIn;
    const msalInstance = new msal.PublicClientApplication(msalConfig);

    msalInstance.events = new Vue();

    // Register Callbacks for Redirect flow
    msalInstance
      .handleRedirectPromise()
      .then(handleResponse)
      .catch(error => {
        msalInstance.events.$emit('login-error', error);
      });

    function handleResponse(resp) {
      if (resp !== null) {
        msalInstance.setAccount(resp.account);
        msalInstance.events.$emit('login-success', resp.account);
      } else {
        const activeAccount = msalInstance.selectAccount();
        msalInstance.events.$emit('login-success', activeAccount);
      }
    }

    msalInstance.signIn = function () {
      return msalInstance
        .loginRedirect(msalConfig.loginRequest)
        .catch(error => msalInstance.events.$emit('login-error', error));
    };

    msalInstance.signOut = function () {
      msalInstance.logoutRedirect();
    };

    msalInstance.selectAccount = function () {
      if (msalInstance.accountId) return msalInstance.getAccountByHomeId(msalInstance.accountId);
      const currentAccounts = msalInstance.getAllAccounts();
      if (!currentAccounts || currentAccounts.length < 1) {
        return null;
      } else if (currentAccounts.length > 1) {
        // Add choose account code here
      } else if (currentAccounts.length === 1) {
        msalInstance.setAccount(currentAccounts[0]);
        return currentAccounts[0];
      }
    };

    msalInstance.setAccount = function (account) {
      if (account) {
        msalInstance.accountId = account.homeAccountId;
        msalInstance.setActiveAccount(account);
      }
    };

    // This function can be removed if you do not need to support IE
    msalInstance.getToken = async function () {
      const request = msalConfig.loginRequest;
      request.account = msalInstance.selectAccount();
      return await msalInstance.acquireTokenSilent(request).catch(async error => {
        if (error instanceof msal.InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          msalInstance.acquireTokenRedirect(request);
        } else {
          msalInstance.events.$emit('login-error', error);
        }
      });
    };

    msalInstance.isAuthenticated = function () {
      const account = msalInstance.selectAccount();
      if (!account) return false;
      msalInstance.accountId = account.homeAccountId;
      const timestamp = Math.floor(new Date().getTime() / 1000);
      return account && account.idTokenClaims && account.idTokenClaims.exp > timestamp;
    };

    Vue.prototype.$msal = msalInstance;
  }
};

export default $msal;

// Create the main msalInstance instance
// configuration parameters are located at authConfig.js
