export const getDefaultState = () => {
  const defaultUnit = {
    vin: null,
    unitTypeId: null,
    unitStatusId: null,
    mileage: null,
    engineHours: null,
    dateInService: null,
    leaseExpirationDate: null,
    purchaseDate: null,
    registrationExpirationDate: null,
    powerSource: {},
    transmission: {},
    attributeMap: null,
    attributes: []
  };

  return {
    unit: {
      ...defaultUnit
    },
    dbUnit: {
      ...defaultUnit
    },
    unitOperationsForecast: [],
    unitServiceOrderHistory: [],
    unitDeferredJobs: [],
    unitStandardOperation: {},
    unitStandardOperations: [],
    unitInspections: [],
    unitDvirDefects: [],
    unitServiceOrdersAsideShow: false,
    loading: []
  };
};

export default {
  ...getDefaultState()
};
