<template>
  <div class="mt-4 pt-3 d-flex justify-content-between">
    <div v-if="unit != null" class="flex w-100 mr-2">
      <form>
        <b-row no-gutters>
          <b-col lg="6" md="12" sm="12" class="pr-3">
            <div v-if="!readonly" class="text-right mb-2">
              <span class="required-legend">* Required</span>
            </div>
            <unit-edit-component ref="unitEdit" v-bind="{ readonly, labelColsLg, labelColsSm }" />
          </b-col>

          <b-col lg="6" md="12" sm="12" class="pb-2">
            <div v-if="!readonly" class="text-right mb-2 mr-3">
              <span class="required-legend">&nbsp;</span>
            </div>
            <div v-if="isOutOfService" class="unit-out-of-service">
              UNIT OUT OF SERVICE
              <sup>
                <font-awesome-icon
                  id="oos_tooltip"
                  icon="info-circle"
                  class="mx-1 text-primary align-self-center"
                  display="inline-block"
                />
                <b-tooltip target="oos_tooltip" triggers="hover">
                  <div class="d-flex">
                    <span>Added by&nbsp;</span>
                    <employee-display :employee="outOfService.employeeCreate" :show-email="false" />
                  </div>
                  {{ outOfService.dateCreate | dateTime }}
                </b-tooltip>
              </sup>
            </div>

            <unit-attributes ref="unitAttributes" v-bind="{ readonly, labelColsLg, labelColsSm }" />
          </b-col>
        </b-row>

        <b-row v-if="allowCustomersAndTechsToEdit" no-gutters class="col-lg-6 col-12 pr-3">
          <div class="form-row form-group w-100">
            <b-col :offset-lg="labelColsLg">
              <div v-show="isBusy">
                <b-button size="lg" variant="primary" disabled>
                  <b-spinner small></b-spinner>
                  SAVING...
                </b-button>
              </div>
              <div v-show="!isBusy">
                <b-button variant="primary" @click="clickSave">SAVE</b-button>
              </div>
            </b-col>
          </div>
        </b-row>
      </form>
    </div>
    <div v-else>No units found.</div>
    <unsaved-changes-modal
      ref="UnsavedChangesModal"
      v-bind="{ hasChanges }"
      continue-btn-text="Continue With Unit"
    ></unsaved-changes-modal>
    <warning-modal
      id="NoLocationWarningForBreakdowns"
      ref="NoLocationWarningForBreakdowns"
      warning-text="Location assignment is required for creating breakdowns. Please contact a VehiCare representative."
      continue-btn-text="Okay"
      hide-cancel-btn
    ></warning-modal>
  </div>
</template>

<script>
// components
import UnitEditComponent from '@/shared/components/unit/UnitEditComponent';
import UnitAttributes from './UnitAttributesComponent';
import EmployeeDisplay from '@/shared/components/ui/EmployeeDisplayComponent';
// vuex
import { mapGetters } from 'vuex';
import { UnitActions, UnitGetters, UnitMutations } from '@/shared/store/unit/types';
import { OutOfServiceActions, OutOfServiceGetters } from '@/shared/store/unit/out-of-service/types';
// helpers
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';
import Cloner from '@/shared/helpers/cloner';
import DataHelper from '@/shared/helpers/data-helper';
import UnitMixin from '@/shared/mixins/UnitMixin';

export default {
  name: 'UnitDetailsPage',
  components: {
    'unsaved-changes-modal': () => import('@/shared/components/UnsavedChangesModal'),
    'warning-modal': () => import('@/shared/components/WarningModal'),
    'unit-edit-component': UnitEditComponent,
    'unit-attributes': UnitAttributes,
    'employee-display': EmployeeDisplay
  },
  mixins: [UnitMixin],
  beforeRouteLeave(to, from, next) {
    if (this.$isCustomer && to.name == 'BreakdownDetails' && !this.unit.locationId) {
      this.$refs.NoLocationWarningForBreakdowns.show(this);
    } else {
      if (this.hasChanges()) {
        const discard = (cont = true) => {
          if (cont) this.$store.commit(UnitMutations.DISCARD_CHANGES);
          next(cont);
        };
        this.$refs.UnsavedChangesModal.show(this, discard);
      } else {
        next();
      }
    }
  },
  data() {
    return {
      isBusy: false,
      labelColsLg: 4,
      labelColsSm: 12
    };
  },
  computed: {
    ...mapGetters({
      unit: UnitGetters.GET_UNIT,
      dbUnit: UnitGetters.GET_OLD_UNIT,
      unitHasChanges: UnitGetters.HAS_CHANGES,
      outOfService: OutOfServiceGetters.GET_CURRENT_OUT_OF_SERVICE,
      isOutOfService: OutOfServiceGetters.HAS_OPEN_UNIT_OUT_OF_SERVICE
    }),
    readonly() {
      return !this.isCsrOrCsm;
    }
  },
  watch: {
    dbUnit: {
      async handler(newUnit) {
        if (newUnit.unitId) {
          await this.$store.dispatch(OutOfServiceActions.FETCH_UNIT_OUT_OF_SERVICES, newUnit.unitId);
        }
      },
      immediate: true
    }
  },
  methods: {
    async save() {
      this.isBusy = true;
      try {
        this.$refs.unitEdit.$v.$touch();
        this.$refs.unitAttributes.$v.$touch();
        this.$refs.unitAttributes.showErrorsInCollapsedSections();

        if (this.$refs.unitEdit.$v.$anyError || this.$refs.unitAttributes.$v.$anyError) {
          const errorMessage = 'Error saving unit. Please see fields indicated below.';
          ErrorService.createErrorToast(this, errorMessage);
          return;
        }

        if (this.$refs.unitEdit.checkWarnings() == false) return;

        let unitToSave = transformUnitToSave(this.unit);
        await this.$store.dispatch(UnitActions.UPDATE_UNIT, unitToSave);

        SuccessService.createSuccessToast(this.$root, 'Unit saved successfully.');
        this.$v.$reset();
        this.$refs.unitEdit.resetWarnings();
      } catch (err) {
        let errorMessage = '';
        if (err.response.status === 409) {
          errorMessage = err.response.data.message;
        } else {
          errorMessage = 'Error saving unit.';
        }
        ErrorService.createErrorToast(this, errorMessage);
      } finally {
        this.isBusy = false;
      }
    },
    async clickSave() {
      await this.save().catch(() => {
        this.isBusy = false;
      });
    },
    hasChanges() {
      return this.allowCustomersAndTechsToEdit && this.unitHasChanges;
    }
  }
};

function transformUnitToSave(unit) {
  let clonedUnit = Cloner.shallowClone(unit);

  clonedUnit.isMileageOverride = true;
  clonedUnit.isEngineHoursOverride = true;

  clonedUnit.averageWeeklyMileage = DataHelper.transformEmptyToNull(clonedUnit.averageWeeklyMileage);
  clonedUnit.averageWeeklyEngineHours = DataHelper.transformEmptyToNull(clonedUnit.averageWeeklyEngineHours);
  clonedUnit.registrationCost = DataHelper.transformEmptyToNull(clonedUnit.registrationCost);
  clonedUnit.leaseAmount = DataHelper.transformEmptyToNull(clonedUnit.leaseAmount);

  return clonedUnit;
}
</script>
