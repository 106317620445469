<template>
  <div>
    <h5>Request Approval</h5>
    <h6 class="text-uppercase">Estimate ID: {{ serviceOrderId }}</h6>
    <b-row no-gutters>
      <b-col sm="12" lg="6">
        <span class="required-legend float-right p-0">* Required</span>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col sm="12" lg="6">
        <b-form-group label="Approver:" label-class="col-head" label-for="approver">
          <b-input-group>
            <employee-select
              id="approver"
              v-model="approver"
              :options="approverList"
              :placeholder="approverPlaceholder"
              :class="$v.approver.$error && !$v.approver.required ? 'is-invalid' : ''"
              no-options-text="No approvers available."
            />
            <span class="required-asterisk">*</span>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col>
        <b-form-group label="Notify:" label-class="col-head" class="required-placeholder">
          <b-input-group>
            <employee-select v-model="recipients" multiple :options="recipientList" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col>
        <b-form-group label="Comments:" label-class="col-head" label-for="comments" class="required-placeholder">
          <b-textarea id="comments" v-model="comments" class="overflow-auto" size="sm" rows="3" max-rows="4" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col md="12" lg="6" xl="5">
        <date-time-input
          id="asideDatePromise"
          v-model="$v.datePromise.$model"
          label="Promised (ETR):"
          label-class="col-head"
          :validator="$v.datePromise"
          :readonly="readonly"
          required
        />
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col>
        <div class="required-placeholder">
          <warning-alert v-show="noEstimateJobWarning" full-width>
            {{ warningMessage }} Click Send again to send approval request.
          </warning-alert>
        </div>
      </b-col>
    </b-row>

    <b-button variant="primary" :disabled="sending" @click="send">
      <div v-show="sending">
        <b-spinner small></b-spinner>
        Sending...
      </div>
      <div v-show="!sending">Send</div>
    </b-button>
  </div>
</template>

<script>
// Components
import EmployeeSelectComponent from '@/shared/components/ui/EmployeeSelectComponent';
import timeInput from '@/shared/directives/TimeInput';
import WarningAlertComponent from '@/shared/components/WarningAlertComponent';
import DateTimeInput from '@/shared/components/ui/DateTimeInput';
// Vuex
import { CustomerGetters } from '@/shared/store/customer/types';
import { ServiceOrderActions, ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
// Helpers
import { required } from 'vuelidate/lib/validators';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';
import { JOB_OPERATION_STATUS_IDS } from '@/shared/store/lookup/state';

export default {
  name: 'ServiceOrderRequestApproval',
  components: {
    'employee-select': EmployeeSelectComponent,
    'warning-alert': WarningAlertComponent,
    'date-time-input': DateTimeInput
  },
  directives: {
    timeInput
  },
  data() {
    return {
      sending: false,
      approverPlaceholder: null,
      noEstimateJobWarning: false,
      allowOverride: true,
      warningMessage: ''
    };
  },
  validations: {
    approver: {
      required
    },
    datePromise: {
      // minValue: minValue(minimumDate()),
      required
    }
  },
  computed: {
    ...mapState('unit', ['unit']),
    ...mapGetters({
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      branchId: ServiceOrderGetters.GET_BRANCH_ID,
      readonly: ServiceOrderGetters.GET_READONLY,
      requestApproval: ServiceOrderGetters.GET_REQUEST_APPROVAL,
      approverList: CustomerGetters.GET_APPROVER_LIST,
      jobs: ServiceOrderGetters.GET_JOBS,
      vcpUserList: CustomerGetters.GET_VCP_USER_LIST,
      employeeList: LookupGetters.GET_EMPLOYEE_LIST
    }),
    serviceOrderId() {
      return this.serviceOrder.serviceOrderId;
    },
    timestampSVSLS() {
      return this.serviceOrder.timestampSVSLS;
    },
    hasEstimateJob() {
      return (
        this.jobs &&
        Object.values(this.jobs).some(job => job.jobOperationStatusId === JOB_OPERATION_STATUS_IDS.ESTIMATE)
      );
    },
    recipientList() {
      return [...this.employeeList, ...this.vcpUserList];
    },
    approver: {
      get() {
        return this.requestApproval.approver;
      },
      set(value) {
        this.setData('approver', value);
      }
    },
    recipients: {
      get() {
        return this.requestApproval.recipients;
      },
      set(value) {
        this.setData('recipients', value);
      }
    },
    comments: {
      get() {
        return this.requestApproval.comments;
      },
      set(value) {
        this.setData('comments', value);
      }
    },
    datePromise: {
      get() {
        return this.requestApproval.datePromise;
      },
      set(value) {
        if (value == this.datePromise) return;
        this.setData('datePromise', value);
      }
    }
  },
  methods: {
    ...mapActions([ServiceOrderActions.SEND_APPROVAL_REQUEST]),
    ...mapMutations([ServiceOrderMutations.SET_PROP]),
    async send() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.approverPlaceholder = 'Select Approver';
        ErrorService.createErrorToast(this, 'Error requesting approval. See indicated fields below.');
        return;
      }
      this.checkWarnings();
      if (!this.allowOverride) {
        this.allowOverride = true;
        return;
      }
      try {
        this.sending = true;
        await this[ServiceOrderActions.SEND_APPROVAL_REQUEST]({
          approver: this.approver,
          recipients: this.recipients,
          comments: this.comments,
          datePromise: this.datePromise,
          timestampSVSLS: this.timestampSVSLS
        });
        SuccessService.createSuccessToast(this.$root, `Request for Approval email successfully sent.`);
        this.resetForm();
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to send approval request.');
      } finally {
        this.sending = false;
      }
    },
    resetForm() {
      this.approverPlaceholder = null;
      this.noEstimateJobWarning = false;
      this.allowOverride = true;
      this.$v.$reset();
    },
    setData(key, newValue) {
      let value = { ...this.requestApproval };
      value[key] = newValue;
      this[ServiceOrderMutations.SET_PROP]({ key: 'requestApproval', value });
    },
    checkWarnings() {
      if (!this.hasEstimateJob && this.noEstimateJobWarning == false) {
        this.noEstimateJobWarning = true;
        this.warningMessage =
          Object.values(this.jobs).length > 0 ? 'No jobs with Estimate status.' : 'No jobs added on this Estimate.';
        this.allowOverride = false;
      }
    }
  }
};
</script>
