import axios from 'axios';

const STANDARD_OPERATIONS_FORECAST_PATH = '/api/operations-forecast';

class OperationsForecastService {
  async getOperationsForecast(parameters) {
    const url = STANDARD_OPERATIONS_FORECAST_PATH;
    const operationsForecastResponse = await axios.get(url, {
      params: parameters
    });
    return operationsForecastResponse;
  }
}

export default new OperationsForecastService(); // singleton object
