import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, NoteGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, NoteGetters);

export default {
  [_getters.GET_NOTES]: state => {
    return state.notes;
  },
  [_getters.GET_NEW_NOTE]: state => {
    return state.newNote;
  },
  [_getters.GET_OLD_NOTE]: state => {
    return state.oldNote;
  },
  [_getters.HAS_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    return JSON.stringify(state.oldNote, replacer) !== JSON.stringify(state.newNote, replacer);
  }
};
