<template>
  <b-row>
    <div class="d-flex flex-row flex-wrap align-content-start align-items-stretch no-gutters">
      <slot name="start"></slot>
      <div class="mr-4">
        <label class="col-head">Fleet Unit ID:</label>
        <h3>{{ unit.fleetUnitId }}</h3>
      </div>
      <div class="mr-4">
        <label class="col-head">Location:</label>
        <h3>{{ getUnitLocationName }}</h3>
      </div>
      <div class="mr-4">
        <label class="col-head">Customer:</label>
        <h3>{{ unit.customerName }}</h3>
      </div>
      <div class="mr-4">
        <label class="col-head">Customer ID:</label>
        <router-link v-if="!$isCustomer" :to="'/customers/' + unit.customerId">
          <h3>{{ unit.customerId }}</h3>
        </router-link>
        <div v-else>
          <h3>{{ unit.customerId }}</h3>
        </div>
      </div>
      <div class="mr-4">
        <label class="col-head">VIN/Serial:</label>
        <font-awesome-icon
          id="unit_tooltip_icon"
          icon="info-circle"
          class="mx-1 text-primary align-self-center"
          display="inline-block"
        />
        <b-tooltip id="unit_tooltip" target="unit_tooltip_icon" triggers="hover">
          <div>
            {{ unit.year }}
            {{ unit.make }}
            {{ unit.model }}
          </div>
        </b-tooltip>
        <h3>
          <router-link v-if="vinAsLink" :to="'/units/' + unit.unitId">
            {{ unit.vin }}
          </router-link>
          <span v-else>{{ unit.vin }}</span>
        </h3>
      </div>
      <customer-attributes></customer-attributes>
      <div class="d-inline-block">
        <div v-show="hasDueOperations">
          <b-badge id="tooltip-due" variant="warning" class="w16px mr-1">!</b-badge>
          <b-tooltip target="tooltip-due" triggers="hover">Operation Due</b-tooltip>
        </div>
      </div>
      <div class="d-inline-block">
        <div v-show="hasOverdueOperations">
          <b-badge id="tooltip-overdue" variant="danger" class="w16px mr-1">!</b-badge>
          <b-tooltip target="tooltip-overdue" triggers="hover">Operation Overdue</b-tooltip>
        </div>
      </div>
      <slot></slot>
    </div>
  </b-row>
</template>

<script>
import { UnitGetters } from '@/shared/store/unit/types';
import { mapGetters, mapState } from 'vuex';
import CustomerAttributes from '@/shared/components/customer/CustomerAttributesComponent';

export default {
  name: 'UnitSummaryComponent',
  components: {
    'customer-attributes': CustomerAttributes
  },
  props: {
    vinAsLink: Boolean
  },
  computed: {
    ...mapState('unit', ['unit']),
    ...mapGetters({
      getUnitLocationName: UnitGetters.GET_UNIT_LOCATION_NAME,
      hasDueOperations: UnitGetters.HAS_DUE_OPERATIONS,
      hasOverdueOperations: UnitGetters.HAS_OVERDUE_OPERATIONS
    })
  }
};
</script>
