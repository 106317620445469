function removeNamespace(namespace, types) {
  let newNamespaces = {};
  for (let [key, value] of Object.entries(types)) {
    if (value.startsWith(namespace)) {
      newNamespaces[key] = value.substring(namespace.length);
    } else {
      newNamespaces[key] = value;
    }
  }
  return newNamespaces;
}

export default {
  removeNamespace
};
