import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, InvoiceMutations } from './types';
import { getDefaultState } from './state';
import Cloner from '@/shared/helpers/cloner';
import Vue from 'vue';

const _mutations = namespaceHelper.removeNamespace(namespace, InvoiceMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_INVOICES](state, results) {
    var type = results.parameters.category;
    state.invoices[type] = results.searchResults;
  },
  [_mutations.SET_INVOICE](state, result) {
    state.invoice = result;
    state.dbInvoice = Cloner.deepClone(result);
  },
  [_mutations.SET_DB_INVOICE](state, result) {
    state.dbInvoice = result;
  },
  [_mutations.SET_HISTORY_INVOICES](state, result) {
    state.historyInvoices = result;
  },
  [_mutations.SET_REVIEW_INVOICES](state, result) {
    state.reviewInvoices = result;
  },
  [_mutations.SET_FILTERED_INVOICES](state, list) {
    state.filteredInvoices = list;
  },
  [_mutations.SET_INVOICE_OBJECTS](state, result) {
    result.objects.forEach(x => {
      state.invoiceObjects.push(x);
    });
  },
  [_mutations.CLEAR_INVOICE_OBJECTS](state) {
    state.invoiceObjects = [];
  },
  [_mutations.SET_AP_PROCESSORS](state, result) {
    state.apProcessors = result;
  },
  [_mutations.SET_DIVISION_AP_PROCESSORS](state, result) {
    state.divisionAPProcessors = result;
  },
  [_mutations.SET_INVOICE_APPROVERS](state, result) {
    state.approvers = result;
  },
  [_mutations.SET_INVOICE_DIVISION_APPROVERS](state, result) {
    state.divisionApprovers = result;
  },
  [_mutations.SET_VENDORS_LIST](state, list) {
    state.vendorsList = list;
  },
  [_mutations.SET_POS](state, pos) {
    state.pos = pos;
  },
  [_mutations.SET_APP_E_URL](state, url) {
    state.appEUrl = url;
  },
  [_mutations.ADD_ORIGINAL_VENDOR](state) {
    state.vendorsList.push({ vendorId: state.invoice[0].vendorId, vendorName: state.invoice[0].vendorName });
  },
  [_mutations.SET_COMMON_PROP](state, { key, value }) {
    state.invoice.forEach(i => {
      i[key] = value;
    });
  },
  [_mutations.SET_LINEITEM_PROP](state, { index, key, value }) {
    Vue.set(state.invoice[index], key, value);
  },
  [_mutations.REMOVE_INVOICE](state, index) {
    Vue.delete(state.filteredInvoices, index);
  },
  [_mutations.IS_LOADING_MODAL](state, value) {
    state.loadingModal = value;
  },
  [_mutations.IS_FETCHING_POS](state, value) {
    state.fetchingPOs = value;
  },
  [_mutations.SET_NEW_LINE_ITEM](state, params) {
    var newItem = {
      apProcessor: params.commonInvoice.apProcessor,
      approver: params.commonInvoice.approver,
      approverViewModel: params.commonInvoice.approverViewModel,
      batchName: params.commonInvoice.batchName,
      checkDate: params.commonInvoice.checkDate,
      checkNumber: params.commonInvoice.checkNumber,
      checkType: params.commonInvoice.checkType,
      division: params.commonInvoice.division,
      docId: params.commonInvoice.docId,
      generalLedgerAmount: 0,
      invoiceAmount: params.commonInvoice.invoiceAmount,
      invoiceDate: params.commonInvoice.invoiceDate,
      invoiceDateTime: params.commonInvoice.invoiceDateTime,
      invoiceNumber: params.commonInvoice.invoiceNumber,
      itemNumber: params.newItemNumber,
      pages: params.commonInvoice.pages,
      processorViewModel: params.commonInvoice.processorViewModel,
      recieverNumber: params.commonInvoice.recieverNumber,
      scanDate: params.commonInvoice.scanDate,
      status: params.commonInvoice.status,
      statusDate: params.commonInvoice.statusDate,
      vendorId: params.commonInvoice.vendorId,
      vendorName: params.commonInvoice.vendorName
    };

    state.invoice.push(newItem);
  },
  [_mutations.REMOVE_LINE_ITEM](state, index) {
    Vue.delete(state.invoice, index);
  },
  [_mutations.UPDATE_LINE_ITEM_NUMBERS](state) {
    let startingInt = 10;
    let newItemInt;
    if (newItemInt == null) newItemInt = startingInt;
    state.invoice.forEach(inv => {
      let itemNumberString = newItemInt.toString().padStart(6, '0');
      inv.itemNumber = itemNumberString;
      newItemInt = newItemInt + 10;
    });
  }
};
