import namespaceHelper from '@/shared/helpers/namespace-helper';
import Cloner from '@/shared/helpers/cloner';
import { namespace, TireMutations } from './types';

import { getDefaultState } from './state';

const _mutations = namespaceHelper.removeNamespace(namespace, TireMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_TIRE_REPLACEMENTS](state, tireReplacements) {
    state.tireReplacements = tireReplacements;
  },
  [_mutations.SET_OLD_TIRE](state, payload) {
    state.oldTireReplacement = payload;
    state.dbOldTireReplacement = Cloner.deepClone(payload);
  },
  [_mutations.SET_OLD_TIRE_PROP](state, { key, value }) {
    state.oldTireReplacement[key] = value;
  },
  [_mutations.SET_NEW_TIRE](state, payload) {
    state.newTireReplacement = payload;
    state.dbNewTireReplacement = Cloner.deepClone(payload);
  },
  [_mutations.SET_NEW_TIRE_PROP](state, { key, value }) {
    state.newTireReplacement[key] = value;
  },
  [_mutations.SET_OLD_TIRE_INSPECTION_PROP](state, { key, value }) {
    state.oldTireInspectionResult[key] = value;
  },
  [_mutations.SET_NEW_TIRE_INSPECTION_PROP](state, { key, value }) {
    state.newTireInspectionResult[key] = value;
  }
};
