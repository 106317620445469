export const namespace = 'partsRequest/';

export const PartsRequestGetters = {
  GET_PARTS_REQUEST: namespace + 'getPartsRequest',
  GET_DB_PARTS_REQUEST: namespace + 'getdbPartsRequest',
  GET_REQUEST_PARTS: namespace + 'getRequestParts',
  GET_REQUESTED_PARTS: namespace + 'getRequestedParts',
  GET_ALL_OPS_EMPLOYEES: namespace + 'getAllOpsEmployees',
  GET_MATERIALS_EMPLOYEES: namespace + 'getMaterialsEmployees',
  GET_PARTS_REQUEST_SEARCH_RESULTS: namespace + 'getPartsRequestSearchResults',
  HAS_CHANGES: namespace + 'hasChanges'
};

export const PartsRequestActions = {
  SEND_PARTS_REQUEST: namespace + 'sendPartsRequest',
  SAVE_PARTS_REQUEST: namespace + 'savePartsRequest',
  FETCH_REQUESTED_PARTS: namespace + 'fetchRequestedParts',
  FETCH_PARTS_REQUEST: namespace + 'fetchPartsRequest',
  RESET_PARTS_REQUEST_FORM: namespace + 'resetPartsRequestForm',
  FETCH_ALL_OPS_EMPLOYEES: namespace + 'fetchAllOpsEmployees',
  FETCH_MATERIALS_EMPLOYEES: namespace + 'fetchMaterialsEmployees',
  SEARCH_PARTS_REQUESTS: namespace + 'searchPartsRequests'
};

export const PartsRequestMutations = {
  SET_PROP: namespace + 'setProp',
  SET_PART_PROP: namespace + 'setPartsProp',
  ADD_REQUEST_PART: namespace + 'addRequestPart',
  ADD_DB_REQUEST_PART: namespace + 'addDbRequestPart',
  REMOVE_REQUEST_PART: namespace + 'removeRequestedPart',
  SET_PARTS_REQUEST: namespace + 'setPartsRequest',
  SET_REQUESTED_PARTS: namespace + 'setRequestedParts',
  SET_ALL_OPS_EMPLOYEES: namespace + 'setAllOpsEmployees',
  SET_MATERIALS_EMPLOYEES: namespace + 'setMaterialsEmployees',
  SET_PARTS_REQUEST_SEARCH_RESULTS: namespace + 'setPartsReqeustSearchResults',
  SET_PARTS_REQUEST_PROP: namespace + 'setPartsReqeustProp'
};
