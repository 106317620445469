export const namespace = 'unit/mountedEquipment/';

export const MountedEquipmentGetters = {
  GET_MOUNTED_EQUIPMENTS: namespace + 'getMountedEquipments',
  GET_MOUNTED_EQUIPMENT: namespace + 'getMountedEquipment',
  HAS_GUARANTEED_LIFTGATE: namespace + 'hasGuaranteedLiftgate',
  HAS_NON_GUARANTEED_LIFTGATE: namespace + 'hasNonGuaranteedLiftgate',
  HAS_MOUNTED_EQUIPMENT_CHANGES: namespace + 'hasMountedEquipmentChanges',
  HAS_LIFTGATE: namespace + 'hasLiftgate',
  HAS_TEMPORARILY_EXEMPT_LIFTGATE: namespace + 'hasTemporarilyExemptLiftgate'
};

export const MountedEquipmentActions = {
  FETCH_MOUNTED_EQUIPMENTS: namespace + 'fetchMountedEquipment',
  CREATE_OR_UPDATE_MOUNTED_EQUIPMENT: namespace + 'createOrUpdateMountedEquipment',
  DELETE_MOUNTED_EQUIPMENT: namespace + 'deleteMountedEquipment'
};

export const MountedEquipmentMutations = {
  RESET_STATE: namespace + 'resetState',
  SET_MOUNTED_EQUIPMENTS: namespace + 'setMountedEquipments',
  SET_MOUNTED_EQUIPMENT: namespace + 'setMountedEquipment',
  SET_MOUNTED_EQUIPMENT_PROP: namespace + 'setMountedEquipmentProp'
};
