import namespaceHelper from '@/shared/helpers/namespace-helper';
import OutOfServiceService from '@/shared/services/OutOfServiceService';
import { namespace, OutOfServiceActions, OutOfServiceMutations } from './types';

const _actions = namespaceHelper.removeNamespace(namespace, OutOfServiceActions);
const _mutations = namespaceHelper.removeNamespace(namespace, OutOfServiceMutations);

export default {
  async [_actions.MARK_UNIT_OUT_OF_SERVICE](_, unitId, outOfService) {
    await OutOfServiceService.postUnitOutOfService(unitId, outOfService);
  },
  async [_actions.FETCH_UNIT_OUT_OF_SERVICES]({ commit }, unitId) {
    const outOfServicesResult = await OutOfServiceService.getUnitOutOfServices(unitId);
    const outOfServices = outOfServicesResult.data;
    commit(_mutations.SET_UNIT_OUT_OF_SERVICES, outOfServices);
  },
  async [_actions.UPDATE_UNIT_OUT_OF_SERVICE](_, { unitId, outOfService, notify }) {
    await OutOfServiceService.updateUnitOutOfService(unitId, outOfService, notify);
  },
  async [_actions.MARK_UNIT_BACK_IN_SERVICE](_, { unitId, backInService }) {
    await OutOfServiceService.markUnitBackInService(unitId, backInService);
  }
};
