import namespaceHelper from '@/shared/helpers/namespace-helper';
import TireService from '@/shared/services/TireService';
import { namespace, TireActions, TireMutations } from './types';

const _actions = namespaceHelper.removeNamespace(namespace, TireActions);
const _mutations = namespaceHelper.removeNamespace(namespace, TireMutations);

export default {
  async [_actions.FETCH_TIRE_REPLACEMENTS]({ commit }, params) {
    const tireReplacements = await TireService.getTireReplacements(params);
    commit(_mutations.SET_TIRE_REPLACEMENTS, tireReplacements.data);
  },
  async [_actions.SAVE_UPDATE_TIRE_REPLACEMENTS](_, tireReplacements) {
    await TireService.saveUpdateTireReplacements(tireReplacements);
  }
};
