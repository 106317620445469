import axios from 'axios';

const SCHEDULER_PATH = '/api/scheduler';
const REQUEST_SERVICE_PATH = SCHEDULER_PATH + '/request';
const APPOINTMENTS_PATH = SCHEDULER_PATH + '/appointments';
const SERVICE_REQUESTS_PATH = SCHEDULER_PATH + '/service-requests';

const CUSTOMER_SOURCE = 0;
const VAMS_SOURCE = 1;

class SchedulerService {
  get sources() {
    return {
      customer: CUSTOMER_SOURCE,
      vams: VAMS_SOURCE
    };
  }

  async getAppointmentsForUser() {
    const url = APPOINTMENTS_PATH;
    const response = axios.get(url);
    return response;
  }

  async getServiceRequestsForUser() {
    const url = SERVICE_REQUESTS_PATH;
    const response = axios.get(url);
    return response;
  }

  async requestService(requests) {
    const url = REQUEST_SERVICE_PATH;
    const response = axios.post(url, requests);
    return response;
  }
}

export default new SchedulerService(); // singleton object
