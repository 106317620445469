import namespaceHelper from '@/shared/helpers/namespace-helper';
import Cloner from '@/shared/helpers/cloner';

import { namespace, WarrantyActions, WarrantyGetters, WarrantyMutations } from './types';
import { LookupGetters } from '@/shared/store/lookup/types';
import WarrantyService from '@/shared/services/WarrantyService';
import BranchService from '@/shared/services/BranchService';

const _actions = namespaceHelper.removeNamespace(namespace, WarrantyActions);
const _getters = namespaceHelper.removeNamespace(namespace, WarrantyGetters);
const _mutations = namespaceHelper.removeNamespace(namespace, WarrantyMutations);

export default {
  // Search
  async [_actions.SEARCH_WARRANTIES]({ commit }, parameters) {
    const response = await WarrantyService.searchWarranties(parameters);
    const searchResults = response.data;
    await commit(_mutations.SET_WARRANTY_SEARCH_RESULTS, searchResults);
    return searchResults;
  },
  async [_actions.FETCH_SUBMISSION]({ commit, rootGetters }, warrantySubmissionId) {
    const response = await WarrantyService.fetchSubmission(warrantySubmissionId);
    const submission = response.data;
    if (submission.warrantyDocuments == null || submission.warrantyDocuments.length == 0) {
      submission.warrantyDocuments = rootGetters[LookupGetters.GET_WARRANTY_DOCUMENT_TYPES_LIST];
    }
    await commit(_mutations.SET_SUBMISSION, submission);
  },
  async [_actions.CREATE_SUBMISSION]({ commit, rootGetters }, submission) {
    const response = await WarrantyService.createSubmission(submission);
    const createdSubmission = response.data;
    if (createdSubmission.warrantyDocuments == null || createdSubmission.warrantyDocuments.length == 0) {
      createdSubmission.warrantyDocuments = rootGetters[LookupGetters.GET_WARRANTY_DOCUMENT_TYPES_LIST];
    }
    await commit(_mutations.SET_SUBMISSION, createdSubmission);
  },
  async [_actions.UPDATE_SUBMISSION]({ commit, getters }, { nextStatusId, notify }) {
    const submission = Cloner.deepClone(getters[_getters.GET_SUBMISSION]);
    if (nextStatusId) submission.warrantyStatusId = nextStatusId;
    if (notify) submission.notify = notify;
    const updatedSubmission = await WarrantyService.updateSubmission(submission);
    await commit(_mutations.SET_SUBMISSION, updatedSubmission.data);
  },
  async [_actions.FETCH_WARRANTY_RECIPIENTS_BY_BRANCH]({ commit }, branchId) {
    const response = await BranchService.getWarrantyRecipients(branchId);
    commit(_mutations.SET_RECIPIENT_LIST, response.data);
  },
  async [_actions.FETCH_WARRANTY_ADMINS]({ commit }) {
    const response = await WarrantyService.getWarrantyAdmins();
    commit(_mutations.SET_WARRANTY_ADMINS, response.data);
  }
};
