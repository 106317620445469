export const STAGES = {
  CREATE: 0,
  CREATING: 1,
  EDIT: 2,
  SAVING: 3,
  ATTEMPT_COMPLETE: 4,
  COMPLETING: 5,
  READONLY: 99
};

export const getDefaultState = () => {
  const defaultInspection = {
    unitInspectionId: null,
    serviceOrderId: null,
    operationsId: null,
    sections: []
  };
  return {
    stage: STAGES.CREATE,
    inspectionSearchResults: [],
    details: {
      ...defaultInspection
    },
    dbDetails: {
      ...defaultInspection
    },
    itemMap: {},
    axleInspections: null,
    tireItemMap: {},
    brakeItemMap: {}
  };
};

export default {
  ...getDefaultState()
};
