import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, OutOfServiceGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, OutOfServiceGetters);

export default {
  [_getters.GET_UNIT_OUT_OF_SERVICES]: state => {
    return state.unitOutOfServices;
  },
  [_getters.GET_CURRENT_OUT_OF_SERVICE]: state => {
    return state.currentOutOfService;
  },
  [_getters.GET_BACK_IN_SERVICE]: state => {
    return state.backInService;
  },
  [_getters.HAS_OPEN_UNIT_OUT_OF_SERVICE]: state => {
    return state.unitOutOfServices.filter(outOfService => outOfService.dateBackInService === null).length > 0;
  },
  [_getters.HAS_CURRENT_OOS_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    return (
      JSON.stringify(state.currentOutOfService, replacer) !== JSON.stringify(state.oldCurrentOutOfService, replacer)
    );
  },
  [_getters.HAS_BACK_IN_SERVICE_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    return JSON.stringify(state.backInService, replacer) !== JSON.stringify(state.oldBackInService, replacer);
  },
  [_getters.HAS_ANY_CHANGES]: (state, getters) => {
    return getters[_getters.HAS_CURRENT_OOS_CHANGES] || getters[_getters.HAS_BACK_IN_SERVICE_CHANGES];
  }
};
