export const namespace = 'breakdown/';

export const BreakdownGetters = {
  GET_BREAKDOWN: namespace + 'getBreakdown',
  GET_STAGE: namespace + 'getStage',
  HAS_CHANGES: namespace + 'hasChanges'
};

export const BreakdownActions = {
  SEARCH_BREAKDOWNS: namespace + 'searchBreakdowns',
  SWITCH_STAGE: namespace + 'switchStage',
  FETCH_BREAKDOWN: namespace + 'fetchBreakdown',
  CREATE_BREAKDOWN: namespace + 'createBreakdown',
  UPDATE_BREAKDOWN: namespace + 'updateBreakdown',
  EXPORT_BREAKDOWN_REPORT: namespace + 'exportBreakdown'
};

export const BreakdownMutations = {
  SET_BREAKDOWN_SEARCH_RESULTS: namespace + 'setBreakdownSearchResults',
  RESET_STATE: namespace + 'resetState',
  SET_STAGE: namespace + 'setStage',
  SET_PROP: namespace + 'setProp',
  DEFAULT_PROP: namespace + 'defaultProp',
  SET_BREAKDOWN: namespace + 'setBreakdown'
};
