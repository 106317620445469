import DataHelper from '@/shared/helpers/data-helper';

const responseTransformers = [
  (data, headers) => {
    if (data && typeof data === 'string' && headers['content-type'].includes('application/json')) {
      try {
        const result = JSON.parse(data, DataHelper.dateReviver);
        return result;
      } catch (e) {
        /* Ignore */
      }
    }
    return data;
  }
];

export default {
  responseTransformers
};
