import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, CustomerActions, CustomerMutations } from './types';
import CustomerService from '@/shared/services/CustomerService';

const _actions = namespaceHelper.removeNamespace(namespace, CustomerActions);
const _mutations = namespaceHelper.removeNamespace(namespace, CustomerMutations);

export default {
  async [_actions.SEARCH_CUSTOMERS]({ commit }, parameters) {
    const customers = await CustomerService.searchCustomers(parameters);
    commit(_mutations.SET_CUSTOMER_SEARCH_RESULTS, customers.data);
  },
  async [_actions.FETCH_CUSTOMER]({ commit }, customerId) {
    const customer = await CustomerService.getCustomer(customerId);
    commit(_mutations.SET_CUSTOMER, customer.data);
    return customer.data;
  },
  async [_actions.FETCH_APPROVERS_FOR_CUSTOMER]({ commit }, customerId) {
    const response = await CustomerService.getApprovers(customerId);
    commit(_mutations.SET_APPROVER_LIST, response.data);
  },
  async [_actions.FETCH_USERS_FOR_CUSTOMER]({ commit }, customerId) {
    const response = await CustomerService.getUsers(customerId);
    commit(_mutations.SET_VCP_USER_LIST, response.data);
  },
  async [_actions.CREATE_CUSTOMER]({ commit }, params) {
    const response = await CustomerService.createCustomer(params);
    commit(_mutations.SET_CUSTOMER, response.data);
    return response.data;
  },
  async [_actions.SAVE_CUSTOMER]({ commit }, customer) {
    let updatedCustomerResult = await CustomerService.updateCustomer(customer);
    commit(_mutations.SET_CUSTOMER, updatedCustomerResult.data);
  }
};
