<template>
  <div>
    <h5>Transfer Unit</h5>
    <div v-if="isCsrOrCsmOrRegionalCsm">
      <b-row no-gutters>
        <b-col>
          <span class="required-legend float-right p-0">* Required</span>
        </b-col>
      </b-row>
      <!--Transfer Type-->
      <b-form-group label-class="col-head" label="Transfer Type:" label-for="transfertype">
        <div class="d-flex">
          <v-select
            v-model="$v.newUnitTransfer.transferType.$model"
            label="description"
            placeholder="Select Type"
            :options="transferTypes"
            :clearable="false"
            select-on-tab
            @input="changeTransferType"
          >
            <template #selected-option="l">
              <span class="text-uppercase">{{ l.description }}</span>
            </template>
          </v-select>
          <span class="required-asterisk">*</span>
        </div>
      </b-form-group>
      <!--Current Location-->
      <div v-if="showLocation">
        <b-form-group>
          <div class="col-head">Current Location:</div>
          {{ location ? location.description : 'NONE' }}
        </b-form-group>
      </div>
      <!--Current Customer-->
      <div v-if="showCustomer">
        <b-form-group>
          <div class="col-head">Current Customer:</div>
          {{ unit.customerId }} - {{ unit.customerName }}
        </b-form-group>
      </div>
      <!--New Location-->
      <div v-if="showLocation">
        <b-form-group label-class="col-head" label="New Location:" label-for="newlocation">
          <div class="d-flex">
            <v-select
              id="newlocation"
              v-model="$v.newUnitTransfer.locationId.$model"
              :class="`${hasError($v.newUnitTransfer.locationId) ? 'is-invalid' : ''}`"
              label="description"
              placeholder="Select New Location"
              :options="activeUnitLocations"
              :reduce="l => l.id"
              :clearable="false"
              :required="showLocation"
              select-on-tab
            >
              <template #selected-option="l">
                <span class="text-uppercase">{{ l.description }}</span>
              </template>
            </v-select>
            <span class="required-asterisk">*</span>
          </div>
          <div v-show="$v.$dirty && hasError($v.newUnitTransfer.locationId, 'required') && showLocation" class="error">
            Select a Location.
          </div>
        </b-form-group>
      </div>
      <!--New Customer-->
      <div v-if="showCustomer">
        <customer-search-input
          v-model="$v.newUnitTransfer.cusId.$model"
          :value="customerId"
          :select-class="`${hasError($v.newUnitTransfer.cusId) ? 'is-invalid' : ''}`"
          label="New Customer:"
          :required="showCustomer"
          @input="selectCustomer"
        >
          <template #error>
            <div v-show="$v.$dirty && hasError($v.newUnitTransfer.cusId, 'required') && showCustomer" class="error">
              Enter a Customer.
            </div>
          </template>
        </customer-search-input>
      </div>
      <!--Comments-->
      <div v-if="showCustomer || showLocation">
        <b-form-group label="Transfer Comments: " label-class="col-head" label-for="transfer-comments" class="pb-2">
          <div class="d-flex">
            <b-textarea
              id="transfer-comments"
              v-model="newUnitTransfer.comments"
              class="overflow-auto rounded"
              size="sm"
              rows="1"
              max-rows="4"
            />
            <span class="required-placeholder"></span>
          </div>
        </b-form-group>
      </div>
      <div v-if="showCustomer || showLocation">
        <b-button variant="primary" :disabled="saving" @click="confirmUpdate">
          <div v-show="saving">
            <b-spinner small></b-spinner>
            Saving...
          </div>
          <div v-show="!saving">save</div>
        </b-button>
      </div>
      <div class="py-2" />
    </div>
    <div v-else>
      <!--Current Location-->
      <div>
        <b-form-group>
          <div class="col-head">Current Location:</div>
          {{ location ? location.description : 'NONE' }}
        </b-form-group>
      </div>
      <!--Current Customer-->
      <div v-if="!$isCustomer">
        <b-form-group>
          <div class="col-head">Current Customer:</div>
          {{ unit.customerId }} - {{ unit.customerName }}
        </b-form-group>
      </div>
    </div>
    <div>
      <h3>Transfer History</h3>
      <b-overlay :show="loading" :opacity="0.6">
        <b-table
          striped
          sticky-header="90vh"
          :items="locationHistory"
          :fields="fields"
          class="align-middle border"
          show-empty
          :empty-text="`There is no history for this unit.`"
        >
          <template #cell(NewUnitLocationId)="{ item }">
            {{ item.newUnitLocationId === '' ? '' : getLocationDescription(item.newUnitLocationId) }}
          </template>
          <template #cell(NewCustomer)="{ item }">
            {{ item.newUnitCustomerId }}
          </template>
          <template #cell(ChangedBy)="{ item }">
            <div>
              <employee-display :employee="{ name: item.changedByName }" :show-email="false" />
              {{ item.dateChanged | date }}
            </div>
          </template>
        </b-table>
      </b-overlay>
    </div>
    <unsaved-changes-modal
      id="ConfirmUpdateWarning"
      ref="UpdateWarning"
      title="Warning"
      message="Are you sure you want to tranfer this unit?"
      continue-btn-text="Yes, Transfer"
      cancel-btn-text="Cancel"
    ></unsaved-changes-modal>
  </div>
</template>

<script>
// Components
import CustomerSearchInput from '@/shared/components/customer/CustomerSearchInput';
import EmployeeDisplayComponent from '@/shared/components/ui/EmployeeDisplayComponent';
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';
import vSelect from 'vue-select';
// Vuex
import { UserGetters } from '@/shared/store/user/types';
import { UnitGetters, UnitActions } from '@/shared/store/unit/types';
import { hasError } from '@/shared/helpers/validator-helper.js';
import { LookupGetters } from '@/shared/store/lookup/types';
import { CustomerActions, CustomerGetters, CustomerMutations } from '@/shared/store/customer/types';
import {
  UnitLocationHistoryGetters,
  UnitLocationHistoryActions
} from '@/shared/store/unit/unit-location-history/types';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
// helpers
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';
import Cloner from '@/shared/helpers/cloner';
import debounce from 'lodash.debounce';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'UnitTransfer',
  components: {
    'customer-search-input': CustomerSearchInput,
    'employee-display': EmployeeDisplayComponent,
    'unsaved-changes-modal': UnsavedChangesModal,
    'v-select': vSelect
  },
  props: {
    label: String(),
    title: String()
  },
  data() {
    return {
      loading: false,
      showRemoved: false,
      hasError: hasError,
      saving: false,
      newUnitTransfer: {},
      defaultUnitTransferForm: {
        transferType: null,
        locationId: null,
        cusId: null,
        comments: null,
        previousCustomerId: null,
        currentCustomerId: null,
        currentCustomerName: null
      },
      transferTypes: [
        {
          id: '1',
          description: 'Location'
        },
        {
          id: '2',
          description: 'Customer'
        },
        {
          id: '3',
          description: 'Location & Customer'
        }
      ]
    };
  },
  validations: {
    newUnitTransfer: {
      cusId: {
        required
      },
      locationId: {
        required
      },
      transferType: {
        required
      }
    }
  },
  computed: {
    ...mapState({ customerStore: 'customer' }),
    ...mapGetters([UserGetters.AUTHORIZE_ROLE]),
    ...mapGetters({
      customer: CustomerGetters.GET_CUSTOMER,
      unit: UnitGetters.GET_UNIT,
      locationHistory: UnitLocationHistoryGetters.GET_UNIT_LOCATION_HISTORY,
      unitLocationsMap: LookupGetters.GET_LOCATIONS_MAP,
      unitLocationsList: LookupGetters.GET_LOCATIONS_LIST
    }),

    fields() {
      return [
        { key: 'NewUnitLocationId', label: 'New Location' },
        { key: 'NewCustomer', label: 'New Customer' },
        { key: 'ChangedBy', label: 'Changed' },
        { key: 'comments', label: 'Transfer Comments' }
      ];
    },
    customerList() {
      return this.customerStore.customerSearchResults;
    },
    customerId() {
      return this.$route.query.customerId;
    },
    isCsrOrCsmOrRegionalCsm() {
      return this[UserGetters.AUTHORIZE_ROLE](['CSR', 'CSM', 'RegionalCSM']);
    },
    activeUnitLocations() {
      return this.unitLocationsList.filter(l => !l.inactive);
    },
    location() {
      const unitLocation = this.unitLocationsMap[this.unit.locationId];
      return unitLocation;
    },
    showCustomer() {
      return this.newUnitTransfer.transferType != null
        ? this.newUnitTransfer.transferType.description === 'Customer' ||
            this.newUnitTransfer.transferType.description === 'Location & Customer'
        : false;
    },
    showLocation() {
      return this.newUnitTransfer.transferType != null
        ? this.newUnitTransfer.transferType.description === 'Location' ||
            this.newUnitTransfer.transferType.description === 'Location & Customer'
        : false;
    }
  },
  async created() {
    this.newUnitTransfer = Cloner.deepClone(this.defaultUnitTransferForm);
    this.loading = true;
    try {
      await this[UnitLocationHistoryActions.FETCH_UNIT_LOCATION_HISTORY](this.unit.unitId);
    } catch {
      ErrorService.createErrorToast(this, 'Error loading transfer history.');
    } finally {
      this.loading = false;
      this.newUnitTransfer.transferType = null;
    }
  },
  beforeDestroy() {},
  methods: {
    ...mapActions([
      UnitActions.UPDATE_UNIT_LOCATION,
      UnitActions.FETCH_UNIT,
      UnitLocationHistoryActions.FETCH_UNIT_LOCATION_HISTORY,
      CustomerActions.FETCH_CUSTOMER,
      CustomerActions.SEARCH_CUSTOMERS
    ]),
    ...mapMutations([CustomerMutations.RESET_STATE, CustomerMutations.SET_CUSTOMER]),
    confirmUpdate() {
      this.$v.$touch();
      if (
        (this.newUnitTransfer.cusId === null && this.showCustomer) ||
        (this.newUnitTransfer.locationId === null && this.showLocation)
      ) {
        ErrorService.createErrorToast(this, 'Error transferring Unit. See indicated fields below.');
        return;
      }
      this.$refs.UpdateWarning.show(this, null, this.savenewUnitTransfer);
    },
    async savenewUnitTransfer() {
      this.$v.$touch();
      if (this.newUnitTransfer.transferType.description === 'Location') {
        this.newUnitTransfer.cusId = null;
      }
      if (this.newUnitTransfer.transferType.description === 'Customer') {
        this.newUnitTransfer.locationId = null;
      }
      if (
        (this.newUnitTransfer.cusId === null && this.showCustomer) ||
        (this.newUnitTransfer.locationId === null && this.showLocation)
      ) {
        ErrorService.createErrorToast(this, 'Error transferring Unit. See indicated fields below.');
        return;
      }
      this.saving = true;
      try {
        this.newUnitTransfer.previousCustomerId = this.unit.customerId;
        if (this.newUnitTransfer.cusId != null) {
          this.newUnitTransfer.currentCustomerId = this.newUnitTransfer.cusId.customerId;
          this.newUnitTransfer.currentCustomerName = this.newUnitTransfer.cusId.customerName;
        }
        await this[UnitActions.UPDATE_UNIT_LOCATION](this.newUnitTransfer);
        await this[UnitActions.FETCH_UNIT](this.unit.unitId);
        this.$nextTick(function () {
          this.$v.$reset();
        });
        SuccessService.createSuccessToast(this.$root, 'Unit Location saved successfully.');
      } catch (err) {
        ErrorService.createErrorToast(this.$root, 'Error transferring unit.');
      } finally {
        this.newUnitTransfer = Cloner.deepClone(this.defaultUnitTransferForm);
        await this[CustomerMutations.RESET_STATE]();
        this.saving = false;
      }
    },
    getLocationDescription(unitLocationId) {
      const unitLocation = this.unitLocationsMap[unitLocationId];
      return unitLocation.description;
    },
    async selectCustomer(selected) {
      this[CustomerMutations.SET_CUSTOMER](selected);
    },
    onCustomerSearch(search, loading) {
      if (search.length > 0) {
        loading(true);
        this.searchCustomers(search, loading, this);
      } else {
        loading(false);
      }
    },
    searchCustomers: debounce(async (search, loading, vm) => {
      await vm[CustomerActions.SEARCH_CUSTOMERS]({ customer: search });
      loading(false);
    }, 500),
    changeTransferType() {
      this.$v.$reset();
    },
    hasChanges() {
      return JSON.stringify(this.defaultUnitTransferForm) !== JSON.stringify(this.newUnitTransfer);
    }
  }
};
</script>
