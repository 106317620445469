import axios from 'axios';

export const UNIT_PATH = '/api/units';
export const UNIT_SERVICE_ORDERS_PATH_ENDING = 'service-orders';
const QUICK_ACTION_PATH = 'quick-actions';
const DRIVER_INFO_PATH_ENDING = 'driver-info';

class DriverInfoService {
  async getDriverInfo(unitId, serviceOrderId) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(
      serviceOrderId
    )}/${QUICK_ACTION_PATH}/${DRIVER_INFO_PATH_ENDING}`;
    const driverInfo = await axios.get(url);
    return driverInfo;
  }

  async updateDriverInfo(unitId, serviceOrderId, driverInfo) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(
      serviceOrderId
    )}/${QUICK_ACTION_PATH}/${DRIVER_INFO_PATH_ENDING}`;
    const updatedDriverInfo = await axios.post(url, driverInfo);
    return updatedDriverInfo;
  }
}

export default new DriverInfoService();
