import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, LookupMutations } from './types';
import { getDefaultState } from './state';

const _mutations = namespaceHelper.removeNamespace(namespace, LookupMutations);

export default {
  [_mutations.SET_LOOKUPS](state, lookups) {
    state.lookupFetchDate = new Date();
    state.lookupReloadCache = false;

    state.lookups = lookups;
  },
  [_mutations.RESET_LOOKUPS](state) {
    const resetState = getDefaultState();
    resetState.lookupReloadCache = true;
    resetState.myCustomerReloadCache = true;
    Object.assign(state, resetState);
  },
  [_mutations.SET_EMPLOYEE_LIST](state, employeeList) {
    if (employeeList.length !== 0) {
      state.employeeList = employeeList;
    }
  },
  [_mutations.SET_STANDARD_OPERATIONS](state, standardOperationsList) {
    state.lookups.standardOperationsList = standardOperationsList;
  },
  [_mutations.SET_VMRS_HIERARCHY](state, vmrsHierarchy) {
    state.vmrs.dateFetched = new Date();
    state.vmrs.hierarchy = vmrsHierarchy;
  },
  [_mutations.SET_LOADING](state, loading) {
    state.loading = loading;
  }
};
