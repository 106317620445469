import namespaceHelper from '@/shared/helpers/namespace-helper';
import UnitLocationHistoryService from '@/shared/services/UnitLocationHistoryService';
import { namespace, UnitLocationHistoryActions, UnitLocationHistoryMutations } from './types';

const _actions = namespaceHelper.removeNamespace(namespace, UnitLocationHistoryActions);
const _mutations = namespaceHelper.removeNamespace(namespace, UnitLocationHistoryMutations);

export default {
  async [_actions.FETCH_UNIT_LOCATION_HISTORY]({ commit }, unitId) {
    const unitLocationHistory = await UnitLocationHistoryService.fetchUnitLocationHistory(unitId);
    commit(_mutations.SET_UNIT_LOCATION_HISTORY, unitLocationHistory.data);
  }
};
