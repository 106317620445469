export function generateThreeCs(params) {
  const NA = 'N/A';
  let complaint,
    cause,
    correction = '';
  const description = params.name;

  if (params.excedeJob?.excedeJobId?.substring(0, 1) == '&') {
    //if params have a description, it is a scheduledOperation
    if (description) {
      const current = isCurrent(params.operationStatus);
      const due = hasDue(params);
      const overdue = hasOverdue(params);

      cause = `${description} - `;

      if (current) {
        complaint = `${description} - Due`;
        cause += `Due`;
      } else {
        complaint = `${description} - ${params.operationStatus}`;
      }

      if (overdue.length > 0) {
        cause += 'Overdue ' + overdue.join(', ');
      } else if (due.length > 0) {
        cause += 'Due ' + due.join(', ');
      }

      correction = `${description} - Completed`;
    } else {
      //scheduled operation not associated with unit
      complaint = `${params.complaint ? params.complaint : NA}`;
      cause = `${params.cause ? params.cause : NA}`;
      correction = `${params.correction ? params.correction : NA}`;
    }
  } else {
    // if params doesn't have a description, it is a job
    complaint = `${params.excedeJob.complaint ? params.excedeJob.complaint : NA}`;
    cause = `${params.excedeJob.cause ? params.excedeJob.cause : NA}`;
    correction = `${params.excedeJob.correction ? params.excedeJob.correction : NA}`;
  }
  return {
    complaint,
    cause,
    correction
  };
}

function hasDue(params) {
  let due = [];
  if (params.durationStatus === 'Due') due.push('Duration');
  if (params.mileageStatus === 'Due') due.push('Mileage');
  if (params.engineHoursStatus === 'Due') due.push('Engine Hours');
  return due;
}
function hasOverdue(params) {
  let overdue = [];
  if (params.durationStatus === 'Overdue') overdue.push('Duration');
  if (params.mileageStatus === 'Overdue') overdue.push('Mileage');
  if (params.engineHoursStatus === 'Overdue') overdue.push('Engine Hours');
  return overdue;
}
function isCurrent(status) {
  return !(status === 'Due' || status === 'Overdue');
}
