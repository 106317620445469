import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, BreakdownActions, BreakdownMutations, BreakdownGetters } from './types';
import { STAGES } from './state';
import BreakdownService from '@/shared/services/BreakdownService';
import ErrorService from '@/shared/services/ErrorService';
import Cloner from '@/shared/helpers/cloner';

const _actions = namespaceHelper.removeNamespace(namespace, BreakdownActions);
const _mutations = namespaceHelper.removeNamespace(namespace, BreakdownMutations);
const _getters = namespaceHelper.removeNamespace(namespace, BreakdownGetters);

export default {
  [_actions.SWITCH_STAGE]({ commit, state }, params) {
    const newStage = params.newStage;
    const that = params.that;
    const allVs = [that.$v];
    if (params.otherVs) allVs.push(...params.otherVs);
    allVs.forEach(v => v.$touch());
    if (allVs.some(v => v.$anyError)) {
      const errorMessage = `Error within the breakdown. See indicated fields below.`;
      ErrorService.createErrorToast(that, errorMessage);
      return false;
    }

    commit(_mutations.SET_STAGE, newStage);
    if (state.formStage !== STAGES.CREATING && state.formStage !== STAGES.SAVING) {
      that.$v.$reset();
    }
    return true;
  },

  async [_actions.SEARCH_BREAKDOWNS]({ commit }, parameters) {
    const breakdowns = await BreakdownService.searchBreakdowns(parameters);
    commit(_mutations.SET_BREAKDOWN_SEARCH_RESULTS, breakdowns.data);
  },

  // Fetch
  async [_actions.FETCH_BREAKDOWN]({ commit }, parameters) {
    const response = await BreakdownService.getBreakdown(parameters);
    const breakdown = response.data;
    await commit(_mutations.SET_BREAKDOWN, breakdown);
    return breakdown;
  },

  // Create
  async [_actions.CREATE_BREAKDOWN]({ commit, getters }, parameters) {
    const unitId = parameters.unitId;
    const unitBreakdown = getters[_getters.GET_BREAKDOWN];
    const createServiceOrderResponse = await BreakdownService.createBreakdown({ unitId, unitBreakdown });
    const breakdown = createServiceOrderResponse.data;
    await commit(_mutations.SET_BREAKDOWN, breakdown);
    return breakdown;
  },

  // Update
  async [_actions.UPDATE_BREAKDOWN]({ commit, getters }, parameters) {
    const unitId = parameters.unitId;
    const unitBreakdown = Cloner.shallowClone(getters[_getters.GET_BREAKDOWN]);
    unitBreakdown.stopsMissed = parseInt(unitBreakdown.stopsMissed);
    const createServiceOrderResponse = await BreakdownService.updateBreakdown({ unitId, unitBreakdown });
    const breakdown = createServiceOrderResponse.data;
    await commit(_mutations.SET_BREAKDOWN, breakdown);
    return breakdown;
  },

  // Export
  async [_actions.EXPORT_BREAKDOWN_REPORT](state, parameters) {
    const breakdownExportResult = await BreakdownService.exportBreakdowns(parameters);
    return breakdownExportResult.data;
  }
};
