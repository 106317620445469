<template>
  <unit-service-order-history></unit-service-order-history>
</template>

<script>
import UnitServiceOrderHistoryComponent from '@/shared/components/service-order/UnitServiceOrderHistoryComponent';

export default {
  name: 'UnitServiceOrdersPage',
  components: {
    'unit-service-order-history': UnitServiceOrderHistoryComponent
  },
  watch: {
    status(value) {
      this.$router.push({ query: { filterBy: value } }).catch(() => {});
    }
  }
};
</script>
