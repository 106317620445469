function downloadByteArray(byteArray, fileName, appendDate, fileExtension) {
  var a = window.document.createElement('a');
  a.href = window.URL.createObjectURL(byteArray);

  a.download = `${fileName}${appendDate ? ' ' + getFormattedDate() : ''}${fileExtension ?? ''}`;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function getFormattedDate() {
  var date = new Date();
  var formattedDate =
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '-' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '-' +
    date.getFullYear();
  return formattedDate;
}

export default {
  downloadByteArray
};
