<template>
  <div>
    <h5>Out of Service</h5>
    <div v-if="showForm">
      <b-row no-gutters>
        <b-col>
          <span class="required-legend float-right p-0">* Required</span>
        </b-col>
      </b-row>
      <!-- Date OOS -->
      <b-form-group>
        <b-row no-gutters class="justify-content-between">
          <b-col sm="12" lg="6" xl="4">
            <date-time-input
              id="dateOutOfService"
              v-model="$v.dateOutOfService.$model"
              :validator="$v.dateOutOfService"
              label="Date OOS:"
              :readonly="readonly"
              label-class="col-head"
              hide-time
              required
            >
              <template #default>
                <div v-show="$v.$dirty && $v.dateOutOfService.$error" class="error">
                  Date must be formatted as MM/DD/YYYY
                </div>
              </template>
            </date-time-input>
          </b-col>
          <!-- Marked OOS By -->
          <b-col v-if="isOutOfService" sm="12" class="mb-2">
            <div class="note">
              <div class="d-flex">
                Added By:&nbsp;
                <employee-display :employee="employeeCreate" :show-email="false" />
              </div>
              <div class="d-flex">Date Added:&nbsp;{{ dateCreate | date }}</div>
            </div>
          </b-col>
        </b-row>
        <!--  Est. Return Date -->
        <b-row no-gutters class="justify-content-between">
          <b-col sm="12" lg="6" xl="4">
            <date-time-input
              id="dateEstimatedReturn"
              v-model="$v.dateEstimatedReturn.$model"
              :validator="$v.dateEstimatedReturn"
              label="Estimated Return Date:"
              :readonly="readonly"
              label-class="col-head"
              hide-time
              required
            >
              <template #default>
                <div v-show="$v.$dirty && $v.dateEstimatedReturn.$error" class="error">
                  <span v-if="!$v.dateEstimatedReturn.minValue || !$v.dateEstimatedReturn.maxValue">
                    Date must be formatted as MM/DD/YYYY
                    <br />
                  </span>
                  <span v-if="!$v.dateEstimatedReturn.sameDateOrFuture">
                    Must be on or after {{ dateOutOfService | date }}
                  </span>
                </div>
              </template>
            </date-time-input>
          </b-col>
        </b-row>
      </b-form-group>
      <!-- Breakdown Reason -->
      <b-form-group label-class="col-head" label="Reason:" label-for="reason">
        <div v-if="readonly" id="reason" class="col-form-label">
          {{ getBreakdownReason(currentOutOfService) }}
        </div>
        <b-input-group v-else>
          <v-select
            id="reason"
            v-model="breakdownReasonId"
            :state="!$v.breakdownReasonId.$error ? null : false"
            :options="breakdownReasonsList"
            :filter-by="filterReasons"
            :clearable="false"
            :reduce="reason => reason.breakdownReasonId"
            label="name"
            select-on-tab
            :class="`${$v.breakdownReasonId.$error && !$v.breakdownReasonId.required ? 'is-invalid' : ''}`"
          ></v-select>
          <span class="required-asterisk">*</span>
        </b-input-group>
      </b-form-group>
      <!-- Complaint Description  -->
      <b-form-group label="Complaint Description:" label-class="col-head" label-for="complaint">
        <div v-if="readonly" id="complaint" class="col-form-label">
          {{ currentOutOfService.complaint ? currentOutOfService.complaint : NA }}
        </div>
        <b-input-group v-if="!readonly">
          <b-input id="complaint" v-model="complaint" class="rounded" />
          <span class="required-placeholder" />
        </b-input-group>
      </b-form-group>
      <!-- Service Order Id -->
      <b-form-group label="Service Order ID / Estimate ID:" label-class="col-head" label-for="serviceOrderId">
        <b-row no-gutters class="justify-content-between">
          <b-col sm="12" lg="6" xl="4">
            <div v-if="readonly" id="serviceOrderId" class="col-form-label">
              {{ currentOutOfService.serviceOrderId || NA }}
            </div>
            <b-input-group v-else>
              <v-select id="serviceOrderId" v-model="serviceOrderId" :options="openServiceOrderIds" select-on-tab>
                <template #no-options>No open Service Order for this Unit.</template>
              </v-select>
              <span class="required-placeholder" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-form-group>
      <!-- Notify -->
      <b-form-group label="Notify:" label-class="col-head" label-for="notify">
        <div v-if="readonly" id="notify" class="col-form-label">
          {{ recipientEmails || NA }}
        </div>
        <b-input-group v-if="!$isCustomer">
          <employee-select
            id="notify"
            v-model="recipients"
            :options="recipientsList"
            :class="$v.recipients.$error ? 'is-invalid' : ''"
            multiple
            taggable
          />
          <span class="required-placeholder" />
        </b-input-group>
        <div v-if="$v.recipients.$error && !$v.recipients.email" class="error">Enter a valid Email.</div>
      </b-form-group>

      <div v-if="!unit.locationId" class="note mt-0 pt-0 mb-2">
        No Internal Recipients available for units without locations. Enter the email addresses.
      </div>

      <div v-if="!$isCustomer">
        <div class="pt-3">
          <b-button v-if="!isOutOfService" variant="primary" :disabled="marking" @click="confirmMarkOutOfService">
            <div v-show="marking">
              <b-spinner small></b-spinner>
              Marking...
            </div>
            <div v-show="!marking">Mark OOS</div>
          </b-button>
        </div>
        <div>
          <b-button v-if="isOutOfService" variant="primary" :disabled="saving" @click="updateOutOfService(false)">
            <div v-show="saving">
              <b-spinner small></b-spinner>
              Saving...
            </div>
            <div v-show="!saving">Save</div>
          </b-button>

          <b-button
            v-if="isOutOfService"
            class="ml-3"
            variant="primary"
            :disabled="saving || !recipients.length"
            @click="confirmRecipients"
          >
            <div v-show="saving">
              <b-spinner small></b-spinner>
              Notifying...
            </div>
            <div v-show="!saving">Save & Notify</div>
          </b-button>
        </div>
        <div class="py-2" />
      </div>
      <div v-if="!$isCustomer && isOutOfService">
        <h4>Back In Service:</h4>
        <b-row class="no-gutters">
          <b-col sm="12" lg="6" xl="4">
            <date-time-input
              id="dateBackInService"
              v-model="$v.dateBackInService.$model"
              :validator="$v.dateBackInService"
              label="Date Back In Service:"
              :readonly="readonly"
              label-class="col-head"
              hide-time
              required
            >
              <template #default>
                <div v-show="$v.$dirty && $v.dateBackInService.$error" class="error">
                  <span v-if="!$v.dateBackInService.minValue || !$v.dateBackInService.maxValue">
                    Date must be formatted as MM/DD/YYYY
                    <br />
                  </span>
                  <span v-if="!$v.dateBackInService.sameDateOrFuture">
                    Must be on or after {{ dateOutOfService | date }}
                  </span>
                </div>
              </template>
            </date-time-input>
          </b-col>
        </b-row>
        <div>
          <b-form-group label="Description:" label-class="col-head" label-for="description">
            <div v-if="readonly" id="description" class="col-form-label">
              {{ backInService.description ? backInService.description : NA }}
            </div>
            <b-input-group v-if="!readonly">
              <b-input id="description" v-model="description" class="rounded" />
              <span class="required-placeholder" />
            </b-input-group>
          </b-form-group>
        </div>
        <div v-if="!$isCustomer" class="pt-3">
          <b-row>
            <b-button variant="primary" :disabled="markingBackInService" @click="confirmBackInService">
              <div v-show="markingBackInService">
                <b-spinner small></b-spinner>
                Marking...
              </div>
              <div v-show="!markingBackInService">Mark In Service</div>
            </b-button>

            <b-button class="ml-2" variant="secondary" :disabled="canceling" @click="cancel">Cancel</b-button>
          </b-row>
        </div>
      </div>
      <div class="py-2" />
    </div>

    <h3>OOS History</h3>
    <b-overlay :show="loading" :opacity="0.6">
      <b-table
        striped
        class="border"
        no-border-collapse
        :sticky-header="readonly ? '71vh' : '42vh'"
        :items="outOfServiceHistory"
        :fields="fields"
        show-empty
        :empty-text="`No OOS History on the unit`"
      >
        <template #cell(dateOutOfService)="{ item }">
          {{ item.dateOutOfService | date }}
          <employee-display :employee="item.employeeCreate" :show-email="false" />
        </template>

        <template #cell(reason)="{ item }">
          <span class="text">{{ getBreakdownReason(item) }}</span>
        </template>

        <template #cell(complaint)="{ item }">
          <span class="text">{{ item.complaint }}</span>
        </template>

        <template #cell(serviceOrderId)="{ item }">
          <router-link v-if="item.serviceOrderId" :to="'/units/' + unitId + '/service-orders/' + item.serviceOrderId">
            {{ item.serviceOrderId }}
          </router-link>
        </template>
        <template #cell(dateBackInService)="{ item }">
          <span class="text">{{ item.dateBackInService | date }}</span>
          <employee-display :employee="item.employeeBackInService" :show-email="false" />
        </template>
      </b-table>
    </b-overlay>
    <unsaved-changes-modal
      ref="UnsavedChangesModal"
      message="You have unsaved changes on the Unit Out Of Service. What would you like to do?"
      :continue-btn-text="`Continue With Out of Service`"
    ></unsaved-changes-modal>
    <warning-modal
      id="NotificationRecipientWarning"
      ref="NotificationRecipientWarning"
      title="Save and Notify Recipients"
      :warning-text="notificationRecipientMessage"
      continue-btn-text="Yes, Save and Notify Recipients"
      cancel-btn-text="Cancel"
    ></warning-modal>
    <warning-modal
      id="outOfServiceUpdateWarning"
      ref="outOfServiceUpdateWarning"
      title="Out of Service"
      :warning-text="outOfServiceConfirmationMessage"
      continue-btn-text="Yes, Mark OOS"
      cancel-btn-text="Cancel"
    ></warning-modal>
    <warning-modal
      id="backInServiceUpdateWarning"
      ref="backInServiceUpdateWarning"
      title="Back In Service"
      :warning-text="backInServiceConfirmationMessage"
      continue-btn-text="Yes, Mark In Service"
      cancel-btn-text="Cancel"
    ></warning-modal>
  </div>
</template>

<script>
//vuex
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import {
  OutOfServiceActions,
  OutOfServiceGetters,
  OutOfServiceMutations
} from '@/shared/store/unit/out-of-service/types';
import { CustomerGetters } from '@/shared/store/customer/types';
import { LookupGetters } from '@/shared/store/lookup/types';

//components
import WarningModal from '@/shared/components/WarningModal';
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';
import vSelect from 'vue-select';
import DateTimeInput from '@/shared/components/ui/DateTimeInput';
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';
import EmployeeSelectComponent from '@/shared/components/ui/EmployeeSelectComponent';
import EmployeeDisplayComponent from '@/shared/components/ui/EmployeeDisplayComponent';

import { required, requiredIf, minValue, maxValue, email } from 'vuelidate/lib/validators';

import ServiceOrderService from '@/shared/services/ServiceOrderService';

export default {
  name: 'UnitOutOfService',
  components: {
    'date-time-input': DateTimeInput,
    'warning-modal': WarningModal,
    'unsaved-changes-modal': UnsavedChangesModal,
    'employee-select': EmployeeSelectComponent,
    'employee-display': EmployeeDisplayComponent,
    vSelect
  },
  data() {
    return {
      NA: 'N/A',
      marking: false,
      isDateBackInServiceNull: true,
      markingBackInService: false,
      loading: false,
      saving: false,
      canceling: false,
      outOfServiceConfirmationMessage: `Are you sure you want to mark the unit out of service?`,
      notificationRecipientMessage: `Are you sure you want to update and notify recipients of this unit out of service?`,
      backInServiceConfirmationMessage: `Are you sure you want to mark the unit back in service and notify recipients?`,
      fields: [
        { key: 'dateOutOfService', label: 'Date OOS', sortable: true },
        { key: 'reason', label: 'Reason', sortable: true },
        { key: 'complaint', label: 'Complaint Description', sortable: true },
        { key: 'serviceOrderId', label: 'SO ID / Est ID', tdClass: 'mw-200px' },
        { key: 'dateBackInService', label: 'Back in Service', sortable: true }
      ],
      openServiceOrders: []
    };
  },
  validations: {
    dateOutOfService: {
      required,
      minValue: minValue(new Date().setFullYear(1753))
    },
    dateEstimatedReturn: {
      required,
      minValue: minValue(new Date().setFullYear(1753)),
      maxValue: maxValue(new Date().setFullYear(9999)),
      sameDateOrFuture(val, { dateOutOfService }) {
        return new Date(dateOutOfService) <= new Date(val);
      }
    },
    dateBackInService: {
      required: requiredIf(function () {
        return this.isDateBackInServiceNull;
      }),
      minValue: minValue(new Date().setFullYear(1753)),
      maxValue: maxValue(new Date().setFullYear(9999)),
      sameDateOrFuture(val, { dateOutOfService }) {
        if (val !== null) {
          return new Date(dateOutOfService) <= new Date(val);
        } else {
          return true;
        }
      }
    },
    breakdownReasonId: {
      required
    },
    recipients: {
      $each: {
        emailAddress: {
          email
        }
      }
    }
  },
  computed: {
    ...mapState('unit', ['unit']),
    ...mapGetters({
      outOfServices: OutOfServiceGetters.GET_UNIT_OUT_OF_SERVICES,
      currentOutOfService: OutOfServiceGetters.GET_CURRENT_OUT_OF_SERVICE,
      backInService: OutOfServiceGetters.GET_BACK_IN_SERVICE,
      breakdownReasonsMap: LookupGetters.GET_BREAKDOWN_REASONS_MAP,
      breakdownReasonsList: LookupGetters.GET_BREAKDOWN_REASONS_LIST,
      isOutOfService: OutOfServiceGetters.HAS_OPEN_UNIT_OUT_OF_SERVICE,
      hasChanges: OutOfServiceGetters.HAS_CURRENT_OOS_CHANGES,
      employeeList: LookupGetters.GET_EMPLOYEE_LIST,
      vcpUserList: CustomerGetters.GET_VCP_USER_LIST
    }),
    unitId() {
      return this.unit.unitId;
    },
    openServiceOrderIds() {
      return this.openServiceOrders ? this.openServiceOrders.map(so => so.serviceOrderId) : [];
    },
    employeeCreate() {
      return this.currentOutOfService?.employeeCreate;
    },
    dateCreate() {
      return this.currentOutOfService?.dateCreate;
    },
    // Out Of Service Form
    dateOutOfService: {
      get() {
        return this.currentOutOfService?.dateOutOfService;
      },
      set(value) {
        this[OutOfServiceMutations.SET_CURRENT_OOS_PROP]({ key: 'dateOutOfService', value });
      }
    },
    breakdownReasonId: {
      get() {
        return this.currentOutOfService?.breakdownReasonId;
      },
      set(value) {
        this[OutOfServiceMutations.SET_CURRENT_OOS_PROP]({ key: 'breakdownReasonId', value });
      }
    },
    complaint: {
      get() {
        return this.currentOutOfService?.complaint;
      },
      set(value) {
        this[OutOfServiceMutations.SET_CURRENT_OOS_PROP]({ key: 'complaint', value });
      }
    },
    dateEstimatedReturn: {
      get() {
        return this.currentOutOfService?.dateEstimatedReturn;
      },
      set(value) {
        this[OutOfServiceMutations.SET_CURRENT_OOS_PROP]({ key: 'dateEstimatedReturn', value });
      }
    },
    recipients: {
      get() {
        return this.currentOutOfService?.recipients;
      },
      set(value) {
        this[OutOfServiceMutations.SET_CURRENT_OOS_PROP]({ key: 'recipients', value });
      }
    },
    recipientEmails() {
      var list = this.recipients.map(recipient => {
        return recipient['emailAddress'];
      });
      var joinedList = list.join(', ');
      return joinedList;
    },
    serviceOrderId: {
      get() {
        return this.currentOutOfService?.serviceOrderId;
      },
      set(value) {
        this[OutOfServiceMutations.SET_CURRENT_OOS_PROP]({ key: 'serviceOrderId', value });
      }
    },
    // Back In Service Form
    dateBackInService: {
      get() {
        return this.backInService?.dateBackInService;
      },
      set(value) {
        this[OutOfServiceMutations.SET_BACK_IN_SERVICE_PROP]({ key: 'dateBackInService', value });
      }
    },
    description: {
      get() {
        return this.backInService?.description;
      },
      set(value) {
        this[OutOfServiceMutations.SET_BACK_IN_SERVICE_PROP]({ key: 'description', value });
      }
    },
    notificationRequested: {
      get() {
        return this.currentOutOfService.notificationRequested;
      },
      set(value) {
        this[OutOfServiceMutations.SET_PROP]({ key: 'notificationRequested', value });
      }
    },
    reason() {
      return this.breakdownReasonsMap[this.breakdownReasonId];
    },
    readonly() {
      return this.$isCustomer;
    },
    outOfServiceHistory() {
      return this.outOfServices.filter(oos => oos.dateBackInService != null);
    },
    showForm() {
      return !this.isOutOfService ? (this.$isCustomer ? false : true) : true;
    },
    recipientsList() {
      return [...this.vcpUserList, ...this.employeeList];
    }
  },
  async beforeDestroy() {
    await this.restoreForm();
  },
  async created() {
    await this.restoreForm();
    if (!this.$isCustomer) {
      var soSearchResults = await ServiceOrderService.searchOrders({
        vin: this.unit.vin,
        soStatus: 'Open',
        customerId: this.unit.customerId
      });
      var estSearchResults = await ServiceOrderService.searchOrders({
        vin: this.unit.vin,
        customerId: this.unit.customerId,
        isEstimate: true,
        IsUnexpiredEstimate: true
      });
      soSearchResults.data.forEach(result => this.openServiceOrders.push(result));
      estSearchResults.data.forEach(result => this.openServiceOrders.push(result));
    }
  },
  methods: {
    ...mapActions([
      OutOfServiceActions.MARK_UNIT_OUT_OF_SERVICE,
      OutOfServiceActions.FETCH_UNIT_OUT_OF_SERVICES,
      OutOfServiceActions.UPDATE_UNIT_OUT_OF_SERVICE,
      OutOfServiceActions.MARK_UNIT_BACK_IN_SERVICE
    ]),
    ...mapMutations([
      OutOfServiceMutations.RESET_STATE,
      OutOfServiceMutations.DISCARD_CHANGES,
      OutOfServiceMutations.SET_CURRENT_OOS_PROP,
      OutOfServiceMutations.SET_BACK_IN_SERVICE_PROP
    ]),
    filterReasons(option, label, search) {
      return (
        option.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        (option.vmrsSystemCodeKey && option.vmrsSystemCodeKey.toLowerCase().indexOf(search.toLowerCase()) > -1)
      );
    },
    getBreakdownReason(outOfService) {
      const breakdownReason = this.breakdownReasonsMap[outOfService?.breakdownReasonId];
      return breakdownReason ? breakdownReason.name : '';
    },
    confirmMarkOutOfService: function () {
      this.isDateBackInServiceNull = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        ErrorService.createErrorToast(this, 'Error marking unit out of service. See indicated fields below.');
        return;
      }
      this.$refs.outOfServiceUpdateWarning.show(this, this.markOutOfService.bind(this));
    },
    async markOutOfService() {
      try {
        this.marking = this.loading = true;
        await this[OutOfServiceActions.MARK_UNIT_OUT_OF_SERVICE]({
          unitId: this.unitId,
          outOfService: this.currentOutOfService
        });
        await this[OutOfServiceActions.FETCH_UNIT_OUT_OF_SERVICES](this.unitId);
        SuccessService.createSuccessToast(this.$root, 'Unit Successfuly Marked Out Of Service.');
        this.$v.$reset();
        this.showMarkBackInService = true;
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to Mark Unit Out Of Service');
      } finally {
        this.marking = this.loading = false;
      }
    },
    confirmRecipients: function () {
      this.$refs.NotificationRecipientWarning.show(this, this.updateOutOfService.bind(this, true));
    },
    async updateOutOfService(notify) {
      this.isDateBackInServiceNull = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        ErrorService.createErrorToast(this, 'Error saving unit out of service. See indicated fields below.');
        return;
      }
      try {
        this.saving = this.loading = true;
        this.currentOutOfService.notificationRequested = notify;
        await this[OutOfServiceActions.UPDATE_UNIT_OUT_OF_SERVICE]({
          unitId: this.unitId,
          outOfService: this.currentOutOfService,
          notify: notify
        });
        await this[OutOfServiceActions.FETCH_UNIT_OUT_OF_SERVICES](this.unitId);
        if (notify) {
          SuccessService.createSuccessToast(this.$root, 'Out of Service Successfuly Saved and Recipients Notified.');
        } else {
          SuccessService.createSuccessToast(this.$root, 'Out of Service Successfuly Saved.');
        }
        this.$v.$reset();
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to Save Unit Out Of Service');
      } finally {
        this.saving = this.loading = false;
      }
    },
    confirmBackInService() {
      this.isDateBackInServiceNull = this.backInService.dateBackInService == null;
      this.$v.$touch();
      if (this.$v.$anyError) {
        ErrorService.createErrorToast(this, 'Error marking unit back in service. See indicated fields below.');
        return;
      }

      const discard = (cont = true) => {
        if (cont) this[OutOfServiceMutations.DISCARD_CHANGES]();
      };

      if (this.hasChanges) {
        this.$refs.UnsavedChangesModal.show(this, discard);
      } else {
        this.$refs.backInServiceUpdateWarning.show(this, this.markUnitBackInService.bind(this));
        this.$v.$reset;
      }
    },
    async markUnitBackInService() {
      try {
        this.markingBackInService = this.loading = true;
        this.backInService.outOfServiceId = this.currentOutOfService.outOfServiceId;
        this.backInService.dateOutOfService = this.currentOutOfService.dateOutOfService;
        this.backInService.dateEstimatedReturn = this.currentOutOfService.dateEstimatedReturn;
        this.backInService.complaint = this.currentOutOfService.complaint;
        this.backInService.notify = this.currentOutOfService.notify;
        this.backInService.breakdownReasonId = this.currentOutOfService.breakdownReasonId;
        this.backInService.recipients = this.currentOutOfService.recipients;
        await this[OutOfServiceActions.MARK_UNIT_BACK_IN_SERVICE]({
          unitId: this.unitId,
          backInService: this.backInService
        });
        this.$v.$reset();
        SuccessService.createSuccessToast(this.$root, 'Unit Successfuly Marked Back In Service.');
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to Mark Unit Back In Service');
      } finally {
        this.markingBackInService = this.loading = false;
        this.restoreForm();
      }
    },

    restoreForm() {
      this[OutOfServiceMutations.RESET_STATE]();
      this[OutOfServiceActions.FETCH_UNIT_OUT_OF_SERVICES](this.unitId);
    },
    cancel() {
      this.$emit('close');
    }
  }
};
</script>
