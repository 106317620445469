import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, OutOfServiceMutations } from './types';
import Cloner from '@/shared/helpers/cloner';
import { getDefaultState } from './state';

const _mutations = namespaceHelper.removeNamespace(namespace, OutOfServiceMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.DISCARD_CHANGES](state) {
    state.currentOutOfService = Cloner.deepClone(state.oldCurrentOutOfService);
    state.backInService = Cloner.deepClone(state.oldBackInService);
  },
  [_mutations.SET_UNIT_OUT_OF_SERVICES](state, unitOutOfServices) {
    state.unitOutOfServices = unitOutOfServices;
    const currentOutOfService = unitOutOfServices.find(oos => oos.dateBackInService == null);
    if (currentOutOfService) {
      state.currentOutOfService = Cloner.deepClone(currentOutOfService);
      state.oldCurrentOutOfService = Cloner.deepClone(currentOutOfService);
    }
  },
  [_mutations.SET_CURRENT_OOS_PROP](state, payload) {
    state.currentOutOfService[payload.key] = payload.value;
  },
  [_mutations.SET_BACK_IN_SERVICE_PROP](state, payload) {
    state.backInService[payload.key] = payload.value;
  },
  [_mutations.SET_BACK_IN_SERVICE_PROP](state, payload) {
    state.backInService[payload.key] = payload.value;
  }
};
