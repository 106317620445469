<template>
  <div class="mobile-table">
    <div v-if="hasResults === false" class="mt-row text-center py-3">No results found.</div>
    <div v-for="(item, itemIndex) in shownItems" :key="item.unitInspectionId" class="mt-row">
      <div class="mt-main">
        <div v-for="(field, fIndex) in mainFields" :key="fIndex" class="mt-main-field">
          <slot :name="`${field.key}`" :item="item">
            <label :class="`mt-main-field-label ${labelClass}`">{{ field.label }}:</label>
            <div v-if="field.formatter" :class="`mt-collapse-field-value ${valueClass}`">
              {{ field.formatter(item[field.key], key, item) }}
            </div>
            <div v-else :class="`mt-main-field-value ${valueClass}`">{{ item[field.key] | nullValueToNA }}</div>
          </slot>
        </div>
      </div>
      <b-collapse :id="`mt-collapse-${itemIndex}`" class="mt-collapse">
        <div v-for="(field, fIndex) in collapsibleFields" :key="fIndex" class="mt-collapse-field">
          <slot :name="`${field.key}`" :item="item">
            <label :class="`mt-collapse-field-label ${labelClass}`">{{ field.label }}:</label>
            <div v-if="field.formatter" :class="`mt-collapse-field-value ${valueClass}`">
              {{ field.formatter(item[field.key], key, item) }}
            </div>
            <div v-else :class="`mt-collapse-field-value ${valueClass}`">{{ item[field.key] | nullValueToNA }}</div>
          </slot>
        </div>
        <slot name="collapse-footer" :item="item" />
      </b-collapse>
      <div v-if="allowExpand" class="d-flex justify-content-end">
        <b-button v-b-toggle="`mt-collapse-${itemIndex}`" size="xsm" class="mr-2 mb-2">
          <font-awesome-icon icon="minus" class="when-opened text-primary" />
          <font-awesome-icon icon="plus" class="when-closed text-primary" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileTableComponent',
  props: {
    items: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    labelClass: {
      type: String,
      default: 'col-head w-100'
    },
    valueClass: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      pageSize: 50,
      pageNumber: 1
    };
  },
  computed: {
    mainFields() {
      return this.fields.filter(field => !field.collapsible);
    },
    collapsibleFields() {
      return this.fields.filter(field => field.collapsible);
    },
    allowExpand() {
      return this.collapsibleFields.length > 0;
    },
    hasResults() {
      return typeof this.items == 'object' && this.items.length > 0;
    },
    shownItems() {
      return this.items.slice(0, this.pageNumber * this.pageSize);
    },
    bodyEle() {
      return document.getElementsByTagName('BODY')[0];
    }
  },
  watch: {
    items: {
      handler() {
        this.pageSize = 50;
        this.pageNumber = 1;
      },
      immediate: true
    }
  },
  created() {
    window.addEventListener('scroll', this.scroller);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scroller);
  },
  methods: {
    scroller() {
      const doE = document.documentElement;
      const scrolledY = window.pageYOffset || self.pageYOffset || (doE && doE.scrollTop) || document.body.scrollTop;
      if (scrolledY + window.innerHeight + 100 > this.bodyEle.offsetHeight) {
        this.pageNumber += 1;
      }
    }
  }
};
</script>
