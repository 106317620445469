<template>
  <b-form-group label-class="col-head" :label="label" label-for="customerSearchInput">
    <div class="d-flex">
      <v-select
        id="customerSearchInput"
        :class="selectClass"
        :value="value"
        :placeholder="placeholder"
        :options="customerList"
        :label="selectLabel"
        :clearable="false"
        :filterable="false"
        :title="customerId"
        :required="required"
        @input="input"
        @search="onCustomerSearch"
      >
        <template #option="customerOption">
          <span class="text-uppercase">{{ customerOption.customerId }} - {{ customerOption.customerName }}</span>
        </template>
        <template #no-options>No Customer Found</template>
        <template #selected-option="customerOption">
          <span class="text-uppercase">{{ customerOption.customerId }} - {{ customerOption.customerName }}</span>
        </template>
      </v-select>
      <span v-if="required == true" :class="`required-asterisk`">*</span>
      <span v-else-if="required == false" class="required-placeholder" />
    </div>
    <slot name="error" />
    <!-- <div v-show="$v.$dirty && hasError($v.newUnitTransfer.cusId, 'required') && showCustomer" class="error">
      Enter a Customer.
    </div> -->
  </b-form-group>
</template>

<script>
// components
import vSelect from 'vue-select';
// vuex
import { mapActions, mapGetters } from 'vuex';
import { CustomerActions, CustomerGetters } from '@/shared/store/customer/types';
// helpers
import debounce from 'lodash.debounce';

export default {
  name: 'CustomerSearchInput',
  components: {
    'v-select': vSelect
  },
  props: {
    label: {
      type: String,
      default: 'Customer:'
    },
    value: {
      type: Object,
      default: () => null
    },
    selectClass: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Name or ID'
    },
    selectLabel: {
      type: String,
      default: 'customerName'
    },
    required: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    ...mapGetters({ customerList: CustomerGetters.GET_SEARCH_RESULTS }),
    customerId() {
      return this.value?.cusId;
    }
  },
  methods: {
    ...mapActions([CustomerActions.SEARCH_CUSTOMERS]),
    input(value) {
      this.$emit('input', value);
    },
    onCustomerSearch(search, loading) {
      if (search.length > 0) {
        loading(true);
        this.searchCustomers(search, loading, this);
      } else {
        loading(false);
      }
    },
    searchCustomers: debounce(async (search, loading, vm) => {
      await vm[CustomerActions.SEARCH_CUSTOMERS]({ customer: search });
      loading(false);
    }, 500)
  }
};
</script>
