import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, InspectionActions, InspectionGetters, InspectionMutations } from './types';
import { STAGES } from './state';
import InspectionService from '@/shared/services/InspectionService';
import ErrorService from '@/shared/services/ErrorService';
import PrintService from '@/shared/services/PrintService';

const _actions = namespaceHelper.removeNamespace(namespace, InspectionActions);
const _getters = namespaceHelper.removeNamespace(namespace, InspectionGetters);
const _mutations = namespaceHelper.removeNamespace(namespace, InspectionMutations);

export default {
  [_actions.SWITCH_STAGE]({ commit, state }, params) {
    const newStage = params.newStage;
    const that = params.that;
    const allVs = [that.$v];

    if (params.otherVs) allVs.push(...params.otherVs);
    allVs.forEach(v => v.$touch());
    if (allVs.some(v => v.$anyError)) {
      const errorMessage = `Error within the inspection. See indicated fields below.`;
      ErrorService.createErrorToast(that, errorMessage);
      return false;
    }

    commit(_mutations.SET_STAGE, newStage);
    if (state.formStage !== STAGES.CREATING && state.formStage !== STAGES.SAVING) {
      that.$v.$reset();
    }
    return true;
  },

  // Search
  async [_actions.SEARCH_INSPECTIONS]({ commit }, parameters) {
    const response = await InspectionService.searchInspections(parameters);
    const searchResults = response.data;
    await commit(_mutations.SET_INSPECTION_SEARCH_RESULTS, searchResults);
    return searchResults;
  },

  // Create
  async [_actions.CREATE_INSPECTION]({ dispatch }, parameters) {
    const response = await InspectionService.createInspection(parameters);
    const inspection = response.data;
    await dispatch(_actions.SET_INSPECTION_AND_MAP, inspection);
    return inspection;
  },

  // Fetch
  async [_actions.FETCH_INSPECTION]({ commit, dispatch }, parameters) {
    const response = await InspectionService.getInspection(parameters);
    const inspection = response.data;
    await dispatch(_actions.SET_INSPECTION_AND_MAP, inspection);
    await commit(_mutations.SET_STAGE, STAGES.EDIT);
    return inspection;
  },

  // Fetch - List
  // Used to pre-cache a list of inspections
  [_actions.FETCH_INSPECTIONS](state, parameters) {
    for (let unitInspection of parameters) {
      const unitInspectionId = unitInspection.unitInspectionId;
      InspectionService.getInspection(unitInspectionId);
    }
  },

  // Update
  async [_actions.UPDATE_INSPECTION]({ dispatch }, parameters) {
    const response = await InspectionService.updateInspection(parameters);
    const inspection = response.data;
    await dispatch(_actions.SET_INSPECTION_AND_MAP, inspection);
    return response;
  },

  [_actions.SET_INSPECTION_AND_MAP]({ commit, state }, inspection) {
    commit(_mutations.SET_INSPECTION, inspection);

    if (state.details.sections) {
      let axleInspections = [];

      for (const section of state.details.sections) {
        for (const item of section.items) {
          const { inspectionItemId, inspectionItemType } = item;
          commit(_mutations.SET_ITEM_MAP, { inspectionItemId, item });

          if (inspectionItemType == 'tire' || inspectionItemType == 'brake') {
            axleInspections.push({ itemId: inspectionItemId, sectionId: section.inspectionSectionId });
            const tireConfigurations = item.tireInspection?.tireConfigurations;
            if (inspectionItemType == 'tire' && tireConfigurations != null) {
              commit(_mutations.MAP_TIRE_CONFIGURATIONS, { inspectionItemId, tireConfigurations });
            }
            const brakeConfigurations = item.brakeInspection?.brakeConfigurations;
            if (inspectionItemType == 'brake' && brakeConfigurations != null) {
              commit(_mutations.MAP_BRAKE_CONFIGURATIONS, { inspectionItemId, brakeConfigurations });
            }
          }
        }
      }
      if (axleInspections.length > 0) commit(_mutations.SET_AXLE_INSPECTIONS, axleInspections);
    }
    commit(_mutations.SET_DB_INSPECTION, state.details);
  },

  async [_actions.SET_AXLE_CONFIGURATION]({ state, commit, getters }, axleConfiguration) {
    if (!axleConfiguration) return;
    commit(_mutations.SET_PROP, { key: 'axleConfiguration', value: axleConfiguration });

    if (!state.axleInspections) return;
    for (const inspection of state.axleInspections) {
      const { inspectionItemId, inspectionItemType } = getters[_getters.GET_INSPECTION_ITEM](inspection.itemId);
      if (inspectionItemType == 'tire') {
        commit(_mutations.MAP_TIRE_CONFIGURATIONS, {
          inspectionItemId,
          tireConfigurations: axleConfiguration.tireConfigurations
        });
      } else if (inspectionItemType == 'brake') {
        commit(_mutations.MAP_BRAKE_CONFIGURATIONS, {
          inspectionItemId,
          brakeConfigurations: axleConfiguration.brakeConfigurations
        });
      }
    }
  },

  async [_actions.PRINT_INSPECTION](state, parameters) {
    const inspectionExportResult = await PrintService.printForm(parameters);
    return inspectionExportResult.data;
  },

  async [_actions.ASSIGN_SERVICE_ORDER](state, parameters) {
    await InspectionService.assignServiceOrder(parameters);
  }
};
