<template>
  <div>
    <h5>Workflow</h5>
    <h6 class="text-uppercase">{{ estimorderDisplayText }} ID: {{ serviceOrderId }}</h6>
    <b-row class="no-gutters">
      <b-col sm="12" lg="6">
        <b-form-group label="Current:" label-class="col-head" label-for="current">
          <div id="current" class="col-form-label pl-0">
            <span class="text-capitalize">{{ currentAssignment ? currentAssignment.name.toLowerCase() : NA }}</span>
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <div v-if="!readonly">
      <b-row no-gutters>
        <b-col sm="12" lg="6">
          <span class="required-legend float-right p-0">* Required</span>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col sm="12" lg="6">
          <b-form-group label="Assign To:" label-class="col-head" label-for="assigned-to">
            <b-input-group>
              <employee-select
                v-model="assignTo"
                :placeholder="assignToPlaceholder"
                :class="$v.assignTo.$error && !$v.assignTo.required ? 'is-invalid' : ''"
              />
              <span class="required-asterisk">*</span>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <b-form-group label="Notify:" label-class="col-head" label-for="notify">
            <b-input-group>
              <employee-select v-model="notify" multiple />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <b-form-group label="Comment" label-class="col-head" label-for="comment">
            <b-textarea id="comment" v-model="comment" class="overflow-auto" size="sm" rows="3" max-rows="4" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button variant="primary" :disabled="assigning" @click="assign">
        <div v-show="assigning">
          <b-spinner small></b-spinner>
          Assigning...
        </div>
        <div v-show="!assigning">Assign</div>
      </b-button>

      <div class="py-4" />
    </div>

    <h3>Past Assignments</h3>
    <b-overlay :show="loading" :opacity="0.6">
      <b-table
        striped
        class="border"
        :sticky-header="readonly ? '71vh' : '42vh'"
        :items="pastAssignments"
        :fields="fields"
        show-empty
        :empty-text="`There are no assignments for this ${estimorderDisplayText}`"
      >
        <template #cell(dateCreate)="{ value }">
          {{ value | dateTime }}
        </template>

        <template #cell(assignedFromEmployee)="{ value }">
          <span class="text-capitalize">{{ value ? value.name.toLowerCase() : NA }}</span>
        </template>

        <template #cell(assignedToEmployee)="{ value }">
          <span class="text-capitalize">{{ value ? value.name.toLowerCase() : NA }}</span>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
// Components
import EmployeeSelectComponent from '@/shared/components/ui/EmployeeSelectComponent';
// Vuex
import { ServiceOrderActions, ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';

import { required } from 'vuelidate/lib/validators';
import ErrorService from '@/shared/services/ErrorService';

export default {
  name: 'ServiceOrderWorkflow',
  components: {
    'employee-select': EmployeeSelectComponent
  },
  data() {
    return {
      NA: 'N/A',
      assigning: false,
      loading: false,
      assignToPlaceholder: null,
      fields: [
        { key: 'dateCreate', label: 'Date/Time', sortable: true },
        { key: 'assignedFromEmployee', label: 'From', sortable: true },
        { key: 'assignedToEmployee', label: 'To', sortable: true },
        { key: 'comment', label: 'Comment', tdClass: 'mw-200px' }
      ]
    };
  },
  validations: {
    assignTo: {
      required
    }
  },
  computed: {
    ...mapState('unit', ['unit']),
    ...mapGetters({
      estimorderDisplayText: ServiceOrderGetters.GET_ESTIMORDER_DISPLAY_TEXT,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      branchId: ServiceOrderGetters.GET_BRANCH_ID,
      workflow: ServiceOrderGetters.GET_WORKFLOW,
      readonly: ServiceOrderGetters.GET_READONLY
    }),
    unitId() {
      return this.unit.unitId;
    },
    serviceOrderId() {
      return this.serviceOrder.serviceOrderId;
    },
    currentAssignment() {
      return this.workflow ? this.workflow.currentAssignment : {};
    },
    pastAssignments() {
      return this.workflow ? this.workflow.pastAssignments : [];
    },
    assignTo: {
      get() {
        return this.workflow.assignTo;
      },
      set(value) {
        this.setData('assignTo', value);
      }
    },
    notify: {
      get() {
        return this.workflow.notify;
      },
      set(value) {
        this.setData('notify', value);
      }
    },
    comment: {
      get() {
        return this.workflow.comment;
      },
      set(value) {
        this.setData('comment', value);
      }
    }
  },
  async created() {
    await this.fetch();
  },
  methods: {
    ...mapActions([
      ServiceOrderActions.ASSIGN_EMPLOYEE_TO_SERVICE_ORDER,
      ServiceOrderActions.FETCH_WORKFLOW,
      ServiceOrderActions.NOTIFY_ASSIGNEE
    ]),
    ...mapMutations([ServiceOrderMutations.SET_PROP]),
    async fetch() {
      this.loading = true;
      await this[ServiceOrderActions.FETCH_WORKFLOW]();
      this.loading = false;
    },
    setData(key, newValue) {
      let value = { ...this.workflow };
      value[key] = newValue;
      this[ServiceOrderMutations.SET_PROP]({ key: 'workflow', value });
    },
    async assign() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.assignToPlaceholder = 'Enter Assignee';
        return;
      }
      try {
        this.assigning = this.loading = true;
        await this[ServiceOrderActions.ASSIGN_EMPLOYEE_TO_SERVICE_ORDER]({
          assignedToEmployeeId: this.assignTo.employeeId,
          notify: this.notify,
          comment: this.comment
        });

        this.assignToPlaceholder = '';
        this.$v.$reset();
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to Assign Employee to Service Order');
      } finally {
        this.assigning = this.loading = false;
      }
    }
  }
};
</script>
