export const getDefaultState = () => {
  const defaultCustomerDetails = {
    customerId: null,
    customerName: null,
    branchId: null,
    address1: null,
    address2: null,
    city: null,
    county: null,
    state: null,
    zipCode: null,
    phone: null,
    fax: null,
    email: null,
    status: null,
    poRequired: false,
    ignoreDuplicatePhone: null,
    isABCustomer: null,
    billToCustomer: {
      customerId: null,
      billToCustomerId: null,
      billToCustomerName: null,
      billToAddress1: null,
      billToAddress2: null,
      billToCity: null,
      billToCounty: null,
      billToState: null,
      billToZipCode: null,
      billToPhone: null,
      billToFax: null,
      billToStatus: null,
      billToTermId: null,
      billToAvailableCredit: null
    }
  };

  return {
    customerSearchResults: [],
    customer: {
      ...defaultCustomerDetails
    },
    oldCustomer: {
      ...defaultCustomerDetails
    },
    approverList: [],
    vcpUserList: []
  };
};

export default {
  ...getDefaultState()
};
