<template>
  <div>
    <b-card class="page-card">
      <div class="page-header">
        <h2>Breakdowns</h2>
      </div>
      <responsive-search
        ref="search"
        :fields="searchFields"
        :is-busy="searchIsBusy"
        storage-key="breakdownSearch"
        @searchButtonClick="searchButtonClick"
        @resetButtonClick="resetButtonClick"
      />

      <b-row v-if="!$smallScreen">
        <b-form-group>
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              variant="position-relative"
              type="search"
              placeholder="Type to Filter"
              :debounce="500"
            ></b-form-input>

            <b-input-group-append>
              <div v-show="exportIsBusy">
                <b-button variant="primary" disabled class="ml-2">
                  <b-spinner small></b-spinner>
                  EXPORTING...
                </b-button>
              </div>
              <div class="ml-4 pr-2">
                <b-button v-show="!exportIsBusy" variant="secondary" @click="exportClick">Export</b-button>
              </div>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="pageSize"
          aria-controls="my-table"
          align="right"
          class="ml-auto w-auto"
        ></b-pagination>
      </b-row>
      <mobile-table v-if="$smallScreen" :items="breakdowns" :fields="fields">
        <template #[`breakdownId`]="{ item }">
          <label :class="`col-head`">Breakdown ID:</label>
          <safe-hyperlink :to="'units/' + item.unitId + '/breakdowns/' + item.breakdownId">
            {{ item.breakdownId }}
          </safe-hyperlink>
        </template>
        <template #[`branchId`]="{ item }">
          <label :class="`col-head`">Branch:</label>
          <div>{{ item.branchId }} - {{ item.branchName }}</div>
        </template>
        <template #[`vin`]="{ item }">
          <label :class="`col-head`">VIN/Serial:</label>
          <safe-hyperlink :to="'/units/' + item.unitId">
            {{ item.vin }}
          </safe-hyperlink>
        </template>
        <template #[`dateHelpRequested`]="{ item }">
          <label :class="`col-head`">Help Requested:</label>
          <div>
            {{ item.dateHelpRequested | date }}
          </div>
        </template>
        <template #[`serviceOrderId`]="{ item }">
          <label :class="`col-head`">Service Order ID:</label>
          <div v-if="$isCustomer && !item.posted">{{ item.serviceOrderId }}</div>
          <safe-hyperlink v-else :to="'units/' + item.unitId + '/service-orders/' + item.serviceOrderId">
            {{ item.serviceOrderId }}
          </safe-hyperlink>
        </template>
      </mobile-table>
      <b-table
        v-else
        class="border"
        no-border-collapse
        striped
        sticky-header="63vh"
        :items="breakdowns"
        :fields="fields"
        :per-page="pageSize"
        :current-page="currentPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No results found."
        empty-filtered-text="No results found."
        @filtered="onFiltered"
      >
        <template #cell(breakdownId)="{ item }">
          <safe-hyperlink :to="'units/' + item.unitId + '/breakdowns/' + item.breakdownId">
            {{ item.breakdownId }}
          </safe-hyperlink>
        </template>
        <template #cell(vin)="{ item }">
          <safe-hyperlink :to="'units/' + item.unitId">
            {{ item.vin }}
          </safe-hyperlink>
        </template>
        <template #cell(branchId)="{ item }">
          <div>
            {{ formatBranch(item) }}
          </div>
        </template>
        <template #cell(dateHelpRequested)="{ item }">
          <div>
            {{ item.dateHelpRequested | date }}
          </div>
        </template>
        <template #cell(serviceOrderId)="{ item }">
          <div v-if="$isCustomer && !item.posted">{{ item.serviceOrderId }}</div>
          <safe-hyperlink v-else :to="'units/' + item.unitId + '/service-orders/' + item.serviceOrderId">
            {{ item.serviceOrderId }}
          </safe-hyperlink>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
// Component
import ResponsiveSearchComponent from '@/shared/components/ResponsiveSearchComponent';
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
// mixins
import SearchMixin from '@/shared/mixins/SearchMixin';
// vuex
import { mapActions, mapGetters, mapState } from 'vuex';
import { LookupGetters } from '@/shared/store/lookup/types';
import { BreakdownActions } from '@/shared/store/breakdown/types';
// helpers
import ErrorService from '@/shared/services/ErrorService';
import ExportService from '@/shared/services/ExportService';

export default {
  name: 'BreakdownsSearchPage',
  components: {
    'responsive-search': ResponsiveSearchComponent,
    'safe-hyperlink': SafeHyperlinkComponent,
    'mobile-table': () => import('@/shared/components/MobileTableComponent')
  },
  mixins: [SearchMixin],
  beforeRouteUpdate(to, from, next) {
    next();
    this.$refs.search?.loadSearchParameters(to.query);
    this.runSearch();
  },
  data: function () {
    return {
      LookupGetters: LookupGetters,
      pageSize: 50,
      currentPage: 1,
      totalRows: 0,
      filter: null,
      sortBy: null,
      fields: [
        { key: 'breakdownId', label: 'Breakdown ID', sortable: true },
        { key: 'fleetUnitId', label: 'Fleet Unit Id', sortable: true },
        { key: 'vin', label: 'VIN/Serial', sortable: true, collapsible: true },
        { key: 'branchId', label: 'Branch', sortable: true },
        { key: 'breakdownReason', label: 'Breakdown Reason', sortable: true, collapsible: true },
        { key: 'dateHelpRequested', label: 'Help Requested', sortable: true, collapsible: true },
        { key: 'initiatedByName', label: 'Associate Initiating Call', sortable: true, collapsible: true },
        { key: 'serviceOrderId', label: 'Service Order ID', sortable: true, collapsible: true }
      ],
      searchIsBusy: false,
      exportIsBusy: false,
      filteredItems: null
    };
  },
  computed: {
    ...mapState(['breakdown']),
    ...mapGetters({
      breakdownReasonsList: LookupGetters.GET_BREAKDOWN_REASONS_LIST,
      breakdownBranchesList: LookupGetters.GET_BREAKDOWN_BRANCHES_LIST
    }),
    breakdowns() {
      return this.breakdown.breakdownSearchResults;
    },
    searchFields() {
      const branchList = this.$isCustomer ? { options: this.breakdownBranchesList } : {};
      return [
        { key: 'breakdownId', label: 'Breakdown ID' },
        { ...this.serviceOrderIdField, hidden: true, collapsible: true },
        { ...this.fleetUnitIdField },
        { ...this.vinField, collapsible: true },
        { ...this.branchField, ...branchList, collapsible: true },
        {
          key: 'breakdownReasons',
          label: 'Breakdown Reason',
          placeholder: 'Select Breakdown Reasons',
          options: this.breakdownReasonsList,
          reduce: x => x.name,
          selectLabel: 'name',
          collapsible: true
        },
        { ...this.licensePlateField, collapsible: true, hidden: true },
        { ...this.unitTypesField, collapsible: true, hidden: true },
        { ...this.unitStatusesField, collapsible: true, hidden: true }
      ];
    }
  },
  mounted() {
    const query = this.$refs.search?.getQueryString();
    this.$router.replace({ query }).catch(() => {
      this.runSearch();
    });
  },
  methods: {
    ...mapActions([BreakdownActions.SEARCH_BREAKDOWNS, BreakdownActions.EXPORT_BREAKDOWN_REPORT]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filteredItems = filteredItems;
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatBranch(item) {
      return `${item.branchId} - ${item.branchName}`;
    },
    resetButtonClick: async function () {
      this.filter = '';
      this.sortBy = '';
      await this.searchButtonClick(null);
    },
    searchButtonClick: async function (query) {
      await this.$router.push({ query }).catch(() => {
        this.runSearch();
      });
    },
    async runSearch() {
      try {
        this.searchIsBusy = true;
        await this[BreakdownActions.SEARCH_BREAKDOWNS](this.$route.query);
        this.totalRows = this.breakdowns.length;
        this.filteredItems = this.breakdowns;
      } catch (error) {
        ErrorService.unknownSearchError(this);
      } finally {
        this.searchIsBusy = false;
      }
    },
    exportClick: async function () {
      try {
        this.exportIsBusy = true;
        var byteArray = await this[BreakdownActions.EXPORT_BREAKDOWN_REPORT](this.filteredItems);
        ExportService.downloadByteArray(byteArray, 'BreakdownExport', true, '.xlsx');
      } catch (error) {
        ErrorService.unknownExportError(this);
      } finally {
        this.exportIsBusy = false;
      }
    }
  }
};
</script>
