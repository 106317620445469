<template>
  <div class="px-2">
    <div class="d-flex justify-content-center py-5">
      <img
        src="/images/vehicare-logo.svg"
        :width="$smallScreen ? 200 : 500"
        height="auto"
        class=""
        alt="Vehicare Fleet Solutions"
      />
    </div>
    <b-card v-if="hasError" class="page-card" :title="title">
      <b-card-text>
        <span v-if="$msalError">There was an issue during authentication</span>
        <span v-if="errorCode == 404">Your request could not be found</span>
        <span v-else-if="errorCode == 403">You do not have permission to this application</span>
        <span v-else-if="errorCode == 500">There was a Server Error</span>
        <span v-else>There was an error</span>
        <span>, please contact an administration for assistance.</span>

        <div class="note py-2">{{ $errorObject }}</div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  computed: {
    title() {
      if (this.$msalError || this.errorCode == 403) return 'Authentication Error';
      if (this.errorCode == 500) return 'Server Error';
      return 'Error';
    },
    hasError() {
      return this.$route.name == 'Error';
    },
    errorCode() {
      return this.$route.params.code;
    }
  },
  created() {
    if (!this.hasError && this.$msal.isAuthenticated()) {
      this.$router.push('/');
      return;
    }

    this.$msal.events.$on('login-success', () => {
      this.$router.push('/');
    });
  },
  beforeDestroy() {
    this.$msal.events.$off('login-success');
  }
};
</script>
