import axios from 'axios';

const DVIR_PATH_ENDING = '/api/dvirs';
const DVIRS_COUNT_PATH = '/api/dvirs-count';

class DvirService {
  async searchDvirDefects(parameters) {
    const url = `${DVIR_PATH_ENDING}`;
    const dvirs = await axios.get(url, {
      params: parameters
    });
    return dvirs;
  }

  async getDvirsCount() {
    const url = `${DVIRS_COUNT_PATH}`;
    const dvirsCount = await axios.get(url);
    return dvirsCount;
  }
}

export default new DvirService(); // singleton object
