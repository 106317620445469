<template>
  <div>
    <router-link v-if="activeRoute" :to="to" :append="append"><slot /></router-link>
    <div v-else><slot /></div>
  </div>
</template>

<script>
export default {
  name: 'SafeHyperlink',
  props: {
    to: {
      type: [String || Location],
      required: true
    },
    append: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activeRoute() {
      const resolve = this.$router.resolve(this.to, this.$route, this.append);
      const location = resolve.location;
      const resolved = resolve.resolved;
      if (resolved.redirectedFrom && resolved.redirectedFrom == location.path) return false;
      return true;
    }
  }
};
</script>
