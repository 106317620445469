import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, MountedEquipmentGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, MountedEquipmentGetters);

export default {
  [_getters.GET_MOUNTED_EQUIPMENTS]: state => {
    return state.mountedEquipments;
  },
  [_getters.GET_MOUNTED_EQUIPMENT]: state => {
    return state.mountedEquipment;
  },
  [_getters.HAS_LIFTGATE]: state => {
    let liftgateEquipmentTypeId = 3;
    return state.mountedEquipments.filter(me => me.mountedEquipmentTypeId == liftgateEquipmentTypeId).length > 0;
  },
  [_getters.HAS_GUARANTEED_LIFTGATE]: state => {
    let guaranteedContractStatus = 1;
    let liftgateEquipmentTypeId = 3;
    return (
      state.mountedEquipments.filter(
        me =>
          me.mountedEquipmentContractStatusId == guaranteedContractStatus &&
          me.mountedEquipmentTypeId == liftgateEquipmentTypeId
      ).length > 0
    );
  },
  [_getters.HAS_NON_GUARANTEED_LIFTGATE]: state => {
    let guaranteedContractStatus = 2;
    let liftgateEquipmentTypeId = 3;
    return (
      state.mountedEquipments.filter(
        me =>
          me.mountedEquipmentContractStatusId == guaranteedContractStatus &&
          me.mountedEquipmentTypeId == liftgateEquipmentTypeId
      ).length > 0
    );
  },
  [_getters.HAS_TEMPORARILY_EXEMPT_LIFTGATE]: state => {
    let temporarilyExemptContractStatus = 3;
    let liftgateEquipmentTypeId = 3;
    return (
      state.mountedEquipments.filter(
        me =>
          me.mountedEquipmentContractStatusId == temporarilyExemptContractStatus &&
          me.mountedEquipmentTypeId == liftgateEquipmentTypeId
      ).length > 0
    );
  },
  [_getters.HAS_MOUNTED_EQUIPMENT_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    return JSON.stringify(state.mountedEquipment, replacer) !== JSON.stringify(state.dbMountedEquipment, replacer);
  }
};
