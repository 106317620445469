// Used to pass along props for b-form-group components
// <b-form-group v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }" > ... </b-form-group>

export default {
  props: {
    labelClass: {
      type: [String, Array, Object],
      default: null
    },
    labelColsXl: {
      type: [Number, String, Boolean],
      default: false
    },
    labelColsLg: {
      type: [Number, String, Boolean],
      default: false
    },
    labelColsMd: {
      type: [Number, String, Boolean],
      default: false
    },
    labelColsSm: {
      type: [Number, String, Boolean],
      default: false
    },
    labelCols: {
      type: [Number, String, Boolean],
      default: false
    }
  }
};
