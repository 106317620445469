import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, UnitStatusHistoryMutations } from './types';

const _mutations = namespaceHelper.removeNamespace(namespace, UnitStatusHistoryMutations);

export default {
  [_mutations.SET_UNIT_STATUS_HISTORY](state, payload) {
    state.statusHistory = payload;
  }
};
