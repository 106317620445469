export const namespace = 'unit/outOfService/';

export const OutOfServiceGetters = {
  GET_UNIT_OUT_OF_SERVICES: namespace + 'getUnitOutOfServices',
  GET_CURRENT_OUT_OF_SERVICE: namespace + 'getCurrentOutOfService',
  GET_BACK_IN_SERVICE: namespace + 'getBackInService',
  HAS_OPEN_UNIT_OUT_OF_SERVICE: namespace + 'hasOpenUnitOutOfService',
  HAS_CURRENT_OOS_CHANGES: namespace + 'hasCurrentOOSChanges',
  HAS_BACK_IN_SERVICE_CHANGES: namespace + 'hasBackInServiceChanges',
  HAS_ANY_CHANGES: namespace + 'hasAnyChanges'
};

export const OutOfServiceActions = {
  MARK_UNIT_OUT_OF_SERVICE: namespace + 'markUnitOutOfService',
  FETCH_UNIT_OUT_OF_SERVICES: namespace + 'fetchUnitOutOfServices',
  UPDATE_UNIT_OUT_OF_SERVICE: namespace + 'updateUnitOutOfService',
  MARK_UNIT_BACK_IN_SERVICE: namespace + 'markUnitBackInService'
};

export const OutOfServiceMutations = {
  RESET_STATE: namespace + 'resetState',
  DISCARD_CHANGES: namespace + 'discardChanges',
  SET_UNIT_OUT_OF_SERVICES: namespace + 'setUnitOutOfServices',
  SET_CURRENT_OOS_PROP: namespace + 'setCurrentOutOfServiceProp',
  SET_BACK_IN_SERVICE_PROP: namespace + 'setBackInServiceProp'
};
