var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2"},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('vams-collapse',{attrs:{"title":"Deferred Work","visible":_vm.visible}},[_c('b-table',{attrs:{"items":_vm.deferredJobs,"fields":_vm.fields,"table-variant":"blue","thead-tr-class":"text-small","tbody-tr-class":"bg-half-alpha"},scopedSlots:_vm._u([{key:"head(selected)",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectAll),expression:"selectAll"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.selectAll)?_vm._i(_vm.selectAll,null)>-1:(_vm.selectAll)},on:{"change":function($event){var $$a=_vm.selectAll,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectAll=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectAll=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectAll=$$c}}}})])]},proxy:true},{key:"cell(selected)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(!item.isAssigned),expression:"!item.isAssigned"},{name:"model",rawName:"v-model",value:(_vm.selectedJobs),expression:"selectedJobs"}],attrs:{"type":"checkbox"},domProps:{"value":item,"checked":Array.isArray(_vm.selectedJobs)?_vm._i(_vm.selectedJobs,item)>-1:(_vm.selectedJobs)},on:{"change":function($event){var $$a=_vm.selectedJobs,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedJobs=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedJobs=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedJobs=$$c}}}}),_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(item.isAssigned),expression:"item.isAssigned"}],staticClass:"text-success",attrs:{"icon":"check"}})],1)]}},{key:"cell(deferredByEmployeeName)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left d-flex flex-column"},[_vm._v(" "+_vm._s(item.deferredByEmployeeName)+" ")])]}},{key:"cell(deferredByEmployeeDate)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left d-flex flex-column"},[_vm._v(" "+_vm._s(_vm._f("date")(item.deferredByEmployeeDate))+" ")])]}},{key:"cell(jobSubtotal)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left d-flex flex-column"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.jobSubtotal))+" ")])]}},{key:"cell(jobSlsId)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left d-flex flex-column"},[_vm._v(" "+_vm._s(item.jobSlsId)+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{attrs:{"size":"xsm"},on:{"click":function($event){return _vm.confirmDelete(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt"}})],1)]}}])})],1),_c('warning-modal',{ref:"DeferredJobDeleteWarning",attrs:{"id":"DeferredJobDeleteWarning","title":"Warning","warning-text":_vm.deleteText,"continue-btn-text":"Yes, Remove","cancel-btn-text":"Cancel"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }