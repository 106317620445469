<template>
  <div>
    <b-card class="page-card">
      <div class="d-flex justify-content-between page-header">
        <h2>Operation Forecast</h2>
        <div class="d-flex">
          <b-link :to="'/deferred-work'">
            <div class="ops-forecast-notification">
              <span class="icon">
                <img src="/images/deferred-work-gray.svg" alt="Deferred Work" />
                <span v-if="hasDeferredJobs" class="notification notification-has-jobs"></span>
                <span v-else class="notification notification-no-jobs"></span>
              </span>
              <span class="ml-1">Deferred Work</span>
              <span v-if="hasDeferredJobs" class="ml-1">({{ deferredJobsCount.count }})</span>
            </div>
          </b-link>

          <b-link :to="'/dvirs'" class="ml-3">
            <div class="ops-forecast-notification">
              <span class="icon">
                <img src="/images/road-gray.svg" alt="DVIR Defects" />
                <span v-if="hasDvirDefects" class="notification notification-has-jobs"></span>
                <span v-else class="notification notification-no-jobs"></span>
              </span>
              <span class="ml-1">DVIR Defects</span>
              <span v-if="hasDvirDefects" class="ml-1">
                ({{ dvirsCount.unsafeCount ? dvirsCount.unsafeCount : 0 }}/{{ dvirsCount.count }})
              </span>
            </div>
          </b-link>
        </div>
      </div>

      <responsive-search
        ref="search"
        storage-key="operationForecastSearch"
        :fields="searchFields"
        :is-busy="searchIsBusy"
        @searchButtonClick="searchButtonClick"
        @resetButtonClick="resetButtonClick"
      />

      <search-results v-bind="{ serviceRequestEnabled }" @requestService="requestService" />

      <transition v-if="!$smallScreen" name="slide">
        <unit-service-order-history-aside
          v-if="showServiceOrderAside"
          v-model="serviceOrderWithJob"
          :loading="asideIsBusy"
          class="fixed-right fixed-top"
          mark-complete
          @completed="serviceOrderCompleted"
        />
      </transition>
    </b-card>

    <service-request-modal v-if="serviceRequestEnabled" ref="serviceRequestModal" />

    <bulk-service-request-modal v-if="serviceRequestEnabled" />
  </div>
</template>

<script>
// components
import ResponsiveSearchComponent from '@/shared/components/ResponsiveSearchComponent';
import SearchResults from './OperationsForecastSearchResults';
// mixins
import SearchMixin from '@/shared/mixins/SearchMixin';
import OperationsForecastMixin from '@/shared/mixins/OperationsForecastMixin';
// vuex
import { mapGetters, mapActions } from 'vuex';
import { OperationsForecastActions } from '@/shared/store/operations-forecast/types';
import { UnitActions, UnitGetters } from '@/shared/store/unit/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { DeferredJobGetters } from '@/shared/store/deferred-job/types';
import { DvirGetters } from '@/shared/store/dvir/types';

// helpers
import CssHelper from '@/shared/helpers/operation-status-css-class-helper';
import ErrorService from '@/shared/services/ErrorService';
import AppointmentCssHelper from '@/shared/helpers/appointment-css-helper';

export default {
  name: 'OperationsForecastPage',
  components: {
    'search-results': SearchResults,
    'bulk-service-request-modal': () => import('./BulkServiceRequestModal'),
    'responsive-search': ResponsiveSearchComponent,
    'service-request-modal': () => import('@/shared/components/ServiceRequestModal'),
    'unit-service-order-history-aside': () => import('@/shared/components/service-order/UnitServiceOrderHistoryAside')
  },
  mixins: [SearchMixin, OperationsForecastMixin],
  beforeRouteUpdate(to, from, next) {
    next();
    this.$refs.search?.loadSearchParameters(to.query);
    this.runSearch();
  },
  data: function () {
    return {
      searchIsBusy: false,
      serviceOrderWithJob: {},
      CssHelper: CssHelper,
      AppointmentCssHelper: AppointmentCssHelper
    };
  },
  computed: {
    ...mapGetters({
      isFeatureFlagEnabled: LookupGetters.IS_FEATURE_FLAG_ENABLED,
      showServiceOrderAside: UnitGetters.GET_UNIT_SERVICE_ORDERS_ASIDE_SHOW,
      operationStatusesList: LookupGetters.GET_OPERATION_STATUSES_LIST,
      locationsList: LookupGetters.GET_LOCATIONS_LIST,
      unitBranchesList: LookupGetters.GET_SERVICE_ORDER_BRANCHES_LIST,
      deferredJobsCount: DeferredJobGetters.GET_DEFERRED_JOBS_COUNT,
      dvirsCount: DvirGetters.GET_DVIRS_COUNT,
      hasDeferredJobs: DeferredJobGetters.HAS_DEFERRED_JOBS,
      hasDvirDefects: DvirGetters.HAS_DVIR_DEFECTS
    }),
    searchFields() {
      const customerField = this.$isCustomer ? this.myCustomersField : this.customerField;
      const locationsField = this.$isCustomer ? this.myLocationsField : this.locationsField;
      return [
        {
          key: 'comingDue',
          label: 'Coming Due',
          radioOptions: [
            { text: 'All', value: null, tooltip: 'All managed units in branch and location.' },
            { text: '7 Day', value: '7', tooltip: 'Overdue, Due and Coming Due within 7 days.' },
            { text: '30 Day', value: '30', tooltip: 'Overdue, Due and Coming Due within 30 days.' },
            { text: '90 Day', value: '90', tooltip: 'Overdue, Due and Coming Due within 90 days.' },
            { text: '180 Day', value: '180', tooltip: 'Overdue, Due and Coming Due within 180 days.' }
          ]
        },
        { ...this.fleetUnitIdField },
        { ...this.vinField, collapsible: true, hidden: true },
        { ...customerField },
        { ...locationsField },
        {
          key: 'operationStatuses',
          label: 'Operation Status',
          options: this.operationStatusesList,
          placeholder: 'Select Operation Statuses'
        },
        { ...this.licensePlateField, collapsible: true, hidden: true },
        { ...this.unitTypesField, collapsible: true, hidden: true },
        { ...this.unitStatusesField, collapsible: true, hidden: true }
      ];
    },
    serviceRequestEnabled() {
      return (
        (this.isFeatureFlagEnabled('ServiceRequest') && !this.$isCustomer) ||
        (this.isFeatureFlagEnabled('VcpServiceRequest') && this.$isCustomer)
      );
    }
  },
  watch: {
    totalRows: function (value) {
      //Update totalUnits whenever totalRows is updated.
      let temp = value == this.operationsForecast.length ? this.operationsForecast : this.filteredItems;
      this.totalUnits = temp.map(item => item.vin).filter((value, index, self) => self.indexOf(value) === index).length;
    }
  },
  mounted() {
    const query = this.$refs.search?.getQueryString();
    this.$router.replace({ query }).catch(() => {
      this.runSearch();
    });
  },
  methods: {
    ...mapActions([OperationsForecastActions.FETCH_OPERATIONS_FORECAST, UnitActions.HIDE_SERVICE_ORDER_HISTORY_ASIDE]),
    serviceOrderCompleted: async function () {
      await this[UnitActions.HIDE_SERVICE_ORDER_HISTORY_ASIDE]();
      await this[OperationsForecastActions.FETCH_OPERATIONS_FORECAST](this.$route.query);
      this.totalRows = this.operationsForecast.length;
    },
    resetButtonClick: async function () {
      this.searchFilter = '';
      this.sortBy = '';
      this.searchButtonClick(null);
    },
    searchButtonClick: async function (query) {
      await this.$router.push({ query }).catch(() => {
        this.runSearch();
      });
    },
    runSearch: async function () {
      try {
        this.searchIsBusy = true;
        await this[OperationsForecastActions.FETCH_OPERATIONS_FORECAST](this.$route.query);

        this.filteredItems = this.operationsForecast;
        this.totalRows = this.operationsForecast.length;
        this.currentPage = 1;
        this.searchFilter = '';
      } catch (error) {
        ErrorService.unknownSearchError(this);
      } finally {
        this.searchIsBusy = false;
      }
    },
    requestService(unitId) {
      this.$refs['serviceRequestModal'].showAndLoad(unitId, null);
    }
  }
};
</script>
