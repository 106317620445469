import namespaceHelper from '@/shared/helpers/namespace-helper';
import DeferredJobService from '@/shared/services/DeferredJobService';
import { namespace, DeferredJobActions, DeferredJobMutations } from './types';

const _actions = namespaceHelper.removeNamespace(namespace, DeferredJobActions);
const _mutations = namespaceHelper.removeNamespace(namespace, DeferredJobMutations);

export default {
  async [_actions.FETCH_DEFERRED_JOBS]({ commit }, data) {
    const deferredJobsResult = await DeferredJobService.getDeferredJobs(data.unitId, data.params);
    commit(_mutations.SET_DEFERRED_JOBS, deferredJobsResult.data);
  },
  async [_actions.FETCH_DEFERRED_JOBS_COUNT]({ commit }) {
    const count = await DeferredJobService.getDeferredJobsCount();
    commit(_mutations.SET_DEFERRED_JOBS_COUNT, count.data);
  },
  async [_actions.SEARCH_DEFERRED_JOBS]({ commit }, parameters) {
    const response = await DeferredJobService.searchDeferredJobs(parameters);
    const searchResults = response.data;
    await commit(_mutations.SET_DEFERRED_JOBS_SEARCH_RESULTS, searchResults);
    return searchResults;
  },
  async [_actions.FETCH_UNIT_DEFERRED_JOBS]({ commit }, parameters) {
    const response = await DeferredJobService.searchDeferredJobs(parameters);
    const results = response.data;
    await commit(_mutations.SET_UNIT_DEFERRED_JOBS, results);
    return results;
  },
  async [_actions.DELETE_DEFERRED_JOB](_, data) {
    await DeferredJobService.deleteDeferredJob(data);
  }
};
