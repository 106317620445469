<template>
  <b-modal
    :id="`unsavedChangesModal_${_uid}`"
    :ref="`unsavedChangesModal_${_uid}`"
    :title="title"
    header-class="danger"
  >
    <slot>
      <p>{{ message }}</p>
    </slot>
    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="primary" @click="ok">
        {{ continueBtnText }}
      </b-button>
      <b-button size="sm" @click="cancel">
        {{ cancelBtnText }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'UnsavedChangesModal',
  props: {
    title: {
      type: String,
      default: () => 'Unsaved Changes'
    },
    hasChanges: {
      type: Function,
      default: () => false
    },
    continueBtnText: {
      type: String,
      default: 'Continue'
    },
    message: {
      type: String,
      default: 'You have unsaved changes that will be lost by leaving this page. What would you like to do?'
    },
    cancelBtnText: {
      type: String,
      default: 'Discard'
    }
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.blockNavigation);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.blockNavigation);
  },
  methods: {
    /**
     * @param that - scope of component that is using the modal
     * @param save - async function that saves data
     * @param next - router next function
     */
    show(that, next, save = null) {
      this.$bvModal.show(`unsavedChangesModal_${this._uid}`);
      this.$nextTick(() => {
        this.$refs[`unsavedChangesModal_${this._uid}`].$once('hide', bvEvent => {
          this.$refs[`unsavedChangesModal_${this._uid}`].$once('hidden', async () =>
            handleHide(bvEvent, this, next, save)
          );
        });
      });
    },
    blockNavigation: function (event) {
      if (this.hasChanges()) {
        event.preventDefault();
        event.returnValue = '';
      } else {
        return;
      }
    }
  }
};
async function handleHide(bvEvent, that, next, save) {
  if (bvEvent.trigger === 'ok') {
    if (save == null) {
      next ? next(false) : null;
    } else {
      await save()
        .then(() => {
          next ? next() : null;
        })
        .catch(() => {
          next ? next(false) : null;
        });
    }
  } else if (bvEvent.trigger === 'cancel') {
    next ? next() : null;
  } else {
    next ? next(false) : null;
  }
}
</script>
