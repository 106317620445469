import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, InspectionMutations } from './types';
import { getDefaultState } from './state';
import Cloner from '@/shared/helpers/cloner';

const _mutations = namespaceHelper.removeNamespace(namespace, InspectionMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_STAGE](state, stage) {
    state.stage = stage;
  },
  [_mutations.SET_PROP](state, { key, value }) {
    state.details[key] = value;
  },
  [_mutations.SET_ITEM_PROP](state, { id, key, value }) {
    state.itemMap[id][key] = value;
  },
  [_mutations.SET_TIRE_PROP](state, { itemId, tireId, key, value }) {
    state.tireItemMap[`${itemId}_${tireId}`].result[key] = value;
  },
  [_mutations.SET_BRAKE_PROP](state, { itemId, brakeId, key, value }) {
    state.brakeItemMap[`${itemId}_${brakeId}`].result[key] = value;
  },
  [_mutations.SET_INSPECTION](state, inspection) {
    state.details = Cloner.deepClone(inspection);
  },
  [_mutations.SET_DB_INSPECTION](state, inspection) {
    state.dbDetails = Cloner.deepClone(inspection);
  },
  [_mutations.SET_ITEM_MAP](state, { inspectionItemId, item }) {
    state.itemMap[inspectionItemId] = item;
  },
  [_mutations.SET_AXLE_INSPECTIONS](state, axleInspections) {
    state.axleInspections = axleInspections;
  },
  [_mutations.MAP_TIRE_CONFIGURATIONS](state, { inspectionItemId, tireConfigurations }) {
    state.itemMap[inspectionItemId].tireInspection = {
      ...state.itemMap[inspectionItemId].tireInspection,
      tireConfigurations
    };
    for (const config of tireConfigurations) {
      state.tireItemMap[`${inspectionItemId}_${config.tireConfigurationId}`] = config;
      let tire = state.tireItemMap[`${inspectionItemId}_${config.tireConfigurationId}`];
      if (!state.details.readonly && tire.savedTireSize && !tire.result.tireSize) {
        tire.result.tireSize = tire.savedTireSize;
      }
    }
  },
  [_mutations.MAP_BRAKE_CONFIGURATIONS](state, { inspectionItemId, brakeConfigurations }) {
    state.itemMap[inspectionItemId].brakeInspection = {
      ...state.itemMap[inspectionItemId].brakeInspection,
      brakeConfigurations
    };
    for (const config of brakeConfigurations) {
      state.brakeItemMap[`${inspectionItemId}_${config.brakeConfigurationId}`] = config;
    }
  },
  [_mutations.SET_INSPECTION_SEARCH_RESULTS](state, results) {
    state.inspectionSearchResults = results;
  },
  [_mutations.SET_ATTACHMENTS](state, attachments) {
    state.details.attachments = attachments;
    state.dbDetails.attachments = Cloner.deepClone(attachments);
  }
};
