<template>
  <div class="">
    <b-button
      :id="`collapse_${_uid}`"
      :ref="`collapse`"
      v-b-toggle="`collapse_${_uid}`"
      :aria-expanded="'false'"
      variant="card-header btn-collapse rounded-0"
      :class="textColor"
    >
      {{ title }}
    </b-button>
    <b-collapse :id="`collapse_${_uid}`" class="border border-top-0" :visible="visible">
      <div class="px-3 py-2"><slot></slot></div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'CollapseComponent',
  props: {
    title: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    textColor: {
      type: String,
      default: null
    }
  },
  created() {
    this.$emit('emitId', this._uid, this.title);
  },
  methods: {
    toggleCollapse() {
      this.$root.$emit('bv::toggle::collapse', `collapse_${this._uid}`);
    },
    expand() {
      if (this.$refs['collapse'].getAttribute('aria-expanded') == 'false') {
        this.toggleCollapse();
      }
    },
    collapse() {
      if (this.$refs['collapse'].getAttribute('aria-expanded') == 'true') {
        this.toggleCollapse();
      }
    }
  }
};
</script>
