import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, UnitActions, UnitGetters, UnitMutations } from './types';
import { LookupGetters } from '@/shared/store/lookup/types';
import UnitService from '@/shared/services/UnitService';
import Cloner from '@/shared/helpers/cloner';
import { UnitStatusHistoryMutations } from './unit-status-history/types';
import { UnitLocationHistoryMutations } from './unit-location-history/types';

const _actions = namespaceHelper.removeNamespace(namespace, UnitActions);
const _getters = namespaceHelper.removeNamespace(namespace, UnitGetters);
const _mutations = namespaceHelper.removeNamespace(namespace, UnitMutations);
const _unitStatusHistoryMutations = namespaceHelper.removeNamespace(namespace, UnitStatusHistoryMutations);
const _unitLocationHistoryMutations = namespaceHelper.removeNamespace(namespace, UnitLocationHistoryMutations);

export default {
  async [_actions.EXPORT_OPERATIONS_FORECAST](state, parameters) {
    const operationsForecastExportResult = await UnitService.exportOperationsForecast(parameters);
    return operationsForecastExportResult.data;
  },
  async [_actions.EXPORT_MILEAGE_ENGINE_HOURS](state, parameters) {
    const operationsForecastExportResult = await UnitService.exportMileageEngineHoursUpdateSheet(parameters);
    return operationsForecastExportResult.data;
  },
  async [_actions.EXPORT_FLEET_LIST](state, parameters) {
    const fleetListExportResult = await UnitService.exportFleetList(parameters);
    return fleetListExportResult.data;
  },
  async [_actions.IMPORT_MILEAGE_ENGINE_HOURS](state, parameters) {
    const operationsForecastImportResult = await UnitService.importMileageEngineHoursUpdateSheet(parameters);
    return operationsForecastImportResult.data;
  },
  async [_actions.FETCH_UNIT]({ commit }, unitId) {
    try {
      commit(_mutations.PUSH_LOADING);
      const unitResult = await UnitService.getUnit(unitId);
      commit(_mutations.SET_UNIT, unitResult.data);
      commit(_mutations.SET_OLD_UNIT);
    } finally {
      commit(_mutations.POP_LOADING);
    }
  },
  async [_actions.FETCH_UNIT_OPERATIONS_FORECAST]({ commit }, unitId) {
    const operationsForecastResult = await UnitService.getUnitOperationsForecast(unitId);
    commit(_mutations.SET_UNIT_OPERATIONS_FORECAST, operationsForecastResult.data);
  },
  async [_actions.FETCH_UNIT_SERVICE_ORDER_HISTORY]({ commit }, data) {
    try {
      commit(_mutations.PUSH_LOADING);
      const unitServiceOrderHistoryResult = await UnitService.getServiceOrders(data.unitId, data.params);
      commit(_mutations.SET_UNIT_SERVICE_ORDER_HISTORY, unitServiceOrderHistoryResult.data);
    } finally {
      commit(_mutations.POP_LOADING);
    }
  },
  async [_actions.FETCH_UNIT_STANDARD_OPERATION]({ commit }, data) {
    const unitStandardOperationResult = await UnitService.getUnitStandardOperation(
      data.unitId,
      data.standardOperationId
    );
    commit(_mutations.SET_UNIT_STANDARD_OPERATION, unitStandardOperationResult.data);
  },
  async [_actions.FETCH_UNIT_STANDARD_OPERATIONS]({ commit, rootGetters }, unitId) {
    try {
      commit(_mutations.PUSH_LOADING);
      const unitStandardOperationsResult = await UnitService.getUnitStandardOperations(unitId);
      let unitStandardOperations = unitStandardOperationsResult.data;

      unitStandardOperations =
        rootGetters[LookupGetters.GET_OPERATIONS_WITH_MATCHED_EXCEDE_JOBS](unitStandardOperations);
      commit(_mutations.SET_UNIT_STANDARD_OPERATIONS, unitStandardOperations);
    } finally {
      commit(_mutations.POP_LOADING);
    }
  },
  async [_actions.FETCH_UNIT_INSPECTIONS]({ commit }, unitId) {
    const unitInspectionsResult = await UnitService.getUnitInspections(unitId);
    let unitInspections = unitInspectionsResult.data;
    commit(_mutations.SET_UNIT_INSPECTIONS, unitInspections);
  },
  async [_actions.FETCH_UNIT_DVIR_DEFECTS]({ commit }, unitId) {
    const result = await UnitService.getUnitDvirDefects(unitId);
    let unitInspections = result.data;
    commit(_mutations.SET_UNIT_DVIR_DEFECTS, unitInspections);
  },
  [_actions.HIDE_SERVICE_ORDER_HISTORY_ASIDE]({ commit }) {
    commit(_mutations.SET_UNIT_SERVICE_ORDERS_ASIDE_SHOW, false);
  },
  async [_actions.UPDATE_UNIT_STANDARD_OPERATION]({ commit, getters }, updatedUnitStandardOperation) {
    const operation = Cloner.deepClone(getters[_getters.GET_UNIT_STANDARD_OPERATION]);
    operation.lastCompletedDate = updatedUnitStandardOperation.lastCompletedDate;
    operation.lastCompletedMileage = updatedUnitStandardOperation.lastCompletedMileage;
    operation.lastCompletedEngineHours = updatedUnitStandardOperation.lastCompletedEngineHours;
    operation.description = updatedUnitStandardOperation.description;
    operation.lastCompletedServiceOrderId = updatedUnitStandardOperation.lastCompletedServiceOrderId;
    operation.lastCompletedOperationId = updatedUnitStandardOperation.lastCompletedOperationId;

    const unitStandardOperationsResult = await UnitService.updateUnitStandardOperation(
      getters[_getters.GET_UNIT].unitId,
      getters[_getters.GET_UNIT_STANDARD_OPERATION].standardOperationId,
      operation
    );
    commit(_mutations.SET_UNIT_STANDARD_OPERATION, unitStandardOperationsResult.data);
  },

  [_actions.SHOW_SERVICE_ORDER_HISTORY_ASIDE]({ commit }) {
    commit(_mutations.SET_UNIT_SERVICE_ORDERS_ASIDE_SHOW, true);
  },
  async [_actions.UPDATE_UNIT]({ commit }, unit) {
    let updatedUnitResult = await UnitService.updateUnit(unit);
    commit(_mutations.SET_UNIT, updatedUnitResult.data);
    commit(_mutations.SET_OLD_UNIT);
  },
  async [_actions.UPDATE_UNIT_STANDARD_OPERATIONS]({ commit, getters, rootGetters }, unitStandardOperations) {
    let result = await UnitService.updateUnitStandardOperations(
      getters[_getters.GET_UNIT].unitId,
      unitStandardOperations
    );
    let updatedUnitStandardOperations = result.data;
    updatedUnitStandardOperations =
      rootGetters[LookupGetters.GET_OPERATIONS_WITH_MATCHED_EXCEDE_JOBS](updatedUnitStandardOperations);
    commit(_mutations.SET_UNIT_STANDARD_OPERATIONS, updatedUnitStandardOperations);
  },
  async [_actions.CHECK_VIN]({ commit }, vin) {
    const unitResult = await UnitService.checkVin(vin);
    commit(_mutations.SET_UNIT, unitResult.data);
    commit(_mutations.SET_OLD_UNIT);
  },
  async [_actions.CREATE_UNIT]({ commit }, unit) {
    const unitResult = await UnitService.createUnit(unit);
    commit(_mutations.SET_UNIT, unitResult.data);
    commit(_mutations.SET_OLD_UNIT);
  },
  async [_actions.UPDATE_UNIT_STATUS]({ commit, getters }, newUnitStatus) {
    const unitStatusResult = await UnitService.updateUnitStatus(getters[_getters.GET_UNIT].unitId, newUnitStatus);
    commit(_mutations.SET_PROP_DEFAULT, { key: 'unitStatusId', value: unitStatusResult.data.unitStatusId });
    commit(_unitStatusHistoryMutations.SET_UNIT_STATUS_HISTORY, unitStatusResult.data.statusHistory);
  },
  async [_actions.UPDATE_UNIT_LOCATION]({ commit, getters }, newUnitLocation) {
    const unitTransferResult = await UnitService.updateUnitLocation(getters[_getters.GET_UNIT].unitId, newUnitLocation);
    commit(_mutations.SET_PROP_DEFAULT, { key: 'locationId', value: unitTransferResult.data.unitLocationId });
    commit(_unitLocationHistoryMutations.SET_UNIT_LOCATION_HISTORY, unitTransferResult.data.locationHistory);
  }
};
