<template>
  <div>
    <h5 v-if="!hideHeaderText">{{ headerText }}</h5>
    <h6 class="text-uppercase">{{ title }}</h6>
    <b-spinner v-if="loading" class="text-center"></b-spinner>
    <div v-else>
      <div v-if="!$isCustomer">
        <div v-if="allowEdit" class="d-flex">
          <b-form-file
            v-model="attachmentFiles"
            multiple
            :accept="supportedTypesList.join(',')"
            placeholder="Choose a file..."
            drop-placeholder="Drop file here..."
            :file-name-formatter="formatNames"
            class="w-50"
          ></b-form-file>
          <span class="required-asterisk">*</span>
          <b-button variant="primary" class="ml-3" :disabled="uploadingFiles" @click="uploadAttachments()">
            <div v-show="uploadingFiles">
              <b-spinner small></b-spinner>
              Adding...
            </div>
            <div v-show="!uploadingFiles">Add</div>
          </b-button>
        </div>
        <div v-if="allowEdit" class="note">Size Limit 20MB</div>
        <div v-if="!isWarrantyAside" class="row col justify-content-end">
          <b-form-checkbox v-model="showRemoved" name="check-button" switch>Show Removed Attachments</b-form-checkbox>
        </div>
      </div>
      <b-overlay :show="loading" :opacity="0.6">
        <b-table
          class="align-middle border"
          striped
          sticky-header="90vh"
          no-border-collapse
          :items="attachments"
          :fields="fields"
          show-empty
          :empty-text="`There are no attachments for this ${label}`"
          :tbody-tr-class="grayOutIfRemoved"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(originalFilename)="{ item }">
            <span v-if="item.dateRemoved" disabled>{{ item.originalFilename }}</span>
            <b-link v-else @click="downloadFile(item)">{{ item.originalFilename }}</b-link>
          </template>

          <template #cell(description)="{ item }">
            <b-input-group v-if="!$isCustomer && !item.dateRemoved && allowEdit">
              <b-form-input
                :id="`attachment-${item.attachmentId}`"
                :value="item.description"
                :class="
                  item.description && !hasFocus(`attachment-${item.attachmentId}`) ? 'form-control-plaintext' : ''
                "
                placeholder="Click to add description..."
                @input="updateDescription(item, $event)"
              ></b-form-input>
            </b-input-group>
            <div v-else>{{ item.description }}</div>
          </template>

          <template #cell(info)="{ item }">
            <div>{{ item.dateCreate | date }}</div>
            <div v-if="item.employeeCreate" class="text-capitalize">{{ item.employeeCreate.name.toLowerCase() }}</div>
          </template>

          <template #cell(internal)="{ item }">
            <div class="text-center">
              <b-form-checkbox
                :disabled="!allowEdit || item.dateRemoved != null || !allowInternal"
                :checked="item.internal"
                @change="updateInternalFlag(item)"
              />
            </div>
          </template>

          <template #cell(dateRemoved)="{ item }">
            <div v-if="!item.dateRemoved" class="text-center">
              <b-button v-if="allowEdit" variant="secondary" size="sm" @click="confirmRemove(item)">
                <font-awesome-icon icon="trash-alt" />
              </b-button>
            </div>
            <div v-else>
              <span>Removed</span>
              <div>{{ item.dateRemoved | date }}</div>
              <employee-display :employee="item.employeeRemoved" :show-email="false" />
            </div>
          </template>
        </b-table>
      </b-overlay>
      <b-button
        v-if="!$isCustomer && allowEdit && !isWarrantySubmissionPage"
        variant="primary"
        :disabled="updatingAttachments"
        @click="updateAttachments()"
      >
        <div v-show="updatingAttachments">
          <b-spinner small></b-spinner>
          Updating...
        </div>
        <div v-show="!updatingAttachments">Update</div>
      </b-button>
      <warning-modal
        :id="`AttachmentDeleteWarning_${_uid}`"
        ref="DeleteWarning"
        warning-text="Are you sure you want to remove this attachment?"
        continue-btn-text="Yes, Remove"
      />
    </div>
  </div>
</template>

<script>
// Components
import WarningModal from '@/shared/components/WarningModal';
import EmployeeDisplayComponent from '@/shared/components/ui/EmployeeDisplayComponent';
// Vuex
import { AttachmentGetters, AttachmentActions, AttachmentMutations } from '@/shared/store/attachment/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { mapGetters, mapActions, mapMutations } from 'vuex';
// helpers
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';
import ExportService from '@/shared/services/ExportService';
// mixins
import UserAccessMixin from '@/shared/mixins/UserAccessMixin';
import UploadMixin from '@/shared/mixins/UploadMixin';

export default {
  name: 'Attachments',
  components: {
    'warning-modal': WarningModal,
    'employee-display': EmployeeDisplayComponent
  },
  mixins: [UserAccessMixin, UploadMixin],
  props: {
    storeKey: {
      type: String(),
      default: null
    },
    hideHeaderText: {
      type: Boolean(),
      default: false
    },
    isWarrantyAside: {
      type: Boolean(),
      default: false
    }
  },
  data() {
    return {
      loading: false,
      uploadingFiles: false,
      attachmentFiles: null,
      showRemoved: false,
      updatingAttachments: false,
      activeTab: 0,
      customerAttachments: null,
      numOfCustomerAttachments: 0
    };
  },
  computed: {
    ...mapGetters([
      AttachmentGetters.GET_ATTACHMENTS,
      AttachmentGetters.GET_WARRANTY_ASIDE_ATTACHMENTS,
      AttachmentGetters.GET_ATTACHMENTS_BY_KEY
    ]),
    ...mapGetters({
      supportedTypesList: LookupGetters.GET_SUPPORTED_TYPES_LIST
    }),
    selectedFiles() {
      if (!this.attachmentFiles) return '';
      return this.attachmentFiles.map(x => x.name).join(', ');
    },
    attachments() {
      let attachments = this.storeKey
        ? this[AttachmentGetters.GET_ATTACHMENTS_BY_KEY](this.storeKey)
        : this[AttachmentGetters.GET_ATTACHMENTS];

      if (this.$isCustomer) {
        attachments = attachments?.filter(attachment => !attachment.internal);
      }

      if (!this.showRemoved) {
        attachments = attachments?.filter(attachment => !attachment.dateRemoved);
      }

      if (this.isWarrantyAside) {
        let attachmentsWarrantyAside = this[AttachmentGetters.GET_WARRANTY_ASIDE_ATTACHMENTS];
        attachmentsWarrantyAside = attachmentsWarrantyAside?.filter(attachment => !attachment.dateRemoved);
        return attachmentsWarrantyAside;
      }

      return attachments;
    },
    fields() {
      const sharedOptions = { tdClass: 'align-middle', sortable: true };
      const fields = [
        { key: 'index', label: '#', tdClass: 'align-middle', thStyle: 'width: 20px' },
        { key: 'originalFilename', label: 'Name', ...sharedOptions },
        { key: 'description', label: 'Description', ...sharedOptions },
        { key: 'info', label: 'Added', ...sharedOptions }
      ];
      if (!this.$isCustomer) {
        if (!this.isWarrantySubmissionPage) {
          fields.push({ key: 'internal', label: 'Internal', ...sharedOptions });
        }
        fields.push({ key: 'dateRemoved', label: 'Actions', ...sharedOptions });
      }
      return fields;
    },
    serviceOrderId() {
      return this.$route.params.serviceOrderId;
    },
    unitInspectionId() {
      return this.$route.params.unitInspectionId;
    },
    allowEdit() {
      if (this.readonly || this.isWarrantyAside) return false;
      if (this.serviceOrderId || this.unitInspectionId) {
        return this.branchIsAccessible;
      }
      return true;
    },
    allowInternal() {
      return !this.$route.params.customerId;
    }
  },
  watch: {
    parentRequestsUpdate: {
      async handler(UpdateRequested) {
        if (UpdateRequested) {
          await this.updateAttachments();
          this.resetRequestUpdateFlag();
        }
      },
      immediate: false
    }
  },
  async created() {
    await this.fetch();
  },
  methods: {
    ...mapActions([
      AttachmentActions.FETCH_ATTACHMENTS,
      AttachmentActions.UPLOAD_ATTACHMENTS,
      AttachmentActions.DOWNLOAD_ATTACHMENT,
      AttachmentActions.UPDATE_ATTACHMENTS,
      AttachmentActions.REMOVE_ATTACHMENT
    ]),
    ...mapMutations([
      AttachmentMutations.SET_ATTACHMENT_DESCRIPTION,
      AttachmentMutations.SET_ATTACHMENT_INTERNAL_FLAG,
      AttachmentMutations.RESET_STATE
    ]),
    async fetch() {
      this.loading = true;
      try {
        await this[AttachmentActions.FETCH_ATTACHMENTS]({
          key: this.storeKey,
          route: this.path,
          isWarrantyAside: this.isWarrantyAside
        });
      } catch {
        ErrorService.createErrorToast(this, 'Error loading Attachments.');
      } finally {
        this.loading = false;
      }
    },
    async downloadFile(attachment) {
      try {
        const byteArray = await this[AttachmentActions.DOWNLOAD_ATTACHMENT]({
          attachment,
          route: this.path
        });
        ExportService.downloadByteArray(byteArray, attachment.originalFilename, false);
      } catch (error) {
        ErrorService.unknownExportError(this);
      }
    },
    async updateAttachments() {
      if (!this.isWarrantySubmissionPage && (this.attachments == null || this.attachments.length == 0)) {
        ErrorService.createErrorToast(this, 'Upload attachments before updating.');
        return;
      }
      try {
        this.updatingAttachments = this.loading = true;
        await this[AttachmentActions.UPDATE_ATTACHMENTS]({
          key: this.storeKey,
          attachments: this.attachments,
          route: this.path
        });
        if (!this.isWarrantySubmissionPage) {
          SuccessService.createSuccessToast(this.$root, `Updated attachments successfully.`);
        }
      } catch (error) {
        let errorMessage = 'Failed to update attachments.';
        ErrorService.createErrorToast(this, errorMessage);
      } finally {
        this.updatingAttachments = this.loading = false;
      }
    },
    updateDescription(attachment, description) {
      this[AttachmentMutations.SET_ATTACHMENT_DESCRIPTION]({ ...attachment, description });
    },
    updateInternalFlag(attachment) {
      const newFlagValue = !attachment.internal;
      const payload = {
        attachment,
        newFlagValue
      };
      this[AttachmentMutations.SET_ATTACHMENT_INTERNAL_FLAG](payload);
    },
    resetRequestUpdateFlag() {
      this.$emit('toggleOffRequestUpdateFlag', true);
    },
    hasFocus(id) {
      return document.activeElement === document.getElementById(id);
    },
    confirmRemove(attachment) {
      this.$refs.DeleteWarning.show(this, this.removeAttachment.bind(this, attachment));
    },
    async uploadAttachments() {
      await this.uploadFiles(this.attachmentFiles, this.storeKey);
      this.attachmentFiles = null;
    },
    async removeAttachment(attachment) {
      try {
        this.updatingAttachments = this.loading = true;
        await this[AttachmentActions.REMOVE_ATTACHMENT]({ key: this.storeKey, attachment, route: this.path });
      } catch (error) {
        let errorMessage = `Error removing attachment ${attachment.originalFilename}.`;
        ErrorService.createErrorToast(this, errorMessage);
      } finally {
        this.updatingAttachments = this.loading = false;
      }
    },
    grayOutIfRemoved(item) {
      return item && item.dateRemoved ? 'text-muted font-italic' : '';
    }
  }
};
</script>
