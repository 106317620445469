// vuex
import { mapActions, mapGetters } from 'vuex';
import { AttachmentActions } from '@/shared/store/attachment/types';
import { LookupGetters } from '@/shared/store/lookup/types';
// helpers
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';

export default {
  props: {
    label: String(),
    title: String(),
    readonly: Boolean(),
    branchId: {
      type: String(),
      default: null
    },
    path: {
      type: String(),
      default() {
        return this.$route.path;
      }
    },
    headerText: {
      type: String(),
      default: 'Attachments'
    },
    parentRequestsUpdate: {
      type: Boolean(),
      default: false
    },
    isWarrantySubmissionPage: {
      type: Boolean(),
      default: false
    }
  },
  data: function () {
    return {
      uploadingFiles: false
    };
  },
  computed: {
    ...mapGetters({
      supportedTypesList: LookupGetters.GET_SUPPORTED_TYPES_LIST
    })
  },
  methods: {
    ...mapActions([AttachmentActions.UPLOAD_ATTACHMENTS]),
    async uploadFiles(files, key = null) {
      if (files == null || files.length == 0) {
        ErrorService.createErrorToast(this, 'Choose files to upload.');
        return;
      }
      try {
        this.attachmentsTotalSizeCheckAsync(files);
      } catch (error) {
        ErrorService.createErrorToast(this, 'File size exceeds the maximum of 20MB.');
        files = null;
        return;
      }
      var attachments = null;
      try {
        this.uploadingFiles = true;
        attachments = await this[AttachmentActions.UPLOAD_ATTACHMENTS]({
          key,
          files,
          route: this.path
        });
        SuccessService.createSuccessToast(this.$root, `Uploaded ${this.formatNames(files)} successfully.`);
      } catch (error) {
        let errorMessage = 'Unexpected error. Please try again.';

        if (error.response && (error.response.status == 413 || error.response.status == 415)) {
          errorMessage = error.response.data.message;
        }

        if (error.response && error.response.status == 404) {
          errorMessage = `Failed to upload file.
              ${this.label} not found.  Save ${this.label} before attemping to add attachments.`;
        }
        ErrorService.createErrorToast(this, errorMessage);
      } finally {
        files = null;
        this.uploadingFiles = false;
      }
      return attachments;
    },
    attachmentsTotalSizeCheckAsync(files) {
      const TwentyMegaBytes = 20 * 1024 * 1024;
      let size = 0;
      files.forEach(file => {
        size += file.size;
      });
      if (size >= TwentyMegaBytes) {
        throw Error();
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      } else {
        return `${files.length} files`;
      }
    }
  }
};
