export const namespace = 'deferredJob/';

export const DeferredJobGetters = {
  GET_DEFERRED_JOBS: namespace + 'getDeferredJobs',
  GET_UNIT_DEFERRED_JOBS: namespace + 'getUnitDeferredJobs',
  GET_DEFERRED_JOBS_COUNT: namespace + 'getDeferredJobsCount',
  GET_DEFERRED_JOBS_SEARCH_RESULTS: namespace + 'getDeferredJobsSearchResults',
  GET_SELECTED_DEFERRED_JOBS: namespace + 'getSelectedDeferredJobs',
  HAS_DEFERRED_JOBS: namespace + 'hasDeferredJobs'
};

export const DeferredJobActions = {
  FETCH_DEFERRED_JOBS: namespace + 'fetchDeferredJobs',
  FETCH_DEFERRED_JOBS_COUNT: namespace + 'fetchDeferredJobsCount',
  FETCH_UNIT_DEFERRED_JOBS: namespace + 'fetchUnitDeferredJobs',
  DELETE_DEFERRED_JOB: namespace + 'deleteDeferredJob',
  SEARCH_DEFERRED_JOBS: namespace + 'searchDeferredJobs'
};

export const DeferredJobMutations = {
  RESET_STATE: namespace + 'resetState',
  SET_DEFERRED_JOBS: namespace + 'setDeferredJobs',
  SET_UNIT_DEFERRED_JOBS: namespace + 'setUnitDeferredJobs',
  SET_DEFERRED_JOBS_COUNT: namespace + 'setDeferredJobsCount',
  SET_DEFERRED_JOBS_SEARCH_RESULTS: namespace + 'setDeferredJobsSearchResults',
  SET_SELECTED_DEFERRED_JOBS: namespace + 'setSelectedDeferredJobs'
};
