import namespaceHelper from '@/shared/helpers/namespace-helper';
import Cloner from '@/shared/helpers/cloner';
import { namespace, BreakdownMutations } from './types';
import { getDefaultState } from './state';

const _mutations = namespaceHelper.removeNamespace(namespace, BreakdownMutations);

export default {
  [_mutations.SET_BREAKDOWN_SEARCH_RESULTS](state, payload) {
    state.breakdownSearchResults = payload;
  },
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_STAGE](state, stage) {
    state.stage = stage;
  },
  [_mutations.SET_PROP](state, payload) {
    state.details[payload.key] = payload.value;
  },
  [_mutations.DEFAULT_PROP](state, payload) {
    state.details[payload.key] = payload.value;
    state.oldDetails[payload.key] = payload.value;
  },
  [_mutations.SET_BREAKDOWN](state, breakdown) {
    state.details = breakdown;
    state.oldDetails = Cloner.deepClone(breakdown);
  }
};
