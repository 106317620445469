export const getDefaultState = () => {
  const currentOutOfService = {
    dateOutOfService: null,
    breakdownReasonId: null,
    complaint: null,
    dateEstimatedReturn: null,
    serviceOrderId: null,
    recipients: []
  };
  const backInService = {
    dateBackInService: null,
    description: null
  };

  return {
    unitOutOfServices: [],
    currentOutOfService: {
      ...currentOutOfService
    },
    oldCurrentOutOfService: {
      ...currentOutOfService
    },

    backInService: {
      ...backInService
    },
    oldBackInService: {
      ...backInService
    }
  };
};

export default {
  ...getDefaultState()
};
