import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, UnitGetters } from './types';
import { LookupGetters } from '@/shared/store/lookup/types';

const _getters = namespaceHelper.removeNamespace(namespace, UnitGetters);

export default {
  [_getters.IS_LOADING]: state => {
    return state.loading.length > 0;
  },
  [_getters.GET_UNIT_LOCATION_NAME]: (state, getters, rootState, rootGetters) => {
    const locations = rootGetters[LookupGetters.GET_LOCATIONS_MAP];
    if (!locations) {
      return null;
    }

    let location = locations[state.unit.locationId];

    return location?.description;
  },
  [_getters.GET_UNIT_OPERATIONS_FORECAST]: state => {
    return state.unitOperationsForecast;
  },
  [_getters.GET_UNIT_SERVICE_ORDER_HISTORY]: state => {
    return state.unitServiceOrderHistory;
  },
  [_getters.GET_UNIT]: state => {
    return state.unit;
  },
  [_getters.GET_OLD_UNIT]: state => {
    return state.dbUnit;
  },
  [_getters.GET_UNIT_STANDARD_OPERATION]: state => {
    return state.unitStandardOperation;
  },
  [_getters.GET_UNIT_STANDARD_OPERATIONS]: state => {
    return state.unitStandardOperations;
  },
  [_getters.GET_UNIT_INSPECTIONS]: state => {
    return state.unitInspections;
  },
  [_getters.GET_UNIT_DVIR_DEFECTS]: state => {
    return state.unitDvirDefects;
  },
  [_getters.GET_UNIT_SERVICE_ORDERS_ASIDE_SHOW]: state => {
    return state.unitServiceOrdersAsideShow;
  },
  [_getters.HAS_DUE_OPERATIONS]: state => {
    return state.unitStandardOperations.filter(op => op.operationStatus == 'Due').length > 0;
  },
  [_getters.HAS_OVERDUE_OPERATIONS]: state => {
    return state.unitStandardOperations.filter(op => op.operationStatus == 'Overdue').length > 0;
  },
  [_getters.IS_UNIT_GUARANTEED]: state => {
    return state.unit.attributes.filter(att => att.value === 'Guaranteed').length > 0;
  },
  [_getters.IS_UNIT_NON_GUARANTEED]: state => {
    return state.unit.attributes.filter(att => att.value === 'Non-Guaranteed').length > 0;
  },
  [_getters.IS_UNIT_TEMPORARILY_EXEMPT]: state => {
    return state.unit.attributes.filter(att => att.value === 'Temporarily Exempt').length > 0;
  },
  [_getters.HAS_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    return JSON.stringify(state.unit, replacer) !== JSON.stringify(state.dbUnit, replacer);
  }
};
