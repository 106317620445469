export const namespace = 'unit/';

export const UnitGetters = {
  GET_UNIT: namespace + 'getUnit',
  GET_OLD_UNIT: namespace + 'getOldUnit',
  GET_UNIT_LOCATION_NAME: namespace + 'getUnitLocationName',
  GET_UNIT_OPERATIONS_FORECAST: namespace + 'getUnitOperationsForecast',
  GET_UNIT_SERVICE_ORDER_HISTORY: namespace + 'getUnitServiceOrderHistory',
  GET_UNIT_SERVICE_ORDERS_ASIDE_SHOW: namespace + 'getUnitServiceOrdersAsideShow',
  GET_UNIT_STANDARD_OPERATION: namespace + 'getUnitStandardOperation',
  GET_UNIT_STANDARD_OPERATIONS: namespace + 'getUnitStandardOperations',
  GET_UNIT_INSPECTIONS: namespace + 'getUnitInspections',
  GET_UNIT_DVIR_DEFECTS: namespace + 'getUnitDvirDefects',
  IS_UNIT_GUARANTEED: namespace + 'isUnitGuaranteed',
  IS_UNIT_NON_GUARANTEED: namespace + 'isUnitNonGuaranteed',
  IS_UNIT_TEMPORARILY_EXEMPT: namespace + 'isUnitTemporarilyExempt',
  HAS_DUE_OPERATIONS: namespace + 'hasDueOperations',
  HAS_OVERDUE_OPERATIONS: namespace + 'hasOverdueOperations',
  HAS_CHANGES: namespace + 'hasChanges',
  IS_LOADING: namespace + 'isLoading'
};

export const UnitActions = {
  EXPORT_MILEAGE_ENGINE_HOURS: namespace + 'exportMileageEngineHours',
  EXPORT_FLEET_LIST: namespace + 'exportFleetList',
  EXPORT_OPERATIONS_FORECAST: namespace + 'exportOperationsForecast',
  FETCH_UNIT: namespace + 'fetchUnit',
  FETCH_UNIT_OPERATIONS_FORECAST: namespace + 'fetchUnitOperationsForecast',
  FETCH_UNIT_SERVICE_ORDER_HISTORY: namespace + 'fetchUnitServiceOrderHistory',
  FETCH_UNIT_STANDARD_OPERATION: namespace + 'fetchUnitStandardOperation',
  FETCH_UNIT_STANDARD_OPERATIONS: namespace + 'fetchUnitStandardOperations',
  FETCH_UNIT_INSPECTIONS: namespace + 'fetchUnitInspections',
  FETCH_UNIT_DVIR_DEFECTS: namespace + 'fetchUnitDvirDefects',
  HIDE_SERVICE_ORDER_HISTORY_ASIDE: namespace + 'hideServiceOrderHistoryAside',
  IMPORT_MILEAGE_ENGINE_HOURS: namespace + 'importMileageEngineHours',
  SHOW_SERVICE_ORDER_HISTORY_ASIDE: namespace + 'showServiceOrderHistoryAside',
  UPDATE_UNIT: namespace + 'updateUnit',
  UPDATE_UNIT_STANDARD_OPERATION: namespace + 'updateUnitStandardOperation',
  UPDATE_UNIT_STANDARD_OPERATIONS: namespace + 'updateUnitStandardOperations',
  CHECK_VIN: namespace + 'checkVin',
  CREATE_UNIT: namespace + 'createUnit',
  UPDATE_UNIT_STATUS: namespace + 'updateUnitStatus',
  UPDATE_UNIT_LOCATION: namespace + 'updateUnitLocation'
};

export const UnitMutations = {
  RESET_STATE: namespace + 'resetState',
  SET_OPERATIONS_FORECAST: namespace + 'setOperationsForecast',
  SET_UNIT_SERVICE_ORDER_HISTORY: namespace + 'setUnitServiceOrderHistory',
  SET_UNIT: namespace + 'setUnit',
  SET_OLD_UNIT: namespace + 'setOldUnit',
  DISCARD_CHANGES: namespace + 'setDiscardChanges',
  SET_UNIT_OPERATIONS_FORECAST: namespace + 'setUnitOperationsForecast',
  SET_UNIT_STANDARD_OPERATION: namespace + 'setUnitStandardOperation',
  SET_UNIT_STANDARD_OPERATIONS: namespace + 'setUnitStandardOperations',
  SET_UNIT_INSPECTIONS: namespace + 'setUnitInspections',
  SET_UNIT_DVIR_DEFECTS: namespace + 'setUnitDvirDefects',
  SET_SERVICE_ORDER_JOBS: namespace + 'setServiceOrderJobs',
  SET_UNIT_SERVICE_ORDERS_ASIDE_SHOW: namespace + 'setUnitServiceOrdersAsideShow',
  SET_ATTACHMENTS: namespace + 'setAttachments',
  SET_PROP: namespace + 'setProp',
  SET_PROP_DEFAULT: namespace + 'setPropDefault',
  SET_POWER_SOURCE_PROP: namespace + 'setPowerSourceProp',
  SET_TRANSMISSION_PROP: namespace + 'setTransmissionProp',
  SET_ATTRIBUTE_VALUE: namespace + 'setAttributeValue',
  PUSH_LOADING: namespace + 'pushLoading',
  POP_LOADING: namespace + 'popLoading'
};
