export const getDefaultState = () => {
  const tireReplacement = {
    tireConfigurationId: null,
    tireTypeId: null,
    tireManufacturerId: null,
    dotNumber: null,
    tireModel: null,
    tireReplacementReasonId: null,
    tireSize: null,
    loadRangeId: null,
    treadDepthId: null,
    poundsPerSquareInch: null
  };

  return {
    tireReplacements: [],
    newTireReplacement: {
      ...tireReplacement
    },
    dbNewTireReplacement: {
      ...tireReplacement
    },
    oldTireReplacement: {
      ...tireReplacement
    },
    dbOldTireReplacement: {
      ...tireReplacement
    }
  };
};

export default {
  ...getDefaultState()
};
