import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import App from './App.vue';
import AppRouter from '@/vcp/AppRouter';
import '@/shared/helpers/font-awesome-component';
import '@/vcp/plugins/bootstrap-vue';
import '@/shared/helpers/vue-select-default';
import store from '@/shared/store';
import axios from 'axios';
import Vuelidate from 'vuelidate';
import Vue2Filters from 'vue2-filters';
import VamsFilters from '@/shared/helpers/filters';
import VueCurrencyInput from 'vue-currency-input';
import { ConfigMutations } from '@/shared/store/config/types';
import { RootMutations } from '@/shared/store/types';
import msalVue from '@/vcp/plugins/msal-vue';
import VueAppInsights from 'vue-application-insights';
import DataHelper from '@/shared/helpers/data-helper';
import AxiosHelper from '@/shared/helpers/axios-helper';
import timeZoneHelper from '@/shared/helpers/time-zone-helper';
import GlobalMixin from '@/shared/mixins/GlobalMixin';
import { detect } from 'detect-browser';

function isSupportedBrowser() {
  const supportedBrowsers = ['chrome', 'edge-chromium'];
  const semiSupportedBrowsers = ['firefox'];
  const detected = detect();
  return supportedBrowsers.includes(detected.name) || semiSupportedBrowsers.includes(detected.name);
}

if (!isSupportedBrowser()) {
  const redirectPath = `${window.location.origin}/browser-not-supported.html`;
  window.location.replace(redirectPath);
}

timeZoneHelper.configureZoneAbbreviations();

axios.defaults.transformResponse = AxiosHelper.responseTransformers;

axios.get('/config.json').then(({ data }) => {
  Vue.prototype.$msalError = false;
  Vue.prototype.$errorObject = null;
  Vue.prototype.$isCustomer = true;
  Vue.prototype.$isMobileApp = false;
  Vue.prototype.$isVAMSApp = false;
  Vue.config.productionTip = true;

  Vue.use(VueRouter);
  Vue.use(Vuex);
  Vue.use(Vuelidate);
  Vue.use(Vue2Filters);
  Vue.use(VamsFilters);

  const currencyOptions = {
    globalOptions: { currency: 'USD', locale: 'en-US' }
  };
  Vue.use(VueCurrencyInput, currencyOptions);

  Vue.mixin(GlobalMixin);

  const msalConfig = data.vcp.msalConfig;
  msalConfig.auth.redirectUri = location.origin;
  msalConfig.auth.postLogoutRedirectUri = location.origin + '/login';

  try {
    Vue.use(msalVue, msalConfig);
  } catch (error) {
    Vue.prototype.$msalError = true;
    Vue.prototype.$errorObject = error;
  }

  data.vcp.powerBIReports.enabled = DataHelper.normalizeBoolean(data.vcp.powerBIReports.enabled);

  store.commit(ConfigMutations.SET_CONFIG, data.vcp);

  store.commit(RootMutations.SET_SCREEN_WIDTH, document.body.clientWidth);

  window.addEventListener('resize', function () {
    store.commit(RootMutations.SET_SCREEN_WIDTH, document.body.clientWidth);
  });

  const appRouter = new AppRouter(store).router;

  const instrumentationKey = data.vcp.applicationInsights.instrumentationKey;
  if (instrumentationKey) {
    Vue.use(VueAppInsights, {
      id: instrumentationKey,
      router: appRouter
    });
  }

  new Vue({
    router: appRouter,
    store: store,
    render: h => h(App)
  }).$mount('#app');
});
