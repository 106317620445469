export default {
  format: function (val) {
    const clean = val.replace(/[^0-9()\- ]{11,}/g, '');
    let formatted = clean;
    if (clean.length < 10) formatted = clean.replace(/\D?(\d{3})\D?(\d{4})/, '$1-$2');
    else formatted = clean.replace(/\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})/, '($1) $2-$3');
    return formatted;
  },
  parse: function (val) {
    const unformatted = val.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1$2$3');
    return unformatted;
  }
};
