import axios from 'axios';
import { UNIT_PATH, UNIT_SERVICE_ORDERS_PATH_ENDING } from './UnitService';

const TAXES_ENDING = '/taxes';

class UnitServiceOrderService {
  async calculateTaxes({ unitId, serviceOrderId }) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(
      serviceOrderId
    )}/${TAXES_ENDING}`;
    return await axios.post(url);
  }
}

export default new UnitServiceOrderService(); // singleton object
