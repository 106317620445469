import { RootGetters } from '@/shared/store/types';

export default {
  computed: {
    $smallScreen() {
      return this.$store.getters[RootGetters.IS_SMALL_SCREEN];
    }
  },
  methods: {
    emptyObject(someObject) {
      return Object.keys(someObject).length == 0;
    }
  }
};
