<template>
  <b-row no-gutters class="vams-summary">
    <div class="mr-4">
      <label class="col-head">{{ estimorderDisplayText }} ID:</label>
      <h4>{{ serviceOrder.serviceOrderId }}</h4>
    </div>
    <div class="mr-4">
      <label class="col-head">Fleet Unit ID:</label>
      <h4>{{ serviceOrder.fleetUnitId }}</h4>
    </div>
    <div v-if="getUnitLocationName" class="mr-4">
      <label class="col-head">Unit Location:</label>
      <h4>{{ getUnitLocationName }}</h4>
    </div>
    <div class="mr-4">
      <label class="col-head">Customer:</label>
      <h4>{{ customer.customerName }}</h4>
    </div>
    <div class="mr-4">
      <label class="col-head">Customer ID:</label>
      <router-link v-if="!$isCustomer" :to="'/customers/' + customer.customerId">
        <h4>{{ customer.customerId }}</h4>
      </router-link>
      <div v-else>
        <h4>{{ customer.customerId }}</h4>
      </div>
    </div>
    <div class="mr-4">
      <label class="col-head">VIN/Serial:</label>
      <font-awesome-icon
        v-if="unit.year || unit.make || unit.model"
        id="unit_tooltip_icon"
        icon="info-circle"
        class="mx-1 text-primary align-self-center"
        display="inline-block"
      />
      <b-tooltip id="unit_tooltip" target="unit_tooltip_icon" triggers="hover">
        <div>
          {{ unit.year }}
          {{ unit.make }}
          {{ unit.model }}
        </div>
      </b-tooltip>
      <h4>
        <router-link v-if="unit.unitId" :to="'/units/' + unit.unitId">
          {{ serviceOrder.vin }}
        </router-link>
        <div v-else>{{ serviceOrder.vin }}</div>
      </h4>
    </div>
    <div v-if="!isEstimate" class="mr-4">
      <label class="col-head">SO Status:</label>
      <h4>{{ serviceOrder.invoiced ? 'Invoiced' : 'Open' }}</h4>
    </div>
    <customer-attributes v-if="!serviceOrder.invoiced"></customer-attributes>
    <div v-if="serviceOrder.invoiced" class="mr-4">
      <label class="col-head">Total:</label>
      <h4>{{ serviceOrder.total || 0 | currency }}</h4>
    </div>
    <div v-else class="mr-4">
      <label class="col-head">Subtotal:</label>
      <h4>{{ serviceOrder.subtotal || 0 | currency }}</h4>
    </div>
    <div v-if="!serviceOrder.invoiced" class="d-inline-block">
      <div v-show="hasDueOperations">
        <b-badge id="tooltip-due" variant="warning" class="w16px mr-1">!</b-badge>
        <b-tooltip target="tooltip-due" triggers="hover">Operation Due</b-tooltip>
      </div>
    </div>
    <div v-if="!serviceOrder.invoiced" class="d-inline-block">
      <div v-show="hasOverdueOperations">
        <b-badge id="tooltip-overdue" variant="danger" class="w16px mr-1">!</b-badge>
        <b-tooltip target="tooltip-overdue" triggers="hover">Operation Overdue</b-tooltip>
      </div>
    </div>
    <div v-if="!$isCustomer" class="ml-auto">
      <b-dropdown v-if="isEstimate" :disabled="printIsBusy" class="btn-sm">
        <template #button-content>
          <b-spinner v-show="printIsBusy"></b-spinner>
          <img
            v-show="!printIsBusy"
            v-b-tooltip
            title="Print to PDF"
            triggers="hover"
            src="/images/printer-filled.svg"
            width="20px"
            height="auto"
            alt="print"
          />
        </template>
        <b-dropdown-item
          v-for="form in printFormsList.filter(f => f.showOnEstimate)"
          :key="form.formId"
          @click="printSelectedForm(form)"
        >
          {{ form.name }}
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown v-if="!isEstimate" size="sm" variant="secondary" class="btn-sm">
        <template #button-content>
          <b-spinner v-show="printIsBusy"></b-spinner>
          <img
            v-show="!printIsBusy"
            v-b-tooltip
            title="Print to PDF"
            triggers="hover"
            src="/images/printer-filled.svg"
            width="20px"
            height="auto"
            alt="print"
          />
        </template>
        <b-dropdown-item
          v-for="form in printFormsList.filter(f => f.showOnServiceOrder)"
          :key="form.formId"
          @click="printSelectedForm(form)"
        >
          {{ form.name }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <slot></slot>
  </b-row>
</template>

<script>
// components
import CustomerAttributes from '@/shared/components/customer/CustomerAttributesComponent';
// vuex
import { mapActions, mapGetters } from 'vuex';
import { UnitGetters } from '@/shared/store/unit/types';
import { ServiceOrderActions, ServiceOrderGetters } from '@/shared/store/service-order/types';
import { LookupGetters } from '@/shared/store/lookup/types';
// helpers
import ErrorService from '@/shared/services/ErrorService';
import ExportService from '@/shared/services/ExportService';

export default {
  name: 'ServiceOrderSummary',
  components: {
    'customer-attributes': CustomerAttributes
  },
  data() {
    return {
      printIsBusy: false
    };
  },
  computed: {
    ...mapGetters({
      unit: UnitGetters.GET_UNIT,
      getUnitLocationName: UnitGetters.GET_UNIT_LOCATION_NAME,
      hasDueOperations: UnitGetters.HAS_DUE_OPERATIONS,
      hasOverdueOperations: UnitGetters.HAS_OVERDUE_OPERATIONS,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      customer: ServiceOrderGetters.GET_CUSTOMER,
      estimorderDisplayText: ServiceOrderGetters.GET_ESTIMORDER_DISPLAY_TEXT,
      isEstimate: ServiceOrderGetters.GET_IS_ESTIMATE,
      printFormsList: LookupGetters.GET_PRINT_FORMS_LIST
    })
  },
  methods: {
    ...mapActions({
      print: ServiceOrderActions.PRINT_FORM
    }),
    async printSelectedForm(printForm) {
      try {
        this.printIsBusy = true;
        const fileName = `${printForm.name}-${this.serviceOrder.serviceOrderId}`;

        const byteArray = await this.print(printForm.formId);
        ExportService.downloadByteArray(byteArray, fileName, true, '.pdf');
      } catch (error) {
        ErrorService.unknownPrintError(this);
      } finally {
        this.printIsBusy = false;
      }
    }
  }
};
</script>
