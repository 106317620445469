import axios from 'axios';
export const UNIT_PATH = '/api/units';
const UNIT_TRANSFER_PATH = 'unitTransfer';

class UnitLocationHistoryService {
  async fetchUnitLocationHistory(unitId) {
    const url = `${UNIT_PATH}/${UNIT_TRANSFER_PATH}/${encodeURIComponent(unitId)}`;
    const response = await axios.get(url);
    return response;
  }
}

export default new UnitLocationHistoryService(); // singleton object
