import axios from 'axios';

class UploadService {
  async upload(url, files) {
    let formData = new FormData();

    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      formData.append('files[' + i + ']', file);
    }

    const result = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return result;
  }
}

export default new UploadService();
