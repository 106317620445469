import namespaceHelper from '@/shared/helpers/namespace-helper';
import { replacer } from '@/shared/helpers/json-replacer';
import { namespace, ServiceOrderGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, ServiceOrderGetters);

export default {
  // root
  [_getters.GET_FORM_STAGE]: state => {
    return state.formStage;
  },
  [_getters.GET_CUSTOMER]: state => {
    return state[state.estimorder].customer;
  },
  [_getters.GET_CUSTOMER_ALERTS]: state => {
    return state.customerAlerts;
  },
  [_getters.GET_CUSTOMER_ALERT]: state => {
    return state.customerAlert;
  },
  [_getters.GET_BILL_TO_CUSTOMER]: state => {
    return state[state.estimorder].billToCustomer;
  },
  [_getters.GET_BILL_TO_CUSTOMER_ALERT]: state => {
    return state.billToCustomerAlert;
  },
  [_getters.GET_BILL_TO_CUSTOMER_ALERTS]: state => {
    return state.billToCustomerAlerts;
  },
  [_getters.GET_CUSTOMER_SPECIFIC_REPAIR_REASONS]: state => {
    return state.customerSpecificRepairReasons;
  },
  [_getters.GET_SO_SEARCH_RESULTS]: state => {
    return state.serviceOrderSearchResults;
  },
  [_getters.GET_EST_SEARCH_RESULTS]: state => {
    return state.estimateSearchResults;
  },

  // SO Level
  [_getters.GET_SERVICE_ORDER]: state => {
    return state[state.estimorder];
  },
  [_getters.GET_SERVICE_ORDER_TO_SAVE]: state => {
    const serviceOrder = state[state.estimorder];
    const serviceOrderToSave = {
      isEstimate: serviceOrder.isEstimate,
      estimateId: serviceOrder.estimateId,
      billToCustomerId: serviceOrder.billToCustomer?.customerId,
      appointmentId: serviceOrder.appointmentId,
      breakdownId: serviceOrder.breakdownId,
      branchId: serviceOrder.branchId,
      dateSchedule: serviceOrder.dateSchedule,
      dateOpen: serviceOrder.dateOpen,
      dateUnitArrive: serviceOrder.dateUnitArrive,
      datePromise: serviceOrder.datePromise,
      dateNotify: serviceOrder.dateNotify,
      dateNotifyDiag: serviceOrder.dateNotifyDiag,
      dateAuthorize: serviceOrder.dateAuthorize,
      dateActualRepair: serviceOrder.dateActualRepair,
      dateExpire: serviceOrder.dateExpire,
      repairPriorityId: serviceOrder.repairPriorityId,
      repairReasonId: serviceOrder.repairReasonId,
      repairStatusId: serviceOrder.repairStatusId,
      repairSiteId: serviceOrder.repairSiteId,
      licensePlate: serviceOrder.licensePlate,
      mileage: serviceOrder.mileage || 0,
      engineHours: serviceOrder.engineHours || 0,
      salesTypeId: serviceOrder.salesTypeId,
      purchaseOrder: serviceOrder.purchaseOrder,
      attributes: serviceOrder.attributes ? Object.values(serviceOrder.attributes) : [],
      jobs: serviceOrder.jobs ? Object.values(serviceOrder.jobs) : [],
      timestampSVSLS: serviceOrder.timestampSVSLS,
      total: serviceOrder.total
    };
    return serviceOrderToSave;
  },
  [_getters.GET_SERVICE_ORDER_ID]: state => {
    return state[state.estimorder].serviceOrderId;
  },
  [_getters.GET_ESTIMATE_ID]: state => {
    return state[state.estimorder].estimateId;
  },
  [_getters.GET_READONLY]: state => {
    return state[state.estimorder].readonly;
  },
  [_getters.GET_BRANCH_ID]: state => {
    return state[state.estimorder].branchId;
  },
  [_getters.GET_SALES_TYPE_ID]: state => {
    return state[state.estimorder].salesTypeId;
  },
  [_getters.GET_NEW_BILL_TO_CUSTOMER]: state => {
    return state[state.estimorder].newBillToCustomer;
  },
  [_getters.GET_PURCHASE_ORDER]: state => {
    return state[state.estimorder].purchaseOrder;
  },
  [_getters.GET_REPAIR_PRIORITY]: state => {
    return state[state.estimorder].repairPriorityId;
  },
  [_getters.GET_REPAIR_SITE]: state => {
    return state[state.estimorder].repairSiteId;
  },
  [_getters.GET_REPAIR_REASON]: state => {
    return state[state.estimorder].repairReasonId;
  },
  [_getters.GET_REPAIR_STATUS]: state => {
    return state[state.estimorder].repairStatusId;
  },
  [_getters.GET_IS_ESTIMATE]: state => {
    return state[state.estimorder].isEstimate;
  },
  [_getters.GET_LICENSE_PLATE]: state => {
    return state[state.estimorder].licensePlate;
  },
  [_getters.GET_MILEAGE]: state => {
    return state[state.estimorder].mileage;
  },
  [_getters.GET_ENGINE_HOURS]: state => {
    return state[state.estimorder].engineHours;
  },
  [_getters.GET_DATE_CREATE]: state => {
    return state[state.estimorder].dateCreate;
  },
  [_getters.GET_DATE_OPEN]: state => {
    return state[state.estimorder].dateOpen;
  },
  [_getters.GET_DATE_SCHEDULE]: state => {
    return state[state.estimorder].dateSchedule;
  },
  [_getters.GET_DATE_UNIT_ARRIVE]: state => {
    return state[state.estimorder].dateUnitArrive;
  },
  [_getters.GET_DATE_PROMISE]: state => {
    return state[state.estimorder].datePromise;
  },
  [_getters.GET_DATE_NOTIFY]: state => {
    return state[state.estimorder].dateNotify;
  },
  [_getters.GET_DATE_NOTIFY_DIAG]: state => {
    return state[state.estimorder].dateNotifyDiag;
  },
  [_getters.GET_DATE_ACTUAL_REPAIR]: state => {
    return state[state.estimorder].dateActualRepair;
  },
  [_getters.GET_DATE_EXPIRE]: state => {
    return state[state.estimorder].dateExpire;
  },
  [_getters.GET_DATE_AUTHORIZE]: state => {
    return state[state.estimorder].dateAuthorize;
  },
  [_getters.GET_ESTIMORDER_DISPLAY_TEXT]: state => {
    if (state[state.estimorder].isEstimate) {
      return 'Estimate';
    }
    return 'Service Order';
  },
  [_getters.HAS_OTHER_ESTIMORDER]: state => {
    if (state[state.estimorder].isEstimate) {
      return state.serviceOrder.serviceOrderId != null;
    } else {
      return state.estimate.serviceOrderId != null;
    }
  },
  [_getters.HAS_ESTIMORDER_ERROR]: state => estimorder => {
    return state[estimorder]._error;
  },
  [_getters.HAS_CHANGES]: state => prop => {
    let current = state[state.estimorder];
    let old = state['old' + state.estimorder];
    if (prop) {
      current = current[prop];
      old = old[prop];
    }
    return JSON.stringify(current, replacer) !== JSON.stringify(old, replacer);
  },
  [_getters.GET_OLD_ESTIMORDER]: state => {
    return state['old' + state.estimorder];
  },

  // Job Level
  [_getters.GET_JOBS]: state => {
    return state[state.estimorder].jobs;
  },
  [_getters.GET_JOB]: state => jobKey => {
    return state[state.estimorder].jobs[jobKey];
  },
  [_getters.GET_COMPLAINT]: state => jobKey => {
    return state[state.estimorder].jobs[jobKey].complaint;
  },
  [_getters.GET_CAUSE]: state => jobKey => {
    return state[state.estimorder].jobs[jobKey].cause;
  },
  [_getters.GET_CORRECTION]: state => jobKey => {
    return state[state.estimorder].jobs[jobKey].correction;
  },
  [_getters.GET_JOB_REPAIR_REASON_ID]: state => jobKey => {
    return state[state.estimorder].jobs[jobKey].repairReasonId;
  },
  [_getters.GET_VMRS_CODE_BY_JOB]: state => jobKey => {
    return state[state.estimorder].jobs[jobKey].vmrsCode || {};
  },
  [_getters.GET_TECH_REMARKS]: state => jobKey => {
    return state[state.estimorder].jobs[jobKey].techRemarks;
  },
  [_getters.GET_PARTS]: state => jobKey => {
    return state[state.estimorder].jobs[jobKey].details.parts;
  },
  [_getters.GET_REQUESTED_PART]: state => {
    return state.requestedPart;
  },
  [_getters.GET_MISC_LINES]: state => jobKey => {
    return state[state.estimorder].jobs[jobKey].details.miscLines;
  },
  [_getters.GET_SUBLET_LINES]: state => jobKey => {
    return state[state.estimorder].jobs[jobKey].details.subletLines;
  },
  [_getters.GET_COMMENT_LINES]: state => jobKey => {
    return state[state.estimorder].jobs[jobKey].details.commentLines;
  },
  [_getters.GET_INSPECTIONS]: state => jobKey => {
    return state[state.estimorder].jobs[jobKey].details.inspections;
  },
  [_getters.HAS_LABOR_OR_SUBLET]: state => jobKey => {
    const laborCount = state[state.estimorder].jobs[jobKey].details.laborLines
      ? state[state.estimorder].jobs[jobKey].details.laborLines.length
      : 0;
    const subletCount = state[state.estimorder].jobs[jobKey].details.subletLines
      ? state[state.estimorder].jobs[jobKey].details.subletLines.length
      : 0;
    return laborCount + subletCount > 1;
  },
  [_getters.GET_SHOW_DETAILS]: state => jobKey => {
    if (!state[state.estimorder].jobs[jobKey]) return true;
    return state[state.estimorder].jobs[jobKey]._showDetails;
  },
  [_getters.HAS_CHANGED_JOB_STATUS]: state => jobKey => {
    if (state['old' + state.estimorder].jobs[jobKey]) {
      return (
        state[state.estimorder].jobs[jobKey].jobOperationStatusId !==
        state['old' + state.estimorder].jobs[jobKey].jobOperationStatusId
      );
    }
    return false;
  },

  // Asides
  [_getters.GET_WORKFLOW]: state => {
    return state[state.estimorder].workflow;
  },
  [_getters.GET_CURRENT_ASSIGNMENT]: state => {
    return state[state.estimorder].workflow.currentAssignment;
  },
  [_getters.GET_REQUEST_APPROVAL]: state => {
    return state.estimate.requestApproval;
  },
  [_getters.GET_DRIVER_INFO]: state => {
    return state[state.estimorder].driverInfo;
  },
  [_getters.GET_UNIT_READY]: state => {
    return state[state.estimorder].unitReady;
  },
  [_getters.GET_APPOINTMENT_UNIT_SERVICE_ORDERS]: state => {
    return state.appointmentUnitServiceOrders;
  },
  [_getters.GET_IS_AB_CUSTOMER]: state => {
    return state[state.estimorder].customer.isABCustomer;
  },
  [_getters.HAS_IN_SCOPE_LIFTGATE_JOB]: state => {
    return state.hasInScopeLiftgateJob;
  },
  // Lookups
  [_getters.GET_DEFAULT_TECH]: state => {
    return state.techList.find(tech => tech.isDefault);
  },
  [_getters.GET_CALCULATING_TAXES]: state => {
    return state.calculatingTaxes;
  },
  [_getters.GET_TECH_LIST]: state => {
    return state.techList;
  }
};
