import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, UnitLocationHistoryGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, UnitLocationHistoryGetters);

export default {
  [_getters.GET_UNIT_LOCATION_HISTORY]: state => {
    return state.locationHistory;
  }
};
