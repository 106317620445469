<template>
  <b-row>
    <div v-if="contractStatus.attributeId && !$isCustomer" class="mr-4">
      <label class="col-head">{{ contractStatus.label }}:</label>
      <h4>{{ contractStatus.value }}</h4>
    </div>
    <div v-show="department.attributeId && !$isCustomer" class="mr-4">
      <label class="col-head">{{ department.label }}:</label>
      <h4>{{ department.value }}</h4>
    </div>
  </b-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CustomerAttributes',
  data() {
    return {
      ContractStatusAttributeLabel: 'Unit Contract Status',
      DepartmentAttributeLabel: 'Department'
    };
  },
  computed: {
    ...mapState(['unit']),

    contractStatus() {
      if (!this.unit.unit.attributes) {
        return {};
      }
      var contractStatus = this.unit.unit.attributes.find(
        attribute => attribute.label === this.ContractStatusAttributeLabel
      );
      return contractStatus || {};
    },
    department() {
      if (!this.unit.unit.attributes) {
        return {};
      }
      var department = this.unit.unit.attributes.find(attribute => attribute.label === this.DepartmentAttributeLabel);
      return department || {};
    }
  }
};
</script>
