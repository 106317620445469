export const getDefaultState = () => {
  return {
    operationsForecast: [],
    searchFilter: '',
    sortBy: '',
    filteredItems: null,
    currentPage: 1,
    totalUnits: 0,
    totalRows: 0,
    asideIsBusy: false,
    resultTableColumns: [],
    pageSize: 50,
    operationsForecastUniqueUnits: [],
    showBulkServiceRequestModal: false
  };
};

export default {
  ...getDefaultState()
};
