<template>
  <transition name="fade">
    <div v-show="!hidden">
      <div class="d-flex">
        <div class="my-1 alert-warning border border-warning-dark rounded w-100" role="alert">
          <button v-if="canClose" type="button" class="btn btn-sm close m-1" aria-label="Close" @click="closeWarning()">
            <font-awesome-icon icon="times" />
          </button>
          <div class="p-1"><slot></slot></div>
        </div>
        <span v-if="!fullWidth" class="required-placeholder" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'WarningAlert',
  props: {
    canClose: {
      type: Boolean,
      default: true
    },
    fullWidth: Boolean
  },
  data() {
    return {
      hidden: false
    };
  },
  methods: {
    closeWarning() {
      this.hidden = true;
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
