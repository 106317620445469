export const namespace = 'config/';

export const ConfigGetters = {
  GET_CONFIG: namespace + 'getConfig'
};

export const ConfigActions = {};

export const ConfigMutations = {
  SET_CONFIG: namespace + 'setConfig'
};
