export const RootGetters = {
  GET_SIDEBAR: 'getSidebar',
  GET_ONLINE: 'getOnline',
  GET_OFFLINE: 'getOffline',
  IS_SMALL_SCREEN: 'isSmallScreen'
};

export const RootMutations = {
  SET_SIDEBAR: 'setSidebar',
  SET_ONLINE: 'setOnline',
  SET_SCREEN_WIDTH: 'setScreenWidth'
};
