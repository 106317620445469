function isNumber($event) {
  const keyNum = Number($event.key);

  if (isNaN(keyNum) || !/[0-9]/.test(keyNum)) {
    $event.preventDefault();
  }
}

function isDecimal($event, allowNegative) {
  const prevValue = $event.target.value;
  const keyNum = Number($event.key);

  var regex = /[0-9.]/;
  if (allowNegative) regex = /[0-9.-]/;

  if (
    ($event.key != '.' && $event.key != '-' && isNaN(keyNum)) ||
    !regex.test($event.key) ||
    ($event.key === '.' && prevValue.indexOf('.') != -1) ||
    (prevValue.indexOf('.') != -1 && !/\.\d?$/.test(prevValue)) ||
    (allowNegative && $event.key === '-' && prevValue.indexOf('-') != -1)
  ) {
    $event.preventDefault();
  }
}

export default {
  isNumber,
  isDecimal
};
