<template>
  <vams-collapse :ref="`collapse-${jobKey}`" title="View Complaint, Cause, Correction">
    <div class="d-flex flex-column">
      <b-form-group label="Reason for Repair" label-class="col-head" :label-for="`rfr_${_uid}`">
        <div v-if="readonly" :id="`rfr_${_uid}`" class="pl-0">{{ reasonForRepair || NA }}</div>
        <v-select
          v-else
          :id="`rfr_${_uid}`"
          v-model="repairReasonId"
          :options="selectableRepairReasons"
          :reduce="rr => rr.id"
          label="repairReason"
          class="vw-33"
          :clearable="false"
        >
          <template #option="reas">
            {{ reas.repairReason }}
          </template>
          <template #selected-option="reas">
            {{ reas.repairReason }}
          </template>
        </v-select>
        <div>
          <b-alert :show="showMismatchAlert" class="alert alert-warning d-inline-block vw-33">
            <div>{{ repairReasonWarningMessage }}</div>
          </b-alert>
        </div>
      </b-form-group>
      <b-form-group label="Complaint" label-class="col-head" :label-for="`complaint_${_uid}`">
        <div v-if="readonly" :id="`complaint_${_uid}`" class="pl-0">{{ complaint || NA }}</div>
        <b-textarea
          v-else
          :id="`complaint_${_uid}`"
          v-model="complaint"
          class="overflow-auto"
          size="sm"
          rows="1"
          max-rows="4"
          @keyup="charComplaintCharacterCount()"
        />
        <span class="col-head">{{ totalComplaintCharacter }} / 2000</span>
      </b-form-group>

      <b-form-group label="Cause" label-class="col-head" :label-for="`cause_${_uid}`">
        <div v-if="readonly" :id="`cause_${_uid}`" class="pl-0">{{ cause || NA }}</div>
        <b-textarea
          v-else
          :id="`cause_${_uid}`"
          v-model="cause"
          class="overflow-auto"
          size="sm"
          rows="1"
          max-rows="4"
          @keyup="charCauseCharacterCount()"
        />
        <span class="col-head">{{ totalCauseCharacter }} / 255</span>
      </b-form-group>

      <b-form-group label="Correction" label-class="col-head" :label-for="`correction_${_uid}`">
        <div v-if="readonly" :id="`correction_${_uid}`" class="pl-0">{{ correction || NA }}</div>
        <b-textarea
          v-else
          :id="`correction_${_uid}`"
          v-model="correction"
          class="overflow-auto"
          size="sm"
          rows="1"
          max-rows="4"
          @keyup="charCorrectionCharacterCount()"
        />
        <span class="col-head">{{ totalCorrectionCharacter }} / 4000</span>
      </b-form-group>
    </div>
  </vams-collapse>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { UnitGetters } from '@/shared/store/unit/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { MountedEquipmentGetters } from '@/shared/store/unit/mounted-equipment/types';
import { SCOPES, LIFTGATE_JOB_ID } from '@/shared/store/service-order/state';

import CollapseComponent from '@/shared/components/ui/CollapseComponent';
import vSelect from 'vue-select';

export default {
  name: 'ThreeCsInputComponent',
  components: {
    'vams-collapse': CollapseComponent,
    vSelect
  },
  props: {
    jobKey: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    job: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      SCOPES: SCOPES,
      isMismatch: false,
      NA: 'N/A',
      repairReasonWarningMessage: null
    };
  },
  computed: {
    ...mapGetters([
      ServiceOrderGetters.GET_COMPLAINT,
      ServiceOrderGetters.GET_CAUSE,
      ServiceOrderGetters.GET_CORRECTION,
      ServiceOrderGetters.GET_JOB_REPAIR_REASON_ID
    ]),
    ...mapGetters({
      repairReasonsMap: LookupGetters.GET_REPAIR_REASONS_MAP,
      repairReasonsList: LookupGetters.GET_REPAIR_REASONS_LIST,
      customerSpecificRepairReasons: ServiceOrderGetters.GET_CUSTOMER_SPECIFIC_REPAIR_REASONS,
      isUnitGuaranteed: UnitGetters.IS_UNIT_GUARANTEED,
      isUnitNonGuaranteed: UnitGetters.IS_UNIT_NON_GUARANTEED,
      hasGuaranteedLiftgate: MountedEquipmentGetters.HAS_GUARANTEED_LIFTGATE
    }),
    totalComplaintCharacter: {
      get() {
        return this.complaint ? this.complaint.length : 0;
      },
      set(value) {
        return value;
      }
    },
    totalCauseCharacter: {
      get() {
        return this.cause ? this.cause.length : 0;
      },
      set(value) {
        return value;
      }
    },
    totalCorrectionCharacter: {
      get() {
        return this.correction ? this.correction.length : 0;
      },
      set(value) {
        return value;
      }
    },
    complaint: {
      get() {
        return this[ServiceOrderGetters.GET_COMPLAINT](this.jobKey);
      },
      set(value) {
        this[ServiceOrderMutations.SET_COMPLAINT]({ jobKey: this.jobKey, complaint: value });
      }
    },
    cause: {
      get() {
        return this[ServiceOrderGetters.GET_CAUSE](this.jobKey);
      },
      set(value) {
        this[ServiceOrderMutations.SET_CAUSE]({ jobKey: this.jobKey, cause: value });
      }
    },
    correction: {
      get() {
        return this[ServiceOrderGetters.GET_CORRECTION](this.jobKey);
      },
      set(value) {
        this[ServiceOrderMutations.SET_CORRECTION]({ jobKey: this.jobKey, correction: value });
      }
    },
    repairReasonId: {
      get() {
        return this[ServiceOrderGetters.GET_JOB_REPAIR_REASON_ID](this.jobKey);
      },
      set(repairReasonId) {
        this[ServiceOrderMutations.SET_JOB_REPAIR_REASON_ID]({ jobKey: this.jobKey, repairReasonId });
      }
    },
    reasonForRepair() {
      var reason = this.repairReasonsMap[this.repairReasonId];
      return reason?.repairReason;
    },
    activeRepairReasons() {
      return this.repairReasonsList.filter(x => !x.inactive);
    },
    activeCustomerSpecificRepairReasons() {
      var activeReasons = this.customerSpecificRepairReasons.filter(x => !x.inactive);

      //If currently-selected 'repair reason' is not actually a selectable option, we still want to
      //display it - but not let the user select it again.
      if (
        activeReasons &&
        activeReasons.length > 0 &&
        this.repairReason &&
        !activeReasons.some(reason => reason.id === this.repairReason.id)
      ) {
        activeReasons.push(this.repairReason);
      }

      return activeReasons;
    },
    selectableRepairReasons() {
      if (this.activeCustomerSpecificRepairReasons && this.activeCustomerSpecificRepairReasons.length > 0) {
        return this.activeCustomerSpecificRepairReasons;
      } else {
        return this.activeRepairReasons;
      }
    },
    isLiftgateJob() {
      return this.job.jobId === LIFTGATE_JOB_ID;
    },
    showMismatchAlert() {
      return this.isMismatch && !this.$isCustomer && !this.readonly;
    }
  },
  watch: {
    job: {
      handler() {
        if (this.job.salesTypeId === this.SCOPES.IN_SCOPE || this.job.salesTypeId === this.SCOPES.OUT_SCOPE) {
          this.mismatchedSalesTypeAndReasonForRepair();
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.mismatchedSalesTypeAndReasonForRepair();
  },
  methods: {
    ...mapMutations([
      ServiceOrderMutations.SET_COMPLAINT,
      ServiceOrderMutations.SET_CAUSE,
      ServiceOrderMutations.SET_CORRECTION,
      ServiceOrderMutations.SET_JOB_REPAIR_REASON_ID
    ]),
    charComplaintCharacterCount: function () {
      this.totalComplaintCharacter = this.complaint ? this.complaint.length : 0;
    },
    charCauseCharacterCount: function () {
      this.totalCauseCharacter = this.cause ? this.cause.length : 0;
    },
    charCorrectionCharacterCount: function () {
      this.totalCorrectionCharacter = this.correction ? this.correction.length : 0;
    },
    mismatchedSalesTypeAndReasonForRepair: function () {
      let scopeList = Object.values(this.repairReasonsMap)
        .map(x => x.description)
        .filter((value, index, self) => self.indexOf(value) === index);
      this.repairReasonWarningMessage = `Expected Sales Type is ${
        this.repairReasonsMap[this.repairReasonId]?.description
      }`;
      if (this.hasGuaranteedLiftgate && this.isLiftgateJob) {
        this.isMismatch =
          this.reasonForRepair != null &&
          this.repairReasonsMap[this.repairReasonId]?.description != this.job.salesTypeId &&
          scopeList.indexOf(this.job.salesTypeId) > 0;
      }
      if (this.isUnitGuaranteed && !this.isLiftgateJob) {
        this.isMismatch =
          this.reasonForRepair != null &&
          this.repairReasonsMap[this.repairReasonId]?.description != this.job.salesTypeId &&
          scopeList.indexOf(this.job.salesTypeId) > 0;
      }
    }
  }
};
</script>
