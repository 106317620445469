export const getDefaultState = () => {
  return {
    attachments: [],
    dbAttachments: [],
    warrantyAsideAttachments: []
  };
};

export default {
  ...getDefaultState()
};
