<template>
  <b-modal :id="id" :ref="id" :title="title" header-class="alert-warning">
    <p>{{ warningText }}</p>
    <template #modal-footer="{ ok, cancel }">
      <b-button v-if="!hideContinueBtn" size="sm" variant="primary" @click="ok">
        {{ continueBtnText }}
      </b-button>
      <b-button v-if="!hideCancelBtn" size="sm" @click="cancel">
        {{ cancelBtnText }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'WarningModal',
  props: {
    id: {
      type: String,
      default: 'warningModal'
    },
    title: {
      type: String,
      default: 'Warning'
    },
    continueBtnText: {
      type: String,
      default: 'Continue'
    },
    cancelBtnText: {
      type: String,
      default: 'Close'
    },
    warningText: {
      type: String,
      default: ''
    },
    hideContinueBtn: Boolean,
    hideCancelBtn: Boolean
  },
  methods: {
    /**
     * @param that - scope of component that is using the modal
     * @param save - async function that saves data
     */
    show(that, save) {
      this.$bvModal.show(this.id);
      this.$nextTick(() => {
        this.$refs[this.id].$once('hide', bvEvent => {
          this.$refs[this.id].$once('hidden', async () => handleHide(bvEvent, this, save));
        });
      });
    }
  }
};
async function handleHide(bvEvent, that, save) {
  if (bvEvent.trigger === 'ok' && save != null) {
    await save();
  }
}
</script>
