<template>
  <div class="py-2">
    <b-overlay :show="isLoading">
      <vams-collapse title="Deferred Work" :visible="visible">
        <b-table
          :items="deferredJobs"
          :fields="fields"
          table-variant="blue"
          thead-tr-class="text-small"
          tbody-tr-class="bg-half-alpha"
        >
          <template #head(selected)>
            <div class="text-center">
              <input v-model="selectAll" type="checkbox" />
            </div>
          </template>

          <template #cell(selected)="{ item }">
            <div class="text-center">
              <input v-show="!item.isAssigned" v-model="selectedJobs" type="checkbox" :value="item" />
              <font-awesome-icon v-show="item.isAssigned" icon="check" class="text-success" />
            </div>
          </template>

          <template #cell(deferredByEmployeeName)="{ item }">
            <div class="text-left d-flex flex-column">
              {{ item.deferredByEmployeeName }}
            </div>
          </template>

          <template #cell(deferredByEmployeeDate)="{ item }">
            <div class="text-left d-flex flex-column">
              {{ item.deferredByEmployeeDate | date }}
            </div>
          </template>

          <template #cell(jobSubtotal)="{ item }">
            <div class="text-left d-flex flex-column">
              {{ item.jobSubtotal | currency }}
            </div>
          </template>

          <template #cell(jobSlsId)="{ item }">
            <div class="text-left d-flex flex-column">
              {{ item.jobSlsId }}
            </div>
          </template>

          <template #cell(actions)="{ item }">
            <b-button size="xsm" @click="confirmDelete(item)"><font-awesome-icon icon="trash-alt" /></b-button>
          </template>
        </b-table>
      </vams-collapse>
      <warning-modal
        id="DeferredJobDeleteWarning"
        ref="DeferredJobDeleteWarning"
        title="Warning"
        :warning-text="deleteText"
        continue-btn-text="Yes, Remove"
        cancel-btn-text="Cancel"
      ></warning-modal>
    </b-overlay>
  </div>
</template>

<script>
// components
import CollapseComponent from '@/shared/components/ui/CollapseComponent';
import WarningModal from '@/shared/components/WarningModal';

// vuex
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { ServiceOrderGetters } from '@/shared/store/service-order/types';
import { UnitGetters } from '@/shared/store/unit/types';
import { DeferredJobGetters, DeferredJobActions, DeferredJobMutations } from '@/shared/store/deferred-job/types';

export default {
  name: 'ServiceOrderAddDeferredJobs',
  components: {
    'warning-modal': WarningModal,
    'vams-collapse': CollapseComponent
  },
  props: {
    visible: Boolean()
  },
  data: function () {
    return {
      deleteText: 'Are you sure you want to remove this job?',
      fields: [
        { key: 'selected', label: '', sortable: false, tdClass: 'align-middle', thStyle: 'width: 25px' },
        { key: 'jobId', label: 'Job ID', sortable: true, tdClass: 'align-middle' },
        { key: 'name', label: 'Complaint', sortable: true, tdClass: 'align-middle' },
        { key: 'deferredByEmployeeName', label: 'Deferred By', sortable: true, tdClass: 'align-middle' },
        { key: 'deferredByEmployeeDate', label: 'Deferred Date', sortable: true, tdClass: 'align-middle' },
        { key: 'jobSlsId', label: 'Estimate ID', sortable: true, tdClass: 'align-middle' },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      deferredJobs: DeferredJobGetters.GET_DEFERRED_JOBS,
      selectedDeferredJobs: DeferredJobGetters.GET_SELECTED_DEFERRED_JOBS,
      unit: UnitGetters.GET_UNIT,
      serviceOrderId: ServiceOrderGetters.GET_SERVICE_ORDER_ID
    }),
    selectedJobs: {
      get() {
        return this.selectedDeferredJobs;
      },
      set(value) {
        this[DeferredJobMutations.SET_SELECTED_DEFERRED_JOBS](value);
      }
    },
    selectAll: {
      get() {
        return this.selectedJobs.length == this.deferredJobs.length;
      },
      set(value) {
        this.selectedJobs = [];

        if (value) {
          this.deferredJobs.forEach(item => {
            this.selectedJobs.push(item);
          });
        }
      }
    }
  },
  methods: {
    ...mapActions([DeferredJobActions.DELETE_DEFERRED_JOB, DeferredJobActions.FETCH_DEFERRED_JOBS]),
    ...mapMutations([DeferredJobMutations.SET_SELECTED_DEFERRED_JOBS]),
    isAssigned(job) {
      const jobIds = Object.values(this.selectedJobs).map(job => job.jobId);
      return jobIds.includes(job.jobId);
    },
    confirmDelete: function (item) {
      this.$refs.DeferredJobDeleteWarning.show(this, this.deleteItem.bind(this, item));
    },
    deleteItem: async function (item) {
      this.isLoading = true;
      await this[DeferredJobActions.DELETE_DEFERRED_JOB]({ item: item, unitId: this.unit.unitId });
      await this[DeferredJobActions.FETCH_DEFERRED_JOBS]({
        unitId: this.$route.params.unitId,
        params: { serviceOrderId: this.serviceOrderId }
      });
      this.isLoading = false;
    }
  }
};
</script>
