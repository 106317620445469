export const namespace = 'unit/unitLocationHistory/';

export const UnitLocationHistoryGetters = {
  GET_UNIT_LOCATION_HISTORY: namespace + 'getUnitLocationHistory'
};

export const UnitLocationHistoryActions = {
  FETCH_UNIT_LOCATION_HISTORY: namespace + 'fetchUnitLocationHistory'
};

export const UnitLocationHistoryMutations = {
  SET_UNIT_LOCATION_HISTORY: namespace + 'setUnitLocationHistory'
};
