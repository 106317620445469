<template>
  <div>
    <h5>Driver Info</h5>
    <h6 class="text-uppercase">{{ estimorderDisplayText }} ID: {{ serviceOrderId }}</h6>

    <div>
      <b-row no-gutters>
        <b-col>
          <span v-if="!readonly" class="required-legend float-right p-0">* Required</span>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <b-form-group label="Name:" label-class="col-head" label-for="name">
            <div v-if="readonly" class="col-form-label">
              {{ name || NA }}
            </div>
            <b-input-group v-else>
              <b-form-input v-model="name" :state="!$v.name.$error ? null : false" class="rounded"></b-form-input>
              <span class="required-asterisk">*</span>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <b-form-group label="Phone:" label-class="col-head" label-for="phone">
            <div v-if="readonly" class="col-form-label">
              {{ phoneNumber | phone | nullValueToNA }}
            </div>
            <b-input-group v-else>
              <phone-input v-model="phoneNumber" :state="!$v.phoneNumber.$error ? null : false" class="rounded" />
              <span class="required-asterisk">*</span>
            </b-input-group>
            <div v-show="$v.phoneNumber.$error && !$v.phoneNumber.length" class="error">
              Enter a valid Phone Number of 7 or 10 digits.
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <b-form-group label="Email:" label-class="col-head" label-for="email">
            <div v-if="readonly" class="col-form-label">
              {{ email || NA }}
            </div>
            <b-input-group v-else>
              <b-form-input v-model="email" :state="!$v.email.$error ? null : false" class="rounded"></b-form-input>
              <span class="required-asterisk">*</span>
            </b-input-group>
            <span v-if="!readonly" class="note float-right required-placeholder">Phone and/or Email is required.</span>
            <div
              v-show="($v.phoneNumber.$error && !$v.phoneNumber.required) || ($v.email.$error && !$v.email.required)"
              class="error"
            >
              Enter a valid Phone Number or Email Address.
            </div>
            <div v-show="$v.email.$error && !$v.email.email" class="error">Enter a valid Email Address.</div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <b-form-group label="Comment" label-class="col-head" label-for="comment" class="required-placeholder">
            <div v-if="readonly" class="col-form-label">
              {{ comment || NA }}
            </div>
            <b-textarea v-else id="comment" v-model="comment" class="overflow-auto" size="sm" rows="3" max-rows="4" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button v-if="!readonly" variant="primary" :disabled="saving" @click="save">
        <div v-show="saving">
          <b-spinner small></b-spinner>
          Saving...
        </div>
        <div v-show="!saving">Save</div>
      </b-button>

      <div class="py-4" />
    </div>
  </div>
</template>

<script>
// Components
import PhoneInput from '@/shared/components/ui/PhoneInput';
// Vuex
import { UnitGetters } from '@/shared/store/unit/types';
import { ServiceOrderActions, ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import { required, requiredIf, email } from 'vuelidate/lib/validators';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';

import UserAccessMixin from '@/shared/mixins/UserAccessMixin';

export default {
  name: 'ServiceOrderDriverinfo',
  components: {
    'phone-input': PhoneInput
  },
  mixins: [UserAccessMixin],
  data() {
    return {
      NA: 'N/A',
      saving: false
    };
  },
  validations: {
    name: {
      required
    },
    phoneNumber: {
      required: requiredIf(function () {
        return !this.email;
      }),
      length: value => !value || value.length == 7 || value.length == 10
    },
    email: {
      required: requiredIf(function () {
        return !this.phoneNumber;
      }),
      email
    }
  },
  computed: {
    ...mapGetters({
      unit: UnitGetters.GET_UNIT,
      estimorderDisplayText: ServiceOrderGetters.GET_ESTIMORDER_DISPLAY_TEXT,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      driverInfo: ServiceOrderGetters.GET_DRIVER_INFO,
      branchId: ServiceOrderGetters.GET_BRANCH_ID
    }),
    readonly() {
      return this.$store.state.serviceOrder.serviceOrder.invoiced || !this.branchIsAccessible;
    },
    unitId() {
      return this.unit.unitId;
    },
    serviceOrderId() {
      return this.serviceOrder.serviceOrderId;
    },
    name: {
      get() {
        return this.driverInfo.name;
      },
      set(value) {
        this.setData('name', value);
      }
    },
    phoneNumber: {
      get() {
        return this.driverInfo.phoneNumber;
      },
      set(value) {
        this.setData('phoneNumber', value);
      }
    },
    email: {
      get() {
        return this.driverInfo.email;
      },
      set(value) {
        this.setData('email', value);
      }
    },
    comment: {
      get() {
        return this.driverInfo.comment;
      },
      set(value) {
        this.setData('comment', value);
      }
    }
  },
  async created() {
    this.loading = true;
    await this[ServiceOrderActions.FETCH_DRIVER_INFO]();
    this.loading = false;
  },
  methods: {
    ...mapActions([ServiceOrderActions.FETCH_DRIVER_INFO, ServiceOrderActions.UPDATE_DRIVER_INFO]),
    ...mapMutations([ServiceOrderMutations.SET_PROP]),
    async save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        ErrorService.createErrorToast(this, 'Error saving Driver Info. See indicated fields below.');
        return;
      }
      try {
        this.saving = this.loading = true;
        await this[ServiceOrderActions.UPDATE_DRIVER_INFO]();

        this.$v.$reset();
        SuccessService.createSuccessToast(this.$root, `Driver Info saved successfully.`);
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to save Driver Info.');
      } finally {
        this.saving = this.loading = false;
      }
    },
    setData(key, newValue) {
      let value = { ...this.driverInfo };
      value[key] = newValue;
      this[ServiceOrderMutations.SET_PROP]({ key: 'driverInfo', value });
    }
  }
};
</script>
