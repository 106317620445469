<template>
  <div>
    <b-row>
      <b-col lg="6" sm="12" class="ml-auto">
        <section class="mr-neg30">
          <aside class="so-panel">
            <div class="float-right mb-2 mr-2 mt-neg10">
              <button type="button" class="close" aria-label="Close" @click="closeServiceOrderHistory()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="clearfix"></div>
            <div class="w-50 d-inline-block">
              <h5 v-if="header">{{ header }}</h5>
              <h5 v-else-if="markComplete">Mark Operation Complete</h5>
              <h5 v-else>{{ status }} Service Orders</h5>
            </div>
            <b-overlay :show="loading">
              <unit-summary-component></unit-summary-component>

              <b-form
                v-if="markComplete"
                @submit.stop.prevent="showServiceOrders ? completeSelectedServiceOrder() : completeManualServiceOrder()"
              >
                <h2 class="d-inline-block w-auto font-weight-bold">{{ unitStandardOperation.name }}</h2>
                <b-button
                  v-if="allowManual"
                  variant="secondary"
                  class="float-right mr-2"
                  @click="toggleServiceOrderHistory"
                >
                  <span v-if="showServiceOrders" class="text-icon">+</span>
                  <span v-else class="text-icon">-</span>
                  Manual
                </b-button>

                <div class="float-right mr-2">
                  <div v-show="isBusy">
                    <b-button variant="primary" disabled class="ml-2">
                      <b-spinner small></b-spinner>
                      COMPLETING...
                    </b-button>
                  </div>
                  <div v-show="!isBusy">
                    <b-button type="submit" variant="primary" class="ml-2">COMPLETE</b-button>
                  </div>
                </div>

                <hr />

                <unit-service-order-history v-show="showServiceOrders" v-model="serviceOrderWithJob" selectable aside />

                <unit-service-order-history-manual
                  v-if="allowManual"
                  v-show="!showServiceOrders"
                  ref="unitServiceOrderHistoryManual"
                  v-model="manual"
                />
              </b-form>
              <div v-else>
                <h2 v-if="subheader" class="d-inline-block w-auto font-weight-bold">{{ subheader }}</h2>
                <slot />
                <hr />

                <unit-service-order-history
                  v-model="serviceOrderWithJob"
                  aside
                  v-bind="{ selectable, status, jobsOnly }"
                />
              </div>
            </b-overlay>
          </aside>
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
//components
import UnitServiceOrderHistoryComponent from './UnitServiceOrderHistoryComponent';
import UnitServiceOrderHistoryManualComponent from './UnitServiceOrderHistoryManualComponent';
import UnitSummaryComponent from '@/shared/components/unit/UnitSummaryComponent';
// vuex
import { UnitActions, UnitGetters } from '@/shared/store/unit/types';
import { UserGetters } from '@/shared/store/user/types';
import Cloner from '@/shared/helpers/cloner';
import { mapActions, mapState, mapGetters } from 'vuex';
// helpers
import ErrorService from '@/shared/services/ErrorService';
import DataHelper from '@/shared/helpers/data-helper';

export default {
  name: 'UnitServiceOrderHistoryAside',
  components: {
    'unit-summary-component': UnitSummaryComponent,
    'unit-service-order-history': UnitServiceOrderHistoryComponent,
    'unit-service-order-history-manual': UnitServiceOrderHistoryManualComponent
  },
  props: {
    markComplete: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: null
    },
    subheader: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: 'Open'
    },
    loading: {
      type: Boolean,
      default: false
    },
    jobsOnly: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      default: null
    },
    value: Object()
  },
  data() {
    return {
      showServiceOrders: true,
      manual: {
        lastCompletedDate: null,
        description: null,
        engineHours: null,
        mileage: null
      },
      isBusy: false
    };
  },
  computed: {
    ...mapState('unit', ['unitStandardOperation']),
    ...mapGetters({
      standardOperation: UnitGetters.GET_UNIT_STANDARD_OPERATION,
      authorizeRole: UserGetters.AUTHORIZE_ROLE
    }),
    serviceOrderWithJob: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    manualForm() {
      return this.$refs['unitServiceOrderHistoryManual'].$v.value;
    },
    allowManual() {
      return this.authorizeRole(['CSR', 'CSM', 'RegionalCSM']);
    }
  },
  beforeDestroy() {
    this[UnitActions.HIDE_SERVICE_ORDER_HISTORY_ASIDE]();
  },
  methods: {
    ...mapActions([UnitActions.HIDE_SERVICE_ORDER_HISTORY_ASIDE, UnitActions.UPDATE_UNIT_STANDARD_OPERATION]),
    closeServiceOrderHistory: function () {
      if (this.onClose != null) {
        this.onClose();
      } else {
        this.serviceOrderWithJob = {};
        this[UnitActions.HIDE_SERVICE_ORDER_HISTORY_ASIDE]();
      }
    },
    toggleServiceOrderHistory() {
      this.manualForm.$reset();
      this.showServiceOrders = !this.showServiceOrders;
    },
    async completeSelectedServiceOrder() {
      if (this.emptyObject(this.serviceOrderWithJob)) {
        ErrorService.createErrorToast(this, `Please select an operation to mark complete.`);
        return;
      }

      const { serviceOrder, selectedJob } = this.serviceOrderWithJob;
      const standardOperation = Cloner.deepClone(this.standardOperation);

      // TODO: Do we need to deal with transforming empty to nulls here?
      standardOperation.lastCompletedDate = selectedJob.dateCompleted;
      standardOperation.lastCompletedMileage = serviceOrder.mileage;
      standardOperation.lastCompletedEngineHours = serviceOrder.engineHours;
      standardOperation.lastCompletedServiceOrderId = selectedJob.serviceOrderId;
      standardOperation.lastCompletedOperationId = selectedJob.operationId;

      await this.completeServiceOrder(standardOperation);
    },
    async completeManualServiceOrder() {
      this.manualForm.$touch();
      if (!this.manualForm.$anyError) {
        const standardOperation = {
          lastCompletedDate: new Date(Date.parse(this.manual.lastCompletedDate)),
          lastCompletedMileage: DataHelper.transformEmptyToNull(this.manual.mileage),
          lastCompletedEngineHours: DataHelper.transformEmptyToNull(this.manual.engineHours),
          description: this.manual.description
        };

        await this.completeServiceOrder(standardOperation);
      } else {
        ErrorService.saveError(this, ['Please see fields indicated below']);
        this.error = true;
      }
    },
    async completeServiceOrder(standardOperation) {
      try {
        this.isBusy = true;
        await this[UnitActions.UPDATE_UNIT_STANDARD_OPERATION](standardOperation);
        this.serviceOrderWithJob = {};
        this.$emit('completed');
      } catch {
        this.isBusy = false;
        ErrorService.unknownSaveError(this);
      } finally {
        this.isBusy = false;
      }
    }
  }
};
</script>
