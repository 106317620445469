import { mapGetters } from 'vuex';
import { UserGetters } from '@/shared/store/user/types';

export default {
  computed: {
    ...mapGetters({
      user: UserGetters.GET_USER_PROFILE
    }),
    branchIsAccessible() {
      const myBranchIds = this.user.employeeBranches.map(branch => branch.branchId);
      return myBranchIds.includes(this.branchId);
    }
  }
};
