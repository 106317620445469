import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, UnitLocationHistoryMutations } from './types';

const _mutations = namespaceHelper.removeNamespace(namespace, UnitLocationHistoryMutations);

export default {
  [_mutations.SET_UNIT_LOCATION_HISTORY](state, payload) {
    state.locationHistory = payload;
  }
};
